using H.Skeepy.Click.Web.UI.Common;
using H.Skeepy.Core.Infrastructure;
using H.Skeepy.Core.Model;
using H.Skeepy.Core.Operations;
using H.Skeepy.Core.Operations.Storage;
using Retyped;
using System;
using System.Linq;
using System.Threading.Tasks;

namespace H.Skeepy.Click.Web.UI.Components.LocalStorage
{
    public class PinStorageService : SyncedStorageServiceBase<Pin, Guid>, ImAPinStorageService
    {
        #region Construct
        public override void ReferDependencies(ImADependencyPoolContainer dependencyPoolContainer)
        {
            base.ReferDependencies(dependencyPoolContainer);
            storageSyncer.Use((Func<Guid,Task<Pin>>)Load);
        }
#endregion
protected override Guid GetIdFor(Pin item)
{
    return item.ID;
}
        public Task<long> Count(PinFilterRequest request)
        {
            return base.Count(request);
        }

        public Task<Pin> Load(Guid id)
        {
            return base.Load(storage.Pins, id);
        }

        public Task Save(Pin pin)
        {
            return base.Save(storage.Pins, pin);
        }

        public Task<Pin[]> Search(PinFilterRequest request)
        {
            return base.Search(request);
        }

        public Task Delete(Guid id)
        {
            return base.Delete(storage.Pins, id);
        }

        public Task<long> DeleteMany(PinFilterRequest request)
        {
            return base.DeleteMany(request);
        }

        protected override dexie.Dexie.Collection<object, object> ApplyFilter(object filter)
        {
            PinFilterRequest filterRequest = filter.As<PinFilterRequest>();

            dexie.Dexie.Collection<object, object> filteredCollection;

            if (filterRequest == null)
                return storage.Pins.toCollection();

            if (filterRequest.IDs != null)
            {
                filteredCollection = storage.Pins.where("ID")
                    .anyOfIgnoreCase(System.Linq.Enumerable.Select<Guid,string>(filterRequest.IDs,(Func<Guid,string>)(x => x.ToString())).ToArray())
                    ;
            }
            else
            {
                filteredCollection = storage.Pins.toCollection();
            }

            if (filterRequest.FromInclusive != null)
            {
                filteredCollection = filteredCollection.and((dexie.Dexie.Collection<object,object>.andFn)(x => long.Parse((string)x["HappenedAtTicks"]) >= filterRequest.FromInclusive.Value.Ticks));
            }

            if (filterRequest.ToInclusive != null)
            {
                filteredCollection = filteredCollection.and((dexie.Dexie.Collection<object,object>.andFn)(x => long.Parse((string)x["HappenedAtTicks"]) <= filterRequest.ToInclusive.Value.Ticks));
            }

            return filteredCollection;
        }
    }
}
