using H.Skeepy.Core.Infrastructure;

namespace H.Skeepy.Core.Operations.Storage
{
    public class StorageServicesOperationContract : ImASkeepyOperationContract
    {
        public void ReferDependencies(ImADependencyPoolContainer dependencyPoolContainer)
        {
            ResolveSyncableEntities(dependencyPoolContainer);
        }

        private static void ResolveSyncableEntities(ImADependencyPoolContainer dependencyPoolContainer)
        {
            dependencyPoolContainer.Resolve<ImAPinStorageService>();
            dependencyPoolContainer.Resolve<ImAPinGroupStorageService>();
            dependencyPoolContainer.Resolve<ImAPinGroupSettingsStorageService>();
            dependencyPoolContainer.Resolve<ImASkeepyConsumerStorageService>();
        }
    }
}
