using Bridge;
using Bridge.React;
using System;

namespace H.Skeepy.Click.Web.UI.Components.UI.Elements
{
    public class Elevation : ComponentBase<Elevation.Props, Elevation.State>
    {
        public Elevation(Props props, params Union<ReactElement, string>[] children) : base(props, children) { }
        public Elevation(params Union<ReactElement, string>[] children) : this(new Props { }, children) { }

        public override ReactElement Render()
        {
            ReactStyle reactStyle = new ReactStyle
            {
                MinWidth = 100,
                MinHeight = 60,
                BackgroundColor = style.BackgroundColor.Hex,
                Padding = style.SizingUnitInPixels,
            };

            if (props.StyleDecorator != null)
                reactStyle = props.StyleDecorator(reactStyle);

            return
                DOM.Div(
                    new Attributes
                    {

                        Style = reactStyle,

                        ClassName = string.Format("ms-depth-{0}",(int)props.Depth),

                    },

                    Children

                );
        }

        public class State { }
        public class Props
        {
            public ElevationDepthLevel Depth { get; set; }
            public Func<ReactStyle, ReactStyle> StyleDecorator { get; set; }

        
private ElevationDepthLevel __Property__Initializer__Depth=ElevationDepthLevel.Low;}
    }

    public enum ElevationDepthLevel
    {
        Lowest = 4,
        Low = 8,
        High = 16,
        Hihghest = 64,
    }
}
