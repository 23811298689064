using Bridge.React;
using H.Skeepy.Click.Web.UI.Pages;
using H.Skeepy.Click.Web.UI.Pages.Health;
using H.Skeepy.Click.Web.UI.Pages.PinGroupPages;
using H.Skeepy.Core.Common;
using H.Skeepy.Core.Operations.UI.Navigation;
using ProductiveRage.Immutable;
using ProductiveRage.ReactRouting;
using System;

namespace H.Skeepy.Click.Web.UI.Components.Navigation
{
    public class AppNavigator : Navigator
    {
        private readonly Func<UrlPathDetails> homeRoute;
        private readonly Func<UrlPathDetails> debugRoute;
        private readonly Func<UrlPathDetails> defineNewPinGroupPageRoute;
        private readonly Func<Guid, UrlPathDetails> pinGroupOperationsPageRoute;
        private readonly Func<Guid, UrlPathDetails> pinGroupDetailedDisplayPageRoute;
        private readonly Func<UrlPathDetails> storageHealthRoute;

        public AppNavigator(IDispatcher dispatcher) : base(dispatcher)
        {
            this.homeRoute = AddRelativeRoute(
                segments: NonNullList<string>.Empty,
                routeAction: new AppPageDispatcherAction<WelcomePage>(),
                urlGenerator: (Func<UrlPathDetails>)(() => GetPath()
)                );

            this.debugRoute = AddRelativeRoute(
                segments: new NonNullList<string>(H.Skeepy.Core.Common.DataExtensions.AsArray<string>("debug")),
                routeAction: new AppPageDispatcherAction<DebugPage>(),
                urlGenerator: (Func<UrlPathDetails>)(() => GetPath("debug")
)                );

            this.defineNewPinGroupPageRoute = AddRelativeRoute(
                segments: new NonNullList<string>(H.Skeepy.Core.Common.DataExtensions.AsArray<string>("new")),
                routeAction: new AppPageDispatcherAction<DefineNewPinGroupPage>(),
                urlGenerator: (Func<UrlPathDetails>)(() => GetPath("new")
)                );

            this.pinGroupOperationsPageRoute = AddRelativeRoute<Guid>(
                routeDetails: ProductiveRage.ReactRouting.RouteBuilderExtensions_String.String<Guid>(RouteBuilder.Empty.Fixed("run"),(Func<NonBlankTrimmedString,Guid>)(x => Guid.Parse(x))),
                routeActionGenerator: (Func<Guid,INavigationDispatcherAction>)(id => new AppPageDispatcherAction<PinGroupOperationsPage>(new UiNavigationParams(id))),
                urlGenerator: (Func<Guid,UrlPathDetails>)(id => GetPath("run", id)
)                );

            this.pinGroupDetailedDisplayPageRoute = AddRelativeRoute<Guid>(
                routeDetails: ProductiveRage.ReactRouting.RouteBuilderExtensions_String.String<Guid>(RouteBuilder.Empty.Fixed("view"),(Func<NonBlankTrimmedString,Guid>)(x => Guid.Parse(x))),
                routeActionGenerator: (Func<Guid,INavigationDispatcherAction>)(id => new AppPageDispatcherAction<PinGroupDetailedDisplayPage>(new UiNavigationParams(id))),
                urlGenerator: (Func<Guid,UrlPathDetails>)(id => GetPath("view", id)
)                );

            this.storageHealthRoute = AddRelativeRoute(
                segments: new NonNullList<string>(new string[] { "health", "storage" }),
                routeAction: new AppPageDispatcherAction<StorageHealthPage>(),
                urlGenerator: (Func<UrlPathDetails>)(() => GetPath("health", "storage")
)                );
        }
public UrlPathDetails Home
{
    get
    {
        return homeRoute();
    }
}public UrlPathDetails Debug
{
    get
    {
        return debugRoute();
    }
}public UrlPathDetails DefineNewPinGroupPage
{
    get
    {
        return defineNewPinGroupPageRoute();
    }
}public UrlPathDetails PinGroupOperationsPageRoute(Guid id)
{
    return pinGroupOperationsPageRoute(id);
}public UrlPathDetails StorageHealth
{
    get
    {
        return storageHealthRoute();
    }
}    }
}
