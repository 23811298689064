namespace H.Skeepy.Core.Model
{
    public struct Iota
    {
        public Iota(string id, object jot)
        {
            ID = id;
            Jot = jot!=null?jot.ToString():(string)null;
        }

        public string ID { get; set; }
        public string Jot { get; set; }
    }
}