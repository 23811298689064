using H.Skeepy.Core.Common;
using System;
using System.Linq;

namespace H.Skeepy.Core.Model.Display
{
    public class PinGroupDisplay
    {
        public PinGroupDisplay(PinGroup pinGroup, PinGroupSettings pinGroupSettings = null)
        {
            ID = pinGroup.ID;
            CreatedAt = pinGroup.CreatedAt;
            Name = pinGroup.Name;
            Description = pinGroup.Description;
            Count = (global::Bridge.Script.ToTemp("key1",pinGroup.Pins)!=null?global::Bridge.Script.FromTemp<Guid[]>("key1").Length:(int?)null) ?? 0;
            IsSealed = pinGroup.IsSealed;
            Settings = new PinGroupSettingsDisplay(pinGroupSettings ?? new PinGroupSettings());
        }

        public Guid ID { get; private set; }
        public DateTime CreatedAt { get; private set; }
        public string Name { get; private set; }
        public string Description { get; private set; }
        public int Count { get; private set; }
        public bool IsSealed { get; private set; }
        public PinGroupSettingsDisplay Settings { get; private set; }

        public string PrintCreatedAt()
        {
            return CreatedAt.Print();
        }

        public string PrintBriefDescription(int maxWords = 12, int maxLength = 120)
        {
            if (string.IsNullOrWhiteSpace(Description)) return string.Empty;

            string[] words = Description.Split(new char[] { ' ' }, StringSplitOptions.RemoveEmptyEntries);

            string brief = string.Join(" ", System.Linq.Enumerable.Take<string>(words,maxWords));

            if (brief.Length > maxLength)
            {
                brief = brief.Substring(0, maxLength);
            }

            return string.Format("{0}{1}",brief,(Description != brief ? " [...]" : string.Empty));
        }

        public string PrintCount()
        {
            return string.Format("{0} {1}",Count,(Count == 1 ? "click" : "clicks"));
        }
    }

    public class PinGroupSettingsDisplay
    {
        public PinGroupSettingsDisplay(PinGroupSettings pinGroupSettings)
        {
            IsLocationTrackingEnabled = pinGroupSettings.IsLocationTrackingEnabled;
        }

        public bool IsLocationTrackingEnabled { get; private set; }
    }
}
