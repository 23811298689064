using System.Threading.Tasks;

namespace H.Skeepy.Core.Operations.UI.UserInput
{
    public static class UserInputValidation
    {
        public static Task<UserInputValidationResult> Required(string value, string message = "This field is required")
        {
            if (string.IsNullOrWhiteSpace(value))
                return Task.FromResult<UserInputValidationResult>(new UserInputValidationResult { IsValid = false, Message = message });
            return Task.FromResult<UserInputValidationResult>(UserInputValidationResult.Success);
        }
    }
}
