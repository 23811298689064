using Bridge.Html5;
using Bridge.React;
using H.Skeepy.Click.Web.UI.Components.Maps;
using H.Skeepy.Core.Model.GPS;
using H.Skeepy.Core.Operations.UI.WorldMapping;
using System;

namespace H.Skeepy.Click.Web.UI.Components.UI.Controls.Maps
{
    public class WorldMap : ComponentBase<WorldMap.Props, WorldMap.State>
    {
        ImAWorldMapUiFactory worldMapUiFactory;

        public WorldMap(Props props) : base(props, null) { }

        protected override void EnsureDependencies()
        {
            base.EnsureDependencies();

            if (worldMapUiFactory == null)
            {
                worldMapUiFactory = Get<ImAWorldMapUiFactory>();
                worldMapUiFactory.Use((Func<ImAWorldMapUiComponent>)(() => new MapBoxMaps(state.MapCanvas)));
            }
        }

        public override ReactElement Render()
        {
            ReactStyle reactStyle = new ReactStyle
            {
                Width = 800,
                Height = 600,
                Border = "solid 1px",
                BorderColor = style.Colors.Complementary.Color.Hex,
            };

            if (props.StyleDecorator != null)
                reactStyle = props.StyleDecorator(reactStyle);

            return
                DOM.Div(
                    new Attributes
                    {
                        Ref = x => ConstructMapIfNecesarryOnCanvas(x),
                        Style = reactStyle,
                    }
                );
        }

        private void ConstructMapIfNecesarryOnCanvas(HTMLElement mapCanvas)
        {
            if (mapCanvas == null)
                return;

            if (state.IsMapConstructed)
                return;

            state.MapCanvas = mapCanvas;

            state.MapComponent = worldMapUiFactory
                .AssembleNewMap()
                .SetZoomPercent(85)
                .SetZoomLevel(MapZoomLevel.CityDistrict)
                .MarkTrack(props.GpsPins);

            state.IsMapConstructed = true;
        }

        protected override void ComponentWillUnmount()
        {
            state.MapComponent.Dispose();

            base.ComponentWillUnmount();
        }

        public class State
        {
            public bool IsMapConstructed { get; set; }
            public HTMLElement MapCanvas { get; set; }
            public ImAWorldMapUiComponent MapComponent { get; set; }

        
private bool __Property__Initializer__IsMapConstructed=false;private HTMLElement __Property__Initializer__MapCanvas=null;private ImAWorldMapUiComponent __Property__Initializer__MapComponent=null;}
        public class Props
        {
            public GpsPoint[] GpsPins { get; set; }
            public Func<ReactStyle, ReactStyle> StyleDecorator { get; set; }

        
private GpsPoint[] __Property__Initializer__GpsPins=new GpsPoint[0];}
    }
}

