using Bridge;
using Bridge.React;
using H.Skeepy.Click.Web.UI.Components.UI.Controls;
using H.Skeepy.Click.Web.UI.Components.UI.Elements;
using H.Skeepy.Click.Web.UI.Components.UI.Layout;
using H.Skeepy.Core;
using H.Skeepy.Core.Common;
using H.Skeepy.Core.Common.ExecutionTamers;
using H.Skeepy.Core.Infrastructure;
using H.Skeepy.Core.Infrastructure.Timing;
using H.Skeepy.Core.Model;
using H.Skeepy.Core.Operations;
using H.Skeepy.Core.Operations.Concrete;
using H.Skeepy.Core.Operations.Storage;
using H.Skeepy.Core.Operations.UI.ViewState;
using System;
using System.Linq;
using System.Threading.Tasks;

namespace H.Skeepy.Click.Web.UI.Pages
{
    public class DebugPage : PageBase<DebugPage.Props, DebugViewState>
    {
        Core.Operations.Http.ImAnHttpClient httpClient;
        Core.Operations.ImAGpsService gpsService;
        ImAPinStorageService pinStorageService;
        ImAPinGroupStorageService pinGroupStorageService;
        ImAPeriodicAction periodicAction;
        ImADaemon daemon;
        ImAStorageExplorer storageExplorer;
        ImADataZipper dataZipper;
        ImAContentDeliveryService fileDownloaderService;

        Core.Operations.UI.ImAUiBinder uiBinder;

        protected override void EnsureDependencies()
        {
            base.EnsureDependencies();

            gpsService = gpsService ?? Get<Core.Operations.ImAGpsService>();
            pinStorageService = pinStorageService ?? Get<ImAPinStorageService>();
            pinGroupStorageService = pinGroupStorageService ?? Get<ImAPinGroupStorageService>();
            uiBinder = uiBinder ?? Get<Core.Operations.UI.ImAUiBinder>();
            httpClient = httpClient ?? Get<Core.Operations.Http.ImAnHttpClient>();
            periodicAction = periodicAction ?? Get<ImAPeriodicAction>();
            daemon = daemon ?? Get<ImADaemon>();
            storageExplorer = storageExplorer ?? Get<ImAStorageExplorer>();
            dataZipper = dataZipper ?? Get<ImADataZipper>();
            fileDownloaderService = fileDownloaderService ?? Get<FileDownloadDeliveryService>();
        }

        public DebugPage(params Union<ReactElement, string>[] children) : base(null, children)
        {
        }

        public override ReactElement Render()
        {
            EnsureDependencies();

            return
                new MainLayout(new CenteredContent(

                    DOM.Div(new Attributes { },

                    new Button(new Button.Props { OnClick = TestExecutionTamers }, "Test Execution Tamers")

                    //new Button(new Button.Props { OnClick = TestZip }, "Test Zip")

                    //new Button(new Button.Props { OnClick = TestTryOrFailWithGrace }, "Test Fail With Grace"),

                    //DOM.Input(
                    //    BindTo(nameof(state.DebugMessage), new InputAttributes())
                    //),

                    //DOM.Span(state.DebugMessage),

                    //DOM.Button(new ButtonAttributes
                    //{
                    //    OnClick = x =>
                    //    {
                    //        daemon.Use(() => App.Get<SyncWorker>());
                    //        daemon.StartAndKeepAlive();
                    //    },
                    //},
                    //    "Start Sync Daemon"
                    //),

                    //DOM.Button(new ButtonAttributes
                    //{
                    //    OnClick = x => daemon.Stop(),
                    //},
                    //    "Stop Sync Daemon"
                    //),

                    //DOM.Button(new ButtonAttributes
                    //{
                    //    OnClick = x => periodicAction.Start(TimeSpan.FromSeconds(1), async () => { await Task.Delay(2000); Console.WriteLine($"Ping @ {Rolex.Time}"); }),
                    //},
                    //    "Start periodic action"
                    //),

                    //DOM.Button(new ButtonAttributes
                    //{
                    //    OnClick = x => periodicAction.Stop(),
                    //},
                    //    "Stop periodic action"
                    //),


                    //DOM.Button(new ButtonAttributes
                    //{
                    //    OnClick = x => httpClient.Get("https://httpbin.org/get"),
                    //},
                    //    "HTTP GET"
                    //),

                    //DOM.Button(new ButtonAttributes
                    //{
                    //    OnClick = x => httpClient.Post("https://httpbin.org/post", Guid.NewGuid()),
                    //},
                    //    "HTTP POST"
                    //),

                    //DOM.Button(new ButtonAttributes
                    //{
                    //    OnClick = x => navi.Go<WelcomeViewState>(),
                    //},
                    //    "Go To Welcome Page"
                    //),

                    //DOM.Button(new ButtonAttributes
                    //{
                    //    OnClick = async x => await GeneratePin(),
                    //},
                    //    "Generate Pin"
                    //),

                    //DOM.Button(new ButtonAttributes
                    //{
                    //    OnClick = async x => await CountAllPins(),
                    //},
                    //    "Count All Pins"
                    //),

                    //DOM.Button(new ButtonAttributes
                    //{
                    //    OnClick = async x => await LoadPinByID(),
                    //},
                    //    "Load By ID"
                    //),

                    //DOM.Button(new ButtonAttributes
                    //{
                    //    OnClick = async x => await GenerateAndDeletePin(),
                    //},
                    //    "Generate and delete pin"
                    //),

                    //DOM.Button(new ButtonAttributes
                    //{
                    //    OnClick = async x => await DeletePinsOlderThanFiveMinutes(),
                    //},
                    //    "Delete Pins Older than 5 minutes"
                    //),

                    //DOM.Button(new ButtonAttributes
                    //{
                    //    OnClick = async x => await SearchPins(),
                    //},
                    //    "Search Last 5 minutes pins"
                    //),

                    //RenderUiControls()
                    )

                ));
        }

        private ReactElement RenderUiControls()
        {
            return
                new FontIcon(new FontIcon.Props { IconName = "Mail", Size = new Core.Branding.TypographySize(40), Color = style.SuccessColor });

            //return
            //    DOM.Div(
            //        new Attributes { },

            //        new SkeepyLogo(new SkeepyLogo.Props { Width = 60, Height = 30 }),
            //        new SkeepyLogo(new SkeepyLogo.Props { Width = 60, Height = 30, TintColor = new Core.Branding.ColorInfo("#000000") }),

            //        new Button("Default Button"),
            //        new Button(new Button.Props { IsDisabled = true }, "Disabled Button"),
            //        new Subtitle("SubTitle"),
            //        new TextInput(new TextInput.Props { Decorator = x => BindTo(nameof(state.DebugMessage), x) }),
            //        new TextInput(new TextInput.Props { IsDisabled = true }),
            //        new TextInput(new TextInput.Props { IsDisabled = false, Placeholder = "Bla bla", Label = "Cool Label", Description = "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Maecenas porttitor congue massa. Fusce posuere, magna sed pulvinar ultricies, purus lectus malesuada libero, sit amet commodo magna eros quis urna." }),
            //        new TextInput(new TextInput.Props { IsDisabled = true, Placeholder = "Bla bla", Label = "Cool Label", Description = "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Maecenas porttitor congue massa. Fusce posuere, magna sed pulvinar ultricies, purus lectus malesuada libero, sit amet commodo magna eros quis urna." }),
            //        new TextInput(new TextInput.Props { IsDisabled = false, UserInputValidator = (x, c) => UserInputValidation.Required(x), Placeholder = "Bla bla", Label = "Cool Label", Description = "Lorem ipsum dolor sit amet." }),
            //        new TextInput(new TextInput.Props { IsDisabled = true, UserInputValidator = (x, c) => UserInputValidation.Required(x), Placeholder = "Bla bla", Label = "Cool Label", Description = "Lorem ipsum dolor sit amet." }),
            //        new LongTextInput(),
            //        new LongTextInput(new LongTextInput.Props { IsDisabled = false, UserInputValidator = (x, c) => UserInputValidation.Required(x), Placeholder = "Bla bla", Label = "Cool Label", Description = "Lorem ipsum dolor sit amet." }),
            //        new LongTextInput(new LongTextInput.Props { IsDisabled = true, UserInputValidator = (x, c) => UserInputValidation.Required(x), Placeholder = "Bla bla", Label = "Cool Label", Description = "Lorem ipsum dolor sit amet." }),
            //        new Title("Title"),
            //        new Button(new Button.Props { OnClick = () => { state.IsBusy = !state.IsBusy; SetState(); } }, "Toggle Busy"),
            //        state.IsBusy ? new BusyIndicator(new BusyIndicator.Props { Size = 15, Resolution = 3 }) : null,
            //        DOM.Div(new Attributes { Style = new ReactStyle { Width = 50, Height = 50 } }),
            //        state.IsBusy ? new BusyIndicator(new BusyIndicator.Props { Size = 30, Resolution = 5, Style = BusyIndicatorStyle.Fluent, AnimationSpeed = 50 }) : null,
            //        new Switch("Switch Control"),
            //        new Switch(new Switch.Props { IsOn = true }, "Switch Control Default On"),
            //        new Switch(new Switch.Props { IsDisabled = true }, "Switch Control Disabed"),
            //        new ListItem(new ListItem.Props { }),
            //        new ListItem("Test"),
            //        new ListItem(new Title("Title")),
            //        new ListItem(new ListItem.Props { Title = "Testicles" }),
            //        new ListItem(new ListItem.Props { Title = "Testicles" }, "Some description"),
            //        new ListItem(new ListItem.Props { Title = "Testicles", OnClick = x => { } }, "Some description"),
            //        new ListItem(new ListItem.Props { Title = "Testicles", OnClick = x => { }, OnMoreClick = x => { } }, "Some description")
            //    );
        }

        private async Task GenerateAndDeletePin()
        {
            Core.Model.Pin pin = new Core.Model.Pin();

            await pinStorageService.Save(pin);

            await pinStorageService.Delete(pin.ID);

            pin = await pinStorageService.Load(pin.ID);

            Console.WriteLine(pin);
        }

        private async Task DeletePinsOlderThanFiveMinutes()
        {
            long count = await pinStorageService.DeleteMany(new PinFilterRequest
            {
                ToInclusive = Rolex.Time.AddMinutes(-5),
            });

            Console.WriteLine(string.Format("Deleted {0} pins",count));
        }

        private async Task LoadPinByID()
        {
            Core.Model.Pin pin = new Core.Model.Pin();

            await pinStorageService.Save(pin);

            pin = await pinStorageService.Load(pin.ID);

            Console.WriteLine(pin);
        }

        private async Task CountAllPins()
        {
            long count = await pinStorageService.Count(null);

            Console.WriteLine(count.ToString());
        }

        private async Task SearchPins()
        {
            Core.Model.Pin[] pins = await pinStorageService.Search(new PinFilterRequest
            {
                FromInclusive = Rolex.Time.AddMinutes(-5),
                ToInclusive = Rolex.Time,
            });

            Console.WriteLine(pins);
        }

        private async Task GeneratePin()
        {
            Core.Model.Pin pin = new Core.Model.Pin();

            await pinStorageService.Save(pin);

            pin.Gps = await gpsService.GetCurrentLocation();

            await pinStorageService.Save(pin);
        }

        private async void TestTryOrFailWithGrace()
        {
            new Action(() =>
            {
                throw new InvalidOperationException("Nu-s fraier");
            })
            .TryOrFailWithGrace(
                numberOfTimes: 2,
                onFail: (Action<Exception>)(x => Console.WriteLine(string.Format("Failed after 5 attempts. Exception: {0}",x.Message))),
                onRetry: (Action<Exception>)(x => Console.WriteLine(string.Format("Retrying... Exception: {0}",x.Message))),
                millisecondsToSleepBetweenRetries: 1000
            );

            await ExecutionUtilities.TryAFewTimesOrFailWithGrace(
(Func<Task>)                new Func<Task>(async () =>
                {
                    await Task.Delay(1000);
                    throw new InvalidOperationException("[Async] Nu-s fraier");
                }),
                numberOfTimes: 2,
                onFail: (Action<Exception>)(x => Console.WriteLine(string.Format("[Async] Failed after 5 attempts. Exception: {0}",x.Message))),
                onRetry: (Action<Exception>)(x => Console.WriteLine(string.Format("[Async] Retrying... Exception: {0}",x.Message))),
                millisecondsToSleepBetweenRetries: 1000
            );

            await new Func<Task>(async () =>
            {
                await Task.Delay(1000);
                throw new InvalidOperationException("[async] nu-s fraier");
            })
            .TryOrFailWithGrace(
                numberOfTimes: 2,
                onFail: (Action<Exception>)(x => Console.WriteLine(string.Format("[async] failed after 5 attempts. exception: {0}",x.Message))),
                onRetry: (Action<Exception>)(x => Console.WriteLine(string.Format("[async] retrying... exception: {0}",x.Message))),
                millisecondsToSleepBetweenRetries: 1000
            );
        }

        private async void TestStorageExplorer()
        {
            StorageInfo storageInfo = await storageExplorer.GetStorageInformation();

            Console.WriteLine(storageInfo);

            StorageRecord[] batch = await storageExplorer.BrowseBatch(System.Linq.Enumerable.First<StorageCollectionInfo>(storageInfo.Collections).Name);

            Console.WriteLine(batch);
        }

        private async void TestZip()
        {
            DataBucket zip =
                await dataZipper
                    .NewPackage("Testicles")
                    .UpsertFile("Hintest.txt", "This is the first ZIP test from JavaScript.")
                    .WrapPackage();
            OperationResult deliveryResult = await fileDownloaderService.Deliver(zip);
        }

        private async void TestExecutionTamers()
        {
            Console.WriteLine(string.Format("Execution Tamers Big Bang @ {0}",Rolex.Time));

            //ImAPeriodicAction periodicActionImmediate = App.Get<ImAPeriodicAction>();
            //ImAPeriodicAction periodicActionDelayed = App.Get<ImAPeriodicAction>();

            //periodicActionImmediate.Start(TimeSpan.FromSeconds(1), () => Console.WriteLine($"Immediate Periodic Action every 1 sec @ {Rolex.Time}"));
            //periodicActionDelayed.StartDelayed(TimeSpan.FromSeconds(3), TimeSpan.FromSeconds(5), () => Console.WriteLine($"Delayed Periodic Action every 5 secs @ {Rolex.Time}"));

            Debouncer debouncer = App.Get<Debouncer>();
            debouncer.Use((Action)(() => Console.WriteLine(string.Format("Debounced Invoke @ {0}",Rolex.Time))));
            debouncer.Use(TimeSpan.FromSeconds(2));

            Throttler throttler = App.Get<Throttler>();
            throttler.Use((Action)(() => Console.WriteLine(string.Format("Throttled Invoke @ {0}",Rolex.Time))));
            throttler.Use(TimeSpan.FromSeconds(1));
            await throttler.Invoke(); await Task.Delay(TimeSpan.FromSeconds(.5));
            await throttler.Invoke(); //await Task.Delay(TimeSpan.FromSeconds(.5));
            //await throttler.Invoke(); await Task.Delay(TimeSpan.FromSeconds(.5));
            //await throttler.Invoke(); await Task.Delay(TimeSpan.FromSeconds(.5));
            //await throttler.Invoke();

            await debouncer.Invoke(); await Task.Delay(TimeSpan.FromSeconds(1));
            await debouncer.Invoke(); await Task.Delay(TimeSpan.FromSeconds(1));
            await debouncer.Invoke(); await Task.Delay(TimeSpan.FromSeconds(1));
            await debouncer.Invoke(); await Task.Delay(TimeSpan.FromSeconds(1));
            await debouncer.Invoke(); await Task.Delay(TimeSpan.FromSeconds(1));
            await debouncer.Invoke();
        }

        public class Props : PagePropsBase { }
    }
}