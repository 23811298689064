using Bridge.Html5;
using H.Skeepy.Core.Model;
using H.Skeepy.Core.Operations.Sync;
using System;

namespace H.Skeepy.Click.Web.UI.Common
{
    public static class Extensions
    {
public static DateTime UnixToDateTime(this long timestamp)
{
    return DateTimeOperations.FromUnixTimestamp(timestamp);
}public static DateTime UnixToDateTime(this int timestamp)
{
    return ((long)timestamp).UnixToDateTime();
}public static object ToJson<T>(this T obj)
{
    return JsonOperations.ConvertObjectToJson<T>(obj);
}public static T ToType<T>(this object json)
{
    return JsonOperations.ConvertJsonToObject<T>(json);
}        public static Blob ToBlob(this DataBucket dataBucket)
        {
            if (dataBucket.TypeName == typeof(Blob).TypeName())
            {
                return dataBucket.Payload.As<Blob>();
            }

            if (dataBucket.PayloadAsByteArray != null && dataBucket.PayloadAsByteArray.Length > 0)
            {
                Uint8Array arrayBuffer = new Uint8Array(dataBucket.PayloadAsByteArray.Length);
                for (uint i = 0; i < arrayBuffer.Length; i++)
                    arrayBuffer[i] = dataBucket.PayloadAsByteArray[i];

                return new Blob(new BlobDataObject[] { arrayBuffer.Buffer }, new BlobPropertyBag
                {
                    Type = dataBucket.MimeType,
                });
            }

            return new Blob(new BlobDataObject[] { dataBucket.PayloadAsString }, new BlobPropertyBag
            {
                Type = dataBucket.MimeType,
            });
        }
        public static byte[] ToByteArray(this Uint8Array typedArray)
        {
            if (typedArray == null)
                return new byte[0];

            byte[] result = new byte[typedArray.ByteLength];
            for (int i = 0; i < result.Length; i++)
            {
                result[i] = typedArray[i];
            }

            return result;
        }
public static byte[] ToByteArray(this ArrayBuffer arrayBuffer)
{
    return new Uint8Array(arrayBuffer).ToByteArray();
}    }
}
