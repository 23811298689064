using System;

namespace H.Skeepy.Core.Operations.Sync
{
    public class SyncNode
    {
        public string ID { get; set; }

        public string Name { get; set; }

        public string Description { get; set; }

        public string Uri { get; set; }

        public string SyncRequestUri { get; set; }
    }

    public class SyncRequest
    {
        public string ID { get; set; }

        public string Payload { get; set; }

        public string PayloadIdentifier { get; set; }

        public string PayloadType { get; set; }

        public SyncStatus SyncStatus { get; set; }

        public DateTime HappenedAt { get; set; }

        public static SyncRequest Wrap(string payloadType, object payload, string payloadIdentifier, SyncStatus syncStatus)
        {
            return new SyncRequest
            {
                PayloadIdentifier = payloadIdentifier,
                Payload = Newtonsoft.Json.JsonConvert.SerializeObject(payload),
                PayloadType = payloadType,
                SyncStatus = syncStatus,
            };
        }
public static SyncRequest Wrap<T>(T payload, string payloadIdentifier, SyncStatus syncStatus)
{
    return Wrap(typeof(T).FullName, payload, payloadIdentifier, syncStatus);
}
        public T Unwrap<T>()
        {
            return Newtonsoft.Json.JsonConvert.DeserializeObject<T>(Payload);
        }

    
private string __Property__Initializer__ID=Guid.NewGuid().ToString();private SyncStatus __Property__Initializer__SyncStatus=SyncStatus.NotSynced;private DateTime __Property__Initializer__HappenedAt=Rolex.Time;}

    public class SyncResponse
    {
        public string PayloadIdentifier { get; set; }

        public string PayloadType { get; set; }

        public DateTime HappenedAt { get; set; }

        public SyncStatus SyncStatus { get; set; }

        public string ErrorMessage { get; set; }

    
private DateTime __Property__Initializer__HappenedAt=Rolex.Time;private SyncStatus __Property__Initializer__SyncStatus=SyncStatus.NotSynced;}

    public enum SyncStatus
    {
        Inexistent = -1,

        NotSynced = 0,
        DeletedAndNotSynced = 1,

        Synced = 1000,
        DeletedAndSynced = 1001,
    }
}
