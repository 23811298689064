using H.Skeepy.Core.Infrastructure;
using H.Skeepy.Core.Model.Display;
using H.Skeepy.Core.Operations.UI.Abstract;
using H.Skeepy.Core.Operations.UI.Navigation;
using H.Skeepy.Core.Operations.UseCases;
using System;
using System.Threading.Tasks;

namespace H.Skeepy.Core.Operations.UI.ViewState.PinGroupViewStates
{
    public class PinGroupOperationsViewState : ViewStateBase
    {
        #region Construct
        ImAPinUseCase pinUseCase;
        ImAPinGroupUseCase pinGroupUseCase;
        public override void ReferDependencies(ImADependencyPoolContainer dependencyPoolContainer)
        {
            base.ReferDependencies(dependencyPoolContainer);

            pinGroupUseCase = pinGroupUseCase ?? dependencyPoolContainer.Resolve<ImAPinGroupUseCase>();
            pinUseCase = pinUseCase ?? dependencyPoolContainer.Resolve<ImAPinUseCase>();
        }

        Guid pinGroupID;
        public override void Use(UiNavigationParams uiNavigationParams)
        {
            base.Use(uiNavigationParams);

            pinGroupID = uiNavigationParams.GetValue<Guid>();
        }
#endregion
#region Properties
public PinGroupDisplay PinGroup
{
    get
    {
        return pinGroupUseCase.PinGroup;
    }
}
        public long Count { get; set; }

        public bool CanUndo { get; set; }
        #endregion

        #region Operations
        public async Task Click()
        {
            Model.Pin pin = await pinUseCase.New();
            await pinGroupUseCase.Push(pin);

            await UpdateView();

            if (PinGroup.Settings.IsLocationTrackingEnabled)
            {
                await pinUseCase.AttachGpsTo(pin);
            }
        }

        public async Task Undo()
        {
            await pinGroupUseCase.Undo();

            await UpdateView();
        }

        public override void Bind()
        {
            base.Bind();

            Bind("PinGroup", (Func<object>)(() => PinGroup), (Action<object>)(x => { }));
            Bind("Count", (Func<object>)(() => Count), (Action<object>)(x => { }));
        }
        #endregion

        public override async Task Initialize()
        {
            await base.Initialize();

            using (BusyIndicator())
            {
                await pinGroupUseCase.Resurrect(pinGroupID);
                await UpdateView();
            }
        }

        private async Task UpdateView()
        {
            Count = await pinGroupUseCase.Count();
            CanUndo = await pinGroupUseCase.CanUndo();
            viewUpdater!=null?global::Bridge.Script.FromLambda(()=>viewUpdater.Invoke("Count")):null;
        }

    
private bool __Property__Initializer__CanUndo=false;}
}
