using H.Skeepy.Core.Common;
using System;

namespace H.Skeepy.Core.Model
{
    public class OperationProgress
    {
        public OperationProgress(float percent)
        {
            Percent = percent.TrimToPercent();
        }

        public float Percent { get; private set; }
    }

    public class OperationProgressEventArgs : EventArgs
    {
        readonly OperationProgress operationProgress;
        public OperationProgressEventArgs(float percent)
        {
            operationProgress = new OperationProgress(percent);
        }
public float Percent
{
    get
    {
        return operationProgress.Percent;
    }
}    }
}
