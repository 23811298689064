using H.Skeepy.Core.Operations.UI.Abstract;

namespace H.Skeepy.Core.Operations.UI.ViewState
{
    public class DebugViewState : ViewStateBase
    {
        public string DebugMessage { get; set; }

        public override void Bind()
        {
            base.Bind();

            Bind("DebugMessage", (System.Func<object>)(() => DebugMessage), (System.Action<object>)(x => DebugMessage = (string)x));
        }
    }
}
