using System;

namespace H.Skeepy.Core.Operations.UI.UserInput
{
    public class UserOption
    {
        public object Payload { get; set; }
        public string Icon { get; set; }
        public string Label { get; set; }
        public object Tag { get; set; }
        public Action<object> OnClick { get; set; }

    
private string __Property__Initializer__Icon=null;private string __Property__Initializer__Label=null;private Action<object> __Property__Initializer__OnClick=null;}
}
