using System;

namespace H.Skeepy.Core.Operations.Concrete
{
    public class Timekeeper : ImATimekeeper
    {
public DateTime Time
{
    get
    {
        return DateTime.Now;
    }
}public DateTime Date
{
    get
    {
        return DateTime.Today;
    }
}    }
}
