using H.Skeepy.Core.Common;
using System;
using System.Collections.Concurrent;

namespace H.Skeepy.Core.Operations.UI.Concrete
{
    public class UiBinder : ImAUiBinder
    {
        private readonly ConcurrentDictionary<string, Func<object>> getters = new ConcurrentDictionary<string, Func<object>>();
        private readonly ConcurrentDictionary<string, Action<object>> setters = new ConcurrentDictionary<string, Action<object>>();

        private readonly ConcurrentDictionary<string, Action> modelToViewBindings = new ConcurrentDictionary<string, Action>();
        private readonly ConcurrentDictionary<string, Action<object>> viewToModelBindings = new ConcurrentDictionary<string, Action<object>>();

#pragma warning disable CS0114 // Member hides inherited member; missing override keyword
        public object this[string propertyName]
#pragma warning restore CS0114 // Member hides inherited member; missing override keyword
        {
            get
            {
                return getters.ContainsKey(propertyName) ? global::Bridge.Script.ToTemp("key1",getters[propertyName])!=null?global::Bridge.Script.FromTemp<Func<object>>("key1").Invoke():(object)null : null;
            }
            set
            {
                if (!setters.ContainsKey(propertyName))
                    return;

                object oldValue = this[propertyName];

                global::Bridge.Script.ToTemp("key2",setters[propertyName])!=null?global::Bridge.Script.FromLambda(()=>global::Bridge.Script.FromTemp<Action<object>>("key2").Invoke(value)):null;

                if (modelToViewBindings.ContainsKey(propertyName) && !IsSameValue(oldValue, value))
                    global::Bridge.Script.ToTemp("key3",modelToViewBindings[propertyName])!=null?global::Bridge.Script.FromLambda(()=>global::Bridge.Script.FromTemp<Action>("key3").Invoke()):null;
            }
        }

        public ImAModelToViewBinder WrapGet(string propertyName, Func<object> getter)
        {
            getters.AddOrUpdate(propertyName, (Func<object>)getter, (Func<string,Func<object>,Func<object>>)((x, y) => getter));
            return this;
        }

        public ImAModelToViewBinder WrapSet(string propertyName, Action<object> setter)
        {
            setters.AddOrUpdate(propertyName, (Action<object>)setter, (Func<string,Action<object>,Action<object>>)((x, y) => setter));
            return this;
        }

        public ImAModelToViewBinder Wrap(string propertyName, Func<object> getter, Action<object> setter)
        {
            return
                this
                .WrapGet(propertyName, (Func<object>)getter)
                .WrapSet(propertyName, (Action<object>)setter)
                ;
        }

        public void BindModelToView(string propertyName, Action viewUpdater)
        {
            modelToViewBindings.AddOrUpdate(propertyName, (Action)viewUpdater, (Func<string,Action,Action>)((x, y) => viewUpdater));
        }

        public void BindViewToModel(string eventId, Action<object> modelUpdater)
        {
            viewToModelBindings.AddOrUpdate(eventId, (Action<object>)modelUpdater, (Func<string,Action<object>,Action<object>>)((x, y) => modelUpdater));
        }

        public void NotifyUiChange(string eventId, object value)
        {
            if (!viewToModelBindings.ContainsKey(eventId))
                return;

            global::Bridge.Script.ToTemp("key4",viewToModelBindings[eventId])!=null?global::Bridge.Script.FromLambda(()=>global::Bridge.Script.FromTemp<Action<object>>("key4").Invoke(value)):null;
        }

        private bool IsSameValue(object oldValue, object value)
        {
            return DataComparer.AreSame(oldValue, value);
        }
    }
}
