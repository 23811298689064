using Bridge;
using Bridge.React;
using H.Skeepy.Core.Branding;
using H.Skeepy.Core.Common;
using System.Collections.Generic;
using System.Linq;

namespace H.Skeepy.Click.Web.UI.Components.UI.Layout
{
    public class FormLayout : ComponentBase<FormLayout.Props, FormLayout.State>
    {
        public FormLayout(Props props, params Union<ReactElement, string>[] children) : base(props, children) { }
        public FormLayout(params Union<ReactElement, string>[] children) : this(new Props { }, children) { }

        public override ReactElement Render()
        {
            return
                new FabricUiGridLayout
                (
                    RenderRows()
                );
        }

        private ReactElement[] RenderRows()
        {
            if (!(global::Bridge.Script.ToTemp("key1",Children)!=null?global::Bridge.Script.FromTemp<Union<ReactElement, string>[]>("key1").Any():(bool?)null) ?? true)
                return null;

            var batches = H.Skeepy.Core.Common.DataExtensions.Batch<Union<ReactElement,string>>(System.Linq.Enumerable.Where<Union<ReactElement,string>>(Children,(System.Func<Union<ReactElement,string>,bool>)(x => x != null)),state.BatchSizeForLayout[props.LayoutMode]);

            return
System.Linq.Enumerable.Select<IEnumerable<Union<ReactElement,string>>,ReactElement>(                batches
,(System.Func<IEnumerable<Union<ReactElement,string>>,ReactElement>)RenderRow)
                .ToArray()
                ;
        }

        private ReactElement RenderRow(IEnumerable<Union<ReactElement, string>> cellContents)
        {
            return
                new FabricUiGridRow(new FabricUiGridRow.Props { RowSpacing = props.RowSpacing }, System.Linq.Enumerable.Select<Union<ReactElement,string>,ReactElement>(cellContents,(System.Func<Union<ReactElement,string>,ReactElement>)RenderColumn).ToArray());
        }

        private ReactElement RenderColumn(Union<ReactElement, string> cellContent)
        {
            return
                new FabricUiGridColumn
                (
                    state.ColumnStyleForLayout[props.LayoutMode],

                    cellContent

                )
                ;
        }

        public class State
        {
            public Dictionary<FormLayoutMode, FabricUiGridColumn.Props> ColumnStyleForLayout { get; private set; }

            public Dictionary<FormLayoutMode, int> BatchSizeForLayout { get; private set; }

        
private Dictionary<FormLayoutMode, FabricUiGridColumn.Props> __Property__Initializer__ColumnStyleForLayout=global::Bridge.Script.CallFor(new Dictionary<FormLayoutMode, FabricUiGridColumn.Props>(),(_o1)=>{_o1.Add(FormLayoutMode.InlineAsScreenGrows,new FabricUiGridColumn.Props { Size = FabricUiGridColumnSize.X12, SizeLarge = FabricUiGridColumnSize.X6, SizeXXLarge = FabricUiGridColumnSize.X3 });_o1.Add(FormLayoutMode.OnePerRow,new FabricUiGridColumn.Props { Size = FabricUiGridColumnSize.X12, SizeXLarge = FabricUiGridColumnSize.X6, PushXLarge = FabricUiGridColumnSize.X3, SizeXXXLarge = FabricUiGridColumnSize.X4, PushXXXLarge = FabricUiGridColumnSize.X4 });return _o1;});private Dictionary<FormLayoutMode, int> __Property__Initializer__BatchSizeForLayout=global::Bridge.Script.CallFor(new Dictionary<FormLayoutMode, int>(),(_o2)=>{_o2.Add(FormLayoutMode.InlineAsScreenGrows,4);_o2.Add(FormLayoutMode.OnePerRow,1);return _o2;});}

        public class Props
        {
            public FormLayoutMode LayoutMode { get; set; }
            public int RowSpacing { get; set; } 

        
private FormLayoutMode __Property__Initializer__LayoutMode=FormLayoutMode.InlineAsScreenGrows;private int __Property__Initializer__RowSpacing=15;}
    }

    public enum FormLayoutMode
    {
        InlineAsScreenGrows = 0,
        OnePerRow = 1,
    }
}
