using H.Skeepy.Core.Infrastructure;
using H.Skeepy.Core.Operations.Storage;
using Retyped;
using System.Linq;
using System.Threading.Tasks;

namespace H.Skeepy.Click.Web.UI.Components.LocalStorage
{
    public abstract class SyncedStorageServiceBase<TEntity, TId> : StorageServiceBase<TEntity, TId>
    {
        #region Construct
        protected IWantToSync<TEntity, TId> storageSyncer;
        public override void ReferDependencies(ImADependencyPoolContainer dependencyPoolContainer)
        {
            base.ReferDependencies(dependencyPoolContainer);

            storageSyncer = storageSyncer ?? dependencyPoolContainer.Resolve<IWantToSync<TEntity, TId>>();
        }
        #endregion

        protected override async Task Delete(dexie.Dexie.Table<object, object> table, TId id)
        {
            await base.Delete(table, id);
            await storageSyncer.Delete(id);
        }

        protected override async Task<long> DeleteMany(object filter)
        {
            TEntity[] deletedEntities = await base.Search(filter);
            long count = await base.DeleteMany(filter);
            await Task.WhenAll(System.Linq.Enumerable.Select<TEntity,Task>(deletedEntities,(System.Func<TEntity,Task>)(e => storageSyncer.Delete(GetIdFor(e)))));
            return count;
        }

        protected override async Task Save(dexie.Dexie.Table<object, object> table, TEntity model)
        {
            await base.Save(table, model);
            await storageSyncer.Save(model);
        }
    }
}
