using System;
using System.Collections.Generic;
using System.Linq;
using System.Text;
using System.Threading.Tasks;

namespace H.Skeepy.Core.Common
{
    public class DataComparer
    {
        public static bool AreSame(object valueA, object valueB)
        {
            return string.Equals(
                Newtonsoft.Json.JsonConvert.SerializeObject(valueA),
                Newtonsoft.Json.JsonConvert.SerializeObject(valueB)
                );
        }
    }
}
