using H.Skeepy.Core.Common;
using H.Skeepy.Core.Operations.Sync;
using Retyped;
using System;
using System.Linq;
using System.Threading.Tasks;

namespace H.Skeepy.Click.Web.UI.Components.LocalStorage
{
    public class LocalSyncRegistryStorageService : StorageServiceBase<SyncRegitryEntry, string>, ImASyncRegistry
    {
protected override string GetIdFor(SyncRegitryEntry item)
{
    return item.ID;
}
        public async Task<string[]> GetEntitiesToSync(string entityType, int maxBatchSize = 100)
        {
            SyncRegistryFilter filter = new SyncRegistryFilter
            {
                EntityTypes = new string[] { entityType },
                SyncStatuses = new SyncStatus[] { SyncStatus.NotSynced, SyncStatus.DeletedAndNotSynced },
                Limit = maxBatchSize,
            };

            SyncRegitryEntry[] results = await base.Search(filter);

            return System.Linq.Enumerable.Select<SyncRegitryEntry,string>(results
,(Func<SyncRegitryEntry,string>)(x => x.EntityIdentifier))
                .ToArray();
        }

        public async Task SetStatusFor(string entityType, string entityIdentifier, SyncStatus syncStatus)
        {
            SyncRegitryEntry entry = new SyncRegitryEntry
            {
                EntityType = entityType,
                EntityIdentifier = entityIdentifier,
                SyncStatus = (int)syncStatus,
            };

            await base.Save(storage.SyncRegistry, entry);
        }

        public async Task<SyncStatus> StatusFor(string entityType, string entityIdentifier)
        {
            SyncRegitryEntry entry = new SyncRegitryEntry { EntityType = entityType, EntityIdentifier = entityIdentifier };

            SyncRegitryEntry existingEntry = await base.Load(storage.SyncRegistry, entry.ID);

            return (SyncStatus)(existingEntry ?? entry).SyncStatus;
        }

        protected override dexie.Dexie.Collection<object, object> ApplyFilter(object filter)
        {
            SyncRegistryFilter filterRequest = filter.As<SyncRegistryFilter>();

            dexie.Dexie.Collection<object, object> filteredCollection;

            if (filterRequest == null)
                return storage.SyncRegistry.toCollection();

            filteredCollection = storage.SyncRegistry.toCollection();

            if ((global::Bridge.Script.ToTemp("key1",filterRequest.SyncStatuses)!=null?global::Bridge.Script.FromTemp<SyncStatus[]>("key1").Any():(bool?)null) ?? false)
            {
                filteredCollection = filteredCollection.and((dexie.Dexie.Collection<object,object>.andFn)(x => H.Skeepy.Core.Common.DataExtensions.In<int>(((int)x["SyncStatus"]),filterRequest.SyncStatusesAsInt)));
            }

            if ((global::Bridge.Script.ToTemp("key2",filterRequest.EntityTypes)!=null?global::Bridge.Script.FromTemp<string[]>("key2").Any():(bool?)null) ?? false)
            {
                filteredCollection = filteredCollection.and((dexie.Dexie.Collection<object,object>.andFn)(x => H.Skeepy.Core.Common.DataExtensions.In<string>(((string)x["EntityType"]),filterRequest.EntityTypes)));
            }

            if ((global::Bridge.Script.ToTemp("key3",filterRequest.EntityIdentifiers)!=null?global::Bridge.Script.FromTemp<string[]>("key3").Any():(bool?)null) ?? false)
            {
                filteredCollection = filteredCollection.and((dexie.Dexie.Collection<object,object>.andFn)(x => H.Skeepy.Core.Common.DataExtensions.In<string>(((string)x["EntityIdentifier"]),filterRequest.EntityIdentifiers)));
            }

            if (filterRequest.Limit != null)
                filteredCollection = filteredCollection.limit(filterRequest.Limit.Value);


            return filteredCollection;
        }
    }

    public class SyncRegitryEntry
    {
public string ID
{
    get
    {
        return string.Format("{0}_{1}",EntityType,EntityIdentifier);
    }
}
        public string EntityIdentifier { get; set; }

        public string EntityType { get; set; }

        public int SyncStatus { get; set; }

    
private int __Property__Initializer__SyncStatus=(int)Core.Operations.Sync.SyncStatus.Inexistent;}

    public class SyncRegistryFilter
    {
        public string[] EntityTypes { get; set; }

        public string[] EntityIdentifiers { get; set; }

        public SyncStatus[] SyncStatuses { get; set; }
public int[] SyncStatusesAsInt
{
    get
    {
        return global::Bridge.Script.ToTemp("key4",SyncStatuses)!=null&&global::Bridge.Script.ToTemp("key5",global::Bridge.Script.FromTemp<SyncStatus[]>("key4").Select((Func<SyncStatus,int>)(x => (int)x)))!=null?global::Bridge.Script.FromTemp<EnumerableInstance<int>>("key5").ToArray():(int[])null;
    }
}
        public int? Limit { get; set; }
    }
}
