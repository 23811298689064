using H.Skeepy.Core.Common;
using H.Skeepy.Core.Infrastructure;
using H.Skeepy.Core.Model;
using H.Skeepy.Core.Model.GPS;
using System;
using System.Threading.Tasks;

namespace H.Skeepy.Core.Operations.UseCases.Concrete
{
    public class PinUseCase : ImASkeepyOperationContract, ImAPinUseCase
    {
        #region Construct
        Storage.ImAPinStorageService pinStorageService;
        ImAGpsService gpsService;
        SkeepyConsumer device;
        public void ReferDependencies(ImADependencyPoolContainer dependencyPoolContainer)
        {
            device = device ?? dependencyPoolContainer.Resolve<SkeepyConsumer>();
            pinStorageService = pinStorageService ?? dependencyPoolContainer.Resolve<Storage.ImAPinStorageService>();
            gpsService = gpsService ?? dependencyPoolContainer.Resolve<ImAGpsService>();
        }
        #endregion

        public async Task<Pin> New()
        {
            Pin pin = new Pin { SkeepyConsumerID = device.ID };

            await pinStorageService.Save(pin);

            return pin;
        }

        public async Task<Pin> AttachGpsTo(Pin pin)
        {
            GpsLocation? gpsLocation = null;

            await (new Func<Task>(async () => gpsLocation = await gpsService.GetCurrentLocation())).TryOrFailWithGrace(1, (Action<Exception>)(x => { }));

            if (gpsLocation == null)
                return pin;

            pin.Gps = gpsLocation;

            await pinStorageService.Save(pin);

            return pin;
        }
    }
}
