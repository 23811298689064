using Bridge;
using Bridge.React;
using H.Skeepy.Click.Web.UI.Components.UI.Styles;
using System;

namespace H.Skeepy.Click.Web.UI.Components.UI.Controls
{
    public class Switch : ComponentBase<Switch.Props, Switch.State>
    {
        public Switch(Props props, params Union<ReactElement, string>[] children) : base(props, children) { }
        public Switch(params Union<ReactElement, string>[] children) : this(new Props { }, children) { }

        public override ReactElement Render()
        {
            ReactStyle reactStyle = new ReactStyle
            {
                MinHeight = style.SizingUnitInPixels,
                Width = props.Width,
            };

            return
                DOM.Div(
                    new Attributes
                    {
                        Style = reactStyle.FlexNode(),
                    },

                    RenderSwitch(),

                    DOM.Div(
                        new Attributes
                        {
                            Style = new ReactStyle { Height = "100%", PaddingLeft = 5 }.FlexNode()
                        },

                        Children

                    )
                );
        }

        private ReactElement RenderSwitch()
        {
            return
                DOM.Div(
                    new Attributes
                    {
                        Style = new ReactStyle
                        {
                            Height = style.SizingUnitInPixels,
                            Width = style.SizingUnitInPixels * 2,
                            Border = "solid 1px",
                            BorderColor = style.Colors.Primary.Color.Hex,
                            Position = Bridge.Html5.Position.Relative,
                            Cursor = props.IsDisabled ? Bridge.Html5.Cursor.NotAllowed : Bridge.Html5.Cursor.Pointer,
                            Opacity = props.IsDisabled ? "0.25" : null,
                        },
                        OnClick = x => { if (props.IsDisabled) return; ToggleSwitch(); },
                    },
                    RenderSwitchKnob()
                );
        }

        private ReactElement RenderSwitchKnob()
        {
            ReactStyle reactStyle = new ReactStyle
            {
                Height = style.SizingUnitInPixels - 2,
                Width = style.SizingUnitInPixels - 2,
                Position = Bridge.Html5.Position.Absolute,
                Top = 1,
                Right = 1,
                BackgroundColor = style.Colors.Primary.Color.Hex,
            };

            if (state.IsOff)
            {
                reactStyle.Right = null;
                reactStyle.Left = 1;
                reactStyle.BackgroundColor = style.Colors.Primary.Lighter(2).Hex;
            }

            return
                DOM.Div(
                    new Attributes
                    {
                        Style = reactStyle,
                    }
                );
        }

        private void ToggleSwitch()
        {
            state.IsOn = !state.IsOn;
            SetState();
            global::Bridge.Script.ToTemp("key1",props.OnChange)!=null?global::Bridge.Script.FromLambda(()=>global::Bridge.Script.FromTemp<Action<bool>>("key1").Invoke(state.IsOn)):null;
        }

        protected override State GetInitialState()
        {
            State state = base.GetInitialState();
            state.IsOn = props.IsOn;
            return state;
        }

        protected override void ComponentWillReceiveProps(Props nextProps)
        {
            base.ComponentWillReceiveProps(nextProps);

            state.IsOn = nextProps.IsOn;
            SetState();
        }

        public class State
        {
            public bool IsOn { get; set; }
public bool IsOff
{
    get
    {
        return !IsOn;
    }
}
        
private bool __Property__Initializer__IsOn=false;}
        public class Props
        {
            public bool IsDisabled { get; set; }
            public bool IsOn { get; set; }
public bool IsOff
{
    get
    {
        return !IsOn;
    }
}            public Union<string, int> Width { get; set; }
            public Action<bool> OnChange { get; set; }

        
private bool __Property__Initializer__IsDisabled=false;private bool __Property__Initializer__IsOn=false;private Union<string, int> __Property__Initializer__Width=200;}
    }
}
