using H.Skeepy.Core.Infrastructure;
using H.Skeepy.Core.Model;
using H.Skeepy.Core.Operations.UI.Abstract;
using H.Skeepy.Core.Operations.UI.Navigation;
using H.Skeepy.Core.Operations.UseCases;
using System;
using System.Threading.Tasks;

namespace H.Skeepy.Core.Operations.UI.ViewState.PinGroupViewStates
{
    public class DefineNewPinGroupViewState : ViewStateBase
    {
        #region Construct
        readonly PinGroup pinGroup = new PinGroup();
        readonly PinGroupSettings pinGroupSettings = new PinGroupSettings();

        ImAPinGroupUseCase pinGroupUseCase;
        ImAGpsService gpsService;
        public override void ReferDependencies(ImADependencyPoolContainer dependencyPoolContainer)
        {
            base.ReferDependencies(dependencyPoolContainer);

            gpsService = gpsService ?? dependencyPoolContainer.Resolve<ImAGpsService>();
            pinGroupUseCase = pinGroupUseCase ?? dependencyPoolContainer.Resolve<ImAPinGroupUseCase>();
            pinGroupUseCase.Use(pinGroup);
            pinGroupUseCase.Use(pinGroupSettings);
        }
        #endregion

        #region Properties
        public string Name {
    get
    {
        return pinGroup.Name;
    }

    
    set
    {
        pinGroup.Name = value;
    }
    
}        public string Description {
    get
    {
        return pinGroup.Description;
    }

    
    set
    {
        pinGroup.Description = value;
    }
    
}        public bool IsLocationTrackingEnabled {
    get
    {
        return pinGroupSettings.IsLocationTrackingEnabled;
    }

    
    set
    {
        pinGroupSettings.IsLocationTrackingEnabled = value;
    }
    
}        #endregion

        #region Operations
        public override void Bind()
        {
            base.Bind();

            Bind("Name", (Func<object>)(() => Name), (Action<object>)(x => Name = (string)x));
            Bind("Description", (Func<object>)(() => Description), (Action<object>)(x => Description = (string)x));
            Bind("IsLocationTrackingEnabled", (Func<object>)(() => IsLocationTrackingEnabled), (Action<object>)(x => IsLocationTrackingEnabled = (bool)x));
        }

        public async Task TryCreateNewPinGroup()
        {
            using (BusyIndicator())
            {
                ViewStateValidationResult validationResult = await Validate();
                if (!validationResult.IsValid)
                    return;

                validationResult = await RunWithValidation((Func<Task>)(() => pinGroupUseCase.PersistNew()));

                if (!validationResult.IsValid)
                    return;

                uiNavigator.Go<PinGroupOperationsViewState>(new UiNavigationParams(pinGroup.ID));
            }
        }

        public void Cancel()
        {
            uiNavigator.Go<WelcomeViewState>();
        }
        #endregion

        protected override async Task<ViewStateValidationResult> DoValidation()
        {
            ViewStateValidationResult result = new ViewStateValidationResult();

            if (string.IsNullOrWhiteSpace(pinGroup.Name))
                result.FailBecause("The Pin Group must have a name");

            if (pinGroupSettings.IsLocationTrackingEnabled)
            {
                Model.GPS.GpsLocation? location = await gpsService.GetCurrentLocation();
                if(location == null)
                    result.FailBecause("The GPS service is not available or it is not allowed to be used by Click. Please make sure your device has a working GPS tracker and that Click has explicit acces to it.");
            }

            return result;
        }
    }
}
