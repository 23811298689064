using Bridge.Html5;
using H.Skeepy.Click.Web.UI.Common;
using H.Skeepy.Core.Infrastructure;
using H.Skeepy.Core.Model.GPS;
using H.Skeepy.Core.Operations;
using System;
using System.Threading.Tasks;

namespace H.Skeepy.Click.Web.UI.Components.GPS
{
    public class GpsService : ImASkeepyOperationContract, ImAGpsService
    {
        private ImALogService logger;

        public void ReferDependencies(ImADependencyPoolContainer dependencyPoolContainer)
        {
            logger = logger ?? dependencyPoolContainer.Resolve<ImALogService>();
        }

        private readonly bool isGpsServiceAvailable = false;

        public GpsService()
        {
            isGpsServiceAvailable = (Window.Navigator!=null?Window.Navigator.Geolocation:(Geolocation)null) != null;
        }

        public Task<GpsLocation?> GetCurrentLocation()
        {
            if (!isGpsServiceAvailable)
            {
                logger.Log(new Core.Model.Logging.LogEntry
                {
                    Message = "GPS Service is not available on this device. The returned location will always be NULL",
                    Level = Core.Model.Logging.LogEntryLevel.Warn,
                });
                return Task.FromResult<GpsLocation?>(null);
            }

            TaskCompletionSource<GpsLocation?> taskCompletionSource = new TaskCompletionSource<GpsLocation?>();

            Window.Navigator.Geolocation.GetCurrentPosition(
                success: (Action<GeolocationPosition>)(position =>
                {
                    taskCompletionSource.SetResult(new GpsLocation
                    {
                        Point = new GpsPoint
                        {
                            LatInDegrees = position.Coords.Latitude,
                            LngInDegrees = position.Coords.Longitude,
                            AltFromSeaLevelInMeters = position.Coords.Altitude,
                        },
                        AltAccuracyInMeters = position.Coords.AltitudeAccuracy,
                        GndAccuracyInMeters = position.Coords.Accuracy,
                        HeadingInDegrees = position.Coords.Heading,
                        SpeedInMetersPerSecond = position.Coords.Speed,
                        Timestamp = position.Timestamp.UnixToDateTime(),
                    });
                }),
                error: (Action<GeolocationPositionError>)(err =>
                {
                    taskCompletionSource.SetException(new InvalidOperationException(string.Format("Error getting GPS position. Code {0}: {1}",err.Code.ValueOf(),err.Message)));
                }),
                options: new GeolocationPositionOptions
                {
                    EnableHighAccuracy = true,
                    Timeout = 1000 * 10,
                    MaximumAge = 1000 * 60 * 5,
                }
            );

            return taskCompletionSource.Task;
        }


    }
}
