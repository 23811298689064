using System.Linq;

namespace H.Skeepy.Core.Model.Notifications
{
    public class NotificationPackage
    {
        static readonly NotificationEndpoint[] whomToNotifyAboutAppEvents
            = new NotificationEndpoint[] {
                "hinteadan@yahoo.co.uk".ToNotificationEndpoint("Dan Hintea"),
                "hinteadan@gmail.com".ToNotificationEndpoint("Dan Hintea"),
            };

        static readonly NotificationEndpoint noReplySkeepyNotificationsEmailEndpoint
            = new NotificationEndpoint { Name = "SKeepy Notification (Don't Reply)", Address = "no-reply-skeepy-notifications@skeepy.ro" };

        public NotificationMechanism Mechanism { get; set; }
        public NotificationEndpoint Sender { get; set; }
        public NotificationEndpoint[] Receivers { get; set; }
        public NotificationEndpoint[] GhostReceivers { get; set; }
        public NotificationMessage Message { get; set; }

        public static NotificationPackage NoReplyEmail(NotificationEndpoint[] to, string subject, string content, bool isHtml = false, NotificationEndpoint[] ghosts = null)
        {
            return
                NoReplyEmail(
                    new NotificationMessage
                    {
                        Content = content,
                        Subject = subject,
                        IsHtmlContent = isHtml,
                    },
                    to,
                    ghosts
                );
        }

        public static NotificationPackage NoReplyEmail(NotificationMessage notificationMessage, NotificationEndpoint[] to, NotificationEndpoint[] ghosts = null)
        {
            return
                new NotificationPackage
                {
                    Sender = noReplySkeepyNotificationsEmailEndpoint,
                    Receivers = to ?? new NotificationEndpoint[0],
                    GhostReceivers = ghosts ?? new NotificationEndpoint[0],
                    Mechanism = NotificationMechanism.Email,
                    Message = notificationMessage,
                }
                ;
        }
public static NotificationPackage AppEventEmail(NotificationMessage notificationMessage, NotificationEndpoint[] to = null)
{
    return NoReplyEmail(notificationMessage, to: ((to!=null?to.Any():(bool?)null) ?? false) ? to : whomToNotifyAboutAppEvents, ghosts: ((to!=null?to.Any():(bool?)null) ?? false) ? whomToNotifyAboutAppEvents : null);
}
    
private NotificationMechanism __Property__Initializer__Mechanism=NotificationMechanism.Email;}

    public static class NotificationPackageExtensions
    {
        public static NotificationEndpoint ToNotificationEndpoint(this string address, string name = null)
        {
            return new NotificationEndpoint
            {
                Address = address,
                Name = name,
            };
        }
    }
}
