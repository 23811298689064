using H.Skeepy.Core.Model.GPS;
using System;

namespace H.Skeepy.Core.Model
{
    public class Pin
    {
        public Guid ID { get; set; }

        public Guid SkeepyConsumerID { get; set; }
        public DateTime HappenedAt { get; set; }
public long HappenedAtTicks
{
    get
    {
        return HappenedAt.Ticks;
    }
}
        public GpsLocation? Gps { get; set; }

    
private Guid __Property__Initializer__ID=Guid.NewGuid();private DateTime __Property__Initializer__HappenedAt=Rolex.Time;private GpsLocation? __Property__Initializer__Gps=null;}
}
