using Bridge;
using Bridge.Html5;
using H.Skeepy.Core.Common.ExecutionTamers;
using H.Skeepy.Core.Infrastructure;
using H.Skeepy.Core.Model;
using H.Skeepy.Core.Operations.Sync;
using System;
using System.Threading.Tasks;

namespace H.Skeepy.Core.Operations.Storage.Concrete
{
    public class JSZipDataZipper : ImADataZipper, ImASkeepyOperationContract
    {
        #region Construct
        string packageName;
        JSZip jsZip;
        Throttler progressReportThrottler;

        public void ReferDependencies(ImADependencyPoolContainer dependencyPoolContainer)
        {
            progressReportThrottler = progressReportThrottler ?? dependencyPoolContainer.Resolve<Throttler>();
            progressReportThrottler.Use(throttleInterval: TimeSpan.FromSeconds(.15));
        }

        public ImADataZipper NewPackage(string name = null)
        {
            jsZip = new JSZip();
            packageName = string.IsNullOrWhiteSpace(name) ? Guid.NewGuid().ToString() : name;
            return this;
        }
        #endregion

        public ImADataZipper DeleteFileOrFolder(string path)
        {
            EnsureNewPackageWasCalled();

            jsZip.remove(path);

            return this;
        }

        public ImADataZipper UpsertFile(string filePathAndName, string fileContent)
        {
            EnsureNewPackageWasCalled();

            jsZip.file(filePathAndName, fileContent);

            return this;
        }

        public Task<DataBucket> WrapPackage(Action<ZipProgressInfo> onProgress = null)
        {
            EnsureNewPackageWasCalled();

            TaskCompletionSource<DataBucket> taskCompletionSource = new TaskCompletionSource<DataBucket>();

            ZipProgressInfo zipProgressInfo = new ZipProgressInfo { CurrentFile = string.Empty, GlobalProgressPercent = 0 };
            progressReportThrottler.Use(actionToTame: (Action)(() => onProgress!=null?global::Bridge.Script.FromLambda(()=>onProgress.Invoke(zipProgressInfo)):null));

            jsZip
                .generateAsync(new { type = "blob" }, (Action<dynamic>)(async x => { 
                    zipProgressInfo.CurrentFile = x.currentFile;
                    zipProgressInfo.GlobalProgressPercent = x.percent;
                    await progressReportThrottler.Invoke();
                }))
                .then(new Action<Blob>(blob =>
                {
                    taskCompletionSource.SetResult(
                        new DataBucket(blob)
                            .WithName(packageName, "zip")
                            .WithType(typeof(Blob).TypeName(), "application/zip", "JS ZIP Blob")
                            .WithPayloadSize(blob.Size)
                    );

                    DisposeZipPackage();
                }), new Action<object>(err =>
                {
                    taskCompletionSource.SetException(new InvalidOperationException(err.ToString()));
                    DisposeZipPackage();
                }));

            return taskCompletionSource.Task;
        }

        private void EnsureNewPackageWasCalled()
        {
            if (jsZip == null)
                throw new InvalidOperationException("NewPackage wasn't called");
        }

        private void DisposeZipPackage()
        {
            jsZip = null;
            packageName = null;
        }

        #region JSZip API
#pragma warning disable CS0824 // Constructor is marked external
#pragma warning disable CS0626 // Method, operator, or accessor is marked external and has no attributes on it
        [External]
        [Name("JSZip")]
        private class JSZip
        {
            public extern JSZip();

            public extern JSZip file(string name, string content);

            public extern dynamic file(string name);

            public extern JSZip folder(string name);

            public extern JSZip remove(string fileOrFolderPath);

            public extern dynamic generateAsync(dynamic options, Action<dynamic> onUpdate);

            public extern dynamic loadAsync(dynamic content);

            public extern static dynamic support { get; }
        }
#pragma warning restore CS0626 // Method, operator, or accessor is marked external and has no attributes on it
#pragma warning restore CS0824 // Constructor is marked external
        #endregion
    }
}
