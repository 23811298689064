using H.Skeepy.Core.Infrastructure;
using H.Skeepy.Core.Operations.Sync;
using H.Skeepy.Core.Operations.Sync.Concrete;
using System;
using System.Threading.Tasks;

namespace H.Skeepy.Core.Operations.Storage.Abstract
{
    public abstract class SyncedStorage<TEntity, TId> : ImASkeepyOperationContract, IWantToSync<TEntity, TId>
    {
        #region Construct
        ImASyncRegistry syncRegistry;
        SyncableTypesRegistry syncableTypesRegistry;
        public void ReferDependencies(ImADependencyPoolContainer dependencyPoolContainer)
        {
            syncRegistry = syncRegistry ?? dependencyPoolContainer.Resolve<ImASyncRegistry>();
            syncableTypesRegistry = syncableTypesRegistry ?? dependencyPoolContainer.Resolve<SyncableTypesRegistry>();
            syncableTypesRegistry.RegisterTypeAsSyncable<TEntity>((Func<string,Task<object>>)LoadEntity);
        }

        protected Func<TId, Task<TEntity>> entityLoader;
public void Use(Func<TId, Task<TEntity>> entityLoader)
{
    this.entityLoader = entityLoader;
}        #endregion

        protected abstract TId GetIdFor(TEntity entity);
protected virtual string GetSyncId(TId id)
{
    return id.ToString();
}
        protected abstract TId ParseSyncId(string id);

        public abstract Task<TEntity> Load(TId id);
private async Task<object> LoadEntity(string id)
{
    return await Load(ParseSyncId(id));
}
        public virtual async Task Save(TEntity entity)
        {
            await syncRegistry.SetStatusFor(typeof(TEntity).TypeName(), GetSyncId(GetIdFor(entity)), SyncStatus.NotSynced);
        }

        public virtual async Task Delete(TId id)
        {
            await syncRegistry.SetStatusFor(typeof(TEntity).TypeName(), GetSyncId(id), SyncStatus.DeletedAndNotSynced);
        }
    }    
}
