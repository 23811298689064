using H.Skeepy.Core.Common;
using H.Skeepy.Core.Infrastructure;
using H.Skeepy.Core.Operations.UseCases;
using System;
using System.Linq;
using System.Threading.Tasks;

namespace H.Skeepy.Core.Operations.Broadcasting.Abstract
{
    public abstract class BroadcastReceiverBase
        : ImABroadcastReceiver
        , ImASkeepyOperationContract
        , ImAUseCaseBootstrapper<Func<ImABroadcastEvent, Task>>
        , ImAUseCaseBootstrapper<Type[]>
    {
        #region Construct
        ImABroadcastingTower broadcastingTower;
        public void ReferDependencies(ImADependencyPoolContainer dependencyPoolContainer)
        {
            if (broadcastingTower == null)
            {
                broadcastingTower = dependencyPoolContainer.Resolve<ImABroadcastingTower>();
                broadcastingTower.NewTransmission += BroadcastingTower_NewTransmission;
            }
        }

        Func<ImABroadcastEvent, Task> playTransmissionHandler = null;
        public void Use(Func<ImABroadcastEvent, Task> playTransmissionHandler)
        {
            this.playTransmissionHandler = playTransmissionHandler;
        }

        Type[] knownTypes = new Type[] { typeof(object) };
        public void Use(params Type[] knownTypes)
        {
            this.knownTypes = knownTypes ?? this.knownTypes;
        }
        #endregion

        public virtual async Task PlayTransmission(ImABroadcastEvent broadcastEvent)
        {
            await (playTransmissionHandler!=null?playTransmissionHandler.Invoke(broadcastEvent):(Task)null);
        }

        public virtual void Dispose()
        {
            if (broadcastingTower != null)
            {
                broadcastingTower.NewTransmission -= BroadcastingTower_NewTransmission;
            }
        }

        private async void BroadcastingTower_NewTransmission(object sender, ImABroadcastEvent broadcastEvent)
        {
            if (broadcastEvent == null)
                return;

            if (!IsKnownType(broadcastEvent.Type))
                return;

            await PlayTransmission(broadcastEvent);
        }

        private bool IsKnownType(Type type)
        {
            return System.Linq.Enumerable.Any<Type>(knownTypes,(Func<Type,bool>)(t => t.IsSameOrSubclassOf(type)));
        }
    }
}
