using System;
using System.Linq;

namespace H.Skeepy.Core.Model.Display
{
    public class DetailedPinGroupDisplay : PinGroupDisplay
    {
        public DetailedPinGroupDisplay(PinGroup pinGroup, PinGroupSettings pinGroupSettings = null, params PinDisplay[] pins)
            : base(pinGroup, pinGroupSettings)
        {
            DateTime now = Rolex.Time;

            Pins = pins ?? new PinDisplay[0];

            PinsWithGps = CalculatePinsWithGps(Pins);

            StartedAt =
System.Linq.Enumerable.Any<PinDisplay>(                Pins)
                ? (DateTime?)System.Linq.Enumerable.Min<PinDisplay,DateTime>(Pins,(Func<PinDisplay,DateTime>)(p => p.HappenedAt))
                : null
                ;

            EndedAt =
                StartedAt == null
                ? null
                : !pinGroup.IsSealed
                ? null
                : (DateTime?)System.Linq.Enumerable.Max<PinDisplay,DateTime>(Pins,(Func<PinDisplay,DateTime>)(p => p.HappenedAt))
                ;

            Duration = (EndedAt ?? now) - (StartedAt ?? now);

            TimeSpan[] pinIntervals = CalculatePinIntervals();

            if (System.Linq.Enumerable.Any<TimeSpan>(pinIntervals))
            {
                PinIntervals = pinIntervals;
                MinimumPinInterval = System.Linq.Enumerable.Min<TimeSpan>(pinIntervals);
                AveragePinInterval = TimeSpan.FromSeconds(System.Linq.Enumerable.Average<TimeSpan>(pinIntervals,(Func<TimeSpan,int>)(x => (int)x.TotalSeconds)));
                MaximumPinInterval = System.Linq.Enumerable.Max<TimeSpan>(pinIntervals);
            }
        }

        public DateTime? StartedAt { get; private set; }
        public DateTime? EndedAt { get; private set; }
        public TimeSpan Duration { get; private set; }
        public TimeSpan MinimumPinInterval { get; private set; }
        public TimeSpan AveragePinInterval { get; private set; }
        public TimeSpan MaximumPinInterval { get; private set; }
        public PinDisplay[] Pins { get; private set; }
        public PinDisplay[] PinsWithGps { get; private set; }
        public TimeSpan[] PinIntervals { get; private set; }

        private TimeSpan[] CalculatePinIntervals()
        {
            if (Pins.Length < 2)
                return new TimeSpan[0];

            TimeSpan[] result = new TimeSpan[Pins.Length - 1];

            PinDisplay previousPin = Pins[0];

            for (int i = 1; i < Pins.Length; i++)
            {
                PinDisplay pin = Pins[i];
                int resultIndex = i - 1;
                result[resultIndex] = pin.HappenedAt - previousPin.HappenedAt;
                previousPin = pin;
            }

            return result;
        }

        private PinDisplay[] CalculatePinsWithGps(params PinDisplay[] pins)
        {
            if (pins == null)
                return new PinDisplay[0];

            return
System.Linq.Enumerable.GroupBy<PinDisplay,GPS.GpsPoint?>(                pins
,(Func<PinDisplay,GPS.GpsPoint?>)(x => global::Bridge.Script.ToTemp("key1",x.GpsTile)!=null?global::Bridge.Script.FromTemp<GPS.GpsLocation>("key1").Point:(GPS.GpsPoint?)null))
                    .Where((Func<Grouping<GPS.GpsPoint?,PinDisplay>,bool>)(x => x.Key != null))
                    .Select<PinDisplay>((Func<Grouping<GPS.GpsPoint?,PinDisplay>,PinDisplay>)(x => x.First()))
                    .ToArray();
        }

    
private DateTime? __Property__Initializer__StartedAt=null;private DateTime? __Property__Initializer__EndedAt=null;private TimeSpan __Property__Initializer__Duration=TimeSpan.Zero;private TimeSpan __Property__Initializer__MinimumPinInterval=TimeSpan.Zero;private TimeSpan __Property__Initializer__AveragePinInterval=TimeSpan.Zero;private TimeSpan __Property__Initializer__MaximumPinInterval=TimeSpan.Zero;private PinDisplay[] __Property__Initializer__Pins=new PinDisplay[0];private PinDisplay[] __Property__Initializer__PinsWithGps=new PinDisplay[0];private TimeSpan[] __Property__Initializer__PinIntervals=new TimeSpan[0];}
}