using H.Skeepy.Core.Common;
using H.Skeepy.Core.Infrastructure;
using H.Skeepy.Core.Model;
using H.Skeepy.Core.Model.Display.Health;
using H.Skeepy.Core.Operations.UI.Abstract;
using H.Skeepy.Core.Operations.UseCases;
using System;
using System.Linq;
using System.Threading.Tasks;

namespace H.Skeepy.Core.Operations.UI.ViewState.Health
{
    public class StorageHealthViewState : ViewStateBase
    {
        #region Construction
        ImAStorageHealthUseCase storageHealthUseCase;
        public override void ReferDependencies(ImADependencyPoolContainer dependencyPoolContainer)
        {
            base.ReferDependencies(dependencyPoolContainer);

            storageHealthUseCase = storageHealthUseCase ?? dependencyPoolContainer.Resolve<ImAStorageHealthUseCase>();
        }
        #endregion

        #region Properties
        StorageStatistics[] storageStatistics = null;
        public StorageStatistics[] StorageStatistics
        {
            get { return storageStatistics; }
            set
            {
                storageStatistics = value;
                viewUpdater!=null?global::Bridge.Script.FromLambda(()=>viewUpdater.Invoke("StorageStatistics")):null;
            }
        }

        StorageExport storageExport = null;
        public StorageExport StorageExport
        {
            get { return storageExport; }
            set
            {
                storageExport = value;
                viewUpdater!=null?global::Bridge.Script.FromLambda(()=>viewUpdater.Invoke("StorageExport")):null;
            }
        }

        float? storageExportProgress = null;
        public float? StorageExportProgress
        {
            get { return storageExportProgress; }
            set
            {
                storageExportProgress = value;
                viewUpdater!=null?global::Bridge.Script.FromLambda(()=>viewUpdater.Invoke("StorageExportProgress")):null;
            }
        }

        float? bundleExportProgress = null;
        public float? BundleExportProgress
        {
            get { return bundleExportProgress; }
            set
            {
                bundleExportProgress = value;
                viewUpdater!=null?global::Bridge.Script.FromLambda(()=>viewUpdater.Invoke("BundleExportProgress")):null;
            }
        }

        DataBucket ZipArtifact { get; set; }
        #endregion

        #region Operations
        public override async Task Initialize()
        {
            using (BusyIndicator())
            {
                await base.Initialize();

                storageHealthUseCase.OnExportStorageProgress += StorageHealthUseCase_OnExportStorageProgress;
                storageHealthUseCase.OnBundleExportProgress += StorageHealthUseCase_OnBundleExportProgress;

                await
                    new Func<Task>(RefreshStorageStatistics)
                    .TryOrFailWithGrace(
                        numberOfTimes: 1,
onFail:(Action<Exception>)(                        x =>
                        {
                            StorageStatistics = null;
                            InitializeResult.FailBecause(System.Linq.Enumerable.Select<Exception,string>(x.Flatten(),(Func<Exception,string>)(ex => ex.Print())).ToArray());
                        }
)                        );

                if (StorageStatistics == null && InitializeResult.IsValid)
                {
                    InitializeResult.FailBecause(string.Format("No Storage Statistics were loaded"));
                }
            }

        }

        public override async Task Leaving()
        {
            storageHealthUseCase.OnExportStorageProgress -= StorageHealthUseCase_OnExportStorageProgress;

            await base.Leaving();
        }

        public async Task TriggerStorageExport(StorageIdentifier storage, params string[] collections)
        {
            StorageExportProgress = 0;

            await
                new Func<Task>(async () => StorageExport = await storageHealthUseCase.ExportStorage(storage, collections))
                .TryOrFailWithGrace(
                    numberOfTimes: 1,
                    onFail: (Action<Exception>)(x => ValidationResult = ViewStateValidationResult.Fail(x)
)                );

            StorageExportProgress = null;
        }

        public async Task TriggerWrapAndDownloadExport()
        {
            BundleExportProgress = 0;

            ZipArtifact = null;

            await
                new Func<Task>(async () => ZipArtifact = await storageHealthUseCase.BundleExport(StorageExport))
                .TryOrFailWithGrace(
                    numberOfTimes: 1,
                    onFail: (Action<Exception>)(x => ValidationResult = ViewStateValidationResult.Fail(x)
)                );

            BundleExportProgress = null;

            if (ZipArtifact != null)
                await storageHealthUseCase.DeliverBundle(ZipArtifact);
        }

        public void GoHome()
        {
            uiNavigator.Go<WelcomeViewState>();
        }

        private async Task RefreshStorageStatistics()
        {
            StorageStatistics = await storageHealthUseCase.FetchAllStorageStatistics();
        }

        private void StorageHealthUseCase_OnExportStorageProgress(object sender, OperationProgressEventArgs e)
        {
            StorageExportProgress = e.Percent;
        }

        private void StorageHealthUseCase_OnBundleExportProgress(object sender, OperationProgressEventArgs e)
        {
            if (ZipArtifact != null)
            {
                if (BundleExportProgress != null)
                    BundleExportProgress = null;

                return;
            }

            BundleExportProgress = e.Percent;
        }

        #endregion
    
private 
        DataBucket __Property__Initializer__ZipArtifact=null;}
}
