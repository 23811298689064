using H.Skeepy.Core.Common;
using H.Skeepy.Core.Operations.Templates;

namespace H.Skeepy.Core.Model.Notifications.Templates
{
    public class TemplateMessageInfo
    {
        public string Subject { get; set; }

        public string ContentTemplateType { get; set; }
        public Iota[] ContentTemplateArgs { get; set; }
        public bool IsHtmlContent { get; set; }

        public string ExcerptTemplateType { get; set; }
        public Iota[] ExcerptTemplateArgs { get; set; }
        public bool IsHtmlExcerpt { get; set; }

        public static TemplateMessageInfo NotificationEmail(string subject, string excerpt, params Iota[] contentArgs)
        {
            return
                new TemplateMessageInfo
                {
                    Subject = subject,
                    ContentTemplateType = KnownTemplateTypes.BasicNotificationEmailTemplate.ToString(),
                    ContentTemplateArgs = contentArgs,
                    ExcerptTemplateArgs = H.Skeepy.Core.Common.DataExtensions.AsArray<Iota>(new Iota("Content", excerpt)),
                    ExcerptTemplateType = null,
                    IsHtmlContent = true,
                    IsHtmlExcerpt = false,
                };
        }
public static TemplateMessageInfo NotificationEmail(string subject, params Iota[] contentArgs)
{
    return NotificationEmail(subject, excerpt: null, contentArgs:contentArgs);
}
    
private bool __Property__Initializer__IsHtmlContent=true;private bool __Property__Initializer__IsHtmlExcerpt=false;}
}
