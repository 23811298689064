using System;
using System.Collections.Generic;
using System.Linq;
using System.Text;

namespace H.Skeepy.Core.Operations.UI.UserInput
{
    public class UserOptionSelectionResult
    {
        private UserOptionSelectionResult(bool hasCanceled, params UserOption[] selectedOptions)
        {
            HasCanceled = hasCanceled;
            SelectedOptions = selectedOptions ?? new UserOption[0];
        }

        public bool HasCanceled { get; private set; }
        public UserOption[] SelectedOptions { get;  private set;  }
public UserOption SelectedOption
{
    get
    {
        return global::Bridge.Script.ToTemp("key1",SelectedOptions)!=null?global::Bridge.Script.FromTemp<UserOption[]>("key1").FirstOrDefault():(UserOption)null;
    }
}public static UserOptionSelectionResult Win(params UserOption[] selectedOptions)
{
    return new UserOptionSelectionResult(false, selectedOptions);
}public static UserOptionSelectionResult Fail()
{
    return new UserOptionSelectionResult(true);
}    }
}
