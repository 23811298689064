using Bridge.React;
using H.Skeepy.Click.Web.UI.Components.UI.Layout;
using H.Skeepy.Core.Operations.UI.Abstract;

namespace H.Skeepy.Click.Web.UI.Pages
{
    public class NotFoundPage : PageBase<NotFoundPage.Props, NotFoundPage.State>
    {
        public NotFoundPage() : base(new Props(), null) { }

        public override ReactElement Render()
        {
            return
                new MainLayout(new CenteredContent("Ooops... this page does not exist!"));
        }

        protected override State GetInitialState()
        {
            App.DependencyPoolContainer.Register<State>((System.Func<object>)(() => new State()));

            return base.GetInitialState();
        }

        public class Props : PagePropsBase { }

        public class State : ViewStateBase { }
    }
}
