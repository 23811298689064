using Bridge;
using H.Skeepy.Click.Web.UI.Common;
using H.Skeepy.Core.Common;
using H.Skeepy.Core.Model;
using System;
using System.Threading.Tasks;

namespace H.Skeepy.Core.Operations.Concrete
{
    public class FileDownloadDeliveryService : FileSaverJSContentDeliveryService { }
    public class FileSaverJSContentDeliveryService : ImAContentDeliveryService
    {
        public Task<OperationResult> Deliver(DataBucket package)
        {
            OperationResult result = OperationResult.Win();

            new Action(() => FileSaver.saveAs(package.ToBlob(), package.NameWithExtension))
                .TryOrFailWithGrace(
                    numberOfTimes: 1,
                    onFail: (Action<Exception>)(x => result = OperationResult.Fail(x)
)                )
                ;

            return Task.FromResult<OperationResult>(result);
        }

        #region FielSaverJS API
        private class FileSaver
        {
public static void saveAs(dynamic blob, string fileName)
{
    Script.Call("saveAs", blob, fileName);
}        }
        #endregion
    }
}
