using H.Skeepy.Core.Infrastructure;

namespace H.Skeepy.Core.Operations.UseCases
{
    public class UseCasesModule : ImADependencyModule
    {
        public void RegisterDependencies(ImADependencyPoolContainer dependencyPoolContainer)
        {
            dependencyPoolContainer.Register<ImAConfigurationUseCase>((System.Func<object>)(() => new Concrete.ConfigurationUseCase()));
            dependencyPoolContainer.Register<ImANotificationUseCase>((System.Func<object>)(() => new Concrete.NotificationUseCase()));
            dependencyPoolContainer.Register<ImAPinGroupUseCase>((System.Func<object>)(() => new Concrete.PinGroupUseCase()));
            dependencyPoolContainer.Register<ImAPinGroupBrowserUseCase>((System.Func<object>)(() => new Concrete.PinGroupBrowserUseCase()));
            dependencyPoolContainer.Register<ImAPinGroupViewerUseCase>((System.Func<object>)(() => new Concrete.PinGroupViewerUseCase()));
            dependencyPoolContainer.Register<ImAPinUseCase>((System.Func<object>)(() => new Concrete.PinUseCase()));
            dependencyPoolContainer.Register<ImASkeepyConsumerUseCase>((System.Func<object>)(() => new Concrete.SkeepyConsumerUseCase()));
            dependencyPoolContainer.Register<ImAStorageHealthUseCase>((System.Func<object>)(() => new Concrete.StorageHealthUseCase()));
            dependencyPoolContainer.Register<ImAStartupUseCase>((System.Func<object>)(() => new Concrete.StartupUseCase()));
        }
    }
}
