using H.Skeepy.Core.Common;
using H.Skeepy.Core.Infrastructure;
using H.Skeepy.Core.Operations.UI.Navigation;
using System;
using System.Linq;
using System.Threading.Tasks;

namespace H.Skeepy.Core.Operations.UI.Abstract
{
    public abstract class ViewStateBase
        : ImASkeepyOperationContract
        , UseCases.ImAUseCaseBootstrapper<Action<string>>
        , UseCases.ImAUseCaseBootstrapper<UiNavigationParams>
    {
        #region Construct
        protected Action<string> viewUpdater;
        protected ImAUiBinder uiBinder;
        protected ImAUiNavigator uiNavigator;
        private ImAVersionProviderService versionProviderService;

        public void Use(Action<string> viewUpdater)
        {
            this.viewUpdater = viewUpdater;
        }

        public virtual void Use(UiNavigationParams uiNavigationParams) { }

        public virtual void ReferDependencies(ImADependencyPoolContainer dependencyPoolContainer)
        {
            uiBinder = uiBinder ?? dependencyPoolContainer.Resolve<ImAUiBinder>();
            uiNavigator = uiNavigator ?? dependencyPoolContainer.Resolve<ImAUiNavigator>();
            versionProviderService = versionProviderService ?? dependencyPoolContainer.Resolve<ImAVersionProviderService>();
        }
        #endregion

        #region Properties
        private ViewStateValidationResult validationResult;
        public ViewStateValidationResult ValidationResult { get { return validationResult; } set { validationResult = value; viewUpdater!=null?global::Bridge.Script.FromLambda(()=>viewUpdater.Invoke("ValidationResult")):null; } }

        private ViewStateValidationResult initializeResult = ViewStateValidationResult.Win();
        public ViewStateValidationResult InitializeResult { get { return initializeResult; } set { initializeResult = value; viewUpdater!=null?global::Bridge.Script.FromLambda(()=>viewUpdater.Invoke("InitializeResult")):null; } }

        private bool isValidating = false;
        public bool IsValidating { get { return isValidating; } set { isValidating = value; viewUpdater!=null?global::Bridge.Script.FromLambda(()=>viewUpdater.Invoke("IsValidating")):null; } }

        private bool isBusy = false;
        public bool IsBusy { get { return isBusy; } set { isBusy = value; viewUpdater!=null?global::Bridge.Script.FromLambda(()=>viewUpdater.Invoke("IsBusy")):null; } }

        private Model.Version version = Model.Version.Unknown;
        public Model.Version Version { get { return version; } set { version = value; viewUpdater!=null?global::Bridge.Script.FromLambda(()=>viewUpdater.Invoke("Version")):null; } }
        #endregion

        public virtual async Task Initialize() 
        {
            initializeResult = ViewStateValidationResult.Win();

            await 
                new Func<Task>(async () => Version = await versionProviderService.GetCurrentVersion())
                .TryOrFailWithGrace(
                    numberOfTimes: 1,
                    onFail: (Action<Exception>)(x => {
                        InitializeResult.FailBecause(System.Linq.Enumerable.Select<Exception,string>(x.Flatten(),(Func<Exception,string>)(ex => ex.Print())).ToArray());
                    }
)                )
                ;
        }

        public virtual Task Leaving() { return Task.FromResult<bool>(true); }

        public virtual void Bind() { }

        public async Task<ViewStateValidationResult> Validate()
        {
            ViewStateValidationResult result = new ViewStateValidationResult();
            try
            {
                IsValidating = true;

                result = await DoValidation();
            }
            catch (Exception ex)
            {
                result.IsValid &= false;
                result.Message = "A fatal error occurred while validating the data";
                result.Addendum = new string[]
                {
                    ex.Message,
                    ex.StackTrace,
                };
            }
            finally
            {
                ValidationResult = result;
                IsValidating = false;
            }

            return result;
        }
public void NotifyUiChange(string propertyName, object value)
{
    uiBinder.NotifyUiChange(propertyName, value);
}
        protected IDisposable BusyIndicator()
        {
            return new IsBusyControlledLifecycle(this);
        }

        protected IDisposable FlagIndicator(Action<bool> indicationSetter)
        {
            return new FlagControlledLifecycle(indicationSetter);
        }

        protected virtual Task<ViewStateValidationResult> DoValidation()
        {
            return Task.FromResult<ViewStateValidationResult>(new ViewStateValidationResult());
        }

        protected void Bind(string propertyName, Func<object> getter, Action<object> setter)
        {
            uiBinder.Wrap(propertyName, (Func<object>)getter, (Action<object>)setter);
            uiBinder.BindViewToModel(propertyName, (Action<object>)(x => uiBinder[propertyName] = x));
            uiBinder.BindModelToView(propertyName, (Action)(() => viewUpdater!=null?global::Bridge.Script.FromLambda(()=>viewUpdater.Invoke(propertyName)):null));
        }

        protected async Task<ViewStateValidationResult> RunWithValidation(Func<Task> operation)
        {
            ViewStateValidationResult result = new ViewStateValidationResult();

            await operation.TryOrFailWithGrace(
                numberOfTimes: 1,
                onFail: (Action<Exception>)(ex =>
                {
                    result.IsValid = false;
                    result.Message = "The requested operation encountered a fatal internal error";
                    result.Addendum = new string[] {
                        ex.Message,
                        ex.StackTrace,
                    };
                }
)            );

            ValidationResult = result;

            return result;
        }

        protected ViewStateValidationResult RunWithValidation(Action operation)
        {
            ViewStateValidationResult result = new ViewStateValidationResult();

            operation.TryOrFailWithGrace(
                numberOfTimes: 1,
                onFail: (Action<Exception>)(ex =>
                {
                    result.IsValid = false;
                    result.Message = "The requested operation encountered a fatal internal error";
                    result.Addendum = new string[] {
                        ex.Message,
                        ex.StackTrace,
                    };
                }
)            );

            ValidationResult = result;

            return result;
        }
    }

    public class FlagControlledLifecycle : IDisposable
    {
        readonly Action<bool> flagSetterDelegate;

        private FlagControlledLifecycle() { }
        public FlagControlledLifecycle(Action<bool> flagSetterDelegate)
        {
            this.flagSetterDelegate = flagSetterDelegate;
            flagSetterDelegate!=null?global::Bridge.Script.FromLambda(()=>flagSetterDelegate.Invoke(true)):null;
        }

        public void Dispose()
        {
            flagSetterDelegate!=null?global::Bridge.Script.FromLambda(()=>flagSetterDelegate.Invoke(false)):null;
        }
    }

    public class IsBusyControlledLifecycle : FlagControlledLifecycle
    {
        public IsBusyControlledLifecycle(ViewStateBase viewState)
            : base(x => viewState.IsBusy = x) { }
    }
}
