using System;
using System.Collections.Generic;
using System.Text;
using H.Skeepy.Core.Infrastructure;

namespace H.Skeepy.Core.Operations.UI.Navigation
{
    public class UiNavigationModule : ImADependencyModule
    {
        public void RegisterDependencies(ImADependencyPoolContainer dependencyPoolContainer)
        {
            dependencyPoolContainer.Register<ImAUiNavigator>((Func<object>)(() => new Concrete.InMemoryUiNavigator()));
        }
    }
}
