using H.Skeepy.Core.Infrastructure;
using H.Skeepy.Core.Model;
using H.Skeepy.Core.Operations.Storage;
using H.Skeepy.Core.Operations.Storage.Concrete;
using H.Skeepy.Core.Operations.Sync;
using System;

namespace H.Skeepy.Click.Web.UI.Components.LocalStorage
{
    public class LocalStorageModule : ImADependencyModule
    {
        public void RegisterDependencies(ImADependencyPoolContainer dependencyPoolContainer)
        {
            dependencyPoolContainer.Register<IndexedDBStorage>((Func<object>)(() => new IndexedDBStorage()));
            dependencyPoolContainer.Register<IndexedDBStorageExplorer>((Func<object>)(() => new IndexedDBStorageExplorer()));

            dependencyPoolContainer.Register<ImASyncRegistry>((Func<object>)(() => new LocalSyncRegistryStorageService()));

            dependencyPoolContainer.Register<IWantToSync<Pin, Guid>>((Func<object>)(() => new StorageSyncer<Pin, Guid>(x => x.ID, Guid.Parse)));
            dependencyPoolContainer.Register<IWantToSync<PinGroup, Guid>>((Func<object>)(() => new StorageSyncer<PinGroup, Guid>(x => x.ID, Guid.Parse)));
            dependencyPoolContainer.Register<IWantToSync<PinGroupSettings, Guid>>((Func<object>)(() => new StorageSyncer<PinGroupSettings, Guid>(x => x.ID, Guid.Parse)));
            dependencyPoolContainer.Register<IWantToSync<SkeepyConsumer, Guid>>((Func<object>)(() => new StorageSyncer<SkeepyConsumer, Guid>(x => x.ID, Guid.Parse)));

            dependencyPoolContainer.Register<ImAPinStorageService>((Func<object>)(() => new PinStorageService()));
            dependencyPoolContainer.Register<ImAPinGroupStorageService>((Func<object>)(() => new PinGroupStorageService()));
            dependencyPoolContainer.Register<ImAPinGroupSettingsStorageService>((Func<object>)(() => new PinGroupSettingsStorageService()));
            dependencyPoolContainer.Register<ImASkeepyConsumerStorageService>((Func<object>)(() => new SkeepyConsumerStorageService()));

            dependencyPoolContainer.Register<ImAStorageExplorer[]>((Func<object>)(() => new ImAStorageExplorer[] {
                dependencyPoolContainer.Resolve<IndexedDBStorageExplorer>()
            }));
        }
    }
}
