using Bridge.React;
using H.Skeepy.Core.Branding;
using System;

namespace H.Skeepy.Click.Web.UI.Components.UI.Elements
{
    public class FontIcon : StatelessComponent<FontIcon.Props>
    {
        public FontIcon(Props props) : base(props, null) { }

        public override ReactElement Render()
        {
            Attributes attributes = ApplyAriaAttributes(ConstructIconAttributesBasedOnProvider());

            attributes.Style = ConstructIconStyle();

            return
                DOM.I(attributes);
        }

        private Attributes ApplyAriaAttributes(Attributes attributes)
        {
            if (props.HasExternalLabel)
                attributes.Aria = new { hidden = true };
            else
                attributes.Aria = new { label = props.IconName };

            return attributes;
        }

        private ReactStyle ConstructIconStyle()
        {
            ReactStyle reactStyle = new ReactStyle { };
            if (props.Color != null)
                reactStyle.Color = props.Color.Hex;

            if (props.Size != null)
                reactStyle.FontSize = props.Size.PointsCss;

            if (props.StyleDecorator != null)
                reactStyle = props.StyleDecorator(reactStyle);
            return reactStyle;
        }

        private Attributes ConstructIconAttributesBasedOnProvider()
        {
            switch (props.Provider)
            {
                case Provider.FabricUI:
                    return new Attributes { ClassName = string.Format("ms-Icon ms-Icon--{0}",props.IconName)};
                default: return new Attributes { };
            }
        }

        public class Props
        {
            public Provider Provider { get; set; }
            public string IconName { get; set; }
            public bool HasExternalLabel { get; set; }
            public Func<ReactStyle, ReactStyle> StyleDecorator { get; set; }
            public ColorInfo Color { get; set; }
            public TypographySize Size { get; set; }

        
private Provider __Property__Initializer__Provider=H.Skeepy.Click.Web.UI.Components.UI.Elements.FontIcon.Provider.FabricUI;private string __Property__Initializer__IconName=null;private bool __Property__Initializer__HasExternalLabel=false;private ColorInfo __Property__Initializer__Color=null;private TypographySize __Property__Initializer__Size=null;}

        public enum Provider
        {
            FabricUI = 0,
        }
    }
}
