using Bridge.React;
using H.Skeepy.Core.Operations.UI.UserInput;
using System;

namespace H.Skeepy.Click.Web.UI.Components.UI.Controls.UserFeedback
{
    public static class UserFeedbackFactory
    {
public static Func<UserOptionsContext, ReactElement> Alert
{
    get
    {
        return x => new Alert(new UserFeedbackProps{UserOptionsContext = x});
    }
}public static Func<UserOptionsContext, ReactElement> Confirm
{
    get
    {
        return x => new Confirm(new UserFeedbackProps{UserOptionsContext = x});
    }
}    }
}
