using Bridge.Html5;
using System;
using System.Threading.Tasks;

namespace H.Skeepy.Core.Infrastructure.Timing.Concrete
{
    public class JsPeriodicAction : ImAPeriodicAction
    {
        private int timeoutId = -1;
        private bool isStarted = false;

        public async void StartDelayed(TimeSpan delay, TimeSpan interval, Func<Task> action)
        {
            if (isStarted)
                return;

            isStarted = true;

            if (delay > TimeSpan.Zero)
                await Task.Delay((int)delay.TotalMilliseconds);

            if (!isStarted)
                return;

            await ExecuteActionAndQueueAnother((Func<Task>)action, interval);
        }
public void Start(TimeSpan interval, Func<Task> action)
{
    StartDelayed(TimeSpan.Zero, interval, (Func<Task>)action);
}public void Start(TimeSpan interval, Action action)
{
    Start(interval, (Func<Task>)(() =>
    {
        action!=null?global::Bridge.Script.FromLambda(()=>action.Invoke()):null;
        return Task.FromResult<bool>(true);
    }

)    );
}public void StartDelayed(TimeSpan delay, TimeSpan interval, Action action)
{
    StartDelayed(delay, interval, (Func<Task>)(() =>
    {
        action!=null?global::Bridge.Script.FromLambda(()=>action.Invoke()):null;
        return Task.FromResult<bool>(true);
    }

)    );
}
        public void Stop()
        {
            if (!isStarted)
                return;

            Window.ClearTimeout(timeoutId);

            isStarted = false;
        }

        private async Task ExecuteActionAndQueueAnother(Func<Task> action, TimeSpan interval)
        {
            await (action!=null?action.Invoke():(Task)null);

            if (!isStarted)
                return;

            timeoutId = Window.SetTimeout((Action)(async () => await ExecuteActionAndQueueAnother((Func<Task>)action, interval)), (int)interval.TotalMilliseconds);
        }
    }
}
