using System;

namespace H.Skeepy.Core.Model
{
    public class PinGroup
    {
        public Guid ID { get; set; }

        public Guid? ParentPinGroupID { get; set; }

        public Guid SkeepyConsumerID { get; set; }

        public DateTime CreatedAt { get; set; }
public long CreatedAtTicks
{
    get
    {
        return CreatedAt.Ticks;
    }
}
        public string Name { get; set; }
        public string Description { get; set; }

        public bool IsSealed { get; set; }

        public Guid[] Pins { get; set; }
public PinGroupSettings GetDefaultSettings()
{
    return new PinGroupSettings{ID = ID, IsLocationTrackingEnabled = false, SkeepyConsumerID = SkeepyConsumerID, };
}
    
private Guid __Property__Initializer__ID=Guid.NewGuid();private Guid? __Property__Initializer__ParentPinGroupID=null;private DateTime __Property__Initializer__CreatedAt=Rolex.Time;private bool __Property__Initializer__IsSealed=false;private Guid[] __Property__Initializer__Pins=new Guid[0];}
}
