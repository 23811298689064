using Bridge;
using Bridge.React;

namespace H.Skeepy.Click.Web.UI.Components.UI.Controls
{
    public class Subtitle : ComponentBase<Subtitle.Props, Subtitle.State>
    {
        public Subtitle(Props props, params Union<ReactElement, string>[] children) : base(props, children) { }
        public Subtitle(params Union<ReactElement, string>[] children) : this(new Props { }, children) { }

        public override ReactElement Render()
        {
            return
                DOM.H2(

                    new Attributes
                    {
                        Style = new ReactStyle
                        {
                            Color = style.Colors.Primary.Lighter().Hex,
                            FontSize = style.Typography.FontSizeLarge.PointsCss,
                            TextAlign = props.TextAlign,
                        },
                    },

                    Children

                );
        }

        public class State { }
        public class Props
        {
            public Bridge.Html5.TextAlign TextAlign { get; set; }

        
private Bridge.Html5.TextAlign __Property__Initializer__TextAlign=Bridge.Html5.TextAlign.Center;}
    }
}
