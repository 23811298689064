using System;

namespace H.Skeepy.Core.Operations.Broadcasting.Concrete
{
    public class BroadcastingTower : ImABroadcastingTower
    {
        public event EventHandler<ImABroadcastEvent> NewTransmission;

        public void Transmit(ImABroadcastEvent broadcastEvent)
        {
            NewTransmission!=null?global::Bridge.Script.FromLambda(()=>NewTransmission.Invoke(this, broadcastEvent)):null;
        }
    }
}
