using H.Skeepy.Core.Infrastructure;
using H.Skeepy.Core.Model;
using H.Skeepy.Core.Model.Display;
using System;
using System.Collections.Concurrent;
using System.Collections.Generic;
using System.Linq;
using System.Threading.Tasks;

namespace H.Skeepy.Core.Operations.UseCases.Concrete
{
    public class PinGroupUseCase : ImASkeepyOperationContract, ImAPinGroupUseCase
    {
        #region Construct
        readonly TimeSpan maxAgeToAllowUndos = TimeSpan.FromHours(24);

        Storage.ImAPinStorageService pinStorageService;
        Storage.ImAPinGroupStorageService pinGroupStorageService;
        Storage.ImAPinGroupSettingsStorageService pinGroupSettingsStorageService;
        SkeepyConsumer device;
        public void ReferDependencies(ImADependencyPoolContainer dependencyPoolContainer)
        {
            device = device ?? dependencyPoolContainer.Resolve<SkeepyConsumer>();
            pinStorageService = pinStorageService ?? dependencyPoolContainer.Resolve<Storage.ImAPinStorageService>();
            pinGroupStorageService = pinGroupStorageService ?? dependencyPoolContainer.Resolve<Storage.ImAPinGroupStorageService>();
            pinGroupSettingsStorageService = pinGroupSettingsStorageService ?? dependencyPoolContainer.Resolve<Storage.ImAPinGroupSettingsStorageService>();
        }

        PinGroup pinGroup;
        ConcurrentDictionary<Guid, Guid> groupPins;
        public void Use(PinGroup pinGroup)
        {
            pinGroup.SkeepyConsumerID = device.ID;
            this.pinGroup = pinGroup;
            groupPins = new ConcurrentDictionary<Guid, Guid>();
            foreach (var pin in pinGroup.Pins)
            {
System.Collections.Generic.CollectionExtensions.TryAdd<Guid,Guid>(                groupPins,pin, pin);
            }
            PinGroup = new PinGroupDisplay(this.pinGroup, new PinGroupSettings());
        }

        PinGroupSettings pinGroupSettings;
        public void Use(PinGroupSettings pinGroupSettings)
        {
            pinGroupSettings.SkeepyConsumerID = device.ID;
            this.pinGroupSettings = pinGroupSettings;
            PinGroup = new PinGroupDisplay(this.pinGroup, this.pinGroupSettings);
        }
        #endregion

        public PinGroupDisplay PinGroup { get; private set; }

        public async Task PersistNew()
        {
            EnsureUseCaseIsBootstrapped();

            EnsurePinGroupIsNotSealed();

            await pinGroupStorageService.Save(pinGroup);
            await PersistSettingsIfNecesarry();
        }

        public async Task Resurrect(Guid pinGroupID)
        {
            PinGroup pinGroup = await pinGroupStorageService.Load(pinGroupID);
            Use(pinGroup);

            PinGroupSettings pinGroupSettings = await pinGroupSettingsStorageService.Load(pinGroupID);
            if (pinGroupSettings != null)
                Use(pinGroupSettings);
        }

        public async Task Delete()
        {
            EnsureUseCaseIsBootstrapped();

            await pinStorageService.DeleteMany(new Storage.PinFilterRequest
            {
                IDs = pinGroup.Pins,
            });

            await pinGroupStorageService.Delete(pinGroup.ID);

            await pinGroupSettingsStorageService.Delete(pinGroupSettings.ID);
        }

        public async Task Push(Pin pin)
        {
            EnsureUseCaseIsBootstrapped();

            EnsurePinGroupIsNotSealed();

            pin.SkeepyConsumerID = device.ID;

            await pinStorageService.Save(pin);

            groupPins.AddOrUpdate(pin.ID, pin.ID, (Func<Guid,Guid,Guid>)((x, y) => pin.ID));

            pinGroup.Pins = System.Linq.Enumerable.ToArray<Guid>(groupPins.Keys);

            await pinGroupStorageService.Save(pinGroup);
        }

        public async Task<bool> CanUndo()
        {
            EnsureUseCaseIsBootstrapped();

            if (pinGroup.IsSealed)
                return false;

            if (!System.Linq.Enumerable.Any<Guid>(pinGroup.Pins))
                return false;

            Pin pin = await pinStorageService.Load(System.Linq.Enumerable.Last<KeyValuePair<Guid,Guid>>(groupPins).Key);

            return IsPinUndoable(pin);
        }

        public async Task<Pin> Undo()
        {
            EnsureUseCaseIsBootstrapped();

            EnsurePinGroupIsNotSealed();

            if (!System.Linq.Enumerable.Any<Guid>(pinGroup.Pins))
                return null;

            Pin pin = await pinStorageService.Load(System.Linq.Enumerable.Last<KeyValuePair<Guid,Guid>>(groupPins).Key);

            if (!IsPinUndoable(pin))
            {
                return null;
            }

            Guid removedPinId;
            if (!groupPins.TryRemove(pin.ID, out removedPinId))
            {
                //Pin already removed from another thread
                return pin;
            }
            pinGroup.Pins = System.Linq.Enumerable.ToArray<Guid>(groupPins.Keys);

            await pinGroupStorageService.Save(pinGroup);

            await pinStorageService.Delete(pin.ID);

            return pin;
        }

        public Task<long> Count()
        {
            EnsureUseCaseIsBootstrapped();

            return Task.FromResult<long>((long)groupPins.Keys.Count);
        }

        public async Task Seal()
        {
            EnsureUseCaseIsBootstrapped();

            pinGroup.IsSealed = true;

            await pinGroupStorageService.Save(pinGroup);
        }

        private void EnsureUseCaseIsBootstrapped()
        {
            if (pinGroup == null)
                throw new InvalidOperationException("The use case was is not bootstrapped; Call .Use() before doing any operation.");

        }

        private void EnsurePinGroupIsNotSealed()
        {
            if (pinGroup.IsSealed)
                throw new InvalidOperationException("The PinGroup is sealed. A sealed Pin Group cannot be modified anymore.");

        }

        private async Task PersistSettingsIfNecesarry()
        {
            if (pinGroupSettings == null)
                return;

            pinGroupSettings.ID = pinGroup.ID;
            await pinGroupSettingsStorageService.Save(pinGroupSettings);
        }

        private bool IsPinUndoable(Pin pin)
        {
            TimeSpan pinAge = Rolex.Time - pin.HappenedAt;

            return pinAge <= maxAgeToAllowUndos;
        }
    }
}
