using H.Skeepy.Core.Infrastructure;
using H.Skeepy.Core.Operations.Storage;
using System;
using System.Collections.Concurrent;
using System.Linq;
using System.Threading.Tasks;

namespace H.Skeepy.Core.Operations.Sync.Concrete
{
    public class SyncableTypesRegistry : ImASkeepyOperationContract
    {
        #region Construct
        public void ReferDependencies(ImADependencyPoolContainer dependencyPoolContainer)
        {
            dependencyPoolContainer.Resolve<StorageServicesOperationContract>();
        }
        #endregion

        readonly ConcurrentDictionary<string, Func<string, Task<object>>> syncableTypesDictionary = new ConcurrentDictionary<string, Func<string, Task<object>>>();
public string[] All
{
    get
    {
        return System.Linq.Enumerable.ToArray<string>(syncableTypesDictionary.Keys);
    }
}
        public SyncableTypesRegistry RegisterTypeAsSyncable(string type, Func<string, Task<object>> entityLoader)
        {
            syncableTypesDictionary.AddOrUpdate(type, (Func<string,Task<object>>)entityLoader, (Func<string,Func<string,Task<object>>,Func<string,Task<object>>>)((x, y) => entityLoader));
            return this;
        }
public SyncableTypesRegistry RegisterTypeAsSyncable<T>(Func<string, Task<object>> entityLoader)
{
    return RegisterTypeAsSyncable(typeof(T).TypeName(), (Func<string,Task<object>>)entityLoader);
}
        public async Task<object> LoadEntity(string type, string id)
        {
            if (!syncableTypesDictionary.ContainsKey(type))
                return null;

            return await (global::Bridge.Script.ToTemp("key1",syncableTypesDictionary[type])!=null?global::Bridge.Script.FromTemp<Func<string, Task<object>>>("key1").Invoke(id):(Task<object>)null);
        }
    }
}
