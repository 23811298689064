using System;
using System.Collections.Generic;
using System.Text;

namespace H.Skeepy.Core.Operations.UI.UserInput
{
    public class UserInputValidationResult
    {
        public static readonly UserInputValidationResult Success = new UserInputValidationResult { IsValid = true };
        public bool? IsValid { get; set; }
        public string Message { get; set; }
    }
}
