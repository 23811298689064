using Bridge.React;
using H.Skeepy.Click.Web.UI.Components.Chromes;
using H.Skeepy.Click.Web.UI.Components.UI.Controls;
using H.Skeepy.Click.Web.UI.Components.UI.Layout;
using H.Skeepy.Click.Web.UI.Components.UI.Styles;
using H.Skeepy.Core.Branding;
using H.Skeepy.Core.Common;
using H.Skeepy.Core.Model.Display.Health;
using H.Skeepy.Core.Operations.UI.ViewState.Health;
using System.Linq;

namespace H.Skeepy.Click.Web.UI.Pages.Health
{
    public class StorageHealthPage : PageBase<StorageHealthPage.Props, StorageHealthViewState>
    {
        public StorageHealthPage() : base(new Props { }, null) { }

        public override ReactElement Render()
        {
            return
                new DefaultChrome
                (
                    RenderNecessaire(),

                    new PaddedContent
                    (
                        !state.InitializeResult.IsValid
                        ? RenderInvalidStats()
                        : RenderStorageStats()
                    ),

                    new ValidationResultDisplay(new ValidationResultDisplay.Props { ValidationResult = state.ValidationResult, Width = "100%" })
                )
                ;
        }

        private ReactElement RenderStorageStats()
        {
            return
                new FormLayout
                (
                    new FormLayout.Props { LayoutMode = FormLayoutMode.OnePerRow },

                    new Title("Storage Information"),

                    ((global::Bridge.Script.ToTemp("key1",state.StorageStatistics)!=null?global::Bridge.Script.FromTemp<StorageStatistics[]>("key1").Any():(bool?)null) ?? false)

                     ? DOM.Div(
                        new Attributes
                        {
                            Style = new ReactStyle { Width = "100%" },
                        },
System.Linq.Enumerable.Select<StorageStatistics,ReactElement>(
                        state.StorageStatistics,(System.Func<StorageStatistics,ReactElement>)RenderStorageStats)
                    )

                    : DOM.Div(new Attributes { Style = new ReactStyle { Width = "100%" } }, "No stats available")

                );
        }

        private ReactElement RenderStorageStats(StorageStatistics storageStatistics)
        {
            bool isStorageExportInProgress = state.StorageExportProgress != null;
            bool isBundleExportInProgress = state.BundleExportProgress != null;

            return
                DOM.Div(
                    new Attributes { Style = new ReactStyle { Width = "100%" } },

                    new Subtitle(string.Format("{0} as of {1}",storageStatistics.Name,storageStatistics.AsOf.ToString(BrandingStyle.TimeStampFormat))),

                    DOM.P(new Attributes
                    {
                        Style = new ReactStyle
                        {
                            Margin = string.Format("{0}px 0",style.SizingUnitInPixels / 4),
                            Color = style.Colors.Complementary.Color.Hex,
                            FontSize = style.Typography.FontSizeSmall.PointsCss,
                            JustifyContent = Bridge.Html5.JustifyContent.Center,
                        }.FlexNode(),
                    },
                        storageStatistics.Type.ToString()
                        + (string.IsNullOrWhiteSpace(storageStatistics.TypeNotes) ? string.Empty : string.Format(" ({0})",storageStatistics.TypeNotes))
                    ),

                    new ListOfProperties(
System.Linq.Enumerable.Select<StorageCollectionStatistics,ListOfProperties.Prop>(                        storageStatistics
                            .Collections
,(System.Func<StorageCollectionStatistics,ListOfProperties.Prop>)(x => new ListOfProperties.Prop { Name = x.Name, Value = string.Format("{0} {1}",x.RecordsCount,(x.RecordsCount == 1 ? "entry" : "entries"))}))
                            .ToArray()
                    ),

                    RenderStorageExportInfoIfNecessary(),

                    new Button(new Button.Props
                    {
                        Width = "100%",
                        IsDisabled = isStorageExportInProgress,
                        OnClick = async () => await state.TriggerStorageExport(storageStatistics, System.Linq.Enumerable.Select<StorageCollectionStatistics,string>(storageStatistics.Collections,(System.Func<StorageCollectionStatistics,string>)(x => x.Name)).ToArray())
                    },
                        isStorageExportInProgress
                        ? string.Format("Exporting ({0})...",state.StorageExportProgress.Value.PrintPercent())                        : string.Format("Export All {0} Data",storageStatistics.Name)                    ),

                    state.StorageExport == null ? null : new Button(new Button.Props
                    {
                        Width = "100%",
                        StyleDecorator = x => x.With((System.Action<ReactStyle>)(s => s.MarginTop = style.SizingUnitInPixels)),
                        IsDisabled = isBundleExportInProgress,
                        OnClick = async () => await state.TriggerWrapAndDownloadExport()
                    },
                        isBundleExportInProgress
                        ? string.Format("Bundling ({0})...",state.BundleExportProgress.Value.PrintPercent())                        : string.Format("Zip Wrap and Download")                    )

                );
        }

        private ReactElement RenderStorageExportInfoIfNecessary()
        {
            if (state.StorageExport == null)
                return null;

            return
                DOM.Div(
                    new Attributes { Style = new ReactStyle { Width = "100%" } },

                    new Subtitle(string.Format("Data Export {0} ({1})",state.StorageExport.StorageIdentifier.Name,state.StorageExport.StorageIdentifier.Type.ToString())),

                    new ListOfProperties(
                        new ListOfProperties.Prop { Name = "As Of", Value = state.StorageExport.AsOf.ToString(BrandingStyle.TimeStampFormat) },
                        new ListOfProperties.Prop { Name = string.Format("{0} collection(s)",System.Linq.Enumerable.Count<StorageCollectionExport>(state.StorageExport.Collections)), Value = string.Join(", ", System.Linq.Enumerable.Select<StorageCollectionExport,string>(state.StorageExport.Collections,(System.Func<StorageCollectionExport,string>)(c => c.CollectionName))) },
                        new ListOfProperties.Prop { Name = "Approx. Size", Value = state.StorageExport.ApproximateSizeInBytes.PrintSize() }
                    )

                );
        }

        private ReactElement RenderInvalidStats()
        {
            return
                new FormLayout
                (
                    new FormLayout.Props { LayoutMode = FormLayoutMode.OnePerRow },

                    new Title("The Storage Stats are unavailable."),

                    new ValidationResultDisplay(new ValidationResultDisplay.Props
                    {
                        ValidationResult = state.InitializeResult,
                        Width = "100%",
                        TextAlign = Bridge.Html5.TextAlign.Center,
                    }),

                    new Button(new Button.Props
                    {

                        Width = "100%",
                        OnClick = state.GoHome,

                    }, "OK")
                );
        }

        public class Props : PagePropsBase
        {

        }
    }
}
