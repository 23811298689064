using H.Skeepy.Core.Common;
using System;
using System.Collections.Concurrent;
using System.Threading.Tasks;

namespace H.Skeepy.Core.Operations.Caching.Concrete
{
    public class InMemoryCacheServiceBuilder : ImACacheServiceBuilder
    {
        readonly ConcurrentDictionary<Type, object> constructedCacheServices = new ConcurrentDictionary<Type, object>();

        public Task<ImACacheService<T>> BuildOrRevive<T>()
        {
            ImACacheService<T> cacheService = constructedCacheServices.GetOrAdd(typeof(T), (Func<Type,object>)(t => new InMemoryCacheService<T>())) as ImACacheService<T>;

            return H.Skeepy.Core.Common.DataExtensions.AsFulfilledTask<ImACacheService<T>>(cacheService);
        }
    }
}
