using H.Skeepy.Core.Infrastructure;
using H.Skeepy.Core.Infrastructure.Timing;
using H.Skeepy.Core.Model.Display;
using H.Skeepy.Core.Operations.UI.Abstract;
using H.Skeepy.Core.Operations.UseCases;
using System;
using System.Threading.Tasks;

namespace H.Skeepy.Core.Operations.UI.ViewState
{
    public class WelcomeViewState : ViewStateBase
    {
        #region Construct
#if DEBUG
        readonly TimeSpan refreshInterval = TimeSpan.FromSeconds(5);
#else
        readonly TimeSpan refreshInterval = TimeSpan.FromSeconds(60);
#endif

        ImAPinGroupBrowserUseCase pinGroupBrowserUseCase;
        ImAPinGroupUseCase pinGroupUseCase;
        ImAPeriodicAction periodicAction;
        public override void ReferDependencies(ImADependencyPoolContainer dependencyPoolContainer)
        {
            base.ReferDependencies(dependencyPoolContainer);

            pinGroupBrowserUseCase = pinGroupBrowserUseCase ?? dependencyPoolContainer.Resolve<ImAPinGroupBrowserUseCase>();
            pinGroupUseCase = pinGroupUseCase ?? dependencyPoolContainer.Resolve<ImAPinGroupUseCase>();
            periodicAction = periodicAction ?? dependencyPoolContainer.Resolve<ImAPeriodicAction>();
        }
        #endregion

        #region Properties
        public PinGroupDisplay[] PinGroupsStillActive { get; set; }
        public PinGroupDisplay[] PinGroupsSealed { get; set; }

        private bool isLoadingPinGroups = false;
        public bool IsLoadingPinGroups { get { return isLoadingPinGroups; } set { isLoadingPinGroups = value; viewUpdater!=null?global::Bridge.Script.FromLambda(()=>viewUpdater.Invoke("IsLoadingPinGroups")):null; } }
        #endregion

        #region Operations
        public override async Task Initialize()
        {
            await base.Initialize();

            using (FlagIndicator((Action<bool>)(x => IsLoadingPinGroups = x)))
            {
                await RereshPinGroups();
            }

            periodicAction.StartDelayed(refreshInterval, refreshInterval, (Func<Task>)RereshPinGroups);
        }

        public override async Task Leaving()
        {
            periodicAction.Stop();

            await base.Leaving();
        }

        private async Task RereshPinGroups()
        {
            PinGroupsStillActive = (await pinGroupBrowserUseCase.BrowseAllUnsealed()) ?? new PinGroupDisplay[0];
            viewUpdater!=null?global::Bridge.Script.FromLambda(()=>viewUpdater.Invoke("PinGroupsStillActive")):null;
            PinGroupsSealed = (await pinGroupBrowserUseCase.BrowseAllSealed()) ?? new PinGroupDisplay[0];
            viewUpdater!=null?global::Bridge.Script.FromLambda(()=>viewUpdater.Invoke("PinGroupsSealed")):null;
        }

        public void TriggerStartNewClickSession()
        {
            uiNavigator.Go<PinGroupViewStates.DefineNewPinGroupViewState>();
        }

        public void TriggerOpenClickSession(Guid pinGroupID)
        {
            uiNavigator.Go<PinGroupViewStates.PinGroupOperationsViewState>(new Navigation.UiNavigationParams(pinGroupID));
        }

        public void TriggerViewClickSession(Guid pinGroupID)
        {
            uiNavigator.Go<PinGroupViewStates.PinGroupDetailedDisplayViewState>(new Navigation.UiNavigationParams(pinGroupID));
        }

        public async Task DeleteClickSession(Guid pinGroupID)
        {
            using (BusyIndicator())
            {
                await pinGroupUseCase.Resurrect(pinGroupID);
                await pinGroupUseCase.Delete();
                await RereshPinGroups();
            }
        }

        public async Task SealClickSession(Guid pinGroupID)
        {
            using (BusyIndicator())
            {
                await pinGroupUseCase.Resurrect(pinGroupID);
                await pinGroupUseCase.Seal();
                await RereshPinGroups();
            }
        }

        #endregion
    
private PinGroupDisplay[] __Property__Initializer__PinGroupsStillActive=new PinGroupDisplay[0];private PinGroupDisplay[] __Property__Initializer__PinGroupsSealed=new PinGroupDisplay[0];}
}
