using H.Skeepy.Core.Infrastructure;
using H.Skeepy.Core.Operations.Sync.Concrete;

namespace H.Skeepy.Core.Operations.Sync
{
    public class SyncModule : ImADependencyModule
    {
        public void RegisterDependencies(ImADependencyPoolContainer dependencyPoolContainer)
        {
            dependencyPoolContainer.Register<SyncableTypesRegistry>((System.Func<object>)(() => new SyncableTypesRegistry()));
            dependencyPoolContainer.Register<ImASyncRegistry>((System.Func<object>)(() => new InMemorySyncRegistry()));
            dependencyPoolContainer.Register<ImASyncReceiver>((System.Func<object>)(() => new HttpSyncReceiver()));
            dependencyPoolContainer.Register<ImASyncSender>((System.Func<object>)(() => new HttpSyncSender()));
            dependencyPoolContainer.Register<SyncWorker>((System.Func<object>)(() => new SyncWorker()));
        }
    }
}
