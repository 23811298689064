using H.Skeepy.Core.Common;
using H.Skeepy.Core.Infrastructure;
using H.Skeepy.Core.Model;
using H.Skeepy.Core.Operations.Storage;
using System;
using System.Threading.Tasks;

namespace H.Skeepy.Core.Operations.UseCases.Concrete
{
    public class ConfigurationUseCase : ImAConfigurationUseCase, ImASkeepyOperationContract
    {
        #region Construct
        ImASettingsStorageService settingsStorageService;
        public void ReferDependencies(ImADependencyPoolContainer dependencyPoolContainer)
        {
            settingsStorageService = settingsStorageService ?? dependencyPoolContainer.Resolve<ImASettingsStorageService>();
        }
        #endregion

        public async Task<T> GetOrAddConfiguration<T>(string id, T defaultConfiguration) where T : SettingsPool, new()
        {
            if (string.IsNullOrWhiteSpace(id))
                throw new InvalidOperationException("Configuration ID is empty");

            T result = defaultConfiguration ?? new T() { ID = id };
            result.ID = id;

            SettingsPool existingSettings = await settingsStorageService.Load(id);

            if (existingSettings == null)
            {
                await settingsStorageService.Save(result);
            }
            else
            {
                result.RawSettings = result.RawSettings.OverwriteAndAppendFrom(existingSettings.RawSettings);
                if (!result.HasSameSettingKeysAs(existingSettings))
                    await settingsStorageService.Save(result);
            }

            return result;
        }

        public async Task SetConfiguration<T>(T configuration) where T : SettingsPool
        {
            if (configuration == null)
                return;

            SettingsPool existingSettings = await settingsStorageService.Load(configuration.ID);

            if (existingSettings != null)
                configuration.RawSettings = existingSettings.RawSettings.OverwriteAndAppendFrom(configuration.RawSettings);

            await settingsStorageService.Save(configuration);
        }
    }
}
