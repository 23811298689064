using Bridge;
using Bridge.Html5;
using Bridge.React;
using H.Skeepy.Core.Operations.UI.Abstract;
using System.Linq;

namespace H.Skeepy.Click.Web.UI.Components.UI.Controls
{
    public class ValidationResultDisplay : ComponentBase<ValidationResultDisplay.Props, ValidationResultDisplay.State>
    {
        public ValidationResultDisplay(Props props) : base(props, null) { }
        public ValidationResultDisplay() : this(new Props { }) { }

        public override ReactElement Render()
        {
            if ((global::Bridge.Script.ToTemp("key1",props)!=null?global::Bridge.Script.FromTemp<Props>("key1").ValidationResult:(ViewStateValidationResult)null) == null)
                return null;

            return
                DOM.Div(
                    new Attributes
                    {
                        Style = new ReactStyle
                        {
                            Display = Display.InlineBlock,
                            Width = props.Width,
                        },
                    },

                    RenderMessage(),
                    RenderAddendum()
                );
        }

        private ReactElement RenderMessage()
        {
            if (string.IsNullOrWhiteSpace(props.ValidationResult.Message))
                return null;

            return
                DOM.Div(
                    new Attributes
                    {
                        Style = new ReactStyle
                        {
                            FontSize = style.Typography.FontSizeSmaller.PointsCss,
                            Color = style.Colors.Primary.Color.Hex,
                            Margin = "2px 1px",
                            Width = "100%",
                            TextAlign = props.TextAlign,
                        },
                    },
                    props.ValidationResult.Message
                );
        }

        private ReactElement RenderAddendum()
        {
            if (props.ValidationResult.Addendum == null)
                return null;

            if (System.Linq.Enumerable.All<string>(props.ValidationResult.Addendum,(System.Func<string,bool>)(x => string.IsNullOrWhiteSpace(x))))
                return null;

            return
                DOM.Div(
                    new Attributes
                    {
                        Style = new ReactStyle
                        {
                            FontSize = style.Typography.FontSizeSmaller.PointsCss,
                            Color = style.MutedTextColor.Hex,
                            Margin = "2px 1px",
                            Width = "100%",
                            TextAlign = props.TextAlign,
                        },
                    },
System.Linq.Enumerable.Where<string>(                    props
                        .ValidationResult
                        .Addendum
,(System.Func<string,bool>)(x => !string.IsNullOrWhiteSpace(x)))
                        .Select<ReactElement>((System.Func<string,ReactElement>)RenderAddendumEntry)
                        .ToArray()
                );

        }

        private ReactElement RenderAddendumEntry(string message)
        {
            return
                DOM.Div
                (
                    new Attributes
                    {
                        Style = new ReactStyle
                        {
                            MarginTop = 2,
                            Width = "100%",
                            TextAlign = props.TextAlign,
                        },
                    },

                    message

                );
        }

        public class State { }
        public class Props
        {
            public ViewStateValidationResult ValidationResult { get; set; }
            public Union<string, int> Width { get; set; }
            public TextAlign TextAlign { get; set; }

        
private Union<string, int> __Property__Initializer__Width=200;private TextAlign __Property__Initializer__TextAlign=TextAlign.Left;}
    }
}
