using H.Skeepy.Core.Common;
using System;
using System.Collections.Concurrent;
using System.Threading.Tasks;

namespace H.Skeepy.Core.Operations.Caching.Concrete
{
    public class InMemoryCacheService<T> : ImACacheService<T>
    {
        readonly ConcurrentDictionary<string, CacheEntry> cachedDataDictionary = new ConcurrentDictionary<string, CacheEntry>();

        public Task Clear()
        {
            cachedDataDictionary.Clear();

            return H.Skeepy.Core.Common.DataExtensions.AsFulfilledTask<bool>(true);
        }

        public async Task<T> GetOrAdd(string key, Func<Task<T>> uncachedProvider, TimeSpan periodToCache)
        {
            CacheEntry existingCacheEntry;
            if (cachedDataDictionary.TryGetValue(key, out existingCacheEntry) && !existingCacheEntry.HasExpired)
            {
                return existingCacheEntry.Data;
            }

            CacheEntry cacheEntry = new CacheEntry
            {
                Key = key,
                Data = await uncachedProvider(),
                AsOf = Rolex.Time,
                ExpiresOn = Rolex.Time + periodToCache,
            };

            cachedDataDictionary.AddOrUpdate(key, cacheEntry, (Func<string,InMemoryCacheService<T>.CacheEntry,InMemoryCacheService<T>.CacheEntry>)((x, y) => cacheEntry));

            return cacheEntry.Data;
        }

        public Task<T> Zap(string key)
        {
            CacheEntry existingCacheEntry;
            if (cachedDataDictionary.TryRemove(key, out existingCacheEntry))
                return H.Skeepy.Core.Common.DataExtensions.AsFulfilledTask<T>(existingCacheEntry.Data);
            return H.Skeepy.Core.Common.DataExtensions.AsFulfilledTask<T>(default(T));
        }

        struct CacheEntry
        {
            public string Key { get; set; }
            public T Data { get; set; }
            public DateTime AsOf { get; set; }
            public DateTime ExpiresOn { get; set; }
public bool HasExpired
{
    get
    {
        return Rolex.Time > ExpiresOn;
    }
}        }
    }
}
