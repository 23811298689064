namespace H.Skeepy.Click.Web.UI.Common
{
    public class JsonOperations
    {
        public static object ConvertObjectToJson<T>(T obj)
        {
            if (obj == null)
                return null;
            return Newtonsoft.Json.JsonConvert.DeserializeObject<object>(Newtonsoft.Json.JsonConvert.SerializeObject(obj));
        }

        public static T ConvertJsonToObject<T>(object json)
        {
            if (json == null)
                return default(T);
            return Newtonsoft.Json.JsonConvert.DeserializeObject<T>(Newtonsoft.Json.JsonConvert.SerializeObject(json));
        }
    }
}
