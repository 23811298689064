using H.Skeepy.Core.Infrastructure;
using H.Skeepy.Core.Model.Display.Health;
using H.Skeepy.Core.Operations.Storage;
using Retyped;
using System;
using System.Linq;
using System.Threading.Tasks;

namespace H.Skeepy.Click.Web.UI.Components.LocalStorage
{
    public class IndexedDBStorageExplorer : ImAStorageExplorer, ImASkeepyOperationContract
    {
        #region Construct
        StorageInfo storageInfo = new StorageInfo
        {
            Type = StorageType.LocalIndexedDB,
            TypeNotes = "Wrapped with Dexie.js",
            Name = string.Format("{0} v{1}",IndexedDBStorage.DatabaseName,IndexedDBStorage.DatabaseVersion),
        };

        IndexedDBStorage indexedDBStorage;
        public void ReferDependencies(ImADependencyPoolContainer dependencyPoolContainer)
        {
            indexedDBStorage = indexedDBStorage ?? dependencyPoolContainer.Resolve<IndexedDBStorage>();
        }
        #endregion

        public Task<StorageRecord[]> BrowseBatch(string collectionName, long batchIndex = 0, long batchSize = 10)
        {
            TaskCompletionSource<StorageRecord[]> taskCompletionSource
                = new TaskCompletionSource<StorageRecord[]>();

            dexie.Dexie.Table<object, object> table = indexedDBStorage.GetTable(collectionName);
            if (table == null)
            {
                taskCompletionSource.SetResult(new StorageRecord[0]);
                return taskCompletionSource.Task;
            }

            dexie.Dexie.Collection<object, object> collection = table.offset(batchIndex * batchSize).limit(batchSize);

            object[] primaryKeys = null;

            collection
                .primaryKeys()
                .then(
(dexie.Dexie.Promise<object[]>.thenFn)(                    ids =>
                    {
                        primaryKeys = ids;

                        collection
                            .toArray()
                            .then(
(dexie.Dexie.Promise<es5.Array<object>>.thenFn)(                                entities =>
                                {
                                    if (entities.length != primaryKeys.Length)
                                    {
                                        taskCompletionSource.SetException(new InvalidOperationException(string.Format("Error fetching batch {0} of {1} items from {2} collection; Founds IDs don't match the found entities",batchIndex,batchSize,table.name)));
                                        return null;
                                    }

                                    StorageRecord[] result = new StorageRecord[(long)entities.length];

                                    for (long index = 0; index < result.Length; index++)
                                    {
                                        result[index] = new StorageRecord
                                        {
                                            CollectionName = table.name,
                                            Key = primaryKeys[index].ToString(),
                                            RawRecord = Newtonsoft.Json.JsonConvert.SerializeObject(entities[index]),
                                        };
                                    }

                                    taskCompletionSource.SetResult(result);
                                    return entities;
                                }), (dexie.Dexie.Promise<es5.Array<object>>.thenFn2)(x =>
                                {
                                    taskCompletionSource.SetException(new InvalidOperationException(string.Format("Error fetching batch {0} of {1} items from {2} collection",batchIndex,batchSize,table.name)));
                                    return null;
                                }
)                            );

                        return ids;
                    }), (dexie.Dexie.Promise<object[]>.thenFn2)(x =>
                    {
                        taskCompletionSource.SetException(new InvalidOperationException(string.Format("Error fetching IDs for batch {0} of {1} items from {2} collection",batchIndex,batchSize,table.name)));
                        return null;
                    }
)                );


            return taskCompletionSource.Task;
        }

        public async Task<StorageInfo> GetStorageInformation()
        {
            Retyped.dexie.Dexie.Table<object, object>[] tables = await indexedDBStorage.GetAllTables();
            if (!(tables!=null?tables.Any():(bool?)null) ?? true)
                return storageInfo;

            storageInfo.Collections = await Task.WhenAll<StorageCollectionInfo>(System.Linq.Enumerable.Select<dexie.Dexie.Table<object,object>,Task<StorageCollectionInfo>>(tables,(Func<dexie.Dexie.Table<object,object>,Task<StorageCollectionInfo>>)Map));

            return storageInfo;
        }

        private async Task<StorageCollectionInfo> Map(dexie.Dexie.Table<object, object> table)
        {
            return new StorageCollectionInfo
            {
                Name = table.name,
                RecordsCount = await indexedDBStorage.Count(table),
            };
        }
    }
}
