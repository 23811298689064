using Bridge.React;
using H.Skeepy.Click.Web.UI.Components.Chromes;
using H.Skeepy.Click.Web.UI.Components.UI.Controls;
using H.Skeepy.Click.Web.UI.Components.UI.Controls.Maps;
using H.Skeepy.Click.Web.UI.Components.UI.Elements;
using H.Skeepy.Click.Web.UI.Components.UI.Layout;
using H.Skeepy.Click.Web.UI.Components.UI.Styles;
using H.Skeepy.Core.Common;
using H.Skeepy.Core.Model.Display;
using H.Skeepy.Core.Operations.UI.ViewState.PinGroupViewStates;
using System;
using System.Linq;

namespace H.Skeepy.Click.Web.UI.Pages.PinGroupPages
{
    public class PinGroupDetailedDisplayPage : PageBase<PinGroupDetailedDisplayPage.Props, PinGroupDetailedDisplayViewState>
    {
        public PinGroupDetailedDisplayPage(Props props) : base(props, null) { }

        public override ReactElement Render()
        {
            return
                new DefaultChrome
                (
                    RenderNecessaire(),

                    new PaddedContent
                    (
                        state.PinGroupDetails == null
                        ? RenderInvalidGroup()
                        : RenderGroupDetails()
                    )

                )
                ;
        }

        private ReactElement RenderGroupDetails()
        {
            return
                new FormLayout
                (
                    new FormLayout.Props { LayoutMode = FormLayoutMode.OnePerRow },

                    new Title(state.PinGroupDetails.PrintCount()),

                    !state.CanResume
                    ? null
                    : new Button(new Button.Props { Width = "100%", OnClick = state.ResumePinGroupOperations }, "Resume"),

                    new Subtitle(state.PinGroupDetails.Name),

                    RenderDescriptionIfNecessarry(),

                    new ListOfProperties(
                        new ListOfProperties.Prop { Name = "Created", Value = state.PinGroupDetails.CreatedAt.Print() },
                        new ListOfProperties.Prop { Name = "Started", Value = (global::Bridge.Script.ToTemp("key1",state.PinGroupDetails.StartedAt)!=null?global::Bridge.Script.FromTemp<DateTime>("key1").Print():(string)null) ?? "Not yet" },
                        new ListOfProperties.Prop { Name = "Ended", Value = (global::Bridge.Script.ToTemp("key2",state.PinGroupDetails.EndedAt)!=null?global::Bridge.Script.FromTemp<DateTime>("key2").Print():(string)null) ?? "Not yet" },
                        new ListOfProperties.Prop { Name = "Duration", Value = state.PinGroupDetails.Duration.Print() },
                        new ListOfProperties.Prop { Name = "Minimum Click Interval", Value = state.PinGroupDetails.MinimumPinInterval.Print() },
                        new ListOfProperties.Prop { Name = "Average Click Interval", Value = state.PinGroupDetails.AveragePinInterval.Print() },
                        new ListOfProperties.Prop { Name = "Maximum Click Interval", Value = state.PinGroupDetails.MaximumPinInterval.Print() }
                    ),

                    ((global::Bridge.Script.ToTemp("key3",state.PinGroupDetails.PinsWithGps)!=null?global::Bridge.Script.FromTemp<PinDisplay[]>("key3").Length:(int?)null) ?? 0) == 0 ? null : new Button(new Button.Props { Width = "100%", OnClick = state.ToggleAllPinsMap }, state.IsAllPinsPinMapVisible ? "Hide Click Map" : "View Click Map"),

                    RenderPinMapIfNecessarry(),

                    ((global::Bridge.Script.ToTemp("key4",state.PinGroupDetails.Pins)!=null?global::Bridge.Script.FromTemp<PinDisplay[]>("key4").Length:(int?)null) ?? 0) == 0 ? null : new Subtitle("Click Timeline"),

                    RenderClicksIfNecessarry()
                );
        }

        private ReactElement RenderPinMapIfNecessarry()
        {
            if (!state.IsAllPinsPinMapVisible)
                return null;

            return
                DOM.Div(
                    new Attributes
                    {
                        Style = new ReactStyle { }.FlexNode(isVerticalFlow: true)
                    },

                    new WorldMap(new WorldMap.Props
                    {
                        GpsPins = System.Linq.Enumerable.Select<PinDisplay,Core.Model.GPS.GpsPoint>(state.PinGroupDetails.PinsWithGps,(Func<PinDisplay,Core.Model.GPS.GpsPoint>)(x => x.Gps.Value.Point)).ToArray(),
                        StyleDecorator = x => x
                            .With((Action<ReactStyle>)(s => s.Height = 400))
                            .With((Action<ReactStyle>)(s => s.Width = "100%"))
                    })

                );
        }

        private ReactElement RenderClicksIfNecessarry()
        {
            if (state.PinGroupDetails.Pins == null || state.PinGroupDetails.Pins.Length == 0)
                return null;

            return
                DOM.Div(
                    new Attributes
                    {
                        Style = new ReactStyle { }.FlexNode(isVerticalFlow: true)
                    },
System.Linq.Enumerable.Select<PinDisplay,ReactElement>(
                    state.PinGroupDetails.Pins,(Func<PinDisplay,ReactElement>)RenderClick)

                );
        }

        private ReactElement RenderClick(PinDisplay pin)
        {
            return
                DOM.Div(
                    new Attributes
                    {
                        Style = new ReactStyle
                        {
                            Width = "100%",
                        }
                        .FlexNode(isVerticalFlow: true),
                    },

                    RenderClickIntervalIfNecesarry(pin),

                    RenderPin(pin),

                    RenderPinMapIfNecesarry(pin)
                );
        }

        private ReactElement RenderPinMapIfNecesarry(PinDisplay pin)
        {
            if (state.PinMapIndexToDisplay != pin.Index)
                return null;

            return
                DOM.Div(
                    new Attributes
                    {
                        Style = new ReactStyle { Width = "100%", MarginBottom = style.SizingUnitInPixels * 2 }
                    },

                    new WorldMap(new WorldMap.Props
                    {
                        GpsPins = H.Skeepy.Core.Common.DataExtensions.AsArray<Core.Model.GPS.GpsPoint>(pin.Gps.Value.Point),
                        StyleDecorator = x => x
                            .With((Action<ReactStyle>)(s => s.Height = 230))
                            .With((Action<ReactStyle>)(s => s.Width = "100%"))
                    })

                );
        }

        private ReactElement RenderPin(PinDisplay pin)
        {
            return
                DOM.Div(
                    new Attributes
                    {
                        Style = new ReactStyle
                        {
                            Width = "100%",
                            Display = Bridge.Html5.Display.Flex,
                            JustifyContent = Bridge.Html5.JustifyContent.Center,
                        },
                    },

                    RenderPinNumber(pin),

                    RenderPinDetails(pin),

                    RenderPinGpsOptionsIfNecessarry(pin)
                );
        }

        private ReactElement RenderPinGpsOptionsIfNecessarry(PinDisplay pin)
        {
            if (pin.Gps == null)
                return null;

            return
                DOM.Div(
                    new Attributes
                    {
                        Style = new ReactStyle
                        {
                            MinWidth = style.SizingUnitInPixels,
                            MinHeight = style.SizingUnitInPixels,
                            Padding = style.SizingUnitInPixels / 3,
                            Border = "solid 1px",
                            BorderColor = style.Colors.Complementary.Color.Hex,
                            BackgroundColor = style.Colors.Complementary.Color.Hex,
                            Display = Bridge.Html5.Display.Flex,
                            FontSize = style.Typography.FontSizeSmall.PointsCss,
                            Color = style.BackgroundColor.Hex,
                            Cursor = Bridge.Html5.Cursor.Pointer,
                        },
                        OnClick = x => state.TooglePinMap(pin),
                    },

                    new CenteredContent(new FontIcon(new FontIcon.Props { IconName = "Location" }))

                );
        }

        private ReactElement RenderPinNumber(PinDisplay pin)
        {
            return
                DOM.Div(
                    new Attributes
                    {
                        Style = new ReactStyle
                        {
                            MinWidth = style.SizingUnitInPixels,
                            MinHeight = style.SizingUnitInPixels,
                            Padding = style.SizingUnitInPixels / 3,
                            Border = "solid 1px",
                            BorderColor = style.Colors.Primary.Color.Hex,
                            BorderBottomColor = style.Colors.Complementary.Color.Hex,
                            BackgroundColor = style.Colors.Primary.Color.Hex,
                            Display = Bridge.Html5.Display.Flex,
                            FontSize = style.Typography.FontSizeSmall.PointsCss,
                            Color = style.BackgroundColor.Hex,
                            FontWeight = "bold",
                        },
                    },

                    new CenteredContent(string.Format("{0}",pin.Index + 1))

                );
        }

        private ReactElement RenderPinDetails(PinDisplay pin)
        {
            return
                DOM.Div(
                    new Attributes
                    {
                        Style = new ReactStyle
                        {
                            MinWidth = style.SizingUnitInPixels,
                            MinHeight = style.SizingUnitInPixels,
                            Padding = style.SizingUnitInPixels / 3,
                            BorderBottom = "solid 1px",
                            BorderBottomColor = style.Colors.Complementary.Color.Hex,
                            Display = Bridge.Html5.Display.Flex,
                            FontSize = style.Typography.FontSizeSmall.PointsCss,
                            Color = style.Colors.Primary.Color.Hex,
                        },
                    },

                    new CenteredContent(pin.HappenedAt.Print())

                );
        }

        private ReactElement RenderClickIntervalIfNecesarry(PinDisplay pin)
        {
            if (pin.Index == 0)
                return null;

            TimeSpan interval = state.PinGroupDetails.PinIntervals[pin.Index - 1];

            return
                DOM.Div(
                    new Attributes
                    {
                        Style = new ReactStyle
                        {
                            MinWidth = style.SizingUnitInPixels,
                            MinHeight = style.SizingUnitInPixels,
                            Height = state.TranslatePinIntervalToPixels(interval),
                            FontSize = style.Typography.FontSizeSmaller.PointsCss,
                            Color = style.Colors.Complementary.Lighter(1).Hex,
                            Display = Bridge.Html5.Display.Flex,
                        },
                    },

                    new CenteredContent(string.Format("{0} apart",interval.Print()))

                );
        }

        private ReactElement RenderDescriptionIfNecessarry()
        {
            if (string.IsNullOrWhiteSpace(state.PinGroupDetails.Description))
                return null;

            return
                DOM.P(new Attributes
                {
                    Style = new ReactStyle
                    {
                        Margin = string.Format("{0}px 0",style.SizingUnitInPixels / 4),
                        Color = style.Colors.Complementary.Color.Hex,
                        FontSize = style.Typography.FontSizeSmall.PointsCss,
                        JustifyContent = Bridge.Html5.JustifyContent.Center,
                    }.FlexNode(),
                },
                    state.PinGroupDetails.Description
                );
        }

        private ReactElement RenderInvalidGroup()
        {
            return
                new FormLayout
                (
                    new FormLayout.Props { LayoutMode = FormLayoutMode.OnePerRow },

                    new Title("The Click! Session is unavailable."),

                    new ValidationResultDisplay(new ValidationResultDisplay.Props
                    {
                        ValidationResult = state.InitializeResult,
                        Width = "100%",
                        TextAlign = Bridge.Html5.TextAlign.Center,
                    }),

                    new Button(new Button.Props
                    {

                        Width = "100%",
                        OnClick = state.GoHome,

                    }, "OK")
                );
        }

        public class Props : PagePropsBase
        {

        }
    }
}
