using H.Skeepy.Core.Model.Display.Health;
using System.Threading.Tasks;

namespace H.Skeepy.Core.Operations.Storage
{
    public interface ImAStorageExplorer
    {
        Task<StorageInfo> GetStorageInformation();
        Task<StorageRecord[]> BrowseBatch(string collectionName, long batchIndex = 0, long batchSize = 10);
    }

    public class StorageInfo : StorageIdentifier
    {
        public StorageCollectionInfo[] Collections { get; set; }

    
private StorageCollectionInfo[] __Property__Initializer__Collections=new StorageCollectionInfo[0];}

    public class StorageCollectionInfo
    {
        public string Name { get; set; }
        public long RecordsCount { get; set; }

    
private long __Property__Initializer__RecordsCount=0;}

    public class StorageRecord
    {
        public string CollectionName { get; set; }
        public string Key { get; set; }
        public string RawRecord { get; set; }
    }
}
