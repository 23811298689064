using System.Linq;

namespace H.Skeepy.Core.Model.GPS
{
    public class GpsArea
    {
        public GpsArea(params GpsPoint[] pins)
        {
            Pins = pins ?? new GpsPoint[] { new GpsPoint() };
            NorthBoundary = System.Linq.Enumerable.Max<GpsPoint>(Pins,(System.Func<GpsPoint,double>)(p => p.LatInDegrees));
            SouthBoundary = System.Linq.Enumerable.Min<GpsPoint>(Pins,(System.Func<GpsPoint,double>)(p => p.LatInDegrees));
            EastBoundary = System.Linq.Enumerable.Max<GpsPoint>(Pins,(System.Func<GpsPoint,double>)(p => p.LngInDegrees));
            WestBoundary = System.Linq.Enumerable.Min<GpsPoint>(Pins,(System.Func<GpsPoint,double>)(p => p.LngInDegrees));
            NorthWestBoundary = new GpsPoint { LatInDegrees = NorthBoundary, LngInDegrees = WestBoundary };
            NorthEastBoundary = new GpsPoint { LatInDegrees = NorthBoundary, LngInDegrees = EastBoundary };
            SouthEastBoundary = new GpsPoint { LatInDegrees = SouthBoundary, LngInDegrees = EastBoundary };
            SouthWestBoundary = new GpsPoint { LatInDegrees = SouthBoundary, LngInDegrees = WestBoundary };
        }

        public GpsPoint[] Pins { get; private set; }

        public double NorthBoundary { get; private set; }
        public double SouthBoundary { get; private set; }
        public double EastBoundary { get; private set; }
        public double WestBoundary { get; private set; }

        public GpsPoint NorthWestBoundary { get; private set; }
        public GpsPoint NorthEastBoundary { get; private set; }
        public GpsPoint SouthEastBoundary { get; private set; }
        public GpsPoint SouthWestBoundary { get; private set; }
public GpsPoint[] NorthMost
{
    get
    {
        return System.Linq.Enumerable.Where<GpsPoint>(Pins,(System.Func<GpsPoint,bool>)(x => x.LatInDegrees == NorthBoundary)).ToArray();
    }
}public GpsPoint[] SouthMost
{
    get
    {
        return System.Linq.Enumerable.Where<GpsPoint>(Pins,(System.Func<GpsPoint,bool>)(x => x.LatInDegrees == SouthBoundary)).ToArray();
    }
}public GpsPoint[] EastMost
{
    get
    {
        return System.Linq.Enumerable.Where<GpsPoint>(Pins,(System.Func<GpsPoint,bool>)(x => x.LngInDegrees == EastBoundary)).ToArray();
    }
}public GpsPoint[] WestMost
{
    get
    {
        return System.Linq.Enumerable.Where<GpsPoint>(Pins,(System.Func<GpsPoint,bool>)(x => x.LngInDegrees == WestBoundary)).ToArray();
    }
}    }
}
