using Bridge;
using Bridge.React;
using H.Skeepy.Core.Branding;
using H.Skeepy.Core.Operations.Broadcasting;
using H.Skeepy.Core.Operations.UI.Navigation;
using System.Threading.Tasks;

namespace H.Skeepy.Click.Web.UI.Components.UI
{
    public abstract class ComponentBase<TProps, TState> : Component<TProps, TState> where TState : new()
    {
        protected ComponentBase(TProps props, params Union<ReactElement, string>[] children) : base(props, children) { }

        protected ImABroadcastingTower broadcastingTower;
        protected BrandingStyle style;
        protected ImAUiNavigator navi;
protected T Get<T>()
{
    return App.Get<T>();
}
        protected virtual void EnsureDependencies()
        {
            broadcastingTower = broadcastingTower ?? Get<ImABroadcastingTower>();
            style = style ?? Get<BrandingStyle>();
            navi = navi ?? Get<ImAUiNavigator>();
        }

        protected virtual Task Initialize()
        {
            return Task.FromResult<bool>(true);
        }

        protected override async void ComponentDidMount()
        {
            base.ComponentDidMount();

            await Initialize();
        }

        protected override TState GetInitialState()
        {
            TState state = base.GetInitialState();

            EnsureDependencies();

            if (state != null)
                return state;

            state = Get<TState>();

            if (state != null)
                return state;

            state = new TState();

            return state;
        }
protected virtual void SetState()
{
    SetState(state);
}protected virtual Task SetStateAsync()
{
    return SetStateAsync(state);
}    }
}
