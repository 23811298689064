using Bridge;
using H.Skeepy.Core.Model;
using System;
using System.Threading.Tasks;

namespace H.Skeepy.Click.Web.UI.Components.LocalStorage
{
    public class IndexedDBStorage
    {
        public const string DatabaseName = "H.Skeepy.Click";
        public const int DatabaseVersion = 5;

        public readonly Retyped.dexie.Dexie.Table<object, object> Pins;
        public readonly Retyped.dexie.Dexie.Table<object, object> PinGroups;
        public readonly Retyped.dexie.Dexie.Table<object, object> PinGroupSettings;
        public readonly Retyped.dexie.Dexie.Table<object, object> SyncRegistry;
        public readonly Retyped.dexie.Dexie.Table<object, object> SkeepyConsumer;

        readonly Retyped.dexie.Dexie database;
        public IndexedDBStorage()
        {
            database = EnsureDatabase();

            this.Pins = database.table("Pins");
            this.PinGroups = database.table("PinGroups");
            this.PinGroupSettings = database.table("PinGroupSettings");
            this.SyncRegistry = database.table("SyncRegistry");
            this.SkeepyConsumer = database.table("SkeepyConsumer");
        }

        public Task<long> Count(Retyped.dexie.Dexie.Table<object, object> table)
        {
            TaskCompletionSource<long> taskCompletionSource = new TaskCompletionSource<long>();

            table
                .count()
                .then(
(Retyped.dexie.Dexie.Promise<double>.thenFn)(                    count =>
                    {
                        taskCompletionSource.SetResult((long)count);
                        return count;
                    }),
(Retyped.dexie.Dexie.Promise<double>.thenFn2)(                    x =>
                    {
                        taskCompletionSource.SetException(new InvalidOperationException(string.Format("Error counting {0}",table.name)));
                        return null;
                    }
)                );

            return taskCompletionSource.Task;
        }

        public Task<Retyped.dexie.Dexie.Table<object, object>[]> GetAllTables()
        {
            TaskCompletionSource<Retyped.dexie.Dexie.Table<object, object>[]> taskCompletionSource
                = new TaskCompletionSource<Retyped.dexie.Dexie.Table<object, object>[]>();

            if (!database.isOpen())
            {
                database.open().then((Retyped.dexie.Dexie.Promise<Retyped.dexie.Dexie>.thenFn)(_ =>
                {
                    taskCompletionSource.SetResult(database.tables);
                    return database;
                }), (Retyped.dexie.Dexie.Promise<Retyped.dexie.Dexie>.thenFn2)(x =>
                {
                    taskCompletionSource.SetException(new InvalidOperationException(string.Format("Error opening database {0} v{1}",DatabaseName,DatabaseVersion)));
                    return null;
                }));
            }
            else
            {
                taskCompletionSource.SetResult(database.tables);
            }

            return taskCompletionSource.Task;
        }

        public Retyped.dexie.Dexie.Table<object, object> GetTable(string tableName)
        {
            return database.table(tableName);
        }

        private Retyped.dexie.Dexie EnsureDatabase()
        {
            Retyped.dexie.Dexie db = new Retyped.dexie.Dexie(DatabaseName);

            Retyped.dexie.Dexie.Version dbVersion1 = Script.Call<Retyped.dexie.Dexie.Version>("db.version", 1);
            dbVersion1.stores(ConstructDbVersion1Schema());

            Retyped.dexie.Dexie.Version dbVersion2 = Script.Call<Retyped.dexie.Dexie.Version>("db.version", 2);
            dbVersion2.stores(ConstructDbVersion2Schema()).upgrade((Retyped.dexie.Dexie.Version.upgradeFn)(transaction => { }));

            Retyped.dexie.Dexie.Version dbVersion3 = Script.Call<Retyped.dexie.Dexie.Version>("db.version", 3);
            dbVersion3.stores(ConstructDbVersion3Schema()).upgrade((Retyped.dexie.Dexie.Version.upgradeFn)(transaction =>
            {
                transaction.table("PinGroups").toCollection().modify((Retyped.dexie.Dexie.Collection<object, object>.modifyFn)((pinGroup, collection) =>
                {
                    pinGroup["IsSealed"] = false;
                }));
            }));

            Retyped.dexie.Dexie.Version dbVersion4 = Script.Call<Retyped.dexie.Dexie.Version>("db.version", 4);
            dbVersion4.stores(ConstructDbVersion4Schema()).upgrade((Retyped.dexie.Dexie.Version.upgradeFn)(transaction => { }));

            Retyped.dexie.Dexie.Version dbVersion5 = Script.Call<Retyped.dexie.Dexie.Version>("db.version", 5);
            dbVersion5.stores(ConstructDbVersion5Schema()).upgrade((Retyped.dexie.Dexie.Version.upgradeFn)(transaction =>
            {
                transaction.table("PinGroups").toCollection().modify((Retyped.dexie.Dexie.Collection<object, object>.modifyFn)((doc, collection) =>
                {
                    doc["SkeepyConsumerID"] = Guid.Empty.ToString();
                }));
                transaction.table("PinGroupSettings").toCollection().modify((Retyped.dexie.Dexie.Collection<object, object>.modifyFn)((doc, collection) =>
                {
                    doc["SkeepyConsumerID"] = Guid.Empty.ToString();
                }));
                transaction.table("Pins").toCollection().modify((Retyped.dexie.Dexie.Collection<object, object>.modifyFn)((doc, collection) =>
                {
                    doc["SkeepyConsumerID"] = Guid.Empty.ToString();
                }));
            }));

            return db;
        }

        private Retyped.dexie.Dexie.Version.storesConfig ConstructDbVersion5Schema()
        {
            Retyped.dexie.Dexie.Version.storesConfig schema = ConstructDbVersion4Schema();

            schema["SkeepyConsumer"] = "&ID";
            schema["PinGroups"] = (string)schema["PinGroups"] + ",SkeepyConsumerID";
            schema["PinGroupSettings"] = (string)schema["PinGroupSettings"] + ",SkeepyConsumerID";
            schema["Pins"] = (string)schema["Pins"] + ",SkeepyConsumerID";

            return schema;
        }

        private Retyped.dexie.Dexie.Version.storesConfig ConstructDbVersion4Schema()
        {
            Retyped.dexie.Dexie.Version.storesConfig schema = ConstructDbVersion3Schema();

            schema["PinGroupSettings"] = "&ID";

            return schema;
        }

        private Retyped.dexie.Dexie.Version.storesConfig ConstructDbVersion3Schema()
        {
            Retyped.dexie.Dexie.Version.storesConfig schema = ConstructDbVersion2Schema();

            schema["PinGroups"] = "&ID,CreatedAtTicks,Name,IsSealed";

            return schema;
        }

        private Retyped.dexie.Dexie.Version.storesConfig ConstructDbVersion2Schema()
        {
            Retyped.dexie.Dexie.Version.storesConfig schema = ConstructDbVersion1Schema();

            schema["SyncRegistry"] = "&ID,EntityType,EntityIdentifier,SyncStatus";

            return schema;
        }

        private Retyped.dexie.Dexie.Version.storesConfig ConstructDbVersion1Schema()
        {
            Retyped.dexie.Dexie.Version.storesConfig schema = new Retyped.dexie.Dexie.Version.storesConfig();

            schema["Pins"] = "&ID,HappenedAtTicks";
            schema["PinGroups"] = "&ID,CreatedAtTicks,Name";

            return schema;
        }
    }
}