using Bridge;
using Bridge.Html5;
using Bridge.jQuery2;
using Bridge.React;
using H.Skeepy.Core.Branding;
using System.Threading.Tasks;

namespace H.Skeepy.Click.Web.UI.Components.UI.Elements
{
    public class Pulse : ComponentBase<Pulse.Props, Pulse.State>
    {
        public Pulse(Props props) : base(props, null) { }
        public Pulse() : this(new Props { }) { }

        public override ReactElement Render()
        {
            return
                DOM.Div
                (
                    new Attributes
                    {
                        Style = new ReactStyle
                        {
                            Width = props.Size,
                            Height = props.Size,
                            LineHeight = "0px",
                            BackgroundColor = (global::Bridge.Script.ToTemp("key1",props.Color)!=null?global::Bridge.Script.FromTemp<ColorInfo>("key1").Hex:(string)null) ?? style.Colors.Primary.Color.Hex,
                            Display = props.IsInline ? Display.InlineBlock : Display.Block,
                        },

                        Ref = StartPulseAnimation,
                    }
                );
        }

        protected override void ComponentWillUnmount()
        {
            base.ComponentWillUnmount();

            state.IsDisposed = true;
        }

        private async void StartPulseAnimation(HTMLElement element)
        {
            if (element == null)
                return;

            jQuery jqElement = new jQuery(element);

            if (props.IsInverted)
                jqElement.FadeOut(0);

            if (props.DelayStartInMilliseconds > 0)
                await Task.Delay(props.DelayStartInMilliseconds);

            if (state.IsDisposed) return;

            if(props.IsInverted)
                FadeInAndStartFadeOut(jqElement);
            else
                FadeOutAndStartFadeIn(jqElement);
        }

        private void FadeOutAndStartFadeIn(jQuery jqElement)
        {
            if (state.IsDisposed) return;

            jqElement.FadeOut(props.AnimationDurationInMilliseconds, (System.Action)(() => FadeInAndStartFadeOut(jqElement)));
        }

        private void FadeInAndStartFadeOut(jQuery jqElement)
        {
            if (state.IsDisposed) return;

            jqElement.FadeIn(props.AnimationDurationInMilliseconds, (System.Action)(() => FadeOutAndStartFadeIn(jqElement)));
        }

        public class State
        {
            public bool IsDisposed { get; set; }

        
private bool __Property__Initializer__IsDisposed=false;}

        public class Props
        {
            public ColorInfo Color { get; set; }
            public Union<string, int> Size { get; set; }
            public int AnimationDurationInMilliseconds { get; set; }
            public int DelayStartInMilliseconds { get; set; }
            public bool IsInline { get; set; }
            public bool IsInverted { get; set; }

        
private ColorInfo __Property__Initializer__Color=null;private Union<string, int> __Property__Initializer__Size=20;private int __Property__Initializer__AnimationDurationInMilliseconds=1000;private int __Property__Initializer__DelayStartInMilliseconds=0;private bool __Property__Initializer__IsInline=true;private bool __Property__Initializer__IsInverted=false;}
    }
}
