using Bridge.Html5;
using H.Skeepy.Core;
using H.Skeepy.Core.Model;
using H.Skeepy.Core.Operations;
using System;
using System.Collections.Generic;
using System.Threading.Tasks;

namespace H.Skeepy.Click.Web.UI.Components
{
    public class BrowserBasedDeviceInfoService : ImADeviceInfoService
    {
        public Task<Iota[]> GetRawProperties()
        {
            List<Iota> result = new List<Iota>();

            result.Add(new Iota(SkeepyPlatformStandardIDs.Platform, SkeepyPlatformType.Browser));
            result.Add(new Iota(SkeepyPlatformStandardIDs.Timezone, new Date().GetTimezoneOffset()));
            result.Add(new Iota(SkeepyPlatformStandardIDs.AsOf, Rolex.Time));
            result.Add(new Iota(SkeepyPlatformStandardIDs.AsOfTicks, Rolex.Time.Ticks));
            result.Add(new Iota(SkeepyPlatformStandardIDs.IpAddress, string.Empty));

            try
            {
                result.Add(new Iota("AppName", Window.Navigator.AppName));
                result.Add(new Iota("AppVersion", Window.Navigator.AppVersion));
                result.Add(new Iota("Geolocation", Window.Navigator.Geolocation != null));
                result.Add(new Iota("Language", Window.Navigator.Language));
                result.Add(new Iota("IsOnline", Window.Navigator.OnLine));
                result.Add(new Iota("CPU", Window.Navigator.Oscpu));
                result.Add(new Iota("Platform", Window.Navigator.Platform));
                result.Add(new Iota("Product", Window.Navigator.Product));
                result.Add(new Iota("UserAgent", Window.Navigator.UserAgent));
            }
            catch (Exception) { }

            try
            {
                result.Add(new Iota(SkeepyPlatformStandardIDs.DisplayWidth, Window.Screen.Width));
                result.Add(new Iota(SkeepyPlatformStandardIDs.DisplayWindowWidth, Window.InnerWidth));
                result.Add(new Iota(SkeepyPlatformStandardIDs.DisplayHeight, Window.Screen.Height));
                result.Add(new Iota(SkeepyPlatformStandardIDs.DisplayWindowHeight, Window.InnerHeight));
                result.Add(new Iota(SkeepyPlatformStandardIDs.DisplayColorDepth, Window.Screen.ColotDepth));
                result.Add(new Iota(SkeepyPlatformStandardIDs.DisplayPixelDepth, Window.Screen.PixelDepth));
            }
            catch (Exception) { }

            try
            {
                result.Add(new Iota("Performance-NavigationType", PrintNavigationType()));
                result.Add(new Iota("Performance-NavigationRedirectCount", Window.Performance.Navigation.RedirectCount));
                result.Add(new Iota(string.Format("Performance-Timing-{0}","ConnectEnd"), Window.Performance.Timing.ConnectEnd));
                result.Add(new Iota(string.Format("Performance-Timing-{0}","ConnectStart"), Window.Performance.Timing.ConnectStart));
                result.Add(new Iota(string.Format("Performance-Timing-{0}","DomainLookupEnd"), Window.Performance.Timing.DomainLookupEnd));
                result.Add(new Iota(string.Format("Performance-Timing-{0}","DomainLookupStart"), Window.Performance.Timing.DomainLookupStart));
                result.Add(new Iota(string.Format("Performance-Timing-{0}","DomComplete"), Window.Performance.Timing.DomComplete));
                result.Add(new Iota(string.Format("Performance-Timing-{0}","DomContentLoadedEventEnd"), Window.Performance.Timing.DomContentLoadedEventEnd));
                result.Add(new Iota(string.Format("Performance-Timing-{0}","DomContentLoadedEventStart"), Window.Performance.Timing.DomContentLoadedEventStart));
                result.Add(new Iota(string.Format("Performance-Timing-{0}","DomInteractive"), Window.Performance.Timing.DomInteractive));
                result.Add(new Iota(string.Format("Performance-Timing-{0}","DomLoading"), Window.Performance.Timing.DomLoading));
                result.Add(new Iota(string.Format("Performance-Timing-{0}","FetchStart"), Window.Performance.Timing.FetchStart));
                result.Add(new Iota(string.Format("Performance-Timing-{0}","LoadEventEnd"), Window.Performance.Timing.LoadEventEnd));
                result.Add(new Iota(string.Format("Performance-Timing-{0}","LoadEventStart"), Window.Performance.Timing.LoadEventStart));
                result.Add(new Iota(string.Format("Performance-Timing-{0}","NavigationStart"), Window.Performance.Timing.NavigationStart));
                result.Add(new Iota(string.Format("Performance-Timing-{0}","RedirectEnd"), Window.Performance.Timing.RedirectEnd));
                result.Add(new Iota(string.Format("Performance-Timing-{0}","RedirectStart"), Window.Performance.Timing.RedirectStart));
                result.Add(new Iota(string.Format("Performance-Timing-{0}","RequestStart"), Window.Performance.Timing.RequestStart));
                result.Add(new Iota(string.Format("Performance-Timing-{0}","ResponseEnd"), Window.Performance.Timing.ResponseEnd));
                result.Add(new Iota(string.Format("Performance-Timing-{0}","ResponseStart"), Window.Performance.Timing.ResponseStart));
                result.Add(new Iota(string.Format("Performance-Timing-{0}","SecureConnectionStart"), Window.Performance.Timing.SecureConnectionStart));
                result.Add(new Iota(string.Format("Performance-Timing-{0}","UnloadEventEnd"), Window.Performance.Timing.UnloadEventEnd));
                result.Add(new Iota(string.Format("Performance-Timing-{0}","UnloadEventStart"), Window.Performance.Timing.UnloadEventStart));
            }
            catch (Exception) { }

            return Task.FromResult<Iota[]>(result.ToArray());
        }

        private static string PrintNavigationType()
        {
            switch ((int)Window.Performance.Navigation.Type.ValueOf())
            {
                case 0: return "Direct";
                case 1: return "Reload";
                case 2: return "BackOrForward";
                default: return "OtherUnknown";
            }
        }
    }
}
