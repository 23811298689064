using Bridge;
using Bridge.React;
using H.Skeepy.Core.Branding;
using H.Skeepy.Core.Common;
using System;
using System.Collections.Generic;
using System.Linq;
using System.Text;
using System.Threading.Tasks;

namespace H.Skeepy.Click.Web.UI.Components.UI.Layout
{
    public class FlexFormLayout : ComponentBase<FlexFormLayout.Props, FlexFormLayout.State>
    {
        public FlexFormLayout(Props props, params Union<ReactElement, string>[] children) : base(props, children) { }
        public FlexFormLayout(params Union<ReactElement, string>[] children) : this(new Props { }, children) { }

        public override ReactElement Render()
        {
            return
                new FabricUiGridLayout
                (
                    new FabricUiGridLayout.Props { HasGreedyHeight = true },

                    RenderRows()
                );
        }

        private ReactElement[] RenderRows()
        {
            if (!(global::Bridge.Script.ToTemp("key1",Children)!=null?global::Bridge.Script.FromTemp<Union<ReactElement, string>[]>("key1").Any():(bool?)null) ?? true)
                return null;

            return
System.Linq.Enumerable.Select<Union<ReactElement,string>,ReactElement>(                Children
,(Func<Union<ReactElement,string>,ReactElement>)RenderRow)
                .ToArray()
                ;
        }

        private ReactElement RenderRow(Union<ReactElement, string> cellContent)
        {
            return
                new FabricUiGridRow(new FabricUiGridRow.Props { HasGreedyHeight = true }, RenderColumn(cellContent));
        }

        private ReactElement RenderColumn(Union<ReactElement, string> cellContent)
        {
            return
                new FabricUiGridColumn
                (
                    new FabricUiGridColumn.Props { 
                        HasGreedyHeight = true,
                        Size = FabricUiGridColumnSize.X12, 
                        SizeXLarge = FabricUiGridColumnSize.X6, 
                        PushXLarge = FabricUiGridColumnSize.X3, 
                        SizeXXXLarge = FabricUiGridColumnSize.X4, 
                        PushXXXLarge = FabricUiGridColumnSize.X4 
                    },

                    cellContent

                )
                ;
        }

        public class State
        {
            
        }

        public class Props
        {
            
        }
    }
}
