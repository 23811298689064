using Bridge.Html5;
using Bridge.jQuery2;
using Bridge.React;
using H.Skeepy.Click.Web.UI.Pages;
using ProductiveRage.ReactRouting;
using ProductiveRage.ReactRouting.Helpers;
using System;
using System.Collections.Generic;
using System.Linq;
using System.Text;
using System.Threading.Tasks;

namespace H.Skeepy.Click.Web.UI.Components.Navigation
{
    public class NavigationBootstrapper
    {
        private readonly IDispatcher dispatcher;
        private readonly Html5HistoryRouter historyHandler;

        public NavigationBootstrapper(IDispatcher dispatcher)
        {
            this.dispatcher = dispatcher;
            this.historyHandler = Html5HistoryRouter.Instance;
        }

        public void Wireup(Element pageContainer, Func<NavigateActionMatcher, NavigateActionMatcher> routeMatchFactory)
        {
            AppNavigator appNavigator = new AppNavigator(dispatcher);

            NavigateActionMatcher navigateActionMatchers = routeMatchFactory(
                NavigateActionMatcher
                    .Empty
                    .AddFor<InvalidRoute>(new NotFoundPage())
                )
                ;

            React.Render(
                new RoutingStoreActivatorContainer(dispatcher, navigateActionMatchers),
                pageContainer
            );

            RouteCombiner.StartListening(historyHandler, appNavigator.Routes, dispatcher);

            jQuery.Window.Bind("hashchange", (Action)(() => HandleHashChange((global::Bridge.Script.ToTemp("key1",historyHandler.CurrentLocation)!=null?global::Bridge.Script.FromTemp<UrlDetails>("key1").ToString():(string)null) ?? string.Empty)));

            if (Window.Location.Hash.IsHomePath())
                Html5HistoryRouter.Instance.RaiseNavigateToForCurrentLocation();
            else
                HandleHashChange(global::Bridge.Script.ToTemp("key2",historyHandler.CurrentLocation)!=null?global::Bridge.Script.FromTemp<UrlDetails>("key2").ToString():(string)null);
        }

        private static void HandleHashChange(string currentLocation)
        {
            string requestedHash = Window.Location.Hash;
            currentLocation = currentLocation == "/" ? string.Empty : (currentLocation ?? string.Empty);

            if (currentLocation.IsHomePath() && requestedHash.IsHomePath())
                return;

            if ((requestedHash ?? string.Empty).Equals(currentLocation ?? string.Empty, StringComparison.InvariantCultureIgnoreCase))
                return;

            if (requestedHash.IsHomePath())
            {
                Window.History.ReplaceState(null, null, "/");
                Html5HistoryRouter.Instance.RaiseNavigateToForCurrentLocation();
                Window.History.ReplaceState(null, null, "/#/");
                return;
            }

            Window.History.ReplaceState(null, null, requestedHash.Substring(1));
            Html5HistoryRouter.Instance.RaiseNavigateToForCurrentLocation();
            Window.History.ReplaceState(null, null, string.Format("/{0}",requestedHash));
        }
    }
}
