using H.Skeepy.Core.Infrastructure;
using System.Threading.Tasks;

namespace H.Skeepy.Core.Operations.UseCases.Concrete
{
    public class WebWorkerStartupUseCase : ImAStartupUseCase, ImASkeepyOperationContract
    {
        #region Construct
        ImASkeepyConsumerUseCase skeepyConsumerUseCase;
        public void ReferDependencies(ImADependencyPoolContainer dependencyPoolContainer)
        {
            skeepyConsumerUseCase = skeepyConsumerUseCase ?? dependencyPoolContainer.Resolve<ImASkeepyConsumerUseCase>();
        }
        #endregion

        public async Task Go()
        {
            await skeepyConsumerUseCase.Resurrect();
        }
    }
}
