using H.Skeepy.Core.Common;
using System;
using System.Collections.Generic;
using System.Text;
using System.Threading.Tasks;

namespace H.Skeepy.Core.Operations.Sync
{
    public interface ImASyncRegistry
    {
        Task<SyncStatus> StatusFor(string entityType, string entityIdentifier);

        Task SetStatusFor(string entityType, string entityIdentifier, SyncStatus syncStatus);

        Task<string[]> GetEntitiesToSync(string entityType, int maxBatchSize = 100);
    }

    public static class SyncRegistryExtensions
    {
        public static string TypeName(this object instance)
        {
            if (instance is Type)
                return (instance as Type).FullName;

            return instance.GetType().FullName;
        }
    }
}
