using Bridge;
using Bridge.React;
using H.Skeepy.Click.Web.UI.Components.UI.Styles;
using System.Linq;

namespace H.Skeepy.Click.Web.UI.Components.UI.Controls
{
    public class ListOfProperties : ComponentBase<ListOfProperties.Props, ListOfProperties.State>
    {
        public ListOfProperties(params Prop[] properties) : base(new Props { Properties = properties ?? new Prop[0] }, null) { }

        public override ReactElement Render()
        {
            return
                DOM.UL(
                    new Attributes
                    {
                        Style = new ReactStyle
                        {
                            Padding = 0,
                            Margin = string.Format("{0}px 0 {1}px 0",style.SizingUnitInPixels * 3 / 4,style.SizingUnitInPixels),
                        }
                        .FlexNode(isVerticalFlow: true),
                    },
                    global::Bridge.Script.ToTemp("key1",props.Properties)!=null?global::Bridge.Script.FromTemp<Prop[]>("key1").Where((System.Func<ListOfProperties.Prop,bool>)(x => x != null)).Select<ReactElement>((System.Func<ListOfProperties.Prop,ReactElement>)(x => DOM.Li(new LIAttributes
                    {
                        Style = new ReactStyle
                        {
                            MarginTop = style.SizingUnitInPixels * 1 / 4,
                        }
                        .FlexNode(),
                    }, RenderProperty(x)))):(EnumerableInstance<ReactElement>)null
                );
        }

        private ReactElement RenderProperty(Prop property)
        {
            return
                DOM.Div(
                    new Attributes
                    {
                        Style = new ReactStyle { }.FlexNode()
                    },

                    DOM.Div(new Attributes { Style = new ReactStyle { }.FlexNode() }, RenderName(property.Name)),
                    DOM.Div(new Attributes { Style = new ReactStyle { }.FlexNode() }, RenderValue(property.Value))

                );
        }

        private ReactElement RenderName(Union<ReactElement, string> name)
        {
            return
                DOM.Div(
                    new Attributes
                    {
                        Style = new ReactStyle
                        {
                            Color = style.Colors.Primary.Color.Hex,
                            FontWeight = "bold",
                            JustifyContent = Bridge.Html5.JustifyContent.FlexEnd,
                            PaddingRight = style.SizingUnitInPixels / 2,
                        }.FlexNode(),
                    },

                    name

                );
        }

        private ReactElement RenderValue(Union<ReactElement, string> value)
        {
            return
                DOM.Div(
                    new Attributes
                    {
                        Style = new ReactStyle
                        {
                            Color = style.Colors.Complementary.Color.Hex,
                            JustifyContent = Bridge.Html5.JustifyContent.FlexStart,
                            PaddingLeft = style.SizingUnitInPixels / 2,
                        }.FlexNode(),
                    },

                    value

                );
        }

        public class State
        {

        }

        public class Props
        {
            public Prop[] Properties { get; set; }

        
private Prop[] __Property__Initializer__Properties=new Prop[0];}

        public class Prop
        {
            public Union<ReactElement, string> Name { get; set; }
            public Union<ReactElement, string> Value { get; set; }

        
private Union<ReactElement, string> __Property__Initializer__Name=null;private Union<ReactElement, string> __Property__Initializer__Value=null;}
    }
}
