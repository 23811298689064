using System;
using System.Collections.Generic;
using System.Linq;

namespace H.Skeepy.Core.Model.Display.Health
{
    public class StorageExport
    {
        readonly List<StorageCollectionExport> collections = new List<StorageCollectionExport>();
        public StorageExport(StorageIdentifier storageIdentifier)
        {
            StorageIdentifier = storageIdentifier;
        }
        
        public DateTime AsOf { get; private set; }

        public StorageIdentifier StorageIdentifier { get; private set; }
public IEnumerable<StorageCollectionExport> Collections
{
    get
    {
        return collections;
    }
}        public long ApproximateSizeInBytes { get; private set; }

        public StorageExport Append(params StorageCollectionExport[] exports)
        {
            if (exports != null)
                collections.AddRange(exports);

            RecalculateApproximateSizeInBytes();

            return this;
        }

        private void RecalculateApproximateSizeInBytes()
        {
            if (!System.Linq.Enumerable.Any<StorageCollectionExport>(collections))
            {
                ApproximateSizeInBytes = 0;
                return;
            }

            ApproximateSizeInBytes = System.Linq.Enumerable.Sum<StorageCollectionExport>(collections,(Func<StorageCollectionExport,long>)(x => x.ApproximateSizeInBytes));
        }

    
private DateTime __Property__Initializer__AsOf=Rolex.Time;private long __Property__Initializer__ApproximateSizeInBytes=0;}

    public class StorageCollectionExport
    {
        readonly List<StorageCollectionExportRecord> records = new List<StorageCollectionExportRecord>();

        public StorageCollectionExport(string collectionName)
        {
            CollectionName = collectionName;
        }

        public DateTime AsOf { get; private set; }
        public string CollectionName { get; private set; }
public long RecordsCount
{
    get
    {
        return records.Count;
    }
}public IEnumerable<StorageCollectionExportRecord> Records
{
    get
    {
        return records;
    }
}        public long ApproximateSizeInBytes { get; private set; }

        public StorageCollectionExport Append(params StorageCollectionExportRecord[] batch)
        {
            if (batch != null)
                records.AddRange(batch);

            RecalculateApproximateSizeInBytes();

            return this;
        }

        private void RecalculateApproximateSizeInBytes()
        {
            if(!System.Linq.Enumerable.Any<StorageCollectionExportRecord>(records))
            {
                ApproximateSizeInBytes = 0;
                return;
            }

            ApproximateSizeInBytes = System.Linq.Enumerable.Sum<StorageCollectionExportRecord>(records,(Func<StorageCollectionExportRecord,long>)(x => x.ApproximateSizeInBytes));
        }

    
private DateTime __Property__Initializer__AsOf=Rolex.Time;private long __Property__Initializer__ApproximateSizeInBytes=0;}

    public class StorageCollectionExportRecord
    {
        public StorageCollectionExportRecord(string key, string payload)
        {
            Key = key;
            Payload = payload;
            ApproximateSizeInBytes = payload.Length * 2; //UTF-8
        }

        public string Key { get; private set; }
        public string Payload { get; private set; }
        public long ApproximateSizeInBytes { get; private set; }
    }
}
