using H.Skeepy.Core.Infrastructure;

namespace H.Skeepy.Core.Branding
{
    public class BrandingModule : ImADependencyModule
    {
        public void RegisterDependencies(ImADependencyPoolContainer dependencyPoolContainer)
        {
            dependencyPoolContainer.Register<BrandingStyle>((System.Func<object>)(() => new BrandingStyle()));
        }
    }
}
