using H.Skeepy.Core.Model.GPS;
using System;

namespace H.Skeepy.Core.Model.Display
{
    public class PinDisplay
    {
        const int decimalsToGroupGpsTileBy = 5;

        public PinDisplay(Pin pin, int index)
        {
            Index = index;
            HappenedAt = pin.HappenedAt;
            Gps = pin.Gps;
            GpsTile = Gps == null ? null : (GpsLocation?)new GpsLocation
            {
                AltAccuracyInMeters = Gps.Value.AltAccuracyInMeters,
                GndAccuracyInMeters = Gps.Value.GndAccuracyInMeters,
                HeadingInDegrees = Gps.Value.HeadingInDegrees,
                Timestamp = Gps.Value.Timestamp,
                SpeedInMetersPerSecond = Gps.Value.SpeedInMetersPerSecond,
                Point = new GpsPoint
                {
                    AltFromSeaLevelInMeters = Gps.Value.Point.AltFromSeaLevelInMeters,
                    LatInDegrees = Math.Round(Gps.Value.Point.LatInDegrees, decimalsToGroupGpsTileBy),
                    LngInDegrees = Math.Round(Gps.Value.Point.LngInDegrees, decimalsToGroupGpsTileBy),
                },
            };
        }

        public int Index { get; private set; }

        public DateTime HappenedAt { get; private set; }

        public GpsLocation? Gps { get; private set; }
        public GpsLocation? GpsTile { get; private set; }

    
private int __Property__Initializer__Index=0;private GpsLocation? __Property__Initializer__Gps=null;private GpsLocation? __Property__Initializer__GpsTile=null;}
}
