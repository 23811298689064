using H.Skeepy.Core.Operations;
using H.Skeepy.Core.Operations.Concrete;
using System;

namespace H.Skeepy.Core
{
    public static class Rolex
    {
        static ImATimekeeper timekeeper = new Timekeeper();
public static ImATimekeeper TimeKeepingMechanism
{
    get
    {
        return timekeeper;
    }
}public static DateTime Time
{
    get
    {
        return timekeeper.Time;
    }
}public static DateTime Date
{
    get
    {
        return timekeeper.Date;
    }
}
        public static void UseMechanism(ImATimekeeper timeKeepingMechanism)
        {
            timekeeper = timeKeepingMechanism;
        }
    }

    public class RolexCustomizer : IDisposable
    {
        readonly ImATimekeeper originalMechanism;

        public RolexCustomizer(ImATimekeeper timeKeepingMechanism)
        {
            this.originalMechanism = Rolex.TimeKeepingMechanism;
            Rolex.UseMechanism(timeKeepingMechanism);
        }

        public void Dispose()
        {
            Rolex.UseMechanism(originalMechanism);
        }
    }
}
