using System;
using System.Text;

namespace H.Skeepy.Core.Model.Logging
{
    public class LogEntry
    {
        public Guid ID { get; set; }
        public LogEntryLevel Level { get; set; }
        public string Message { get; set; }
        public string Method { get; set; }
        public string Application { get; set; }
        public DateTime HappenedAt { get; set; }
public long HappenedAtTicks
{
    get
    {
        return HappenedAt.Ticks;
    }
}
        public static LogEntry FromException(Exception ex)
        {
            LogEntry logEntry = new LogEntry();
            logEntry.Application = "H.Skeepy.Click.Web.UI";
            logEntry.Level = LogEntryLevel.Exception;
            logEntry.Method = ex.StackTrace;
            logEntry.Message = string.Format("{0}{1}",ex,PrintInnerExceptionChain(ex));

            if (ex is AggregateException)
            {
                logEntry.Message = PrintAggregateException(ex as AggregateException);
            }
            else if (ex.InnerException != null)
            {
                logEntry.Message += PrintInnerExceptionChain(ex);
            }

            return logEntry;
        }

        private static string PrintAggregateException(AggregateException aggregateException)
        {
            StringBuilder printer = new StringBuilder();
            foreach (Exception ex in aggregateException.InnerExceptions)
            {
                printer.AppendLine(string.Format("{0}{1}",ex,PrintInnerExceptionChain(ex)));
            }
            return printer.ToString();
        }

        private static string PrintInnerExceptionChain(Exception ex)
        {
            if (ex.InnerException == null)
                return string.Empty;

            return string.Format(" | {0}{1}",ex.InnerException,PrintInnerExceptionChain(ex.InnerException));
        }

    
private Guid __Property__Initializer__ID=Guid.NewGuid();private LogEntryLevel __Property__Initializer__Level=LogEntryLevel.Info;private string __Property__Initializer__Application="H.Skeepy";private DateTime __Property__Initializer__HappenedAt=Rolex.Time;}

    public enum LogEntryLevel
    {
        Debug = 0,
        Trace = 1,

        Info = 10,

        Warn = 100,

        Error = 1000,
        Exception = 1001,
    }
}
