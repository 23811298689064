using Bridge;
using Bridge.React;
using H.Skeepy.Core.Branding;
using System;

namespace H.Skeepy.Click.Web.UI.Components.UI.Elements
{
    public class SkeepyLogo : StatelessComponent<SkeepyLogo.Props>
    {
        public SkeepyLogo(Props props) : base(props, null) { }

        public override ReactElement Render()
        {
            string imageUrl = string.Format("url('/{0}')",(props.UseBitmapInsteadOfVector ? "SkeepyLogo_White_640_640.png" : "SkeepyLogo.svg"));

            ReactStyle style = new ReactStyle
            {
                Display = Bridge.Html5.Display.InlineBlock,
                BackgroundImage = imageUrl,
                BackgroundRepeat = Bridge.Html5.BackgroundRepeat.NoRepeat,
                BackgroundPosition = "center center",
                BackgroundSize = "contain",
                Width = props.Width,
                Height = props.Height,
                Margin = props.Margin,
            };

            if (props.OnClick != null)
                style.Cursor = Bridge.Html5.Cursor.Pointer;

            if(props.TintColor != null)
            {
                style.BackgroundImage = "none";
                style.BackgroundColor = props.TintColor.Hex;
                style.BackgroundBlendMode = Bridge.Html5.BackgroundBlendMode.Overlay;

                style["mask-image"] = imageUrl;
                style["-webkit-mask-image"] = imageUrl;
                style["mask-repeat"] = "no-repeat";
                style["-webkit-mask-repeat"] = "no-repeat";
                style["mask-size"] = "contain";
                style["-webkit-mask-size"] = "contain";
                style["mask-position"] = "center center";
                style["-webkit-mask-position"] = "center center";
            }

            return
                DOM.Div(
                    new Attributes
                    {
                        Style = style,
                        OnClick = x => global::Bridge.Script.ToTemp("key1",props)!=null&&global::Bridge.Script.ToTemp("key2",global::Bridge.Script.FromTemp<Props>("key1").OnClick)!=null?global::Bridge.Script.FromLambda(()=>global::Bridge.Script.FromTemp<Action>("key2").Invoke()):null,
                    }
                );
        }

        public class Props
        {
            public bool UseBitmapInsteadOfVector { get; set; }
            public ColorInfo TintColor { get; set; }
            public Union<string, int> Width { get; set; }
            public Union<string, int> Height { get; set; }
            public Union<string, int> Margin { get; set; }
            public Action OnClick { get; set; }

        
private bool __Property__Initializer__UseBitmapInsteadOfVector=false;private ColorInfo __Property__Initializer__TintColor=null;private Union<string, int> __Property__Initializer__Width=100;private Union<string, int> __Property__Initializer__Height=100;private Union<string, int> __Property__Initializer__Margin=0;}
    }
}
