using Bridge;
using Bridge.Html5;
using Bridge.React;
using H.Skeepy.Click.Web.UI.Components.UI;
using H.Skeepy.Click.Web.UI.Components.UI.Controls;
using H.Skeepy.Click.Web.UI.Components.UI.Controls.UserFeedback;
using H.Skeepy.Click.Web.UI.Components.UI.Elements;
using H.Skeepy.Click.Web.UI.Components.UI.Layout;
using H.Skeepy.Core.Common;
using H.Skeepy.Core.Operations.Broadcasting;
using H.Skeepy.Core.Operations.Broadcasting.Abstract;
using H.Skeepy.Core.Operations.Broadcasting.Concrete;
using H.Skeepy.Core.Operations.UI.Abstract;
using H.Skeepy.Core.Operations.UI.Navigation;
using H.Skeepy.Core.Operations.UI.UserInput;
using System;
using System.Linq;
using System.Threading.Tasks;

namespace H.Skeepy.Click.Web.UI.Pages
{
    public abstract class PageBase<TProps, TViewState> : ComponentBase<TProps, TViewState>, ImAnAppPage
        where TViewState : ViewStateBase, new()
        where TProps : PagePropsBase
    {
public virtual string Title
{
    get
    {
        return string.Format("SKeepy Click! - {0}",state.GetType().Name.Replace("ViewState", string.Empty));
    }
}
        protected PageBase(TProps props, params Union<ReactElement, string>[] children)
            : base(props, children) { }

        protected override TViewState GetInitialState()
        {
            base.GetInitialState();
            TViewState state = Get<TViewState>();
            state.Use((Action<string>)(x => SetState()));
            state.Bind();
            return state;
        }

        ImABroadcastReceiver broadcastEventCommandsReceiver;
        protected override void EnsureDependencies()
        {
            base.EnsureDependencies();

            if (broadcastEventCommandsReceiver == null)
            {
                BroadcastReceiverBase receiver = Get<BroadcastReceiverBase>();
                receiver.Use(typeof(BroadcastEventCommand));
                receiver.Use((Func<ImABroadcastEvent,Task>)(x => OnCommand(x.Unwrap<BroadcastEventCommand>())));
                broadcastEventCommandsReceiver = receiver;
            }
        }

        protected virtual async Task OnCommand(BroadcastEventCommand command)
        {
            switch (command)
            {
                case BroadcastEventCommand.OpenAbout:
                    await Alert("About", string.Format("<p style='text-align: center;'>Version: <strong>{0}</strong></p>",state.Version.Number.Semantic)+
string.Format("<p style='text-align: center;'>crafted {0}</p>",state.Version.Timestamp.Print())+
string.Format("<p style='text-align: center;'>on branch: {0}</p>",state.Version.Branch)+
string.Format("<p style='text-align: center;'>source id: {0}</p>",state.Version.Commit)+
string.Format("<p style='text-align: center;'><a href='/#/health/storage'>View More...</a></p>")                    );
                    break;
            }
        }

        protected override async void ComponentWillReceiveProps(TProps nextProps)
        {
            base.ComponentWillReceiveProps(nextProps);

            state.Use((nextProps!=null?nextProps.NavigationParams:(UiNavigationParams)null) ?? UiNavigationParams.None);

            await state.Initialize();
        }

        protected override async void ComponentDidMount()
        {
            base.ComponentDidMount();

            Document.Title = Title;

            state.Use((global::Bridge.Script.ToTemp("key1",props)!=null?global::Bridge.Script.FromTemp<TProps>("key1").NavigationParams:(UiNavigationParams)null) ?? UiNavigationParams.None);

            await state.Initialize();
        }

        protected override async void ComponentWillUnmount()
        {
            base.ComponentWillUnmount();

            broadcastEventCommandsReceiver!=null?global::Bridge.Script.FromLambda(()=>broadcastEventCommandsReceiver.Dispose()):null;

            await state.Leaving();
        }

        protected InputAttributes BindTo(string propertyName, InputAttributes attributes = null)
        {
            attributes = attributes ?? new InputAttributes();

            Action<FormEvent<HTMLInputElement>> originalOnChange = attributes.OnChange;

            attributes.OnChange = x =>
            {
                state.NotifyUiChange(propertyName, x.CurrentTarget!=null?x.CurrentTarget.Value:(string)null);

                originalOnChange!=null?global::Bridge.Script.FromLambda(()=>originalOnChange.Invoke(x)):null;
            };

            return attributes;
        }

        protected TextAreaAttributes BindTo(string propertyName, TextAreaAttributes attributes = null)
        {
            attributes = attributes ?? new TextAreaAttributes();

            Action<FormEvent<HTMLTextAreaElement>> originalOnChange = attributes.OnChange;

            attributes.OnChange = x =>
            {
                state.NotifyUiChange(propertyName, x.CurrentTarget!=null?x.CurrentTarget.Value:(string)null);

                originalOnChange!=null?global::Bridge.Script.FromLambda(()=>originalOnChange.Invoke(x)):null;
            };

            return attributes;
        }

        protected Switch.Props BindTo(string propertyName, Switch.Props props = null)
        {
            props = props ?? new Switch.Props();

            Action<bool> originalOnChange = props.OnChange;

            props.OnChange = x =>
            {
                state.NotifyUiChange(propertyName, x);

                originalOnChange!=null?global::Bridge.Script.FromLambda(()=>originalOnChange.Invoke(x)):null;
            };

            return props;
        }

        protected ReactElement RenderNecessaire()
        {
            ReactElement[] necessaire = new ReactElement[] {
                RenderBusyCurtainIfNecessary(),
                RenderUserOptionsIfNecessary(),
            };

            if (System.Linq.Enumerable.All<ReactElement>(necessaire,(Func<ReactElement,bool>)(x => x == null)))
                return null;

            return DOM.Div(new Attributes { }, necessaire);
        }
#region Simple User Feedback
protected Task Alert(string title, string descriptionHtml = null)
{
    return AskUserForSingleAnswer((Func<UserOptionsContext,ReactElement>)UserFeedbackFactory.Alert, title, descriptionHtml);
}protected async Task<bool> Confirm(string title, string descriptionHtml = null)
{
    return !(await AskUserForSingleAnswer((Func<UserOptionsContext,ReactElement>)UserFeedbackFactory.Confirm, title, descriptionHtml)).HasCanceled;
}        #endregion

        protected async Task<UserOptionSelectionResult> AskUserForSingleAnswer(Func<UserOptionsContext, ReactElement> userFeedbackRenderer, string title, string descriptionHtml = null, params UserOption[] options)
        {
            await AskUser(title, descriptionHtml, isMultipleSelection: false, userFeedbackRenderer:(Func<UserOptionsContext,ReactElement>)userFeedbackRenderer, options:options);
            return await ReadUserResponse();
        }

        protected async Task<UserOptionSelectionResult> AskUserForMultipleAnswers(Func<UserOptionsContext, ReactElement> userFeedbackRenderer, string title, string descriptionHtml = null, params UserOption[] options)
        {
            await AskUser(title, descriptionHtml, isMultipleSelection: true, userFeedbackRenderer:(Func<UserOptionsContext,ReactElement>)userFeedbackRenderer, options:options);
            return await ReadUserResponse();
        }

        UserOptionsContext userOptionsContext = null;
        Func<UserOptionsContext, ReactElement> userFeedbackRenderer = null;
        private async Task AskUser(string title, string descriptionHtml, bool isMultipleSelection, Func<UserOptionsContext, ReactElement> userFeedbackRenderer, params UserOption[] options)
        {
            userOptionsContext = new UserOptionsContext(title, descriptionHtml, isMultipleSelection, options);
            this.userFeedbackRenderer = userFeedbackRenderer;
            await SetStateAsync();
        }

        private async Task<UserOptionSelectionResult> ReadUserResponse()
        {
            UserOptionSelectionResult userResponse = await userOptionsContext.Task;

            userOptionsContext = null;

            await SetStateAsync();

            return userResponse;
        }

        private ReactElement RenderBusyCurtainIfNecessary()
        {
            if (!state.IsBusy)
                return null;

            return new BusyCurtain();
        }


        private ReactElement RenderUserOptionsIfNecessary()
        {
            if (userOptionsContext == null || userFeedbackRenderer == null)
                return null;

            return new Curtain(new Curtain.Props { }, new CenteredContent(userFeedbackRenderer.Invoke(userOptionsContext)));
        }
    }
}
