using System.Globalization;

namespace H.Skeepy.Core.Branding
{
    public class TypographySize
    {
        private const int pointsPerInch = 72;
        private const int pixelsPerInch = 96;
        private const float pixelsInOnePoint = 1.33333337F;
        private const float emsInOnePoint = 0.0833333358F;
        private const float percentsInOnePoint = 8.333334F;

        private TypographySize() { }
        public TypographySize(float fontSizeInPoints = 12)
        {
            Points = fontSizeInPoints;
        }

        public float Points { get; private set; }
public string PointsCss
{
    get
    {
        return string.Format("{0}pt",Points.ToString(CultureInfo.InvariantCulture));
    }
}public float Pixels
{
    get
    {
        return Points * pixelsInOnePoint;
    }
}public string PixelsCss
{
    get
    {
        return string.Format("{0}px",Pixels.ToString(CultureInfo.InvariantCulture));
    }
}public float Ems
{
    get
    {
        return Points * emsInOnePoint;
    }
}public string EmsCss
{
    get
    {
        return string.Format("{0}em",Ems.ToString(CultureInfo.InvariantCulture));
    }
}public float Percent
{
    get
    {
        return Points * percentsInOnePoint;
    }
}public string PercentCss
{
    get
    {
        return string.Format("{0}%",Percent.ToString(CultureInfo.InvariantCulture));
    }
}
        public TypographySize ScaleBy(float scale = 1f)
        {
            return new TypographySize(Points * scale);
        }

    
private float __Property__Initializer__Points=12;}

    public class Typography
    {
        private Typography() { }
        public Typography(string fontFamily, TypographySize fontSize, string fontFamilyUrl = null)
        {
            FontFamily = fontFamily;
            FontFamilyUrl = fontFamilyUrl;
            FontSize = fontSize;

            FontSizeLarge = new TypographySize().ScaleBy(1.3f);
            FontSizeLarger = new TypographySize().ScaleBy(1.6f);

            FontSizeSmall = new TypographySize().ScaleBy(.8f);
            FontSizeSmaller = new TypographySize().ScaleBy(.6f);
        }

        public static readonly Typography Default = new Typography(
            "'Roboto', Calibri, Helvetica, Arial, Sans-Serif",
            new TypographySize(12),
            "https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap&subset=latin-ext"
            );

        public string FontFamily { get; private set; }
        public string FontFamilyUrl { get; private set; }
        public TypographySize FontSize { get; private set; }

        public TypographySize FontSizeLarge { get; private set; }
        public TypographySize FontSizeLarger { get; private set; }
        public TypographySize FontSizeSmall { get; private set; }
        public TypographySize FontSizeSmaller { get; private set; }
    }
}
