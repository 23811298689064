using Bridge.React;
using H.Skeepy.Click.Web.UI.Components.Chromes;
using H.Skeepy.Click.Web.UI.Components.UI.Controls;
using H.Skeepy.Click.Web.UI.Components.UI.Layout;
using H.Skeepy.Core.Operations.UI.UserInput;
using H.Skeepy.Core.Operations.UI.ViewState.PinGroupViewStates;

namespace H.Skeepy.Click.Web.UI.Pages.PinGroupPages
{
    public class DefineNewPinGroupPage : PageBase<DefineNewPinGroupPage.Props, DefineNewPinGroupViewState>
    {
public override string Title
{
    get
    {
        return "New Click Session";
    }
}
        public DefineNewPinGroupPage() : base(new Props { }, null) { }

        public override ReactElement Render()
        {
            return
                new DefaultChrome
                (
                    RenderNecessaire(),

                    new PaddedContent
                    (
                        new Title("New Click Session"),

                        new FormLayout
                        (
                            new FormLayout.Props { LayoutMode = FormLayoutMode.OnePerRow },

                            new TextInput(new TextInput.Props
                            {
                                Decorator = x => BindTo("Name", x),
                                Description = "A meaningful name for this session",
                                Label = "Session Name",
                                Placeholder = "New Click Session Name",
                                Width = "100%",
                                UserInputValidator = (x, c) => UserInputValidation.Required(x),
                            }),

                            new LongTextInput(new LongTextInput.Props
                            {
                                Decorator = x => BindTo("Description", x),
                                Description = "Give a brief description to this session, if you see fit. It's not mandatory.",
                                Label = "Session Description",
                                Placeholder = "Session Description",
                                Width = "100%",
                            }),

                            new Switch(BindTo("IsLocationTrackingEnabled", new Switch.Props
                            {
                                Width = "100%",
                                IsOn = state.IsLocationTrackingEnabled,
                            }),
                                "Track GPS location for clicks"
                            ),

                            new Button
                            (new Button.Props
                            {
                                Width = "100%",
                                OnClick = async () => await state.TryCreateNewPinGroup(),
                                IsDisabled = state.IsValidating,
                            },
                                "Start"
                            ),

                            new Button
                            (new Button.Props
                            {
                                Role = ButtonRole.Negative,
                                Width = "100%",
                                OnClick = () => state.Cancel(),
                                IsDisabled = state.IsValidating,
                            },
                                "Cancel"
                            ),

                            new ValidationResultDisplay(new ValidationResultDisplay.Props { ValidationResult = state.ValidationResult, Width = "100%" })
                        )

                    )

                )
                ;
        }

        public class Props : PagePropsBase { }
    }
}
