using Bridge;
using Bridge.React;
using H.Skeepy.Core;
using H.Skeepy.Core.Branding;
using System;

namespace H.Skeepy.Click.Web.UI.Components.UI.Elements
{
    public class BusyIndicator : ComponentBase<BusyIndicator.Props, BusyIndicator.State>
    {
        public BusyIndicator(Props props) : base(props, null) { }
        public BusyIndicator() : this(new Props { }) { }

        public override ReactElement Render()
        {
            return
                DOM.Div
                (
                    new Attributes
                    {
                        Style = new ReactStyle
                        {
                            Width = props.Size,
                            Height = props.Size,
                            LineHeight = "0px",
                            FontSize = 0,
                        },
                    },

                    RenderPulseMatrix()

                );
        }

        private ReactElement[] RenderPulseMatrix()
        {
            BusyIndicatorStyle style = props.Style;
            if (style == BusyIndicatorStyle.Random)
            {
                style = Rolex.Time.Millisecond % 2 == 0 ? BusyIndicatorStyle.Scattered : BusyIndicatorStyle.Fluent;
            }

            switch (style)
            {
                case BusyIndicatorStyle.Fluent: return RenderPulseMatrixFluent();
                default: return RenderPulseMatrixScattered();
            }
        }

        private ReactElement[] RenderPulseMatrixFluent()
        {
            int fullCycleAnimationDuration = props.AnimationSpeed * props.NumberOfPulses;

            return RenderPulseMatrixWithCustomProps((Func<int,Pulse.Props>)(index => new Pulse.Props
            {
                Size = string.Format("{0}%",100f / props.Resolution),
                AnimationDurationInMilliseconds = fullCycleAnimationDuration,
                DelayStartInMilliseconds = index * props.AnimationSpeed,
                Color = props.Color,
                IsInverted = props.IsInverted,
            }));
        }

        private ReactElement[] RenderPulseMatrixScattered()
        {
            Random random = new Random();

            int animationDurationMidPoint = props.AnimationSpeed * props.NumberOfPulses;

            return RenderPulseMatrixWithCustomProps((Func<int,Pulse.Props>)(index => new Pulse.Props
            {
                Size = string.Format("{0}%",100f / props.Resolution),
                AnimationDurationInMilliseconds = random.Next(animationDurationMidPoint / 2, animationDurationMidPoint * 2),
                Color = props.Color,
                IsInverted = props.IsInverted,
            }));
        }

        private ReactElement[] RenderPulseMatrixWithCustomProps(Func<int, Pulse.Props> propsFactory)
        {
            Random random = new Random();

            ReactElement[] result = new ReactElement[props.NumberOfPulses];

            string pulseSize = string.Format("{0}%",100f / props.Resolution);


            for (var i = 0; i < result.Length; i++)
            {
                result[i] = new Pulse(propsFactory(i));
            }

            return result;
        }

        public class State
        {

        }

        public class Props
        {
            public BusyIndicatorStyle Style { get; set; }
            public Union<string, int> Size { get; set; }
            public ColorInfo Color { get; set; }
            public int Resolution { get; set; }
public int NumberOfPulses
{
    get
    {
        return Resolution * Resolution;
    }
}            public int AnimationSpeed { get; set; }
            public bool IsInverted { get; set; }

        
private BusyIndicatorStyle __Property__Initializer__Style=BusyIndicatorStyle.Random;private Union<string, int> __Property__Initializer__Size=15;private ColorInfo __Property__Initializer__Color=null;private int __Property__Initializer__Resolution=3;private int __Property__Initializer__AnimationSpeed=100;private bool __Property__Initializer__IsInverted=false;}
    }

    public enum BusyIndicatorStyle
    {
        Scattered = 0,
        Fluent = 1,

        Random = 1000,
    }
}
