using H.Skeepy.Core.Infrastructure;
using H.Skeepy.Core.Model.Display;
using H.Skeepy.Core.Operations.Storage;
using System.Linq;
using System.Threading.Tasks;

namespace H.Skeepy.Core.Operations.UseCases.Concrete
{
    public class PinGroupBrowserUseCase : ImAPinGroupBrowserUseCase, ImASkeepyOperationContract
    {
        #region Construct
        ImAPinGroupStorageService pinGroupStorageService;
        public void ReferDependencies(ImADependencyPoolContainer dependencyPoolContainer)
        {
            pinGroupStorageService = pinGroupStorageService ?? dependencyPoolContainer.Resolve<ImAPinGroupStorageService>();
        }
        #endregion

        public async Task<PinGroupDisplay[]> BrowseAllUnsealed()
        {
            Core.Model.PinGroup[] result = await pinGroupStorageService.Search(new PinGroupFilterRequest
            {
                IsSealed = false,
            });

            if (!(result!=null?result.Any():(bool?)null) ?? true)
                return new PinGroupDisplay[0];

            return System.Linq.Enumerable.OrderByDescending<Model.PinGroup,long>(result,(System.Func<Model.PinGroup,long>)(x => x.CreatedAtTicks)).Select<PinGroupDisplay>((System.Func<Model.PinGroup,PinGroupDisplay>)(x => new PinGroupDisplay(x))).ToArray();
        }

        public async Task<PinGroupDisplay[]> BrowseAllSealed()
        {
            Core.Model.PinGroup[] result = await pinGroupStorageService.Search(new PinGroupFilterRequest
            {
                IsSealed = true,
            });

            if (!(result!=null?result.Any():(bool?)null) ?? true)
                return new PinGroupDisplay[0];

            return System.Linq.Enumerable.OrderByDescending<Model.PinGroup,long>(result,(System.Func<Model.PinGroup,long>)(x => x.CreatedAtTicks)).Select<PinGroupDisplay>((System.Func<Model.PinGroup,PinGroupDisplay>)(x => new PinGroupDisplay(x))).ToArray();
        }
    }
}
