using H.Skeepy.Core.Common;
using System;
using System.Linq;

namespace H.Skeepy.Core.Operations.UI.Abstract
{
    public class ViewStateValidationResult
    {
        public bool IsValid { get; set; }

        public string Message { get; set; }

        public string[] Addendum { get; set; }

        public ViewStateValidationResult FailBecause(params string[] reasons)
        {
            IsValid &= false;

            if(reasons == null && reasons.Length == 0)
            {
                return this;
            }

            if (string.IsNullOrWhiteSpace(Message))
                Message = reasons[0];

            if(reasons.Length > 1)
                Addendum = System.Linq.Enumerable.Concat<string>(Addendum,H.Skeepy.Core.Common.DataExtensions.Jump<string>(reasons,1)).ToArray();

            return this;
        }
public static ViewStateValidationResult Win()
{
    return new ViewStateValidationResult();
}public static ViewStateValidationResult Fail(params string[] message)
{
    return new ViewStateValidationResult().FailBecause(message);
}public static ViewStateValidationResult Fail(Exception exception)
{
    return new ViewStateValidationResult().FailBecause(System.Linq.Enumerable.Concat<string>(H.Skeepy.Core.Common.DataExtensions.AsArray<string>(exception.Message),System.Linq.Enumerable.Select<Exception,string>(exception.Flatten(),(Func<Exception,string>)(x => x.Print()))).ToArray());
}
    
private bool __Property__Initializer__IsValid=true;private string[] __Property__Initializer__Addendum=new string[0];}
}
