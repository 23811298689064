using System;
using System.Collections.Concurrent;
using System.Collections.Generic;
using System.Linq;

namespace H.Skeepy.Core.Infrastructure.Concrete
{
    public class DependencyPoolContainer : ImADependencyPoolContainer, ImADependencyPoolBrowser
    {
        private class InstanceFactory
        {
            private readonly DependencyPoolContainer dependencyPoolContainer;
            private readonly Func<object> factory;
            private object instance;

            public InstanceFactory(DependencyPoolContainer dependencyPoolContainer, Func<object> factory)
            {
                this.dependencyPoolContainer = dependencyPoolContainer;
                this.factory = factory;
            }

            public object GetInstance()
            {
                EnsureInstance();
                instance = instance ?? factory();
                return instance;
            }

            public T GetInstance<T>()
            {
                return (T)GetInstance();
            }

            private void EnsureInstance()
            {
                if (instance != null)
                    return;

                instance = factory();
                if (instance is ImASkeepyOperationContract)
                {
                    (instance as ImASkeepyOperationContract).ReferDependencies(dependencyPoolContainer);
                }
            }
        }

        private readonly ConcurrentDictionary<Type, Func<object>> factoryDictionary = new ConcurrentDictionary<Type, Func<object>>();
        private readonly ConcurrentDictionary<Type, InstanceFactory> dependencyDictionary = new ConcurrentDictionary<Type, InstanceFactory>();

        public ImADependencyPoolContainer Register<T>(Func<object> factory)
        {
            if (typeof(ImADependencyModule).IsAssignableFrom(typeof(T)))
            {
                ((ImADependencyModule)factory()).RegisterDependencies(this);
            }

            InstanceFactory instanceFactory = new InstanceFactory(this, factory);
            dependencyDictionary.AddOrUpdate(typeof(T), instanceFactory, (Func<Type,DependencyPoolContainer.InstanceFactory,DependencyPoolContainer.InstanceFactory>)((x, y) => instanceFactory));
            return this;
        }

        public ImADependencyPoolContainer RegisterAlwaysNew<T>(Func<object> factory)
        {
            factoryDictionary.AddOrUpdate(typeof(T), (Func<object>)factory, (Func<Type,Func<object>,Func<object>>)((x, y) => factory));
            return this;
        }

        public ImADependencyPoolContainer Unregister<T>()
        {
            InstanceFactory removedFactory;
            dependencyDictionary.TryRemove(typeof(T), out removedFactory);
            Func<object> removedInstance;
            factoryDictionary.TryRemove(typeof(T), out removedInstance);
            return this;
        }

        public T Resolve<T>()
        {
            if (factoryDictionary.ContainsKey(typeof(T)))
                return ResolveAlwaysNew<T>();

            if (!dependencyDictionary.ContainsKey(typeof(T)))
                return default(T);

            return dependencyDictionary[typeof(T)].GetInstance<T>();
        }

        public KeyValuePair<Type, Func<object>>[] GetAllOneTimeTypes()
        {
            return System.Linq.Enumerable.Select<KeyValuePair<Type,DependencyPoolContainer.InstanceFactory>,KeyValuePair<Type,Func<object>>>(dependencyDictionary
,(Func<KeyValuePair<Type,DependencyPoolContainer.InstanceFactory>,KeyValuePair<Type,Func<object>>>)(x => new KeyValuePair<Type, Func<object>>(x.Key, x.Value.GetInstance)))
                .ToArray();
        }

        public KeyValuePair<Type, Func<object>>[] GetAllAlwaysNewTypes()
        {
            return System.Linq.Enumerable.ToArray<KeyValuePair<Type,Func<object>>>(factoryDictionary
);
        }

        private T ResolveAlwaysNew<T>()
        {
            T instance = (T)(global::Bridge.Script.ToTemp("key1",factoryDictionary[typeof(T)])!=null?global::Bridge.Script.FromTemp<Func<object>>("key1").Invoke():(object)null);

            if (instance is ImASkeepyOperationContract)
            {
                (instance as ImASkeepyOperationContract).ReferDependencies(this);
            }

            return instance;
        }
    }
}
