using System;
using System.Text;

namespace H.Skeepy.Core.Model
{
    public class Version
    {
        public static readonly Version Unknown = new Version
        {
            Number = VersionNumber.Unknown,
            Timestamp = DateTime.MinValue,
            Branch = "N/A",
            Commit = "N/A",
        };

        public VersionNumber Number { get; set; }
        public DateTime Timestamp { get; set; }
        public string Branch { get; set; }
        public string Commit { get; set; }

    
private VersionNumber __Property__Initializer__Number=VersionNumber.Unknown;}

    public class VersionNumber
    {
        public static readonly VersionNumber Unknown = new VersionNumber
        {
            Major = 0,
            Minor = 0,
            Build = 0,
            Patch = 0,
            Suffix = "unknown",
            Semantic = "0.0.0.0-unknown",
        };

        public int Major { get; set; }
        public int Minor { get; set; }
        public int? Patch { get; set; }
        public int? Build { get; set; }
        public string Suffix { get; set; }

        public string Semantic { get; set; }

    
private int __Property__Initializer__Major=0;private int __Property__Initializer__Minor=0;}
}
