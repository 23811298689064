using H.Skeepy.Core.Infrastructure;
using System.Threading.Tasks;

namespace H.Skeepy.Core.Operations.Sync.Concrete
{
    public class HttpSyncSender : ImASkeepyOperationContract, ImASyncSender
    {
        #region Construct
        Http.ImAnHttpClient httpClient;
        ImASyncRegistry syncRegistry;
        public void ReferDependencies(ImADependencyPoolContainer dependencyPoolContainer)
        {
            syncRegistry = syncRegistry ?? dependencyPoolContainer.Resolve<ImASyncRegistry>();
            httpClient = httpClient ?? dependencyPoolContainer.Resolve<Http.ImAnHttpClient>();
        }
        #endregion

        public async Task<SyncResponse> SyncWith(ImASyncReceiver syncNode, SyncRequest request)
        {
            Http.HttpResponse<SyncResponse> response = await httpClient.PostJson<SyncResponse>(syncNode.NodeInfo.SyncRequestUri, request);

            SyncResponse syncResponse
                = response.IsSuccessful
                ? response.Payload
                : new SyncResponse
                {
                    ErrorMessage = response.Error,
                    PayloadIdentifier = request.PayloadIdentifier,
                    PayloadType = request.PayloadType,
                    SyncStatus = SyncFailureStatusFor(request.SyncStatus),
                }
                ;

            await syncRegistry.SetStatusFor(request.PayloadType, request.PayloadIdentifier, syncResponse.SyncStatus);

            return response.Payload;
        }

        private SyncStatus SyncFailureStatusFor(SyncStatus syncStatus)
        {
            switch (syncStatus)
            {
                case SyncStatus.Inexistent:
                case SyncStatus.NotSynced:
                    return SyncStatus.NotSynced;
                case SyncStatus.DeletedAndNotSynced:
                    return SyncStatus.DeletedAndNotSynced;
            }

            return SyncStatus.NotSynced;
        }
    }
}
