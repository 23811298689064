using H.Skeepy.Core.Infrastructure;
using H.Skeepy.Core.Infrastructure.Timing;
using H.Skeepy.Core.Operations.UseCases;
using System;
using System.Threading.Tasks;

namespace H.Skeepy.Core.Common.ExecutionTamers
{
    public class Debouncer :
        ImASkeepyOperationContract,
        ImAUseCaseBootstrapper<Func<Task>>,
        ImAUseCaseBootstrapper<Action>,
        ImAUseCaseBootstrapper<TimeSpan>
    {
        #region Construction
        Func<Task> asyncActionToTame;
        TimeSpan debounceInterval = TimeSpan.Zero;
        ImAPeriodicAction periodicActionExecutioner;
        Func<ImAPeriodicAction> periodicActionFactory;

        public void ReferDependencies(ImADependencyPoolContainer dependencyPoolContainer)
        {
            periodicActionExecutioner = periodicActionExecutioner ?? dependencyPoolContainer.Resolve<ImAPeriodicAction>();
            periodicActionFactory = () => dependencyPoolContainer.Resolve<ImAPeriodicAction>();
        }

        public void Use(Func<Task> asyncActionToTame)
        {
            this.asyncActionToTame = asyncActionToTame;
        }

        public void Use(Action actionToTame)
        {
            this.asyncActionToTame = actionToTame.AsAsync();
        }

        public void Use(TimeSpan debounceInterval)
        {
            this.debounceInterval = debounceInterval;
        }
        #endregion

        public async Task Invoke()
        {
            periodicActionExecutioner!=null?global::Bridge.Script.FromLambda(()=>periodicActionExecutioner.Stop()):null;
            periodicActionExecutioner = periodicActionFactory.Invoke();
            periodicActionExecutioner.StartDelayed(debounceInterval, debounceInterval, (Func<Task>)(async () =>
            {
                periodicActionExecutioner.Stop();
                await (asyncActionToTame!=null?asyncActionToTame.Invoke():(Task)null);
            }));

            await Task.FromResult<bool>(true);
        }

        public void Dispose()
        {
            periodicActionExecutioner!=null?global::Bridge.Script.FromLambda(()=>periodicActionExecutioner.Stop()):null;
            periodicActionExecutioner = null;
            asyncActionToTame = null;
        }
    }

    public class Throttler :
        ImASkeepyOperationContract,
        ImAUseCaseBootstrapper<Func<Task>>,
        ImAUseCaseBootstrapper<Action>,
        ImAUseCaseBootstrapper<TimeSpan>
    {
        #region Construction
        Func<Task> asyncActionToTame;
        TimeSpan throttleInterval = TimeSpan.Zero;
        DateTime latestExecutionAt = DateTime.MinValue;
        ImAPeriodicAction periodicActionExecutioner;
        ImAPeriodicAction periodicActionDestroyer;
        Func<ImAPeriodicAction> periodicActionFactory;

        public void ReferDependencies(ImADependencyPoolContainer dependencyPoolContainer)
        {
            periodicActionExecutioner = periodicActionExecutioner ?? dependencyPoolContainer.Resolve<ImAPeriodicAction>();
            periodicActionDestroyer = periodicActionDestroyer ?? dependencyPoolContainer.Resolve<ImAPeriodicAction>();
            periodicActionFactory = () => dependencyPoolContainer.Resolve<ImAPeriodicAction>();
        }

        public void Use(Func<Task> asyncActionToTame)
        {
            this.asyncActionToTame = asyncActionToTame;
        }

        public void Use(Action actionToTame)
        {
            this.asyncActionToTame = actionToTame.AsAsync();
        }

        public void Use(TimeSpan throttleInterval)
        {
            this.throttleInterval = throttleInterval;
        }
        #endregion

        public async Task Invoke()
        {
            periodicActionExecutioner.Start(throttleInterval, (Func<Task>)(async () =>
            {
                await (asyncActionToTame!=null?asyncActionToTame.Invoke():(Task)null);
            }));

            periodicActionDestroyer!=null?global::Bridge.Script.FromLambda(()=>periodicActionDestroyer.Stop()):null;
            periodicActionDestroyer = periodicActionFactory.Invoke();
            periodicActionDestroyer.StartDelayed(throttleInterval, throttleInterval, (Action)(() => { periodicActionExecutioner.Stop(); }));

            await Task.FromResult<bool>(true);
        }
    }
}
