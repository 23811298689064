using H.Skeepy.Core.Operations.Storage.Abstract;
using System;
using System.Threading.Tasks;

namespace H.Skeepy.Core.Operations.Storage.Concrete
{
    public class StorageSyncer<TEntity, TId> : SyncedStorage<TEntity, TId>
    {
        #region Construct
        readonly Func<TEntity, TId> idGetter;
        readonly Func<TId, string> syncIdGetter;
        readonly Func<string, TId> syncIdParser;
        public StorageSyncer(Func<TEntity, TId> idGetter, Func<string, TId> syncIdParser, Func<TId, string> syncIdGetter)
        {
            this.idGetter = idGetter;
            this.syncIdGetter = syncIdGetter;
            this.syncIdParser = syncIdParser;
        }
        #endregion

        public StorageSyncer(Func<TEntity, TId> idGetter, Func<string, TId> syncIdParser)
            : this(idGetter, syncIdParser, null) { }
protected override TId GetIdFor(TEntity entity)
{
    return idGetter(entity);
}
        protected override string GetSyncId(TId id)
        {
            return (syncIdGetter!=null?syncIdGetter.Invoke(id):(string)null) ?? base.GetSyncId(id);
        }

        public override async Task<TEntity> Load(TId id)
        {
            if (entityLoader == null)
                throw new InvalidOperationException("The Storage Syncer was not yet bootrstrapped. Must Call Use(Func<TId, Task<TEntity>> entityLoader) before using it.");

            return await entityLoader(id);
        }
protected override TId ParseSyncId(string id)
{
    return syncIdParser(id);
}    }
}
