using Bridge;
using Bridge.React;
using H.Skeepy.Click.Web.UI.Components.UI.Styles;
using H.Skeepy.Core;
using H.Skeepy.Core.Operations;
using H.Skeepy.Core.Operations.Broadcasting.Concrete;
using System.Threading.Tasks;

namespace H.Skeepy.Click.Web.UI.Components.UI.Layout
{
    public class MainLayout : ComponentBase<MainLayout.Props, MainLayout.State>
    {
        #region Construct
        public MainLayout(Props props, params Union<ReactElement, string>[] children) : base(props, children) { }
        public MainLayout(params Union<ReactElement, string>[] children) : base(null, children) { }

        private ImAVersionProviderService versionProviderService;
        protected override void EnsureDependencies()
        {
            base.EnsureDependencies();

            versionProviderService = versionProviderService ?? Get<ImAVersionProviderService>();
        }
        #endregion

        protected override async Task Initialize()
        {
            await base.Initialize();


        }

        public override ReactElement Render()
        {
            return
                DOM.Div(
                    new Attributes
                    {
                        Style = new ReactStyle
                        {
                            Width = "100%",
                            Height = "100%",
                            FlexDirection = Bridge.Html5.FlexDirection.Column,
                        }
                        .FlexNode(),
                    },

                    DOM.Div(
                        new Attributes
                        {
                            Style = new ReactStyle().FlexNode(),
                        },
                        Children
                    ),

                    RenderFooter()

                );
        }

        private ReactElement RenderFooter()
        {
            return DOM.Footer(
                new Attributes
                {
                    Style = new ReactStyle
                    {
                        Height = 40,
                        Display = Bridge.Html5.Display.Flex,
                        FlexDirection = Bridge.Html5.FlexDirection.Column,
                        JustifyContent = Bridge.Html5.JustifyContent.Center,
                        TextAlign = Bridge.Html5.TextAlign.Center,
                        Color = style.MutedTextColor.Hex,
                        FontSize = style.Typography.FontSizeSmaller.EmsCss,
                    },
                },

                DOM.Div(
                    new Attributes { },
                    DOM.Span(new Attributes { DangerouslySetInnerHTML = new RawHtml { Html = string.Format("Copyright &copy; {0}. Skeepy Software SRL. All rights reserved.",Rolex.Date.Year)} }),
                    DOM.A(new AnchorAttributes
                    {
                        Style = new ReactStyle { MarginLeft = style.SizingUnitInPixels / 3, Cursor = Bridge.Html5.Cursor.Pointer, },
                        OnClick = x => broadcastingTower.Transmit(BroadcastEventCommand.OpenAbout.ToBroadcastEvent()),
                    }, string.Format("v{0}",state.Version.Number.Semantic))
                )
            );
        }

        public class Props
        {

        }

        public class State
        {
            public Core.Model.Version Version { get; set; }

        
private Core.Model.Version __Property__Initializer__Version=Core.Model.Version.Unknown;}
    }
}
