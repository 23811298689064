using H.Skeepy.Core.Infrastructure;
using H.Skeepy.Core.Model;
using H.Skeepy.Core.Operations.Storage;
using Retyped;
using System;
using System.Linq;
using System.Threading.Tasks;

namespace H.Skeepy.Click.Web.UI.Components.LocalStorage
{
    public class SkeepyConsumerStorageService : SyncedStorageServiceBase<SkeepyConsumer, Guid>, ImASkeepyConsumerStorageService
    {
        #region Construct
        public override void ReferDependencies(ImADependencyPoolContainer dependencyPoolContainer)
        {
            base.ReferDependencies(dependencyPoolContainer);
            storageSyncer.Use((Func<Guid,Task<SkeepyConsumer>>)(async id => await Search()));
            this.Use((Func<object,SkeepyConsumer,SkeepyConsumer>)FixIdioticBridgeJsonParser);
        }
        #endregion

        public Task Delete(Guid id)
        {
            return base.Delete(storage.SkeepyConsumer, id);
        }

        public Task Save(SkeepyConsumer skeepyConsumer)
        {
            return base.Save(storage.SkeepyConsumer, skeepyConsumer);
        }

        public async Task<SkeepyConsumer> Search()
        {
            return global::Bridge.Script.ToTemp("key1",(await base.Search(null)))!=null?global::Bridge.Script.FromTemp<SkeepyConsumer[]>("key1").FirstOrDefault():(SkeepyConsumer)null;
        }

        protected override dexie.Dexie.Collection<object, object> ApplyFilter(object filter)
        {
            return storage.SkeepyConsumer.toCollection();
        }
protected override Guid GetIdFor(SkeepyConsumer item)
{
    return item.ID;
}
        private SkeepyConsumer FixIdioticBridgeJsonParser(object json, SkeepyConsumer idioticParseResult)
        {
            if (json == null) 
                return null;

            idioticParseResult.RawProperties = System.Linq.Enumerable.Select<object,Iota>(((object[])json["RawProperties"]),(Func<object,Iota>)(x => new Iota((string)x["ID"], (string)x["Jot"]))).ToArray();
            return idioticParseResult;
        }
    }
}
