using H.Skeepy.Core.Infrastructure;
using H.Skeepy.Core.Model;
using H.Skeepy.Core.Operations.Sync.Concrete;
using System.Threading.Tasks;

namespace H.Skeepy.Core.Operations.UseCases.Concrete
{
    public class StartupUseCase : ImAStartupUseCase, ImASkeepyOperationContract
    {
        #region Construct
        ImASkeepyConsumerUseCase skeepyConsumerUseCase;
        ImADaemon syncDaemon;
        public void ReferDependencies(ImADependencyPoolContainer dependencyPoolContainer)
        {
            syncDaemon = syncDaemon ?? dependencyPoolContainer.Resolve<ImADaemon>();
            skeepyConsumerUseCase = skeepyConsumerUseCase ?? dependencyPoolContainer.Resolve<ImASkeepyConsumerUseCase>();
        }
        #endregion

        public async Task Go()
        {
            SkeepyConsumer device = await skeepyConsumerUseCase.CreateOrResurrect();
            syncDaemon.Use((System.Func<ImADaemonWorker>)(() => Wireup.Get<SyncWorker>()));
            syncDaemon.StartAndKeepAlive();
        }
    }

    public class DoNothingStartupUseCase : ImAStartupUseCase
    {
        public Task Go()
        {
            return Task.FromResult<bool>(true);
        }
    }
}
