using Bridge;
using Bridge.React;
using H.Skeepy.Click.Web.UI.Components.UI.Styles;
using System;
using System.Collections.Generic;
using System.Linq;
using System.Text;
using System.Threading.Tasks;

namespace H.Skeepy.Click.Web.UI.Components.UI.Layout
{
    public class PaddedContent : ComponentBase<PaddedContent.Props, PaddedContent.State>
    {
        public PaddedContent(Props props, params Union<ReactElement, string>[] children) : base(props, children) { }
        public PaddedContent(params Union<ReactElement, string>[] children) : this(new Props { }, children) { }

        public override ReactElement Render()
        {
            return
                DOM.Div
                (
                    new Attributes
                    {
                        Style = new ReactStyle
                        {
                            PaddingLeft = style.SizingUnitInPixels,
                            PaddingRight = style.SizingUnitInPixels,
                        }.FlexNode(isVerticalFlow: props.IsVerticalFlow)
                    },

                    Children

                );
        }

        public class State { }
        public class Props 
        {
            public bool IsVerticalFlow { get; set; }

        
private bool __Property__Initializer__IsVerticalFlow=true;}
    }
}
