using Bridge;
using Bridge.React;

namespace H.Skeepy.Click.Web.UI.Components.UI.Layout
{
    public class CenteredContent : ComponentBase<CenteredContent.Props, CenteredContent.State>
    {
        public CenteredContent(Props props, params Union<ReactElement, string>[] children) : base(props, children) { }

        public CenteredContent(params Union<ReactElement, string>[] children) : this(new Props { }, children) { }

        public override ReactElement Render()
        {
            return
                DOM.Div(
                    new Attributes
                    {
                        Style = new ReactStyle
                        {
                            Flex = "1",
                            Display = Bridge.Html5.Display.Flex,
                            FlexDirection = Bridge.Html5.FlexDirection.Column,
                            JustifyContent = Bridge.Html5.JustifyContent.Center,
                            MinHeight = props.MinHeight,
                        },
                    },
                    DOM.Div(
                        new Attributes
                        {
                            Style = new ReactStyle
                            {
                                Display = Bridge.Html5.Display.Flex,
                                JustifyContent = Bridge.Html5.JustifyContent.Center,
                            },
                        },

                        Children

                    )
                );
        }

        public class State { }

        public class Props
        {
            public Union<string, int> MinHeight { get; set; }

        
private Union<string, int> __Property__Initializer__MinHeight=0;}
    }
}
