using H.Skeepy.Core.Branding;
using H.Skeepy.Core.Infrastructure;
using H.Skeepy.Core.Operations.UI.ViewState.PinGroupViewStates;

namespace H.Skeepy.Core.Operations.UI
{
    public class UiModule : ImADependencyModule
    {
        public void RegisterDependencies(ImADependencyPoolContainer dependencyPoolContainer)
        {
            dependencyPoolContainer.Register<BrandingModule>((System.Func<object>)(() => new BrandingModule()));

            dependencyPoolContainer.RegisterAlwaysNew<ImAUiBinder>((System.Func<object>)(() => new Concrete.UiBinder()));

            dependencyPoolContainer.Register<PinGroupViewStateModule>((System.Func<object>)(() => new PinGroupViewStateModule()));

            dependencyPoolContainer.RegisterAlwaysNew<ViewState.DebugViewState>((System.Func<object>)(() => new ViewState.DebugViewState()));

            dependencyPoolContainer.RegisterAlwaysNew<ViewState.WelcomeViewState>((System.Func<object>)(() => new ViewState.WelcomeViewState()));

            dependencyPoolContainer.RegisterAlwaysNew<ViewState.Health.StorageHealthViewState>((System.Func<object>)(() => new ViewState.Health.StorageHealthViewState()));

            dependencyPoolContainer.Register<Navigation.UiNavigationModule>((System.Func<object>)(() => new Navigation.UiNavigationModule()));

            dependencyPoolContainer.Register<WorldMapping.ImAWorldMapUiFactory>((System.Func<object>)(() => new WorldMapping.Concrete.WorldMapUiFactory()));
        }
    }
}
