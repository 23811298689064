using Bridge;
using Bridge.React;
using H.Skeepy.Click.Web.UI.Components.UI.Elements;
using H.Skeepy.Click.Web.UI.Components.UI.Layout;
using H.Skeepy.Click.Web.UI.Components.UI.Styles;
using H.Skeepy.Core.Operations.UI.UserInput;
using System;
using System.Linq;

namespace H.Skeepy.Click.Web.UI.Components.UI.Controls.UserFeedback
{
    public class ActionSheet : ComponentBase<ActionSheet.Props, Alert.State>
    {
        public ActionSheet(Props props) : base(props, null) { }

        public override ReactElement Render()
        {
            return
                new FormLayout
                (
                    new FormLayout.Props { LayoutMode = FormLayoutMode.OnePerRow },

                    new Elevation(new Elevation.Props { Depth = ElevationDepthLevel.Hihghest, StyleDecorator = x => x.With((Action<ReactStyle>)(s => s.MaxHeight = "70vh")).FlexNode(isVerticalFlow: true) },

                        DOM.Div(
                            new Attributes
                            {
                                Style = new ReactStyle { MarginBottom = style.SizingUnitInPixels }.FlexNode(isVerticalFlow: true),
                            },

                            props.Header != null ? props.Header : new CenteredContent(new Title(props.UserOptionsContext.Title))

                        ),

                        string.IsNullOrWhiteSpace(props.UserOptionsContext.DescriptionHtml) ? null : DOM.Div(
                            new Attributes
                            {
                                Style = new ReactStyle { }.FlexNode(isVerticalFlow: true),
                            },

                            new CenteredContent(
                                DOM.Span(new Attributes
                                {
                                    DangerouslySetInnerHTML = new RawHtml { Html = props.UserOptionsContext.DescriptionHtml },
                                })
                            )

                        ),

                        DOM.Div(
                            new Attributes
                            {
                                Style = new ReactStyle { }.FlexNode(isVerticalFlow: true).ScrollContent(),
                            },
System.Linq.Enumerable.Select<UserOption,ReactElement>(

                            props.UserOptionsContext.Options,(Func<UserOption,ReactElement>)RenderOption).ToArray()

                        ),

                        DOM.Div(
                            new Attributes
                            {
                                Style = new ReactStyle { MarginTop = style.SizingUnitInPixels }.FlexNode(isVerticalFlow: true),
                            },

                            new CenteredContent(
                                new Button(
                                    new Button.Props
                                    {
                                        OnClick = () => props.UserOptionsContext.Cancel(),
                                    },
                                    "Cancel"
                                )
                            )

                        )

                    )
                );
        }

        private ReactElement RenderOption(UserOption option)
        {
            return
                DOM.Div(
                    new Attributes
                    {
                        Style = new ReactStyle { Height = 80 },
                    },
                    new ListItem(new ListItem.Props
                    {
                        Width = "100%",
                        IconUrl = option.Icon,
                        Payload = option,
                        OnClick = x =>
                        {
                            props.UserOptionsContext.Select(x.As<UserOption>());
                            global::Bridge.Script.ToTemp("key1",option.OnClick)!=null?global::Bridge.Script.FromLambda(()=>global::Bridge.Script.FromTemp<Action<object>>("key1").Invoke(x.As<UserOption>().Payload)):null;
                        },
                    }, new CenteredContent(
                        new Subtitle(option.Label)
                    ))
                );
        }

        public class State { }

        public class Props : UserFeedbackProps
        {
            public ReactElement Header { get; set; }

        
private ReactElement __Property__Initializer__Header=null;}
    }
}
