using Bridge;
using Bridge.Html5;
using Bridge.React;
using System;

namespace H.Skeepy.Click.Web.UI.Components.UI.Controls
{
    public class Button : ComponentBase<Button.Props, Button.State>
    {
        public Button(Props props, params Union<ReactElement, string>[] children) : base(props, children) { }
        public Button(params Union<ReactElement, string>[] children) : this(new Props { }, children) { }

        public override ReactElement Render()
        {
            ReactStyle reactStyle = new ReactStyle
            {
                BorderRadius = 0,
                Border = "none",
                Margin = 0,
                Padding = "10px 30px",
                BackgroundColor = GetBackgroundColor(),
                Color = GetTextColor(),
                Cursor = props.IsDisabled ? Cursor.NotAllowed : Cursor.Pointer,
                Outline = "none",
                FontFamily = style.Typography.FontFamily,
                FontSize = style.Typography.FontSize.PointsCss,
                Opacity = props.IsDisabled ? "0.25" : null,
                Width = props.Width,
                Height = props.Height,
            };

            if (props.StyleDecorator != null)
                reactStyle = props.StyleDecorator(reactStyle);

            ButtonAttributes attributes
                = new ButtonAttributes
                {

                    Style = reactStyle,

                    Disabled = props.IsDisabled,

                    OnMouseOver = x => { EnableOnHoverStyle(x.CurrentTarget); },
                    OnMouseOut = x => { DisableOnHoverStyle(x.CurrentTarget); },
                    OnMouseDown = x => { EnableOnPressedStyle(x.CurrentTarget); },
                    OnTouchStart = x => { EnableOnPressedStyle(x.CurrentTarget); },
                    OnMouseUp = x => { DisableOnPressedStyle(x.CurrentTarget); },
                    OnTouchEnd = x => { DisableOnPressedStyle(x.CurrentTarget); },

                    OnClick = x => global::Bridge.Script.ToTemp("key1",props.OnClick)!=null?global::Bridge.Script.FromLambda(()=>global::Bridge.Script.FromTemp<Action>("key1").Invoke()):null,

                };

            if (props.Decorator != null) attributes = props.Decorator.Invoke(attributes);

            return
                DOM.Button(attributes, Children);
        }

        private void EnableOnPressedStyle(HTMLButtonElement buttonElement)
        {
            if (props.IsDisabled) return;

            buttonElement.Style.BackgroundColor = GetBackgroundPressedColor();
        }

        private void DisableOnPressedStyle(HTMLButtonElement buttonElement)
        {
            EnableOnHoverStyle(buttonElement);
            //buttonElement.Style.BackgroundColor = GetBackgroundColor();
        }

        private void EnableOnHoverStyle(HTMLButtonElement buttonElement)
        {
            if (props.IsDisabled) return;

            buttonElement.Style.BackgroundColor = GetBackgroundHoverColor();
        }

        private void DisableOnHoverStyle(HTMLButtonElement buttonElement)
        {
            buttonElement.Style.BackgroundColor = GetBackgroundColor();
        }

        private string GetTextColor()
        {
            switch (props.Role)
            {
                case ButtonRole.Negative:
                case ButtonRole.Default:
                default: return style.BackgroundColor.Hex;
            }
        }

        private string GetBackgroundColor()
        {
            switch (props.Role)
            {
                case ButtonRole.Negative: return style.Colors.PrimaryIsh().Lighter().Hex;
                case ButtonRole.Default:
                default: return style.Colors.Primary.Color.Hex;
            }
        }

        private string GetBackgroundHoverColor()
        {
            switch (props.Role)
            {
                case ButtonRole.Negative: return style.Colors.PrimaryIsh().Lighter(2).Hex;
                case ButtonRole.Default:
                default: return style.Colors.Primary.Lighter().Hex;
            }
        }

        private string GetBackgroundPressedColor()
        {
            switch (props.Role)
            {
                case ButtonRole.Negative: return style.Colors.PrimaryIsh().Color.Hex;
                case ButtonRole.Default:
                default: return style.Colors.Primary.Darker().Hex;
            }
        }

        public class State { }
        public class Props
        {
            public Action OnClick { get; set; }
            public ButtonRole Role { get; set; }
            public bool IsDisabled { get; set; }
            public Union<string, int> Width { get; set; }
            public Union<string, int> Height { get; set; }
            public Func<ButtonAttributes, ButtonAttributes> Decorator { get; set; }
            public Func<ReactStyle, ReactStyle> StyleDecorator { get; set; }

        
private ButtonRole __Property__Initializer__Role=ButtonRole.Default;private bool __Property__Initializer__IsDisabled=false;private Union<string, int> __Property__Initializer__Width=200;private Union<string, int> __Property__Initializer__Height=null;}
    }

    public enum ButtonRole
    {
        Default = 0,
        Negative = 10,
    }
}
