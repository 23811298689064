using H.Skeepy.Core.Operations.UI.Navigation;
using System;
using System.Collections.Generic;
using System.Linq;
using System.Text;
using System.Threading.Tasks;

namespace H.Skeepy.Click.Web.UI.Pages
{
    public abstract class PagePropsBase
    {
        public UiNavigationParams NavigationParams { get; set; }

    
private UiNavigationParams __Property__Initializer__NavigationParams=UiNavigationParams.None;}
}
