using H.Skeepy.Core.Model;
using System;
using System.Collections.Generic;
using System.Linq;
using System.Threading.Tasks;

namespace H.Skeepy.Core.Common
{
    public static class DataExtensions
    {
        public static T Duplicate<T>(this T data)
        {
            return Newtonsoft.Json.JsonConvert.DeserializeObject<T>(Newtonsoft.Json.JsonConvert.SerializeObject(data));
        }

        public static bool In<T>(this T item, params T[] collection)
        {
            return H.Skeepy.Core.Common.DataExtensions.In<T>(item,System.Linq.Enumerable.AsEnumerable<T>(collection));
        }
public static bool NotIn<T>(this T item, params T[] collection)
{
    return !In<T>(item, collection);
}


        public static bool In<T>(this T item, IEnumerable<T> collection)
        {
            return H.Skeepy.Core.Common.DataExtensions.In<T>(item,collection, (Predicate<T>)(x => (x!=null?x.Equals(item):(bool?)null) ?? item == null));
        }
public static bool NotIn<T>(this T item, IEnumerable<T> collection)
{
    return !In<T>(item, collection);
}


        public static bool In<T>(this T item, IEnumerable<T> collection, Predicate<T> comparer)
        {
            return System.Linq.Enumerable.Any<T>(collection,(Func<T,bool>)(x => comparer.Invoke(x)));
        }
public static bool NotIn<T>(this T item, IEnumerable<T> collection, Predicate<T> comparer)
{
    return !In<T>(item, collection, (Predicate<T>)comparer);
}
        public static IEnumerable<IEnumerable<T>> Batch<T>(this IEnumerable<T> collection, int batchSize)
        {
            if (collection == null)
                return null;

            if (!System.Linq.Enumerable.Any<T>(collection))
                return Enumerable.Empty<IEnumerable<T>>();

            if (batchSize < 1)
                throw new InvalidOperationException("The batch size must be higher than zero");

            if (batchSize >= System.Linq.Enumerable.Count<T>(collection))
                return new IEnumerable<T>[] { collection };

            List<IEnumerable<T>> result = new List<IEnumerable<T>>();

            List<T> batch = new List<T>();
            foreach (T item in collection)
            {
                if (batch.Count == batchSize)
                {
                    result.Add(batch.ToArray());
                    batch.Clear();
                }

                batch.Add(item);
            }

            if (System.Linq.Enumerable.Any<T>(batch))
                result.Add(batch.ToArray());

            return result.ToArray();
        }

        public static bool IsSameOrSubclassOf(this Type typeToCheck, Type typeToCompareWith)
        {
            return
                typeToCheck == typeToCompareWith
                || typeToCompareWith.IsSubclassOf(typeToCheck)
                ;
        }

        public static T[] Jump<T>(this T[] array, int numberOfElementsToJump)
        {
            if (array == null)
                return null;

            if (numberOfElementsToJump < 1)
                return array;

            if (numberOfElementsToJump >= array.Length)
                return new T[0];

            T[] result = new T[array.Length - numberOfElementsToJump];

            for (var i = numberOfElementsToJump; i < array.Length; i++)
            {
                result[i - numberOfElementsToJump] = array[i];
            }

            return result;
        }

        public static T Set<T>(this T value, Action<T> setter)
        {
            setter!=null?global::Bridge.Script.FromLambda(()=>setter.Invoke(value)):null;
            return value;
        }
public static T[] AsArray<T>(this T value)
{
    return new T[]{value};
}
        public static float TrimToPercent(this float value)
        {
            return
                value < 0 ? 0
                : value > 100 ? 100
                : value
                ;
        }
public static float TrimToPercent(this int value)
{
    return TrimToPercent((float)value);
}public static float TrimToPercent(this decimal value)
{
    return TrimToPercent((float)value);
}public static float TrimToPercent(this double value)
{
    return TrimToPercent((float)value);
}
        public static string Simplify(this string value)
        {
            if (string.IsNullOrWhiteSpace(value)) return value!=null?value.Trim():(string)null;

            return
                value
                .Trim()
                .Replace(" ", "_")
                .Replace(".", "_")
                ;
        }

        public static Func<Task> AsAsync(this Action action)
        {
            return new Func<Task>(() => { action!=null?global::Bridge.Script.FromLambda(()=>action.Invoke()):null; return Task.FromResult<bool>(true); });
        }
public static Task<T> AsFulfilledTask<T>(this T result)
{
    return Task.FromResult<T>(result);
}
        public static Guid? ParseToGuidOrFallbackTo(this string rawValue, Guid? fallbackValue = null)
        {
            if (string.IsNullOrWhiteSpace(rawValue))
                return fallbackValue;

            Guid parseResult;
            if (Guid.TryParse(rawValue, out parseResult))
                return parseResult;

            return fallbackValue;
        }

        public static int? ParseToIntOrFallbackTo(this string rawValue, int? fallbackValue = null)
        {
            if (string.IsNullOrWhiteSpace(rawValue))
                return fallbackValue;

            int parseResult;
            if (int.TryParse(rawValue, out parseResult))
                return parseResult;

            return fallbackValue;
        }

        public static bool IsBetweenInclusive(this DateTime dateTime, DateTime? from, DateTime? to)
        {
            return
                (dateTime >= (from ?? DateTime.MinValue))
                &&
                (dateTime <= (to ?? DateTime.MaxValue));
        }

        public static Iota Property(this Iota[] iotas, string id)
        {
            return
                (iotas!=null?iotas.LastOrDefault((Func<Iota,bool>)(x => x.ID == id)):(Iota?)null)
                ??
                new Iota { ID = id };
        }

        public static Iota[] Properties(this Iota[] iotas, string id)
        {
            return
                (iotas!=null?iotas.Where((Func<Iota,bool>)(x => x.ID == id))
                .ToArray():(Iota[])null)
                ??
                new Iota[0]
                ;
        }

        public static string Jot(this Iota[] iotas, string id)
        {
            return iotas.Property(id).Jot;
        }
public static Iota Jot(this string id, object value = null)
{
    return new Iota(id, value);
}
        public static string[] Jots(this Iota[] iotas, string id)
        {
            return
System.Linq.Enumerable.Select<Iota,string>(                iotas
                .Properties(id)
,(Func<Iota,string>)(x => x.Jot))
                .ToArray()
                ;
        }

        public static Iota[] Set(this Iota[] iotas, Iota iota)
        {
            if (iotas == null)
                iotas = new Iota[0];

            bool isExistent = false;

            for (int i = 0; i < iotas.Length; i++)
            {
                if (iotas[i].ID != iota.ID)
                    continue;

                isExistent = true;

                iotas[i] = iota;
            }

            if (!isExistent)
            {
                iotas = System.Linq.Enumerable.Concat<Iota>(iotas,H.Skeepy.Core.Common.DataExtensions.AsArray<Iota>(iota)).ToArray();
            }

            return iotas;
        }

        public static Iota[] OverwriteAndAppendFrom(this Iota[] me, Iota[] overwrites)
        {
            me = me ?? new Iota[0];

            foreach (Iota overwrite in overwrites ?? new Iota[0])
            {
                me.Set(overwrite);
            }

            return me;
        }
    }
}
