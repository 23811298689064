using System;
using System.Collections.Generic;
using System.Linq;

namespace H.Skeepy.Core.Common
{
    public static class ExceptionExtensions
    {
        public static Exception[] Flatten(this Exception ex)
        {
            if (ex == null)
                return null;

            List<Exception> result = new List<Exception>();

            if (ex is AggregateException)
            {
                result.Add(ex);
                result.AddRange(System.Linq.Enumerable.SelectMany<Exception,Exception>((ex as AggregateException).InnerExceptions,(Func<Exception,IEnumerable<Exception>>)(x => x.Flatten())));
            }
            else if (ex.InnerException != null)
            {
                result.Add(ex);
                result.AddRange(ex.InnerException.Flatten());
            }
            else
            {
                result.Add(ex);
            }

            return System.Linq.Enumerable.Where<Exception>(result,(Func<Exception,bool>)(x => x != null)).ToArray();
        }
    }
}
