using System;
using System.Linq;

namespace H.Skeepy.Core.Branding
{
    public class ColorInfo
    {
        private ColorInfo() { }
        public ColorInfo(string hex)
        {
            if (string.IsNullOrWhiteSpace(hex))
                throw new ArgumentNullException("hex", "Must provide a color");

            hex = hex!=null?hex.Replace("#", string.Empty).ToUpper():(string)null;

            if (hex.Length != 6)
                throw new InvalidOperationException("Color hex must be 6 chars long");

            Hex = string.Format("#{0}",hex);

            Red = Convert.ToByte(hex.Substring(0, 2), 16);
            Green = Convert.ToByte(hex.Substring(2, 2), 16);
            Blue = Convert.ToByte(hex.Substring(4, 2), 16);
        }

        public ColorInfo(byte red, byte green, byte blue)
        {
            Red = red;
            Green = green;
            Blue = blue;
            Hex = BitConverter.ToString(new byte[] { red, green, blue }).Replace("-", string.Empty);
        }

        public byte Red { get; private set; }
        public byte Green { get; private set; }
        public byte Blue { get; private set; }
        public string Hex { get; private set; }
public string ToCssRGBA(float alpha = 1f)
{
    return string.Format("rgba({0},{1},{2},{3})",Red,Green,Blue,alpha);
}
    
private byte __Property__Initializer__Red=0;private byte __Property__Initializer__Green=0;private byte __Property__Initializer__Blue=0;private string __Property__Initializer__Hex="#000000";}

    public class ColorVariation
    {
        private readonly ColorInfo[] lighterShades;
        private readonly ColorInfo[] darkerShades;

        private ColorVariation() { }
        public ColorVariation(ColorInfo main, ColorInfo[] lighterShades = null, ColorInfo[] darkerShades = null)
        {
            if (main == null)
                throw new ArgumentNullException("main", "At least a main color must be given");

            Color = main;

            this.lighterShades = lighterShades ?? new ColorInfo[0];
            this.darkerShades = darkerShades ?? new ColorInfo[0];
        }

        public ColorInfo Color { get; private set; }
public ColorInfo Lighter(int level = 1)
{
    return GetShade(level, lighterShades);
}public ColorInfo Darker(int level = 1)
{
    return GetShade(level, darkerShades);
}
        private ColorInfo GetShade(int level, ColorInfo[] shades)
        {
            if (level < 1)
                return Color;

            if (!(shades!=null?shades.Any():(bool?)null) ?? true)
                return Color;

            if (level >= shades.Length)
                return System.Linq.Enumerable.Last<ColorInfo>(shades);

            return shades[level - 1];
        }
    }

    public class ColorPalette
    {
        public static readonly ColorPalette Default =
            new ColorPalette(
                new ColorVariation(new ColorInfo("#FF7100"), new ColorInfo[] { new ColorInfo("#FF9139"), new ColorInfo("#FFA863") }, new ColorInfo[] { new ColorInfo("#C55700"), new ColorInfo("#9B4500") })
                , new ColorVariation(new ColorInfo("#FF8C00"), new ColorInfo[] { new ColorInfo("#FFA639"), new ColorInfo("#FFB963"), new ColorInfo("#FFDDB2") }, new ColorInfo[] { new ColorInfo("#C56C00"), new ColorInfo("#9B5500") })
                , new ColorVariation(new ColorInfo("#0969A2"), new ColorInfo[] { new ColorInfo("#2D7AA8"), new ColorInfo("#4F92BA") }, new ColorInfo[] { new ColorInfo("#07517D"), new ColorInfo("#043F63") })
                , new ColorVariation(new ColorInfo("#009B95"), new ColorInfo[] { new ColorInfo("#24A19C"), new ColorInfo("#46B4B0"), new ColorInfo("#FBFFFF") }, new ColorInfo[] { new ColorInfo("#007873"), new ColorInfo("#005E5A"), new ColorInfo("#000B0A") })
            );

        private readonly ColorVariation[] colorVariations;

        private ColorPalette() { }
        public ColorPalette(params ColorVariation[] colorVariations)
        {
            if (!(colorVariations!=null?colorVariations.Any():(bool?)null) ?? true)
                throw new ArgumentException("Must provide at least one color variation", "colorVariations");

            this.colorVariations = colorVariations;
        }
public ColorVariation Primary
{
    get
    {
        return System.Linq.Enumerable.First<ColorVariation>(colorVariations);
    }
}public ColorVariation Complementary
{
    get
    {
        return System.Linq.Enumerable.Last<ColorVariation>(colorVariations);
    }
}
        public ColorVariation PrimaryIsh(int level = 1)
        {
            if (level < 1)
                return Primary;

            if (level >= colorVariations.Length - 1)
                return System.Linq.Enumerable.Last<ColorVariation>(colorVariations);

            return colorVariations[level];
        }

        public ColorVariation ComplementaryIsh(int level = 1)
        {
            if (level < 1)
                return Complementary;

            if (level >= colorVariations.Length - 1)
                return System.Linq.Enumerable.First<ColorVariation>(colorVariations);

            return colorVariations[colorVariations.Length - 1 - level];
        }
    }
}
