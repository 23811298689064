using H.Skeepy.Core.Branding;
using System;

namespace H.Skeepy.Core.Common
{
    public static class UIPrinters
    {
        public static string PrintDateTime(DateTime dateTime)
        {
            TimeSpan life = Rolex.Time - dateTime;

            if (life < TimeSpan.FromMinutes(1))
                return "just now";
            if (life < TimeSpan.FromMinutes(5))
                return string.Format("a few minutes ago at {0}",dateTime.ToString(BrandingStyle.TimeFormat));
            if (life < TimeSpan.FromMinutes(59))
                return string.Format("{0} minutes ago at {1}",(int)life.TotalMinutes,dateTime.ToString(BrandingStyle.TimeFormat));
            if (life < TimeSpan.FromHours(2))
                return string.Format("about an hour ago at {0}",dateTime.ToString(BrandingStyle.TimeFormat));
            if (life < TimeSpan.FromHours(24))
                return string.Format("{0} hours ago at {1}",(int)life.TotalHours,dateTime.ToString(BrandingStyle.TimeFormat));
            if (life < TimeSpan.FromDays(2))
                return string.Format("{0}, {1} day ago at {2}",dateTime.ToString(BrandingStyle.DayOfWeekFormat),(int)life.TotalDays,dateTime.ToString(BrandingStyle.TimeFormat));
            if (life < TimeSpan.FromDays(7))
                return string.Format("{0}, {1} days ago at {2}",dateTime.ToString(BrandingStyle.DayOfWeekFormat),(int)life.TotalDays,dateTime.ToString(BrandingStyle.TimeFormat));
            if (dateTime.Year == Rolex.Date.Year)
                return dateTime.ToString(BrandingStyle.TimeStampFormatThisYear);

            return dateTime.ToString(BrandingStyle.TimeStampFormat);
        }

        public static string PrintTimeSpan(TimeSpan timeSpan)
        {
            if (timeSpan.TotalSeconds < 5)
                return "a heartbeat";
            if (timeSpan.TotalMinutes < 1)
                return string.Format("{0} seconds",timeSpan.Seconds);
            if (timeSpan.TotalMinutes < 2)
                return string.Format("{0} minute & {1}s",timeSpan.Minutes,timeSpan.Seconds);
            if (timeSpan.TotalHours < 1)
                return string.Format("{0} minutes & {1}s",timeSpan.Minutes,timeSpan.Seconds);
            if (timeSpan.TotalHours < 2)
                return string.Format("{0} hour & {1}m",timeSpan.Hours,timeSpan.Minutes);
            if (timeSpan.TotalDays < 1)
                return string.Format("{0} hours & {1}m",timeSpan.Hours,timeSpan.Minutes);
            if (timeSpan.TotalDays < 2)
                return string.Format("{0} day & {1}h{2}m",timeSpan.Days,timeSpan.Hours,timeSpan.Minutes);

            return string.Format("{0} days & {1}h{2}m",timeSpan.Days,timeSpan.Hours,timeSpan.Minutes);
        }

        public static string PrintException(Exception ex)
        {
            return ex.ToString();
        }

        public static string PrintPercent(float percentValue)
        {
            return string.Format("{0}%",Math.Round(percentValue.TrimToPercent(), 1));
        }

        public static string PrintSize(long sizeInBytes)
        {
            float sizeInKiloBytes = sizeInBytes / 1024f;
            if (sizeInKiloBytes < 1024 / 2)
                return string.Format("{0} KiB",Math.Round(sizeInKiloBytes, 2));

            float sizeInMegaBytes = sizeInKiloBytes / 1024f;
            if (sizeInMegaBytes < 1024 / 2)
                return string.Format("{0} MiB",Math.Round(sizeInMegaBytes, 2));

            float sizeInGigaBytes = sizeInMegaBytes / 1024f;
            if (sizeInGigaBytes < 1024 / 2)
                return string.Format("{0} GiB",Math.Round(sizeInGigaBytes, 2));

            float sizeInTeraBytes = sizeInGigaBytes / 1024f;

            return string.Format("{0} TiB",Math.Round(sizeInTeraBytes, 2));
        }
    }

    public static class UIPrintersExtensions
    {
public static string Print(this DateTime dateTime)
{
    return UIPrinters.PrintDateTime(dateTime);
}public static string Print(this TimeSpan timeSpan)
{
    return UIPrinters.PrintTimeSpan(timeSpan);
}public static string Print(this Exception exception)
{
    return UIPrinters.PrintException(exception);
}public static string PrintPercent(this float percentValue)
{
    return UIPrinters.PrintPercent(percentValue);
}public static string PrintSize(this long percentValue)
{
    return UIPrinters.PrintSize(percentValue);
}    }

}
