using H.Skeepy.Core.Branding;
using H.Skeepy.Core.Common;
using H.Skeepy.Core.Infrastructure;
using H.Skeepy.Core.Model;
using H.Skeepy.Core.Model.Display.Health;
using H.Skeepy.Core.Operations.Storage;
using System;
using System.Collections.Generic;
using System.Linq;
using System.Threading.Tasks;

namespace H.Skeepy.Core.Operations.UseCases.Concrete
{
    public class StorageHealthUseCase : ImAStorageHealthUseCase, ImASkeepyOperationContract
    {
        public event EventHandler<OperationProgressEventArgs> OnExportStorageProgress;
        public event EventHandler<OperationProgressEventArgs> OnBundleExportProgress;

        #region Construct
        const int exportBatchSize = 50;
        Dictionary<StorageInfo, ImAStorageExplorer> storageDictionary;
        ImAStorageExplorer[] storageExplorers;
        ImADataZipper dataZipper;
        ImAContentDeliveryService contentDeliveryService;

        public void ReferDependencies(ImADependencyPoolContainer dependencyPoolContainer)
        {
            storageExplorers = storageExplorers ?? dependencyPoolContainer.Resolve<ImAStorageExplorer[]>() ?? new ImAStorageExplorer[0];
            if (!System.Linq.Enumerable.Any<ImAStorageExplorer>(storageExplorers))
            {
                ImAStorageExplorer theOneStorage = dependencyPoolContainer.Resolve<ImAStorageExplorer>();
                if (theOneStorage != null)
                {
                    storageExplorers = H.Skeepy.Core.Common.DataExtensions.AsArray<ImAStorageExplorer>(theOneStorage);
                }
            }

            dataZipper = dataZipper ?? dependencyPoolContainer.Resolve<ImADataZipper>();
            contentDeliveryService = contentDeliveryService ?? dependencyPoolContainer.Resolve<ImAContentDeliveryService>();
        }
        #endregion

        public async Task<StorageStatistics[]> FetchAllStorageStatistics()
        {
            await EnsureStorageDictionary();

            return
System.Linq.Enumerable.Select<StorageInfo,StorageStatistics>(                storageDictionary
                .Keys
,(Func<StorageInfo,StorageStatistics>)Map)
                .ToArray()
                ;
        }

        public async Task<StorageExport> ExportStorage(StorageIdentifier storage, params string[] collections)
        {
            await EnsureStorageDictionary();

            KeyValuePair<StorageInfo, ImAStorageExplorer> storageEntry = System.Linq.Enumerable.Single<KeyValuePair<StorageInfo,ImAStorageExplorer>>(storageDictionary,(Func<KeyValuePair<StorageInfo,ImAStorageExplorer>,bool>)(x => x.Key.Equals(storage)));
            StorageInfo storageInfo = storageEntry.Key;
            ImAStorageExplorer storageExplorer = storageEntry.Value;
            StorageCollectionInfo[] collectionsToExport = System.Linq.Enumerable.Where<StorageCollectionInfo>(storageInfo.Collections,(Func<StorageCollectionInfo,bool>)(x => H.Skeepy.Core.Common.DataExtensions.In<string>(x.Name,collections))).ToArray();

            Dictionary<string, float> progressPerCollection = System.Linq.Enumerable.ToDictionary<StorageCollectionInfo,string,float>(collectionsToExport,(Func<StorageCollectionInfo,string>)(X => X.Name), (Func<StorageCollectionInfo,float>)(X => 0f));

            StorageCollectionExport[] collectionExports
                = await Task.WhenAll<StorageCollectionExport>(System.Linq.Enumerable.Select<StorageCollectionInfo,Task<StorageCollectionExport>>(collectionsToExport,(Func<StorageCollectionInfo,Task<StorageCollectionExport>>)(x => ExportStorageCollection(x, storageExplorer, (Action<string,float>)((collectionName, percentCompleted) =>
                {
                    progressPerCollection[collectionName] = percentCompleted.TrimToPercent();

                    ReportExportStorageProgress(progressPerCollection.Values.Average());

                })))));

            StorageExport storageExport = new StorageExport(storage);

            storageExport.Append(collectionExports);

            ReportExportStorageProgress(100);

            return storageExport;
        }

        public async Task<DataBucket> BundleExport(StorageExport export)
        {
            ReportBundleExportProgress(0);

            dataZipper.NewPackage(string.Format("{0}_AsOf_{1}",export.StorageIdentifier.ToString().Simplify(),export.AsOf.ToString(BrandingStyle.TimeStampIdentifierFormat)));


            foreach (StorageCollectionExport collection in export.Collections)
            {
                foreach (StorageCollectionExportRecord record in collection.Records)
                {
                    string filePath = string.Format("{0}/{1}.json",collection.CollectionName,record.Key);
                    dataZipper.UpsertFile(filePath, record.Payload);
                }
            }

            DataBucket artifact = await dataZipper.WrapPackage((Action<ZipProgressInfo>)(x => ReportBundleExportProgress(x.GlobalProgressPercent)));

            return artifact;
        }

        public Task<OperationResult> DeliverBundle(DataBucket exportBundle)
        {
            return contentDeliveryService.Deliver(exportBundle);
        }

        private async Task EnsureStorageDictionary()
        {
            storageDictionary = storageDictionary
            ??
System.Linq.Enumerable.ToDictionary<KeyValuePair<StorageInfo,ImAStorageExplorer>,StorageInfo,ImAStorageExplorer>(            (
                await Task.WhenAll
<KeyValuePair<StorageInfo,ImAStorageExplorer>>
                (
System.Linq.Enumerable.Select<ImAStorageExplorer,Task<KeyValuePair<StorageInfo,ImAStorageExplorer>>>(                    storageExplorers,(Func<ImAStorageExplorer,Task<KeyValuePair<StorageInfo,ImAStorageExplorer>>>)(async x =>
                    {
                        StorageInfo storageInfo = await x.GetStorageInformation();
                        return new KeyValuePair<StorageInfo, ImAStorageExplorer>(storageInfo, x);
                    }))
                )
            )
,(Func<KeyValuePair<StorageInfo,ImAStorageExplorer>,StorageInfo>)(x => x.Key), (Func<KeyValuePair<StorageInfo,ImAStorageExplorer>,ImAStorageExplorer>)(X => X.Value));
        }

        private StorageStatistics Map(StorageInfo storageInfo)
        {
            return
                new StorageStatistics(storageInfo.Name, storageInfo.Type, storageInfo.TypeNotes, System.Linq.Enumerable.Select<StorageCollectionInfo,StorageCollectionStatistics>(storageInfo.Collections,(Func<StorageCollectionInfo,StorageCollectionStatistics>)Map).ToArray());
        }

        private StorageCollectionStatistics Map(StorageCollectionInfo storageCollectionInfo)
        {
            return
                new StorageCollectionStatistics(storageCollectionInfo.Name, storageCollectionInfo.RecordsCount);
        }

        private async Task<StorageCollectionExport> ExportStorageCollection(StorageCollectionInfo collection, ImAStorageExplorer storageExplorer, Action<string, float> onProgress)
        {
            int numberOfFullBatches = (int)(collection.RecordsCount / exportBatchSize);
            int numberOfPartialBatches = collection.RecordsCount % exportBatchSize == 0 ? 0 : 1;
            int numberOfBatches = numberOfFullBatches + numberOfPartialBatches;

            int[] batchIndexes = Enumerable.Range(0, numberOfBatches).ToArray();

            StorageCollectionExport collectionExport = new StorageCollectionExport(collection.Name);

            foreach (int batchIndex in batchIndexes)
            {
                StorageRecord[] exportBatch = await storageExplorer.BrowseBatch(collection.Name, batchIndex, exportBatchSize);

                collectionExport.Append(System.Linq.Enumerable.Select<StorageRecord,StorageCollectionExportRecord>(exportBatch,(Func<StorageRecord,StorageCollectionExportRecord>)Map).ToArray());

                float progressPercent = ((float)batchIndex / (float)numberOfBatches) * 100f;

                onProgress!=null?global::Bridge.Script.FromLambda(()=>onProgress.Invoke(collection.Name, progressPercent)):null;
            }

            return collectionExport;
        }

        private StorageCollectionExportRecord Map(StorageRecord storageRecord)
        {
            return
                new StorageCollectionExportRecord(
                    storageRecord.Key,
                    storageRecord.RawRecord
                );
        }

        private void ReportExportStorageProgress(float percent)
        {
            OnExportStorageProgress!=null?global::Bridge.Script.FromLambda(()=>OnExportStorageProgress.Invoke(this, new OperationProgressEventArgs(percent))):null;
        }

        private void ReportBundleExportProgress(float percent)
        {
            OnBundleExportProgress!=null?global::Bridge.Script.FromLambda(()=>OnBundleExportProgress.Invoke(this, new OperationProgressEventArgs(percent))):null;
        }
    }
}
