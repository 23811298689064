using System;
using System.Collections.Generic;
using System.Text;
using System.Threading.Tasks;

namespace H.Skeepy.Core.Operations.Http
{
    public interface ImAnHttpClient
    {
        Task<HttpResponse> Get(string url);
        Task<HttpResponse<T>> GetJson<T>(string url);

        Task<HttpResponse> Post(string url, object payload = null);
        Task<HttpResponse<T>> PostJson<T>(string url, object payload = null);

        Task<HttpResponse> Put(string url, object payload = null);

        Task<HttpResponse> Delete(string url, object payload = null);
        Task<HttpResponse<T>> DeleteJson<T>(string url, object payload = null);

        //Task<HttpResponse> PostMultipart(string url, object formData);
    }

    public class HttpResponse
    {
        public HttpResponse(int statusCode, string error = null)
        {
            StatusCode = statusCode;
            Error = error;
        }

        public bool IsSuccessful
        {
            get
            {
                return StatusCode >= 200  && StatusCode < 300 && string.IsNullOrEmpty(Error);
            }
        }

        public int StatusCode { get; set; }

        public string Error { get; set; }

        public bool IsUnauthorized { get; set; }

        public bool SessionExpired { get; set; }

        public string Content { get; set; }

        public HttpResponse<T> TypeTo<T>()
        {
            return new HttpResponse<T>(
                Newtonsoft.Json.JsonConvert.DeserializeObject<T>(Content),
                StatusCode,
                Error
                )
            {
                IsUnauthorized = IsUnauthorized,
                SessionExpired = SessionExpired,
                Content = Content,
            };
        }
    }

    public class HttpResponse<TPayload> : HttpResponse
    {
        public HttpResponse(TPayload resultJson, int statusCode, string error = null) 
            : base(statusCode, error)
        {
            Payload = resultJson;
        }

        public TPayload Payload { get; set; }
    }
}
