using Bridge;
using Bridge.Html5;
using Bridge.jQuery2;
using Bridge.React;
using H.Skeepy.Click.Web.UI.Components.Navigation;
using H.Skeepy.Click.Web.UI.Pages;
using H.Skeepy.Click.Web.UI.Pages.Health;
using H.Skeepy.Click.Web.UI.Pages.PinGroupPages;
using H.Skeepy.Core;
using H.Skeepy.Core.Branding;
using H.Skeepy.Core.Infrastructure;
using H.Skeepy.Core.Infrastructure.Concrete;
using H.Skeepy.Core.Operations.UseCases;
using H.Skeepy.Core.Operations.UseCases.Concrete;
using System;
using System.Threading.Tasks;

namespace H.Skeepy.Click.Web.UI
{
    public class App
    {
        public static readonly ImADependencyPoolContainer DependencyPoolContainer = new DependencyPoolContainer();
public static T Get<T>()
{
    return DependencyPoolContainer.Resolve<T>();
}public static void Main()
{
    MainAsync();
}
        private static async void MainAsync()
        {
            if (IsWebWorker())
                return;

            DisableMobileResize();

            using (new AppLoadIndicator())
            {
                await ReferenceLibs();
            }

            await RegisterDependencies();

            SetGlobalStyling();

            WireupNavigation();
        }

        public static async void MainAsWebWorker()
        {
            await RegisterDependencies(true);
        }

        private static bool IsWebWorker()
        {
            return Window.Document["isWebWorkerContext"] != null;
        }

        private static async Task RegisterDependencies(bool isWebWorkerContext = false)
        {
            await
                new Wireup(DependencyPoolContainer)
                .ForWebUI()
                .With((Action<ImADependencyPoolContainer>)(x =>
                {
                    if (isWebWorkerContext)
                    {
                        x.Register<ImAStartupUseCase>((Func<object>)(() => new WebWorkerStartupUseCase()));
                    }
                }))
                .Boot()
                ;
        }

        private static void WireupNavigation()
        {
            new NavigationBootstrapper(new AppDispatcher())
                .Wireup(
                    CreateAppContainer(),
(Func<ProductiveRage.ReactRouting.Helpers.NavigateActionMatcher,ProductiveRage.ReactRouting.Helpers.NavigateActionMatcher>)(                    router => router
                    .AddFor<AppPageDispatcherAction<WelcomePage>>(new WelcomePage())
                    .AddFor<AppPageDispatcherAction<DebugPage>>(new DebugPage())
                    .AddFor<AppPageDispatcherAction<DefineNewPinGroupPage>>(new DefineNewPinGroupPage())
                    .AddFor<AppPageDispatcherAction<PinGroupOperationsPage>>((Func<AppPageDispatcherAction<PinGroupOperationsPage>,ReactElement>)(x => new PinGroupOperationsPage(new PinGroupOperationsPage.Props { NavigationParams = x.NavigationParams })))
                    .AddFor<AppPageDispatcherAction<PinGroupDetailedDisplayPage>>((Func<AppPageDispatcherAction<PinGroupDetailedDisplayPage>,ReactElement>)(x => new PinGroupDetailedDisplayPage(new PinGroupDetailedDisplayPage.Props { NavigationParams = x.NavigationParams })))
                    .AddFor<AppPageDispatcherAction<StorageHealthPage>>((Func<AppPageDispatcherAction<StorageHealthPage>,ReactElement>)(x => new StorageHealthPage()))
)                );

            Core.Operations.UI.Navigation.ImAUiNavigator navi
                = DependencyPoolContainer.Resolve<Core.Operations.UI.Navigation.ImAUiNavigator>();

            navi.Bind<Core.Operations.UI.ViewState.DebugViewState>((Action<Core.Operations.UI.Navigation.UiNavigationParams>)(x => Navi.Go("debug")));
            navi.Bind<Core.Operations.UI.ViewState.WelcomeViewState>((Action<Core.Operations.UI.Navigation.UiNavigationParams>)(x => Navi.Go(string.Empty)));
            navi.Bind<Core.Operations.UI.ViewState.PinGroupViewStates.DefineNewPinGroupViewState>((Action<Core.Operations.UI.Navigation.UiNavigationParams>)(x => Navi.Go("new")));
            navi.Bind<Core.Operations.UI.ViewState.PinGroupViewStates.PinGroupOperationsViewState>((Action<Core.Operations.UI.Navigation.UiNavigationParams>)(x => Navi.Go("run", x.GetValue<Guid>())));
            navi.Bind<Core.Operations.UI.ViewState.PinGroupViewStates.PinGroupDetailedDisplayViewState>((Action<Core.Operations.UI.Navigation.UiNavigationParams>)(x => Navi.Go("view", x.GetValue<Guid>())));
            navi.Bind<Core.Operations.UI.ViewState.Health.StorageHealthViewState>((Action<Core.Operations.UI.Navigation.UiNavigationParams>)(x => Navi.Go("health", "storage")));
        }

        private static void DisableMobileResize()
        {
            Document.Head.AppendChild(new HTMLMetaElement
            {
                Name = "viewport",
                Content = "width=device-width, user-scalable=no",
            });
        }

        private static Element CreateAppContainer()
        {
            HTMLDivElement appContainer = new HTMLDivElement();
            appContainer.Id = "AppContainer";
            appContainer.Style.Height = "100%";
            appContainer.Style.Width = "100%";
            Document.Body.AppendChild(appContainer);
            return appContainer;
        }

        private static void SetGlobalStyling()
        {
            BrandingStyle style = Get<BrandingStyle>();

            ReferenceCss();

            ReferenceFonts(style);

            jQuery.Select("html, body").Css(new
            {
                width = "100%",
                height = "100%",
                margin = 0,
                padding = 0,
            });

            jQuery.Select("body").Css(new
            {
                color = style.DefaultTextColor.Hex,
                backgroundColor = style.BackgroundColor.Hex,
                fontFamily = style.Typography.FontFamily,
                fontSize = style.Typography.FontSize.PointsCss,
            });
        }

        private static void ReferenceCss()
        {
            Document.Head.AppendChild(new HTMLLinkElement
            {
                Href = "https://static2.sharepointonline.com/files/fabric/office-ui-fabric-core/10.0.0/css/fabric.min.css",
                Rel = "stylesheet",
            });

            Document.Head.AppendChild(new HTMLLinkElement
            {
                Href = "https://api.mapbox.com/mapbox-gl-js/v1.4.1/mapbox-gl.css",
                Rel = "stylesheet",
            });

            BrandingStyle style = Get<BrandingStyle>();
            Document.Head.AppendChild(new HTMLStyleElement
            {
                InnerHTML = "a:link, a:visited, a:hover, a:active, a:focus {" +
string.Format("color: inherit;")+
string.Format("cursor: pointer;")+
                "text-decoration: underline;" +
                "}" +
                "" +
                "a:focus, a:hover {" +
                "opacity: 0.8;" +
                "}",
            });
        }

        private static void ReferenceFonts(BrandingStyle style)
        {
            Document.Head.AppendChild(new HTMLLinkElement
            {
                Href = style.Typography.FontFamilyUrl,
                Rel = "stylesheet",
            });
        }

        private static async Task ReferenceLibs()
        {
            jQuery.AjaxSetup(new AjaxOptions
            {
                Cache = true,
            });

            await ReferenceLib("https://unpkg.com/react@16/umd/react.production.min.js");
            await ReferenceLib("https://unpkg.com/react-dom@16/umd/react-dom.production.min.js");
            await ReferenceLib("https://unpkg.com/dexie@2.0.4/dist/dexie.js");
            Script.Write("window.dexie = Dexie;");

            await ReferenceLib("https://api.mapbox.com/mapbox-gl-js/v1.4.1/mapbox-gl.js");
#if DEBUG
            await ReferenceLib("/FileSaver.js");
            await ReferenceLib("/jszip.js");
#else
            await ReferenceLib("/FileSaver.min.js");
            await ReferenceLib("/jszip.min.js");
#endif
        }

        private static Task ReferenceLib(string lib)
        {
            TaskCompletionSource<bool> taskCompletionSource = new TaskCompletionSource<bool>();

            jQuery
                .GetScript(lib)
                .Done((Action)(() => taskCompletionSource.SetResult(true)))
                .Fail((Action)(() => taskCompletionSource.SetException(new InvalidOperationException(string.Format("Error loading library: {0}",lib)))))
                ;

            return taskCompletionSource.Task;
        }
    }

    class AppLoadIndicator : IDisposable
    {
        private readonly HTMLElement loadingContainer;

        public AppLoadIndicator()
        {
            loadingContainer = new HTMLDivElement();
            DrawAndDisplayLoadingIndicator();
        }

        private void DrawAndDisplayLoadingIndicator()
        {
            loadingContainer.InnerHTML = "<em style='padding: 15px;'>Loading, please wait...</em>";
            Document.Body.AppendChild(loadingContainer);
        }

        public void Dispose()
        {
            loadingContainer.Remove();
        }
    }
}