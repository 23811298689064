using Bridge;
using Bridge.Html5;
using Bridge.React;
using H.Skeepy.Core.Operations.UI.UserInput;
using System;
using System.Threading;
using System.Threading.Tasks;

namespace H.Skeepy.Click.Web.UI.Components.UI.Controls
{
    public class TextInput : ComponentBase<TextInput.Props, TextInput.State>
    {
        public TextInput(Props props) : base(props, null) { }
        public TextInput() : this(new Props { }) { }

        public override ReactElement Render()
        {
            var attributes
                = new InputAttributes
                {
                    Style = new ReactStyle
                    {
                        Display = Display.Block,
                        Border = "solid 1px",
                        BorderColor = GetBorderColor(),
                        Margin = "0 -20px 0 0",
                        Padding = 10,
                        BackgroundColor = style.BackgroundColor.Hex,
                        Color = style.DefaultTextColor.Hex,
                        FontFamily = style.Typography.FontFamily,
                        FontSize = style.Typography.FontSize.PointsCss,
                        Outline = "none",
                        Width = "100%",
                        BoxSizing = BoxSizing.BorderBox,
                    },

                    Placeholder = string.IsNullOrWhiteSpace(props.Placeholder) ? null : props.Placeholder,

                    Disabled = props.IsDisabled,

                    OnFocus = x => { EnableOnFocusedStyle(x.CurrentTarget); },
                    OnBlur = async x => { DisableOnFocusedStyle(x.CurrentTarget); await ValidateUserInput(x.CurrentTarget.Value); },

                    OnChange = x => ValidateUserInputWhileTyping(x.CurrentTarget.Value),
                };

            if (props.Decorator != null) attributes = props.Decorator.Invoke(attributes);

            return
                DOM.Div(
                    new Attributes
                    {
                        Style = new ReactStyle
                        {
                            Display = Display.InlineBlock,
                            Width = props.Width,
                            Opacity = props.IsDisabled ? "0.25" : null,
                        },
                    },
                    RenderLabel(),
                    DOM.Div(new Attributes { Style = new ReactStyle { Width = "100%", Position = Position.Relative } },
                        DOM.Input(attributes),
                        RenderHighlight()
                    ),
                    RenderDescription()
                );
        }

        private void ValidateUserInputWhileTyping(string value)
        {
            if (props.UserInputValidator == null)
                return;

            CancelUserInputValidation();

            state.validateUserInputWhileTypingTimeoutID = Window.SetTimeout((Action)(async () => { await ValidateUserInput(value); state.validateUserInputWhileTypingTimeoutID = -1; }), (int)state.idleTimeToConsiderUserTypingIsFinished.TotalMilliseconds);
        }

        private async Task ValidateUserInput(string value)
        {
            if (props.UserInputValidator == null)
                return;

            CancelUserInputValidation();

            using (state.validateUserInputWhileTypingCancelTokenSource = new CancellationTokenSource())
            {
                try
                {
                    state.ValidationState = await props.UserInputValidator.Invoke(value, state.validateUserInputWhileTypingCancelTokenSource.Token);
                    await SetStateAsync();
                }
                finally { }
            }
            state.validateUserInputWhileTypingCancelTokenSource = null;


        }

        private void CancelUserInputValidation()
        {
            if (state.validateUserInputWhileTypingCancelTokenSource != null)
            {
                try { state.validateUserInputWhileTypingCancelTokenSource.Cancel(); } finally { }
            }

            if (state.validateUserInputWhileTypingTimeoutID >= 0)
            {
                Window.ClearTimeout(state.validateUserInputWhileTypingTimeoutID);
                state.validateUserInputWhileTypingTimeoutID = -1;
            }
        }

        private ReactElement RenderHighlight()
        {
            if (!props.IsHighlighted) return null;

            return
                DOM.Div(
                    new Attributes
                    {
                        Style = new ReactStyle
                        {
                            Height = 8,
                            Width = 8,
                            Position = Position.Absolute,
                            Bottom = 0,
                            Right = 0,
                            BackgroundColor = GetHighlightColor(),
                        }
                    }
                );
        }

        private string GetHighlightColor()
        {
            if ((global::Bridge.Script.ToTemp("key1",state)!=null&&global::Bridge.Script.ToTemp("key2",global::Bridge.Script.FromTemp<State>("key1").ValidationState)!=null?global::Bridge.Script.FromTemp<UserInputValidationResult>("key2").IsValid:(bool?)null) == null)
                return style.Colors.Primary.Lighter(2).Hex;

            return
                state.ValidationState.IsValid == true
                ? style.SuccessColor.Hex
                : style.DangerColor.Hex
                ;
        }

        private ReactElement RenderDescription()
        {
            if (string.IsNullOrWhiteSpace(props.Description))
                return null;

            return
                DOM.Div(
                    new Attributes
                    {
                        Style = new ReactStyle
                        {
                            FontSize = style.Typography.FontSizeSmaller.PointsCss,
                            Color = style.MutedTextColor.Hex,
                            Margin = "2px 1px",
                            Width = "100%",
                        },
                    },

                    string.IsNullOrWhiteSpace(global::Bridge.Script.ToTemp("key3",state)!=null&&global::Bridge.Script.ToTemp("key4",global::Bridge.Script.FromTemp<State>("key3").ValidationState)!=null?global::Bridge.Script.FromTemp<UserInputValidationResult>("key4").Message:(string)null) ? props.Description : state.ValidationState.Message

                );
        }

        private ReactElement RenderLabel()
        {
            if (string.IsNullOrWhiteSpace(props.Label))
                return null;

            return
                DOM.Div(
                    new Attributes
                    {
                        Style = new ReactStyle
                        {
                            FontSize = style.Typography.FontSizeSmaller.PointsCss,
                            Color = style.Colors.Primary.Color.Hex,
                            Margin = "2px 1px",
                            Width = "100%",
                        },
                    },
                    props.Label
                );
        }

        private void EnableOnFocusedStyle(HTMLInputElement inputElement)
        {
            if (props.IsDisabled) return;

            inputElement.Style.BorderColor = style.Colors.Primary.Color.Hex;
        }

        private void DisableOnFocusedStyle(HTMLInputElement inputElement)
        {
            inputElement.Style.BorderColor = GetBorderColor();
        }

        private string GetBorderColor()
        {
            return style.Colors.Primary.Lighter(2).Hex;
        }

        public class State
        {
            public TimeSpan idleTimeToConsiderUserTypingIsFinished { get; private set; }
            public int validateUserInputWhileTypingTimeoutID = -1;
            public CancellationTokenSource validateUserInputWhileTypingCancelTokenSource = null;

            public UserInputValidationResult ValidationState { get; set; }

        
private TimeSpan __Property__Initializer__idleTimeToConsiderUserTypingIsFinished=TimeSpan.FromMilliseconds(500);}
        public class Props
        {
            public bool IsDisabled { get; set; }
            public Func<InputAttributes, InputAttributes> Decorator { get; set; }
            public string Label { get; set; }
            public string Placeholder { get; set; }
            public string Description { get; set; }
            public Union<string, int> Width { get; set; }
            public Func<string, CancellationToken, Task<UserInputValidationResult>> UserInputValidator { get; set; }
public bool IsHighlighted
{
    get
    {
        return UserInputValidator != null;
    }
}
        
private bool __Property__Initializer__IsDisabled=false;private Union<string, int> __Property__Initializer__Width=200;}
    }
}
