using System;
using System.Globalization;

namespace H.Skeepy.Core.Model
{
    public struct NumberInterval
    {
        public NumberInterval(double min, double max)
        {
            if (min > max)
                throw new InvalidOperationException("min > max");

            Min = min;
            Max = max;
        }

        public double Min { get; set; }
        public double Max { get; set; }

        public override string ToString()
        {
            return string.Format("[{0},{1}]",Min.ToString(CultureInfo.InvariantCulture),Max.ToString(CultureInfo.InvariantCulture));
        }
    }
}
