using H.Skeepy.Core.Common;
using H.Skeepy.Core.Infrastructure;
using H.Skeepy.Core.Infrastructure.Timing;
using System;
using System.Linq;
using System.Threading.Tasks;

namespace H.Skeepy.Core.Operations.Sync.Concrete
{
    public class SyncWorker : ImADaemonWorker, ImASkeepyOperationContract
    {
        const int syncInitialDelayInSeconds = 5;
#if DEBUG
        const int syncIntervalInSeconds = 15;
        const int syncBatchSizePerType = 1;
#else
        const int syncIntervalInSeconds = 30;
        const int syncBatchSizePerType = 5;
#endif


        #region Construct
        SyncableTypesRegistry syncableTypesRegistry;
        ImASyncRegistry syncRegistry;
        ImAPeriodicAction periodicAction;
        ImASyncSender syncSender;
        ImASyncReceiver syncReceiver;
        ImALogService logService;
        public void ReferDependencies(ImADependencyPoolContainer dependencyPoolContainer)
        {
            logService = logService ?? dependencyPoolContainer.Resolve<ImALogService>();
            syncableTypesRegistry = syncableTypesRegistry ?? dependencyPoolContainer.Resolve<SyncableTypesRegistry>();
            periodicAction = periodicAction ?? dependencyPoolContainer.Resolve<ImAPeriodicAction>();
            syncRegistry = syncRegistry ?? dependencyPoolContainer.Resolve<ImASyncRegistry>();
            syncSender = syncSender ?? dependencyPoolContainer.Resolve<ImASyncSender>();
            syncReceiver = syncReceiver ?? dependencyPoolContainer.Resolve<ImASyncReceiver>();
#if DEBUG
            syncReceiver.Use(new SyncNode
            {
                ID = "Localhost",
                Description = "Local Dev Skeepy Core Server",
                Name = "LocalDebug Sync Node",
                Uri = "http://localhost:9969",
                SyncRequestUri = "http://localhost:9969/sync/",
            });
#else
        syncReceiver.Use(new SyncNode
            {
                ID = "clk.skeepy.ro",
                Description = "Skeepy Click Core Server",
                Name = "Skeepy Click Sync Gateway",
                Uri = "https://clk.skeepy.ro/",
                SyncRequestUri = "https://clk.skeepy.ro/sync/",
            });
#endif

        }
        #endregion

        public void DoWork()
        {
            periodicAction.StartDelayed(TimeSpan.FromSeconds(syncInitialDelayInSeconds), TimeSpan.FromSeconds(syncIntervalInSeconds), (Func<Task>)RunSyncEpoch);
        }

        public async Task RunSyncEpoch()
        {
            await
                new Func<Task>(() => Task.WhenAll(System.Linq.Enumerable.Select<string,Task>(syncableTypesRegistry.All,(Func<string,Task>)RunSyncEpochForType).ToArray()))
                .TryOrFailWithGrace(1, (Action<Exception>)(x => logService.Log(new Model.Logging.LogEntry
                {
                    Level = Model.Logging.LogEntryLevel.Warn,
                    Message = x.Message,
                    Method = "RunSyncEpoch",
                })));
        }

        private async Task RunSyncEpochForType(string type)
        {
            string[] entityIdsToSync = await syncRegistry.GetEntitiesToSync(type, syncBatchSizePerType);

            await Task.WhenAll<SyncResponse>(
System.Linq.Enumerable.Select<string,Task<SyncResponse>>(                entityIdsToSync,(Func<string,Task<SyncResponse>>)(id => RunSyncForEntity(type, id))).ToArray()
            );
        }

        private async Task<SyncResponse> RunSyncForEntity(string type, string id)
        {
            object entity = await syncableTypesRegistry.LoadEntity(type, id);
            SyncResponse syncResult = await syncSender.SyncWith(syncReceiver, SyncRequest.Wrap(type, entity, id, await syncRegistry.StatusFor(type, id)));
            return syncResult;
        }
    }
}
