using H.Skeepy.Click.Web.UI.Common;
using H.Skeepy.Core.Infrastructure;
using H.Skeepy.Core.Operations;
using H.Skeepy.Core.Operations.Storage;
using H.Skeepy.Core.Operations.UseCases;
using System;
using System.Linq;
using System.Threading.Tasks;

namespace H.Skeepy.Click.Web.UI.Components.LocalStorage
{
    public abstract class StorageServiceBase<TEntity, TId> : ImASkeepyOperationContract, ImAUseCaseBootstrapper<Func<object, TEntity, TEntity>>
    {
        protected IndexedDBStorage storage;
        protected ImALogService logger;
        public virtual void ReferDependencies(ImADependencyPoolContainer dependencyPoolContainer)
        {
            storage = storage ?? dependencyPoolContainer.Resolve<IndexedDBStorage>();
            logger = logger ?? dependencyPoolContainer.Resolve<ImALogService>();
        }

        Func<object, TEntity, TEntity> jsonParsingDecorator;
        public void Use(Func<object, TEntity, TEntity> jsonParsingDecorator)
        {
            this.jsonParsingDecorator = jsonParsingDecorator;
        }

        protected abstract TId GetIdFor(TEntity item);
protected virtual string GetIdForStore(TId id)
{
    return id.ToString();
}
        protected virtual Task<TEntity> Load(Retyped.dexie.Dexie.Table<object, object> table, TId id)
        {
            TaskCompletionSource<TEntity> taskCompletionSource = new TaskCompletionSource<TEntity>();

            table
                .get(GetIdForStore(id))
                .then(
(Retyped.dexie.Dexie.Promise<Bridge.Union<object, Retyped.Primitive.Undefined>>.thenFn)(                    json => {
                        TEntity result = json.ToType<TEntity>();
                        result = jsonParsingDecorator == null ? result : jsonParsingDecorator.Invoke(json, result);
                        taskCompletionSource.SetResult(result);
                        return json;
                    }),
(Retyped.dexie.Dexie.Promise<Bridge.Union<object, Retyped.Primitive.Undefined>>.thenFn2)(                    x => {
                        taskCompletionSource.SetException(new InvalidOperationException(string.Format("Error Loading model from {0}",table.name)));
                        return null;
                    }
)                );

            return taskCompletionSource.Task;
        }

        protected virtual Task<long> DeleteMany(object filter)
        {
            Retyped.dexie.Dexie.Collection<object, object> filteredCollection = ApplyFilter(filter);

            TaskCompletionSource<long> taskCompletionSource = new TaskCompletionSource<long>();

            filteredCollection
                .delete()
                .then(
(Retyped.dexie.Dexie.Promise<double>.thenFn)(                    count =>
                    {
                        taskCompletionSource.SetResult((long)count);
                        return count;
                    }),
(Retyped.dexie.Dexie.Promise<double>.thenFn2)(                    x =>
                    {
                        taskCompletionSource.SetException(new InvalidOperationException(string.Format("Error deleting {0} collection","filter")));
                        return null;
                    }
)                )
                ;

            return taskCompletionSource.Task;
        }

        protected virtual Task Save(Retyped.dexie.Dexie.Table<object, object> table, TEntity model)
        {
            TaskCompletionSource<bool> taskCompletionSource = new TaskCompletionSource<bool>();

            object modelAsJson = H.Skeepy.Click.Web.UI.Common.Extensions.ToJson<TEntity>(model);

            table
                .put(modelAsJson)
                .then(
(Retyped.dexie.Dexie.Promise<object>.thenFn)(                    x => {
                        taskCompletionSource.SetResult(true);
                        return x;
                    }),
(Retyped.dexie.Dexie.Promise<object>.thenFn2)(                    x => {
                        taskCompletionSource.SetException(new InvalidOperationException(string.Format("Error saving model to {0}",table.name)));
                        return x;
                    }
)                );

            return taskCompletionSource.Task;
        }

        protected virtual Task Delete(Retyped.dexie.Dexie.Table<object, object> table, TId id)
        {
            TaskCompletionSource<bool> taskCompletionSource = new TaskCompletionSource<bool>();

            table
                .delete(GetIdForStore(id))
                .then(
(Retyped.dexie.Dexie.Promise<Retyped.Primitive.Void>.thenFn)(                    json =>
                    {
                        taskCompletionSource.SetResult(true);
                        return json;
                    }),
(Retyped.dexie.Dexie.Promise<Retyped.Primitive.Void>.thenFn2)(                    x =>
                    {
                        taskCompletionSource.SetException(new InvalidOperationException(string.Format("Error deleting model {0} from {1}",id,table.name)));
                        return null;
                    }
)                );

            return taskCompletionSource.Task;
        }

        protected virtual Task<TEntity[]> Search(object filter)
        {
            Retyped.dexie.Dexie.Collection<object, object> filteredCollection = ApplyFilter(filter);

            TaskCompletionSource<TEntity[]> taskCompletionSource = new TaskCompletionSource<TEntity[]>();

            filteredCollection
                .toArray()
                .then(
(Retyped.dexie.Dexie.Promise<Retyped.es5.Array<object>>.thenFn)(                    x =>
                    {
                        var result = System.Linq.Enumerable.Select<object,TEntity>(x,(Func<object,TEntity>)(o => {
                            TEntity res = o.ToType<TEntity>();
                            res = jsonParsingDecorator == null ? res : jsonParsingDecorator.Invoke(o, res);
                            return res; 
                        })).ToArray();
                        taskCompletionSource.SetResult(result);
                        return x;
                    }),
(Retyped.dexie.Dexie.Promise<Retyped.es5.Array<object>>.thenFn2)(                    x =>
                    {
                        taskCompletionSource.SetException(new InvalidOperationException(string.Format("Error filtering {0} collection","TEntity")));
                        return null;
                    }
)                );

            return taskCompletionSource.Task;
        }

        protected virtual Task<long> Count(object filter)
        {
            Retyped.dexie.Dexie.Collection<object, object> filteredCollection = ApplyFilter(filter);

            TaskCompletionSource<long> taskCompletionSource = new TaskCompletionSource<long>();

            filteredCollection
                .count()
                .then(
(Retyped.dexie.Dexie.Promise<double>.thenFn)(                    count =>
                    {
                        taskCompletionSource.SetResult((long)count);
                        return count;
                    }),
(Retyped.dexie.Dexie.Promise<double>.thenFn2)(                    x =>
                    {
                        taskCompletionSource.SetException(new InvalidOperationException(string.Format("Error counting {0} collection","filter")));
                        return null;
                    }
)                );

            return taskCompletionSource.Task;
        }

        protected abstract Retyped.dexie.Dexie.Collection<object, object> ApplyFilter(object filter);
    }
}
