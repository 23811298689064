using System.Linq;

namespace H.Skeepy.Core.Model
{
    public class DataBucket
    {
        public DataBucket(object payload = null)
        {
            this.Payload = payload;
        }

        public string Name { get; private set; }
        public string Extension { get; private set; }
public string NameWithExtension
{
    get
    {
        return string.IsNullOrWhiteSpace(Extension) ? Name : string.Format("{0}.{1}",Name,Extension);
    }
}        public string TypeName { get; private set; }
        public string TypeDescription { get; private set; }
        public string MimeType { get; private set; }
        public object Payload { get; private set; }
        public string PayloadAsString { get; private set; }
        public byte[] PayloadAsByteArray { get; private set; }
        public uint? PayloadSizeInBytes { get; private set; }

        public DataBucket WithName(string name, string extension = null)
        {
            Name = name;
            Extension = extension;
            return this;
        }

        public DataBucket WithType(string typeName, string mimeTye, string typeDescription = null)
        {
            TypeName = typeName;
            MimeType = mimeTye;
            TypeDescription = typeDescription;
            return this;
        }

        public DataBucket WithJsonPayload()
        {
            PayloadAsString = Newtonsoft.Json.JsonConvert.SerializeObject(Payload);
            return this;
        }

        public DataBucket WithBytePayload(byte[] byteArrayPayload)
        {
            PayloadAsByteArray = byteArrayPayload;
            if (Payload == null)
                Payload = byteArrayPayload;
            return this;
        }

        public DataBucket WithStringPayload(string stringPayload)
        {
            PayloadAsString = stringPayload;
            if (Payload == null)
                Payload = stringPayload;
            return this;
        }

        public DataBucket WithCalculatedApproximationPayloadSize()
        {
            if ((global::Bridge.Script.ToTemp("key1",PayloadAsByteArray)!=null?global::Bridge.Script.FromTemp<byte[]>("key1").Any():(bool?)null) ?? false)
            {
                PayloadSizeInBytes = (uint)PayloadAsByteArray.LongLength;
                return this;
            }

            if(!string.IsNullOrWhiteSpace(PayloadAsString))
            {
                PayloadSizeInBytes = (uint)PayloadAsString.Length * 2;//Assume UTF-8
                return this;
            }

            return this;
        }

        public DataBucket WithPayloadSize(uint sizeInBytes)
        {
            PayloadSizeInBytes = sizeInBytes;
            return this;
        }
    }
}
