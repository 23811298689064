using H.Skeepy.Core.Common;
using System;
using System.Linq;

namespace H.Skeepy.Core.Model
{
    public class OperationRequestContext
    {
        public const string SkeepyConsumerIDKey = "X-H-Skeepy-SkeepyConsumerID";
        public const string RequestPathKey = "Request.Path";
        public const string RequestMethodKey = "Request.Method";
        public const string RequestClientIpAddressKey = "Request.ClientIpAddress";

        public Guid? SkeepyConsumerID { get; private set; }
        public string RequestPath { get; private set; }
        public string RequestMethod { get; private set; }
        public string ClientIpAddress { get; private set; }
        public OperationRequestContext(params Iota[] properties)
        {
            RawProperties = properties ?? new Iota[0];

            SkeepyConsumerID = global::Bridge.Script.ToTemp("key1",RawProperties.FirstOrDefault<Iota>((Func<Iota,bool>)(x => x.ID == SkeepyConsumerIDKey)).Jot)!=null?global::Bridge.Script.FromTemp<string>("key1").ParseToGuidOrFallbackTo(null):(Guid?)null;
            RequestPath = System.Linq.Enumerable.FirstOrDefault<Iota>(RawProperties,(Func<Iota,bool>)(x => x.ID == RequestPathKey)).Jot;
            RequestMethod = System.Linq.Enumerable.FirstOrDefault<Iota>(RawProperties,(Func<Iota,bool>)(x => x.ID == RequestMethodKey)).Jot;
            ClientIpAddress = System.Linq.Enumerable.FirstOrDefault<Iota>(RawProperties,(Func<Iota,bool>)(x => x.ID == RequestClientIpAddressKey)).Jot;
        }

        public Iota[] RawProperties { get; private set; }

    
private Iota[] __Property__Initializer__RawProperties=new Iota[0];}
}
