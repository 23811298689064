using Bridge;
using Bridge.Html5;
using System;
using System.Linq;

namespace H.Skeepy.Core.Infrastructure.Concrete
{
    public class WebWorkerDaemon : ImADaemon
    {
        Func<ImADaemonWorker> daemonWorker;
        Retyped.dom.Worker daemonThread;
public void Use(Func<ImADaemonWorker> daemonWorker)
{
    this.daemonWorker = daemonWorker;
}public void Start()
{
    Start(keepAlive: false);
}public void StartAndKeepAlive()
{
    Start(keepAlive: true);
}
        public void Stop()
        {
            if (daemonThread == null)
                return;

            daemonThread.terminate();
            daemonThread = null;
        }

        private void Start(bool keepAlive = false)
        {
            if (daemonWorker == null)
                return;

            if (daemonThread != null)
                return;

            string[] jsFiles = new string[] {
                Window.Location.Origin + "/bridge.js",
                Window.Location.Origin + "/bridge.meta.js",
                Window.Location.Origin + "/ProductiveRage.Immutable.js",
                Window.Location.Origin + "/ProductiveRage.Immutable.meta.js",
                Window.Location.Origin + "/newtonsoft.json.js",
                Window.Location.Origin + "/Bridge.React.js",
                Window.Location.Origin + "/Bridge.React.meta.js",
                Window.Location.Origin + "/jquery-2.2.4.js",
                Window.Location.Origin + "/productiveRage.immutable.extensions.js",
                Window.Location.Origin + "/productiveRage.immutable.extensions.meta.js",
                Window.Location.Origin + "/ProductiveRage.ReactRouting.js",
                Window.Location.Origin + "/ProductiveRage.ReactRouting.meta.js",
                Window.Location.Origin + "/en-US.js",
                Window.Location.Origin + "/ro-RO.js",
                "https://unpkg.com/dexie@2.0.4/dist/dexie.js",
                Window.Location.Origin + "/H.Skeepy.Click.Web.UI.js",
                Window.Location.Origin + "/H.Skeepy.Click.Web.UI.meta.js",
            };

            string actionAsString
                = string.Format("")+
string.Format("{0}",PrintFakeDomForJquery())+
string.Format("{0} ",string.Join(string.Empty, System.Linq.Enumerable.Select<string,string>(jsFiles,(Func<string,string>)(x => string.Format("importScripts('{0}');",x)))))+
string.Format("self.dexie = Dexie;")+
string.Format("H.Skeepy.Click.Web.UI.App.MainAsWebWorker();")+
string.Format("({0})().DoWork(); ",daemonWorker)+
string.Format("{0}",(keepAlive ? string.Empty : "self.postMessage('WebWorkerThreadDoneExecuting'); self.close();"));

            Blob workerJsCode = new Blob(new BlobDataObject[] { actionAsString }, new BlobPropertyBag { Type = "text/javascript" });

            //Console.WriteLine(actionAsString);

            daemonThread = new Retyped.dom.Worker(Retyped.dom.URL.createObjectURL(workerJsCode));

            daemonThread.onmessage = x =>
            {
                if (x.data.ToString() == "WebWorkerThreadDoneExecuting")
                {
                    daemonThread = null;
                }
            };

            daemonThread.onerror = x =>
            {
                daemonThread = null;
            };
        }

        public static void End()
        {
            Script.Write("self.postMessage('WebWorkerThreadDoneExecuting'); self.close();");
        }

        private string PrintFakeDomForJquery()
        {
            return @"
            var document = self.document = { isWebWorkerContext : true, parentNode: null, nodeType: 9, toString: function() {return ""FakeDocument""}};
            var window = self.window = self;
            var fakeElement = Object.create(document);
            fakeElement.nodeType = 1;
            fakeElement.toString = function() { return ""FakeElement""};
            fakeElement.parentNode = fakeElement.firstChild = fakeElement.lastChild = fakeElement;
            fakeElement.ownerDocument = document;

            document.head = document.body = fakeElement;
            document.ownerDocument = document.documentElement = document;
            document.getElementById = document.createElement = function() { return fakeElement; };
            document.createDocumentFragment = function() { return this; };
            document.getElementsByTagName = document.getElementsByClassName = function() { return [fakeElement]; };
            document.getAttribute = document.setAttribute = document.removeChild =
            document.addEventListener = document.removeEventListener =
            function() { return null; };
            document.cloneNode = document.appendChild = function() { return this; };
            document.appendChild = function(child) { return child; };
            ";
        }

    }
}
