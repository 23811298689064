using H.Skeepy.Core.Infrastructure;

namespace H.Skeepy.Core.Operations.Caching
{
    public class CachingModule : ImADependencyModule
    {
        public void RegisterDependencies(ImADependencyPoolContainer dependencyPoolContainer)
        {
            dependencyPoolContainer.Register<ImACacheServiceBuilder>((System.Func<object>)(() => new Concrete.InMemoryCacheServiceBuilder()));
        }
    }
}
