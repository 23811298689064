using H.Skeepy.Core.Infrastructure;
using H.Skeepy.Core.Operations.Broadcasting.Abstract;

namespace H.Skeepy.Core.Operations.Broadcasting
{
    public class BroadcastingModule : ImADependencyModule
    {
        public void RegisterDependencies(ImADependencyPoolContainer dependencyPoolContainer)
        {
            dependencyPoolContainer.Register<ImABroadcastingTower>((System.Func<object>)(() => new Concrete.BroadcastingTower()));

            dependencyPoolContainer.RegisterAlwaysNew<ImABroadcastReceiver>((System.Func<object>)(() => new Concrete.BroadcastReceiver()));
            dependencyPoolContainer.RegisterAlwaysNew<BroadcastReceiverBase>((System.Func<object>)(() => new Concrete.BroadcastReceiver()));
        }
    }
}
