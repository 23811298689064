using Bridge;
using Bridge.Html5;
using H.Skeepy.Core.Common;
using H.Skeepy.Core.Model;
using H.Skeepy.Core.Model.GPS;
using H.Skeepy.Core.Operations.UI.WorldMapping;
using System;
using System.Collections.Generic;
using System.Linq;

namespace H.Skeepy.Click.Web.UI.Components.Maps
{
    public class MapBoxMaps : ImAWorldMapUiComponent
    {
        const string accessToken = "pk.eyJ1IjoiaGludGVhZGFuIiwiYSI6ImNrNDEydWV0YzA3aDgzbXF1NnkydXRyeGgifQ.815iWIgrtxw4vQkxiiwS8Q";
        const string defaultMapStyleUrl = "mapbox://styles/hinteadan/ck41e56ra2t9v1dpjdpx8abqp";

        const double zoomLevelBoulevard = 15.8;
        const double zoomLevelCityDistrict = 12.1;
        const double zoomLevelCountry = 5.5;
        const double zoomLevelContinent = 3.2;
        const double defaultZoom = 5.5;


        #region Construct
        static readonly GpsPoint defaultCenter = new GpsPoint { LatInDegrees = 46.770439, LngInDegrees = 23.591423 };//Cluj
        static readonly HTMLElement mapCanvas = new HTMLDivElement();
        static readonly MapBoxGlMap mapBoxGlMap;
        static readonly List<MapBoxGlMarker> markersRenderedOnMap = new List<MapBoxGlMarker>();

        static MapBoxMaps()
        {
            mapCanvas.Style["width"] = "400px";
            mapCanvas.Style["height"] = "300px";
            mapBoxGlMap = RenderMapCanvas();
            mapBoxGlMap.setCenter(Map(defaultCenter)).setZoom(defaultZoom);
        }
        readonly DataNormalizer zoomNormalizer = new DataNormalizer(new NumberInterval(0, 100), new NumberInterval(0, 22));
        readonly HTMLElement mapCanvasFrame;
        public MapBoxMaps(HTMLElement mapCanvasFrame, string width = "100%", string height = "100%")
        {
            EnsurePreviousDisposal();

            if (mapCanvasFrame == null)
                return;

            this.mapCanvasFrame = mapCanvasFrame;
            mapCanvas.Style["width"] = width;
            mapCanvas.Style["height"] = height;
            this.mapCanvasFrame.AppendChild(mapCanvas);
            mapBoxGlMap.resize();
        }
        #endregion

        #region Operations
        public ImAWorldMapUiComponent SetZoomPercent(double zoomPercent)
        {
            mapBoxGlMap.setZoom(zoomNormalizer.Do(zoomPercent));

            return this;
        }

        public ImAWorldMapUiComponent SetZoomLevel(MapZoomLevel zoomLevel)
        {
            switch (zoomLevel)
            {
                case MapZoomLevel.Boulevard:
                    mapBoxGlMap.setZoom(zoomLevelBoulevard);
                    break;
                case MapZoomLevel.CityDistrict:
                    mapBoxGlMap.setZoom(zoomLevelCityDistrict);
                    break;
                case MapZoomLevel.Country:
                    mapBoxGlMap.setZoom(zoomLevelCountry);
                    break;
                case MapZoomLevel.Continent:
                    mapBoxGlMap.setZoom(zoomLevelContinent);
                    break;
            }

            return this;
        }

        public ImAWorldMapUiComponent Mark(GpsPoint pin)
        {
            var mapPin = Map(pin);

            mapBoxGlMap.setCenter(mapPin);

            MapBoxGlMarker marker = new MapBoxGlMarker(new { });
            marker
                .setLngLat(mapPin)
                .addTo(mapBoxGlMap);

            markersRenderedOnMap.Add(marker);

            return this;
        }

        public ImAWorldMapUiComponent MarkTrack(params GpsPoint[] pins)
        {
            if (pins == null)
                return this;

            if (pins.Length == 1)
                return Mark(System.Linq.Enumerable.Single<GpsPoint>(pins));

            GpsArea gpsArea = new GpsArea(pins);

            MapBoxGlMarker[] markers = System.Linq.Enumerable.Select<GpsPoint,MapBoxMaps.MapBoxGlMarker>(pins,(Func<GpsPoint,MapBoxMaps.MapBoxGlMarker>)(pin =>
            {
                MapBoxGlMarker marker = new MapBoxGlMarker(new { });
                marker
                    .setLngLat(Map(pin))
                    .addTo(mapBoxGlMap);
                return marker;
            })).ToArray();

            markersRenderedOnMap.AddRange(markers);

            mapBoxGlMap.fitBounds(Map(gpsArea), new
            {
                padding = Core.Branding.BrandingStyle.DefaultSizingUnitInPixels * 2,
            });

            return this;
        }

        public void Dispose()
        {
            mapCanvas.ParentElement!=null?mapCanvas.ParentElement.RemoveChild(mapCanvas):(Node)null;
            foreach (MapBoxGlMarker marker in markersRenderedOnMap)
            {
                marker.remove();
            }
            markersRenderedOnMap.Clear();
            mapBoxGlMap.setCenter(Map(defaultCenter)).setZoom(defaultZoom);
        }

        private static MapBoxGlMap RenderMapCanvas(GpsPoint? center = null)
        {
            MapBoxGl.accessToken = accessToken;
            dynamic options = new
            {
                container = mapCanvas,
                style = defaultMapStyleUrl,
                dragRotate = false,
            };
            if (center != null)
            {
                options.center = new MapBoxGlLngLat(center.Value.LngInDegrees, center.Value.LatInDegrees);
            }

            MapBoxGlMap map = new MapBoxGlMap(options);

            map.on("zoomend", (Action<dynamic>)OnZoom);

            return map;
        }

        private static void OnZoom(dynamic ev)
        {

        }

        private void EnsurePreviousDisposal()
        {
            if (mapCanvas.ParentElement != null || System.Linq.Enumerable.Any<MapBoxMaps.MapBoxGlMarker>(markersRenderedOnMap))//Dispose was not called on previous use, nasty
                Dispose();
        }

        private static MapBoxGlLngLat Map(GpsPoint pin)
        {
            return new MapBoxGlLngLat(pin.LngInDegrees, pin.LatInDegrees);
        }

        private static MapBoxGlLngLatBounds Map(GpsArea area)
        {
            return new MapBoxGlLngLatBounds(Map(area.SouthWestBoundary), Map(area.NorthEastBoundary));
        }
        #endregion


        #region MapBoxGl API
#pragma warning disable CS0824 // Constructor is marked external
#pragma warning disable CS0626 // Method, operator, or accessor is marked external and has no attributes on it
        [External]
        [Name("mapboxgl.Map")]
        private class MapBoxGlMap
        {
            public extern MapBoxGlMap(dynamic options);

            public extern MapBoxGlMap resize();

            public extern MapBoxGlPoint project(MapBoxGlLngLat pin);
            public extern MapBoxGlLngLat unproject(MapBoxGlPoint point);

            public extern MapBoxGlMap on(string eventType, Action<dynamic> listener);
            public extern MapBoxGlMap off(string eventType, Action<dynamic> listener);
            public extern MapBoxGlMap once(string eventType, Action<dynamic> listener);

            public extern MapBoxGlLngLat getCenter();
            public extern MapBoxGlMap setCenter(MapBoxGlLngLat pin, object eventData = null);
            public extern double getZoom();
            public extern MapBoxGlMap setZoom(double zoomLevel, object eventData = null);

            public extern double getBearing();
            public extern MapBoxGlMap setBearing(double bearing, object eventData = null);
            public extern double getPitch();
            public extern MapBoxGlMap setPitch(double pitchInDegrees, object eventData = null);

            public extern MapBoxGlMap fitBounds(MapBoxGlLngLatBounds bounds, dynamic options = null, object eventData = null);
            public extern MapBoxGlMap triggerRepaint();
        }

        [External]
        [Name("mapboxgl.LngLat")]
        private class MapBoxGlLngLat
        {
            public extern MapBoxGlLngLat(double lng, double lat);
        }

        [External]
        [Name("mapboxgl.LngLatBounds")]
        private class MapBoxGlLngLatBounds
        {
            public extern MapBoxGlLngLatBounds(MapBoxGlLngLat sw, MapBoxGlLngLat ne);
        }

        [External]
        [Name("mapboxgl.Point")]
        private class MapBoxGlPoint
        {
            public extern MapBoxGlPoint(double x, double y);
            public extern double x { get; set; }
            public extern double y { get; set; }
        }

        [External]
        [Name("mapboxgl.Marker")]
        private class MapBoxGlMarker
        {
            public extern MapBoxGlMarker(dynamic options);

            public extern MapBoxGlMarker addTo(MapBoxGlMap map);
            public extern MapBoxGlMarker remove();
            public extern MapBoxGlLngLat getLngLat();
            public extern MapBoxGlMarker setLngLat(MapBoxGlLngLat pin);
            public extern HTMLElement getElement();
            public extern MapBoxGlMarker on(string eventType, Action<dynamic> listener);
            public extern MapBoxGlMarker off(string eventType, Action<dynamic> listener);
            public extern MapBoxGlMarker once(string eventType, Action<dynamic> listener);
        }

        [External]
        [Name("mapboxgl.Popup")]
        private class MapBoxGlPopup
        {
            public extern MapBoxGlPopup(dynamic options);

            public extern MapBoxGlPopup addTo(MapBoxGlMap map);
            public extern MapBoxGlPopup remove();
            public extern bool isOpen();
            public extern MapBoxGlLngLat getLngLat();
            public extern MapBoxGlPopup setLngLat(MapBoxGlLngLat pin);
            public extern HTMLElement getElement();
            public extern MapBoxGlPopup setText(string textContent);
            public extern MapBoxGlPopup setHTML(string htmlContent);
            public extern MapBoxGlPopup setDOMContent(Node dom);
            public extern MapBoxGlPopup on(string eventType, Action<dynamic> listener);
            public extern MapBoxGlPopup off(string eventType, Action<dynamic> listener);
            public extern MapBoxGlPopup once(string eventType, Action<dynamic> listener);
        }

        [External]
        [Name("mapboxgl")]
        private static class MapBoxGl
        {
            public static extern string accessToken { get; set; }
        }
#pragma warning restore CS0626 // Method, operator, or accessor is marked external and has no attributes on it
#pragma warning restore CS0824 // Constructor is marked external
        #endregion
    }
}
