using System;

namespace H.Skeepy.Core.Model.Display.Health
{
    public class StorageStatistics : StorageIdentifier
    {
        public StorageStatistics(string name, StorageType storageType, string storageTypeNotes, params StorageCollectionStatistics[] collections)
        {
            Name = name;
            Type = storageType;
            TypeNotes = storageTypeNotes;
            Collections = collections ?? new StorageCollectionStatistics[0];
        }

        public DateTime AsOf { get; private set; }

        public StorageCollectionStatistics[] Collections { get; private set; }

    
private DateTime __Property__Initializer__AsOf=Rolex.Time;private StorageCollectionStatistics[] __Property__Initializer__Collections=new StorageCollectionStatistics[0];}

    public class StorageCollectionStatistics
    {
        public StorageCollectionStatistics(string name, long count)
        {
            Name = name;
            RecordsCount = count;
        }

        public string Name { get; private set; }
        public long RecordsCount { get; private set; }

    
private long __Property__Initializer__RecordsCount=0;}
}
