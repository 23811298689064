using H.Skeepy.Core.Common;
using System;

namespace H.Skeepy.Core.Model
{
    public class SkeepyConsumer
    {
        public Guid ID { get; set; }

        public DateTime AsOf { get; set; }
public long AsOfTicks
{
    get
    {
        return AsOf.Ticks;
    }
}
        public Iota[] RawProperties { get; set; }
public Iota Property(string id)
{
    return RawProperties.Property(id);
}public Iota[] Properties(string id)
{
    return RawProperties.Properties(id);
}public string Jot(string id)
{
    return RawProperties.Jot(id);
}public string[] Jots(string id)
{
    return RawProperties.Jots(id);
}
        public SkeepyConsumer Set(Iota iota)
        {
            RawProperties = RawProperties.Set(iota);

            return this;
        }

    
private Guid __Property__Initializer__ID=Guid.NewGuid();private DateTime __Property__Initializer__AsOf=Rolex.Time;private Iota[] __Property__Initializer__RawProperties=new Iota[0];}
}
