using Bridge.Html5;
using Bridge.Utils;
using H.Skeepy.Core.Model.Logging;
using H.Skeepy.Core.Operations;

namespace H.Skeepy.Click.Web.UI.Components.Logging
{
    public class ConsoleLogService : ImALogService
    {
        public ImALogService Log(LogEntry entry)
        {
            Console.Log(entry);            
            return this;
        }
    }
}
