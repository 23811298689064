using System;
using System.Collections.Concurrent;
using System.Collections.Generic;
using System.Text;
using H.Skeepy.Core.Operations.UI.Abstract;

namespace H.Skeepy.Core.Operations.UI.Navigation.Concrete
{
    public class InMemoryUiNavigator : ImAUiNavigator
    {
        private readonly ConcurrentDictionary<Type, Action<UiNavigationParams>> navigationRoutes = new ConcurrentDictionary<Type, Action<UiNavigationParams>>();

        public void Bind<TViewState>(Action<UiNavigationParams> platformSpecificNavigationInvoker) where TViewState : ViewStateBase
        {
            navigationRoutes.AddOrUpdate(typeof(TViewState), (Action<UiNavigationParams>)platformSpecificNavigationInvoker, (Func<Type,Action<UiNavigationParams>,Action<UiNavigationParams>>)((x, y) => platformSpecificNavigationInvoker));
        }

        public void Go<TViewState>(UiNavigationParams uiNavigationParams = null)
        {
            if (!navigationRoutes.ContainsKey(typeof(TViewState)))
                throw new InvalidOperationException("There is no registred route for the desired view state");

            Action<UiNavigationParams> platformSpecificNavigationInvoker = navigationRoutes[typeof(TViewState)];

            platformSpecificNavigationInvoker!=null?global::Bridge.Script.FromLambda(()=>platformSpecificNavigationInvoker.Invoke(uiNavigationParams)):null;
        }
    }
}
