using H.Skeepy.Core.Infrastructure;
using H.Skeepy.Core.Operations.Http;
using System;
using System.Threading.Tasks;

namespace H.Skeepy.Core.Operations.Concrete
{
    public class VersionProviderService : ImAVersionProviderService, ImASkeepyOperationContract
    {
        static readonly TimeSpan cacheForSpan = TimeSpan.FromHours(1);
        static Model.Version cachedVersion = null;
        static DateTime cachedVersionAt = DateTime.MinValue;

        #region Construct
        const string versionUrl = "/health/v";
        ImAnHttpClient httpClient;
        public void ReferDependencies(ImADependencyPoolContainer dependencyPoolContainer)
        {
            httpClient = httpClient ?? dependencyPoolContainer.Resolve<ImAnHttpClient>();
        }
        #endregion

        public async Task<Model.Version> GetCurrentVersion()
        {
            if (cachedVersion != null && !IsCacheExpired())
                return cachedVersion;

            Model.Version version = Model.Version.Unknown;

            HttpResponse<Model.Version> response = await httpClient.GetJson<Model.Version>(versionUrl);

            if (response.IsSuccessful)
            {
                version = response.Payload;
            }

            cachedVersion = version;
            cachedVersionAt = Rolex.Time;

            return version;
        }

        private bool IsCacheExpired()
        {
            return Rolex.Time - cachedVersionAt > cacheForSpan;
        }
    }
}
