using System;
using System.Collections.Generic;

namespace H.Skeepy.Core.Operations.UI.Navigation
{
    public interface ImAUiNavigator
    {
        void Bind<TViewState>(Action<UiNavigationParams> platformSpecificNavigationInvoker) where TViewState : Abstract.ViewStateBase;
        void Go<T>(UiNavigationParams uiNavigationParams = null);
    }

    public class UiNavigationParams
    {
        public static readonly UiNavigationParams None = new UiNavigationParams(null);

        private readonly Dictionary<string, object> parameters;

        public UiNavigationParams(Dictionary<string, object> parameters)
        {
            this.parameters = parameters ?? new Dictionary<string, object>();
        }

        public UiNavigationParams(object parameter)
            : this(global::Bridge.Script.CallFor(new Dictionary<string, object>(),(_o1)=>{_o1.Add(string.Empty,parameter);return _o1;}))
        { }

        public T GetValueFor<T>(string parameter, Action orFail = null)
        {
            if (!parameters.ContainsKey(parameter))
            {
                orFail!=null?global::Bridge.Script.FromLambda(()=>orFail.Invoke()):null;
                return default(T);
            }

            object value = parameters[parameter];

            if(!(value is T))
            {
                orFail!=null?global::Bridge.Script.FromLambda(()=>orFail.Invoke()):null;
                return default(T);
            }

            return (T)value;
        }
public T GetValue<T>(Action orFail = null)
{
    return GetValueFor<T>(string.Empty, (Action)orFail);
}    }
}
