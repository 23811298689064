using Bridge;
using Bridge.React;
using H.Skeepy.Click.Web.UI.Components.UI.Styles;
using H.Skeepy.Core.Branding;
using System.Linq;

namespace H.Skeepy.Click.Web.UI.Components.UI.Layout
{
    public class FabricUiGridRow : ComponentBase<FabricUiGridRow.Props, FabricUiGridRow.State>
    {
        public FabricUiGridRow(Props props, params Union<ReactElement, string>[] children) : base(props, children) { }
        public FabricUiGridRow(params Union<ReactElement, string>[] children) : this(new Props { }, children) { }
        public FabricUiGridRow(Props props, params ReactElement[] children) : this(props, System.Linq.Enumerable.Select<ReactElement,Union<ReactElement,string>>(children,(System.Func<ReactElement,Union<ReactElement,string>>)(x => (Union<ReactElement, string>)x)).ToArray()) { }
        public FabricUiGridRow(params ReactElement[] children) : this(new Props { }, System.Linq.Enumerable.Select<ReactElement,Union<ReactElement,string>>(children,(System.Func<ReactElement,Union<ReactElement,string>>)(x => (Union<ReactElement, string>)x)).ToArray()) { }

        public override ReactElement Render()
        {
            ReactStyle reactStyle = new ReactStyle { MarginTop = props.RowSpacing };
            if (props.HasGreedyHeight)
            {
                reactStyle = reactStyle.FlexNode(isVerticalFlow: true);
            }

            return
                DOM.Div(
                    new Attributes
                    {
                        ClassName = "ms-Grid-row",
                        Style = reactStyle,
                    },

                    Children

                );
        }

        public class State { }
        public class Props
        {
            public int RowSpacing { get; set; } 
            public bool HasGreedyHeight { get; set; }

        
private int __Property__Initializer__RowSpacing=15;private bool __Property__Initializer__HasGreedyHeight=false;}
    }
}
