using System;

namespace H.Skeepy.Core.Operations.UI.WorldMapping.Concrete
{
    public class WorldMapUiFactory : ImAWorldMapUiFactory
    {
        #region Construct
        Func<ImAWorldMapUiComponent> mapBuilder = null;
        public void Use(Func<ImAWorldMapUiComponent> mapBuilder)
        {
            this.mapBuilder = mapBuilder;
        }
        #endregion

        public ImAWorldMapUiComponent AssembleNewMap()
        {
            return mapBuilder!=null?mapBuilder.Invoke():(ImAWorldMapUiComponent)null;
        }
    }
}
