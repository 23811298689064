using H.Skeepy.Core.Common;
using System;
using System.Collections.Concurrent;
using System.Collections.Generic;
using System.Linq;
using System.Text;
using System.Threading.Tasks;

namespace H.Skeepy.Core.Operations.Sync.Concrete
{
    public class InMemorySyncRegistry : ImASyncRegistry
    {
        private readonly ConcurrentDictionary<string, ConcurrentDictionary<string, SyncStatus>> syncRegistry = new ConcurrentDictionary<string, ConcurrentDictionary<string, SyncStatus>>();

        public Task SetStatusFor(string entityType, string entityIdentifier, SyncStatus syncStatus)
        {
            EnsureRegistrySetForType(entityType);

            syncRegistry[entityType].AddOrUpdate(entityIdentifier, syncStatus, (Func<string,SyncStatus,SyncStatus>)((x, y) => syncStatus));

            return Task.FromResult<bool>(true);
        }

        public Task<SyncStatus> StatusFor(string entityType, string entityIdentifier)
        {
            EnsureRegistrySetForType(entityType);

            if (!syncRegistry[entityType].ContainsKey(entityIdentifier))
                return Task.FromResult<SyncStatus>(SyncStatus.Inexistent);

            SyncStatus syncStatus = SyncStatus.NotSynced;
            syncRegistry[entityType].TryGetValue(entityIdentifier, out syncStatus);
            return Task.FromResult<SyncStatus>(syncStatus);
        }

        public Task<string[]> GetEntitiesToSync(string entityType, int maxBatchSize = 100)
        {
            string[] result = new string[0];

            if (!syncRegistry.ContainsKey(entityType))
                return Task.FromResult<string[]>(result);

            result = System.Linq.Enumerable.Where<KeyValuePair<string,SyncStatus>>(syncRegistry[entityType]
,(Func<KeyValuePair<string,SyncStatus>,bool>)(x => x.Value < SyncStatus.Synced))
                .Select<string>((Func<KeyValuePair<string,SyncStatus>,string>)(x => x.Key))
                .ToArray();

            return Task.FromResult<string[]>(result);
        }

        private void EnsureRegistrySetForType(string entityType)
        {
            if (syncRegistry.ContainsKey(entityType))
                return;
System.Collections.Generic.CollectionExtensions.TryAdd<string,ConcurrentDictionary<string,SyncStatus>>(            syncRegistry,entityType, new ConcurrentDictionary<string, SyncStatus>());
        }

        private static string Key<T>()
        {
            return typeof(T).FullName;
        }
    }
}
