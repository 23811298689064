using H.Skeepy.Core.Model;
using H.Skeepy.Core.Model.Notifications;
using H.Skeepy.Core.Model.Notifications.Templates;
using System.Threading.Tasks;

namespace H.Skeepy.Core.Operations.UseCases.Concrete
{
    public class NotificationUseCase : ImANotificationUseCase
    {
        public Task<NotificationPackage> AssembleTemplatedEmailNotification(TemplateMessageInfo templateMessageInfo, params NotificationEndpoint[] to)
        {
            //TODO: Implement this on client side
            throw new System.NotImplementedException();
        }

        public Task<OperationResult> SendNotification(NotificationPackage notification)
        {
            //TODO: Implement this on client side
            return Task.FromResult<OperationResult>(OperationResult.Win());
        }
    }
}