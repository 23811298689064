using H.Skeepy.Core.Infrastructure;
using System;
using System.Threading.Tasks;

namespace H.Skeepy.Core.Operations.Sync.Concrete
{
    public class HttpSyncReceiver : ImASkeepyOperationContract, ImASyncReceiver
    {
        #region Construct
        private Func<SyncRequest, Task> syncRequestStoreDelegate;

        private ImASyncRegistry syncRegistry;

        public void ReferDependencies(ImADependencyPoolContainer dependencyPoolContainer)
        {
            syncRegistry = syncRegistry ?? dependencyPoolContainer.Resolve<ImASyncRegistry>();
        }
        #endregion

        public SyncNode NodeInfo { get; private set; }

        public async Task<SyncResponse> ProcessSyncRequest(SyncRequest request)
        {
            SyncResponse response = new SyncResponse
            {
                PayloadIdentifier = request.PayloadIdentifier,
                PayloadType = request.PayloadType,
            };

            try
            {
                await (syncRequestStoreDelegate!=null?syncRequestStoreDelegate.Invoke(request):(Task)null);
                response.HappenedAt = Rolex.Time;
            }
            catch (Exception ex)
            {
                response.SyncStatus = request.SyncStatus;
                response.ErrorMessage = ex.Message;
                return response;
            }

            response.SyncStatus = SyncSuccessStatusFor(request.SyncStatus);
            return response;
        }

        private SyncStatus SyncSuccessStatusFor(SyncStatus syncStatus)
        {
            switch (syncStatus)
            {
                case SyncStatus.Inexistent:
                case SyncStatus.NotSynced:
                    return SyncStatus.Synced;
                case SyncStatus.DeletedAndNotSynced:
                    return SyncStatus.DeletedAndSynced;
            }

            return SyncStatus.Synced;
        }

        public void Use(Func<SyncRequest, Task> syncRequestStoreDelegate)
        {
            this.syncRequestStoreDelegate = syncRequestStoreDelegate;
        }

        public void Use(SyncNode nodeInfo)
        {
            NodeInfo = nodeInfo;
        }
    }
}
