using Bridge;
using Bridge.React;
using H.Skeepy.Click.Web.UI.Components.UI.Layout;
using H.Skeepy.Click.Web.UI.Components.UI.Styles;
using System;
using System.Linq;

namespace H.Skeepy.Click.Web.UI.Components.UI.Controls
{
    public class ListItem : ComponentBase<ListItem.Props, ListItem.State>
    {
        public ListItem(Props props, params Union<ReactElement, string>[] children) : base(props, children) { }
        public ListItem(params Union<ReactElement, string>[] children) : this(new Props { }, children) { }

        public override ReactElement Render()
        {
            ReactStyle reactStyle = new ReactStyle
            {
                MinWidth = 200,
                Width = props.Width,
                MinHeight = 80,
                MarginTop = style.SizingUnitInPixels,
                BorderBottom = "solid 1px",
                BorderBottomColor = style.Colors.Complementary.Lighter(2).Hex,
            }.FlexNode();

            if (props.OnClick != null)
                reactStyle.Cursor = Bridge.Html5.Cursor.Pointer;

            ReactElement icon = RenderIcon();

            return
                DOM.Div
                (
                    new Attributes
                    {
                        Style = reactStyle,
                        OnClick = x => global::Bridge.Script.ToTemp("key1",props.OnClick)!=null?global::Bridge.Script.FromLambda(()=>global::Bridge.Script.FromTemp<Action<object>>("key1").Invoke(props.Payload)):null,
                    },
                    icon == null ? null : DOM.Div
                    (
                        new Attributes
                        {
                            Style = new ReactStyle
                            {
                                BackgroundColor = style.Colors.Primary.Color.Hex,
                            }.FlexNode(isVerticalFlow: false, size: style.SizingUnitInPixels * 3)
                        },
                        new CenteredContent
                        (
                            RenderIcon()
                        )
                    ),
                    DOM.Div
                    (
                        new Attributes
                        {
                            Style = new ReactStyle
                            {
                                PaddingLeft = style.SizingUnitInPixels / 2,
                            }.FlexNode(isVerticalFlow: true)
                        },
                        RenderContent()
                    ),

                    props.OnMoreClick == null ? null :
                    DOM.Div
                    (
                        new Attributes
                        {
                            OnClick = x => { x.StopPropagation(); props.OnMoreClick.Invoke(props.Payload); },
                            Style = new ReactStyle
                            {
                                BackgroundColor = style.Colors.Complementary.Color.Hex,
                                Cursor = Bridge.Html5.Cursor.Pointer,
                            }.FlexNode(isVerticalFlow: false, size: style.SizingUnitInPixels * 3)
                        },
                        new CenteredContent
                        (
                            RenderMoreOperation()
                        )
                    )
                );
        }

        private ReactElement RenderMoreOperation()
        {
            return
                DOM.Div(new Attributes
                {
                    Style = new ReactStyle
                    {
                        FontSize = style.Typography.FontSizeLarger.PointsCss,
                        Color = style.BackgroundColor.Hex,
                    },
                }, "...");
        }

        private ReactElement RenderContent()
        {
            bool hasChildren = (global::Bridge.Script.ToTemp("key2",Children)!=null?global::Bridge.Script.FromTemp<Union<ReactElement, string>[]>("key2").Any():(bool?)null) ?? false;
            bool hasTitle = !string.IsNullOrWhiteSpace(props.Title);

            if (!hasTitle && !hasChildren)
                return RenderEmptyContent();

            if (hasTitle && !hasChildren)
                return RenderOnlyTitleContent();

            if (!hasTitle && hasChildren)
                return RenderOnlyChildrenContent();

            return RenderTitleAndChildren();
        }

        private ReactElement RenderTitleAndChildren()
        {
            return
                DOM.Div
                (
                    new Attributes { Style = new ReactStyle { }.FlexNode(isVerticalFlow: true) },
                    DOM.Div
                    (
                        new Attributes
                        {
                            Style = new ReactStyle
                            {
                                FontSize = style.Typography.FontSizeLarge.PointsCss,
                                JustifyContent = Bridge.Html5.JustifyContent.Center,
                            }.FlexNode(isVerticalFlow: true, size:30)
                        },
                        props.Title
                    ),
                    DOM.Div
                    (
                        new Attributes
                        {
                            Style = new ReactStyle
                            {
                                FontSize = style.Typography.FontSizeSmall.PointsCss,
                            }.FlexNode(isVerticalFlow: true)
                        },
                        new ScrollableContent(Children)
                    )
                );
        }

        private ReactElement RenderOnlyTitleContent()
        {
            return
                DOM.Div
                (
                    new Attributes
                    {
                        Style = new ReactStyle
                        {
                            JustifyContent = Bridge.Html5.JustifyContent.Center,
                            FontSize = style.Typography.FontSizeLarge.PointsCss,
                        }.FlexNode(isVerticalFlow: true)
                    },
                    props.Title
                );
        }

        private ReactElement RenderOnlyChildrenContent()
        {
            return
                DOM.Div
                (
                    new Attributes
                    {
                        Style = new ReactStyle
                        {
                            FontSize = style.Typography.FontSizeSmall.PointsCss,
                        }.FlexNode(isVerticalFlow: true)
                    },
                    new ScrollableContent(Children)
                );
        }

        private ReactElement RenderEmptyContent()
        {
            return
                DOM.Div
                (
                    new Attributes
                    {
                        Style = new ReactStyle
                        {
                            FontSize = style.Typography.FontSizeSmall.PointsCss,
                            Opacity = "0.2",
                        }.FlexNode()
                    },
                    new CenteredContent(DOM.Em("Nothing to show here, so sorry...  :("))
                );
        }

        private ReactElement RenderIcon()
        {
            if (string.IsNullOrWhiteSpace(props.Title) && string.IsNullOrWhiteSpace(props.IconUrl))
            {
                return null;
            }

            if (string.IsNullOrWhiteSpace(props.IconUrl))
            {
                string iconText
                    = props.Title.Length >= 2
                    ? props.Title.Substring(0, 1).ToUpper() + props.Title.Substring(1, 1).ToLower()
                    : props.Title.ToUpper()
                    ;

                return DOM.Div(new Attributes
                {
                    Style = new ReactStyle
                    {
                        FontSize = style.Typography.FontSizeLarger.PointsCss,
                        Color = style.BackgroundColor.Hex,
                    }
                }, iconText);
            }

            return new Icon(new Icon.Props { Url = props.IconUrl });
        }

        public class State { }
        public class Props
        {
            public object Payload { get; set; }
            public Union<string, int> Width { get; set; }
            public string IconUrl { get; set; }
            public string Title { get; set; }
            public Action<object> OnClick { get; set; }
            public Action<object> OnMoreClick { get; set; }

        
private Union<string, int> __Property__Initializer__Width=400;private string __Property__Initializer__IconUrl=null;private string __Property__Initializer__Title=null;private Action<object> __Property__Initializer__OnClick=null;private Action<object> __Property__Initializer__OnMoreClick=null;}
    }
}
