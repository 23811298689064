using H.Skeepy.Core.Common;
using H.Skeepy.Core.Infrastructure;
using H.Skeepy.Core.Model;
using H.Skeepy.Core.Operations.Storage;
using Retyped;
using System;
using System.Linq;
using System.Threading.Tasks;

namespace H.Skeepy.Click.Web.UI.Components.LocalStorage
{
    public class PinGroupStorageService : SyncedStorageServiceBase<PinGroup, Guid>, ImAPinGroupStorageService
    {
        #region Construct
        public override void ReferDependencies(ImADependencyPoolContainer dependencyPoolContainer)
        {
            base.ReferDependencies(dependencyPoolContainer);
            storageSyncer.Use((Func<Guid,Task<PinGroup>>)Load);
            this.Use((Func<object,PinGroup,PinGroup>)FixIdioticBridgeJsonParser);
        }
#endregion
protected override Guid GetIdFor(PinGroup item)
{
    return item.ID;
}
        public Task<long> Count(PinGroupFilterRequest request)
        {
            return base.Count(request);
        }

        public Task Delete(Guid id)
        {
            return base.Delete(storage.PinGroups, id);
        }

        public Task<long> DeleteMany(PinGroupFilterRequest request)
        {
            return base.DeleteMany(request);
        }

        public Task<PinGroup> Load(Guid id)
        {
            return base.Load(storage.PinGroups, id);
        }

        public Task Save(PinGroup pinGroup)
        {
            return base.Save(storage.PinGroups, pinGroup);
        }

        public Task<PinGroup[]> Search(PinGroupFilterRequest request)
        {
            return base.Search(request);
        }

        protected override dexie.Dexie.Collection<object, object> ApplyFilter(object filter)
        {
            PinGroupFilterRequest filterRequest = filter.As<PinGroupFilterRequest>();

            dexie.Dexie.Collection<object, object> filteredCollection;

            if (filterRequest == null)
                return storage.PinGroups.toCollection();

            if (filterRequest.IDs != null)
            {
                filteredCollection = storage.PinGroups.where("ID")
                    .anyOfIgnoreCase(System.Linq.Enumerable.Select<Guid,string>(filterRequest.IDs,(Func<Guid,string>)(x => x.ToString())).ToArray())
                    ;
            }
            else
            {
                filteredCollection = storage.PinGroups.toCollection();
            }

            if (filterRequest.IsSealed != null)
            {
                filteredCollection = filteredCollection.and((dexie.Dexie.Collection<object,object>.andFn)(x => ((bool)x["IsSealed"]) == filterRequest.IsSealed.Value));
            }

            if (filterRequest.ContainingPinIDs != null && System.Linq.Enumerable.Any<Guid>(filterRequest.ContainingPinIDs))
            {
                filteredCollection = filteredCollection.and((dexie.Dexie.Collection<object,object>.andFn)(x => System.Linq.Enumerable.Any<Guid>(((Guid[])x["Pins"]),(Func<Guid,bool>)(pinID => H.Skeepy.Core.Common.DataExtensions.In<Guid>(pinID,filterRequest.ContainingPinIDs)))));
            }

            if (!string.IsNullOrWhiteSpace(filterRequest.SearchTerm))
            {
                filteredCollection = filteredCollection.and((dexie.Dexie.Collection<object,object>.andFn)(x => ((string)x["Name"]).ToLower().Contains(filterRequest.SearchTerm.ToLower()) || ((string)x["Description"]).ToLower().Contains(filterRequest.SearchTerm.ToLower())));
            }

            if (filterRequest.CreatedFromInclusive != null)
            {
                filteredCollection = filteredCollection.and((dexie.Dexie.Collection<object,object>.andFn)(x => long.Parse((string)x["CreatedAtTicks"]) >= filterRequest.CreatedFromInclusive.Value.Ticks));
            }

            if (filterRequest.CreatedToInclusive != null)
            {
                filteredCollection = filteredCollection.and((dexie.Dexie.Collection<object,object>.andFn)(x => long.Parse((string)x["CreatedAtTicks"]) <= filterRequest.CreatedToInclusive.Value.Ticks));
            }

            return filteredCollection;
        }

        private PinGroup FixIdioticBridgeJsonParser(object json, PinGroup idioticParseResult)
        {
            if (json == null)
                return null;

            idioticParseResult.Pins = System.Linq.Enumerable.Select<string,Guid>(((string[])json["Pins"]),(Func<string,Guid>)Guid.Parse).ToArray();
            return idioticParseResult;
        }
    }
}
