using System;
using System.Threading.Tasks;

namespace H.Skeepy.Core.Common
{
    public static class ExecutionUtilities
    {
        public static void TryAFewTimesOrFailWithGrace(Action doThis, int numberOfTimes = 1, Action<Exception> onFail = null, Action<Exception> onRetry = null, int millisecondsToSleepBetweenRetries = 500)
        {
            if (numberOfTimes <= 0)
                return;

            try
            {
                doThis.Invoke();
            }
            catch (Exception ex)
            {
                numberOfTimes--;
                if (numberOfTimes == 0)
                {
                    try { onFail!=null?global::Bridge.Script.FromLambda(()=>onFail.Invoke(ex)):null; } catch (Exception) { }
                    return;
                }
                else
                    try { onRetry!=null?global::Bridge.Script.FromLambda(()=>onRetry.Invoke(ex)):null; } catch (Exception) { }

                System.Threading.Thread.Sleep(millisecondsToSleepBetweenRetries);

                TryAFewTimesOrFailWithGrace((Action)doThis, numberOfTimes, (Action<Exception>)onFail, (Action<Exception>)onRetry, (int)(millisecondsToSleepBetweenRetries * 1.3));
            }
        }

        public static async Task TryAFewTimesOrFailWithGrace(Func<Task> doThis, int numberOfTimes = 1, Action<Exception> onFail = null, Action<Exception> onRetry = null, int millisecondsToSleepBetweenRetries = 500)
        {
            if (numberOfTimes <= 0)
                return;

            try
            {
                await doThis.Invoke();
            }
            catch (Exception ex)
            {
                numberOfTimes--;
                if (numberOfTimes == 0)
                {
                    try { onFail!=null?global::Bridge.Script.FromLambda(()=>onFail.Invoke(ex)):null; } catch (Exception) { }
                    return;
                }
                else
                    try { onRetry!=null?global::Bridge.Script.FromLambda(()=>onRetry.Invoke(ex)):null; } catch (Exception) { }

                System.Threading.Thread.Sleep(millisecondsToSleepBetweenRetries);

                await TryAFewTimesOrFailWithGrace((Func<Task>)doThis, numberOfTimes, (Action<Exception>)onFail, (Action<Exception>)onRetry, (int)(millisecondsToSleepBetweenRetries * 1.3));
            }

        }

        public static async Task TryAFewTimesOrFailWithGrace(Func<Task> doThis, int numberOfTimes = 1, Func<Exception, Task> onFail = null, Func<Exception, Task> onRetry = null, int millisecondsToSleepBetweenRetries = 500)
        {
            if (numberOfTimes <= 0)
                return;

            try
            {
                await doThis.Invoke();
            }
            catch (Exception ex)
            {
                numberOfTimes--;
                if (numberOfTimes == 0)
                {
                    try { await (onFail!=null?onFail.Invoke(ex):(Task)null); } catch (Exception) { }
                    return;
                }
                else
                    try { await (onRetry!=null?onRetry.Invoke(ex):(Task)null); } catch (Exception) { }

                System.Threading.Thread.Sleep(millisecondsToSleepBetweenRetries);

                await TryAFewTimesOrFailWithGrace((Func<Task>)doThis, numberOfTimes, (Func<Exception,Task>)onFail, (Func<Exception,Task>)onRetry, (int)(millisecondsToSleepBetweenRetries * 1.3));
            }

        }
public static void TryOrFailWithGrace(this Action doThis, int numberOfTimes = 1, Action<Exception> onFail = null, Action<Exception> onRetry = null, int millisecondsToSleepBetweenRetries = 500)
{
    TryAFewTimesOrFailWithGrace((Action)doThis, numberOfTimes, (Action<Exception>)onFail, (Action<Exception>)onRetry, millisecondsToSleepBetweenRetries);
}public static Task TryOrFailWithGrace(this Func<Task> doThis, int numberOfTimes = 1, Action<Exception> onFail = null, Action<Exception> onRetry = null, int millisecondsToSleepBetweenRetries = 500)
{
    return TryAFewTimesOrFailWithGrace((Func<Task>)doThis, numberOfTimes, (Action<Exception>)onFail, (Action<Exception>)onRetry, millisecondsToSleepBetweenRetries);
}public static Task TryOrFailWithGrace(this Func<Task> doThis, int numberOfTimes = 1, Func<Exception, Task> onFail = null, Func<Exception, Task> onRetry = null, int millisecondsToSleepBetweenRetries = 500)
{
    return TryAFewTimesOrFailWithGrace((Func<Task>)doThis, numberOfTimes, (Func<Exception,Task>)onFail, (Func<Exception,Task>)onRetry, millisecondsToSleepBetweenRetries);
}    }
}
