using System;
using Bridge;
using Bridge.React;
using H.Skeepy.Click.Web.UI.Components.UI.Styles;

namespace H.Skeepy.Click.Web.UI.Components.UI.Layout
{
    /*
     
        Breakpoint	Range
        small	320px - 479px
        medium	480px - 639px
        large	640px - 1023px
        extra large	1024px - 1365px
        exta extra large	1366px - 1919px
        extra extra extra large	1920px and up
         
         */

    public class FabricUiGridColumn : ComponentBase<FabricUiGridColumn.Props, FabricUiGridColumn.State>
    {
        private const string SizeSmall = "sm";
        private const string SizeMedium = "md";
        private const string SizeLarge = "lg";
        private const string SizeXLarge = "xl";
        private const string SizeXXLarge = "xxl";
        private const string SizeXXXLarge = "xxxl";

        public FabricUiGridColumn(Props props, params Union<ReactElement, string>[] children) : base(props, children) { }
        public FabricUiGridColumn(params Union<ReactElement, string>[] children) : this(new Props { }, children) { }

        public override ReactElement Render()
        {
            ReactStyle reactStyle = new ReactStyle {  };
            if (props.HasGreedyHeight)
            {
                reactStyle = reactStyle.FlexNode(isVerticalFlow: true);
            }

            return
                DOM.Div(
                    new Attributes
                    {
                        ClassName = string.Format("ms-Grid-col{0}{1}{2}{3}",SizeToClassNames(),PushToClassNames(),PullToClassNames(),HiddensToClassNames()),
                        Style = reactStyle,
                    },

                    Children

                );
        }

        private string HiddensToClassNames()
        {
            string result = string.Empty;

            if(props.HiddenSmall)
                result += string.Format(" ms-hiddenSm");

            if (props.HiddenMedium)
                result += string.Format(" ms-hiddenMd");
            if (props.HiddenMediumAndLess)
                result += string.Format(" ms-hiddenMdDown");
            if (props.HiddenMediumAndMore)
                result += string.Format(" ms-hiddenMdUp");

            if (props.HiddenLarge)
                result += string.Format(" ms-hiddenLg");
            if (props.HiddenLargeAndLess)
                result += string.Format(" ms-hiddenLgDown");
            if (props.HiddenLargeAndMore)
                result += string.Format(" ms-hiddenLgUp");

            if (props.HiddenXLarge)
                result += string.Format(" ms-hiddenXl");
            if (props.HiddenXLargeAndLess)
                result += string.Format(" ms-hiddenXlDown");
            if (props.HiddenXLargeAndMore)
                result += string.Format(" ms-hiddenXlUp");

            if (props.HiddenXXLarge)
                result += string.Format(" ms-hiddenXxl");
            if (props.HiddenXXLargeAndLess)
                result += string.Format(" ms-hiddenXxlDown");
            if (props.HiddenXXLargeAndMore)
                result += string.Format(" ms-hiddenXxlUp");

            if (props.HiddenXXXLarge)
                result += string.Format(" ms-hiddenXxxl");

            return result;
        }

        private string PushToClassNames()
        {
            string result = string.Empty;

            if (props.Push != null)
                result += string.Format(" ms-{0}Push{1}",SizeSmall,(int)props.Push.Value);
            if (props.PushMedium != null)
                result += string.Format(" ms-{0}Push{1}",SizeMedium,(int)props.PushMedium.Value);
            if (props.PushLarge != null)
                result += string.Format(" ms-{0}Push{1}",SizeLarge,(int)props.PushLarge.Value);
            if (props.PushXLarge != null)
                result += string.Format(" ms-{0}Push{1}",SizeXLarge,(int)props.PushXLarge.Value);
            if (props.PushXXLarge != null)
                result += string.Format(" ms-{0}Push{1}",SizeXXLarge,(int)props.PushXXLarge.Value);
            if (props.PushXXXLarge != null)
                result += string.Format(" ms-{0}Push{1}",SizeXXXLarge,(int)props.PushXXXLarge.Value);

            return result;
        }

        private string PullToClassNames()
        {
            string result = string.Empty;

            if (props.Pull != null)
                result += string.Format(" ms-{0}Push{1}",SizeSmall,(int)props.Pull.Value);
            if (props.PullMedium != null)
                result += string.Format(" ms-{0}Pull{1}",SizeMedium,(int)props.PullMedium.Value);
            if (props.PullLarge != null)
                result += string.Format(" ms-{0}Pull{1}",SizeLarge,(int)props.PullLarge.Value);
            if (props.PullXLarge != null)
                result += string.Format(" ms-{0}Pull{1}",SizeXLarge,(int)props.PullXLarge.Value);
            if (props.PullXXLarge != null)
                result += string.Format(" ms-{0}Pull{1}",SizeXXLarge,(int)props.PullXXLarge.Value);
            if (props.PullXXXLarge != null)
                result += string.Format(" ms-{0}Pull{1}",SizeXXXLarge,(int)props.PullXXXLarge.Value);

            return result;
        }

        private string SizeToClassNames()
        {
            string result = string.Format(" ms-{0}{1}",SizeSmall,(int)props.Size);

            if (props.SizeMedium != null)
                result += string.Format(" ms-{0}{1}",SizeMedium,(int)props.SizeMedium.Value);
            if (props.SizeLarge != null)
                result += string.Format(" ms-{0}{1}",SizeLarge,(int)props.SizeLarge.Value);
            if (props.SizeXLarge != null)
                result += string.Format(" ms-{0}{1}",SizeXLarge,(int)props.SizeXLarge.Value);
            if (props.SizeXXLarge != null)
                result += string.Format(" ms-{0}{1}",SizeXXLarge,(int)props.SizeXXLarge.Value);
            if (props.SizeXXXLarge != null)
                result += string.Format(" ms-{0}{1}",SizeXXXLarge,(int)props.SizeXXXLarge.Value);

            return result;
        }

        public class State { }
        public class Props
        {
            public FabricUiGridColumnSize Size { get; set; }
            public FabricUiGridColumnSize? SizeMedium { get; set; }
            public FabricUiGridColumnSize? SizeLarge { get; set; }
            public FabricUiGridColumnSize? SizeXLarge { get; set; }
            public FabricUiGridColumnSize? SizeXXLarge { get; set; }
            public FabricUiGridColumnSize? SizeXXXLarge { get; set; }

            public FabricUiGridColumnSize? Push { get; set; }
            public FabricUiGridColumnSize? PushMedium { get; set; }
            public FabricUiGridColumnSize? PushLarge { get; set; }
            public FabricUiGridColumnSize? PushXLarge { get; set; }
            public FabricUiGridColumnSize? PushXXLarge { get; set; }
            public FabricUiGridColumnSize? PushXXXLarge { get; set; }

            public FabricUiGridColumnSize? Pull { get; set; }
            public FabricUiGridColumnSize? PullMedium { get; set; }
            public FabricUiGridColumnSize? PullLarge { get; set; }
            public FabricUiGridColumnSize? PullXLarge { get; set; }
            public FabricUiGridColumnSize? PullXXLarge { get; set; }
            public FabricUiGridColumnSize? PullXXXLarge { get; set; }

            public bool HiddenSmall { get; set; }

            public bool HiddenMedium { get; set; }
            public bool HiddenMediumAndLess { get; set; }
            public bool HiddenMediumAndMore { get; set; }

            public bool HiddenLarge { get; set; }
            public bool HiddenLargeAndLess { get; set; }
            public bool HiddenLargeAndMore { get; set; }

            public bool HiddenXLarge { get; set; }
            public bool HiddenXLargeAndLess { get; set; }
            public bool HiddenXLargeAndMore { get; set; }

            public bool HiddenXXLarge { get; set; }
            public bool HiddenXXLargeAndLess { get; set; }
            public bool HiddenXXLargeAndMore { get; set; }

            public bool HiddenXXXLarge { get; set; }

            public bool HasGreedyHeight { get; set; }

        
private FabricUiGridColumnSize __Property__Initializer__Size=FabricUiGridColumnSize.X1;private bool __Property__Initializer__HiddenSmall=false;private bool __Property__Initializer__HiddenMedium=false;private bool __Property__Initializer__HiddenMediumAndLess=false;private bool __Property__Initializer__HiddenMediumAndMore=false;private bool __Property__Initializer__HiddenLarge=false;private bool __Property__Initializer__HiddenLargeAndLess=false;private bool __Property__Initializer__HiddenLargeAndMore=false;private bool __Property__Initializer__HiddenXLarge=false;private bool __Property__Initializer__HiddenXLargeAndLess=false;private bool __Property__Initializer__HiddenXLargeAndMore=false;private bool __Property__Initializer__HiddenXXLarge=false;private bool __Property__Initializer__HiddenXXLargeAndLess=false;private bool __Property__Initializer__HiddenXXLargeAndMore=false;private bool __Property__Initializer__HiddenXXXLarge=false;private bool __Property__Initializer__HasGreedyHeight=false;}
    }

    public enum FabricUiGridColumnSize
    {
        X1 = 1,
        X2 = 2,
        X3 = 3,
        X4 = 4,
        X5 = 5,
        X6 = 6,
        X7 = 7,
        X8 = 8,
        X9 = 9,
        X10 = 10,
        X11 = 11,
        X12 = 12,
    }
}
