using Bridge.React;
using H.Skeepy.Core.Operations.UI.ViewState;

namespace H.Skeepy.Click.Web.UI.Components.UI.Elements
{
    public class BrandingHeader : ComponentBase<BrandingHeader.Props, BrandingHeader.State>
    {
        public BrandingHeader() : base(new Props(), null) { }

        public override ReactElement Render()
        {
            return
                DOM.Div(
                    new Attributes
                    {
                        Style = new ReactStyle
                        {
                            Width = "100%",
                            Height = 50,
                            BackgroundColor = style.Colors.Primary.Color.Hex,
                        },
                    },
                    new SkeepyLogo(new SkeepyLogo.Props
                    {
                        Height = 40,
                        Width = 60,
                        Margin = "5px 15px",
                        TintColor = style.BackgroundColor,
                        OnClick = () => navi.Go<WelcomeViewState>(),
                    })
                );
        }

        public class State { }
        public class Props { }
    }
}
