using System;

namespace H.Skeepy.Click.Web.UI.Common
{
    public class DateTimeOperations
    {
        public static DateTime UnixEpoch = new DateTime(1970, 1, 1, 0, 0, 0, 0, DateTimeKind.Utc);

        public static DateTime FromUnixTimestamp(long miliseconds)
        {
            return DateTime.Parse(new Bridge.Html5.Date(miliseconds).ToString()).ToLocalTime();
        }

        public static long ToUnixTimestamp(DateTime date)
        {
            TimeSpan t = (date.ToUniversalTime() - UnixEpoch);
            return (long)(t.TotalMilliseconds + 0.5);
        }
    }
}
