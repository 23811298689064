using Bridge.React;
using H.Skeepy.Click.Web.UI.Components.UI.Elements;
using H.Skeepy.Click.Web.UI.Components.UI.Layout;
using H.Skeepy.Click.Web.UI.Components.UI.Styles;

namespace H.Skeepy.Click.Web.UI.Components.UI.Controls.UserFeedback
{
    public class Alert : ComponentBase<UserFeedbackProps, Alert.State>
    {
        public Alert(UserFeedbackProps props) : base(props, null) { }

        public override ReactElement Render()
        {
            return
                new FormLayout
                (
                    new FormLayout.Props { LayoutMode = FormLayoutMode.OnePerRow },

                    new Elevation(new Elevation.Props { Depth = ElevationDepthLevel.Hihghest, StyleDecorator = x => x.FlexNode(isVerticalFlow: true) },

                        DOM.Div(
                            new Attributes
                            {
                                Style = new ReactStyle { }.FlexNode(isVerticalFlow: true),
                            },

                            new CenteredContent(new Title(props.UserOptionsContext.Title))

                        ),

                        string.IsNullOrWhiteSpace(props.UserOptionsContext.DescriptionHtml) ? null : DOM.Div(
                            new Attributes
                            {
                                Style = new ReactStyle { MarginTop = style.SizingUnitInPixels }.FlexNode(isVerticalFlow: true),
                            },

                            new CenteredContent(
                                DOM.Span(new Attributes
                                {
                                    DangerouslySetInnerHTML = new RawHtml { Html = props.UserOptionsContext.DescriptionHtml },
                                })
                            )

                        ),

                        DOM.Div(
                            new Attributes
                            {
                                Style = new ReactStyle { MarginTop = style.SizingUnitInPixels }.FlexNode(isVerticalFlow: true),
                            },

                            new CenteredContent(
                                new Button(
                                    new Button.Props
                                    {
                                        Width = style.SizingUnitInPixels * 7,
                                        OnClick = () => props.UserOptionsContext.ConfirmSelection(),
                                    },
                                    "OK"
                                )
                            )

                        )

                    )
                );
        }


        public class State { }
    }
}
