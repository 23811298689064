using H.Skeepy.Core.Infrastructure;
using H.Skeepy.Core.Model;
using H.Skeepy.Core.Model.Display;
using System;
using System.Linq;
using System.Threading.Tasks;

namespace H.Skeepy.Core.Operations.UseCases.Concrete
{
    public class PinGroupViewerUseCase : ImAPinGroupViewerUseCase, ImASkeepyOperationContract
    {
        #region Construct
        Storage.ImAPinStorageService pinStorageService;
        Storage.ImAPinGroupStorageService pinGroupStorageService;
        Storage.ImAPinGroupSettingsStorageService pinGroupSettingsStorageService;
        public void ReferDependencies(ImADependencyPoolContainer dependencyPoolContainer)
        {
            pinStorageService = pinStorageService ?? dependencyPoolContainer.Resolve<Storage.ImAPinStorageService>();
            pinGroupStorageService = pinGroupStorageService ?? dependencyPoolContainer.Resolve<Storage.ImAPinGroupStorageService>();
            pinGroupSettingsStorageService = pinGroupSettingsStorageService ?? dependencyPoolContainer.Resolve<Storage.ImAPinGroupSettingsStorageService>();
        }
        #endregion

        public async Task<DetailedPinGroupDisplay> Load(Guid pinGroupID)
        {
            PinGroup pinGroup = await pinGroupStorageService.Load(pinGroupID);

            if (pinGroup == null)
                return null;

            PinGroupSettings pinGroupSettings = await pinGroupSettingsStorageService.Load(pinGroupID) ?? pinGroup.GetDefaultSettings();

            Pin[] pins = await pinStorageService.Search(new Storage.PinFilterRequest { IDs = pinGroup.Pins });

            PinDisplay[] pinDisplays = System.Linq.Enumerable.OrderBy<Pin,DateTime>(pins
,(Func<Pin,DateTime>)(x => x.HappenedAt))
                .Select<PinDisplay>((Func<Pin,int,PinDisplay>)((p, i) => new PinDisplay(p, i)))
                .ToArray();

            DetailedPinGroupDisplay result
                = new DetailedPinGroupDisplay(pinGroup, pinGroupSettings, pinDisplays);

            return result;
        }
    }
}
