using H.Skeepy.Core.Common;
using System;
using System.Linq;
using System.Text;

namespace H.Skeepy.Core.Model
{
    public class OperationResult
    {
        private OperationResult(bool isSuccessful = true, int? reasonCode = null, string reason = null, params string[] reasonAddendum)
        {
            IsSuccessful = isSuccessful;
            ReasonCode = reasonCode;
            Reason = reason;
            ReasonAddendum = reasonAddendum ?? new string[0];
        }

        private OperationResult(bool isSuccessful = true, string reason = null, params string[] reasonAddendum)
            : this(isSuccessful, null, reason, reasonAddendum) { }

        public bool IsSuccessful { get; private set; }

        public int? ReasonCode { get; private set; }

        public string Reason { get; set; }

        public string[] ReasonAddendum { get; set; }

        public OperationResult ThrowIfUnsuccessful()
        {
            if (!IsSuccessful)
                throw new InvalidOperationException(ToString());

            return this;
        }

        public OperationResult WithCode(int reasonCode)
        {
            ReasonCode = reasonCode;
            return this;
        }

        public override string ToString()
        {
            StringBuilder printer = new StringBuilder(IsSuccessful ? "SUCCESS" : "FAILURE");

            if (ReasonCode != null)
            {
                printer.Append(string.Format(" (Code {0})",ReasonCode));
            }

            if (!string.IsNullOrWhiteSpace(Reason))
                printer.Append(string.Format(": {0}",Reason));

            if ((global::Bridge.Script.ToTemp("key1",ReasonAddendum)!=null?global::Bridge.Script.FromTemp<string[]>("key1").Any():(bool?)null) ?? false)
                printer.Append(string.Format(" ({0})",string.Join(", ", ReasonAddendum)));

            return printer.ToString();
        }
public static OperationResult Win()
{
    return new OperationResult(true, reason: null, reasonAddendum: null);
}public static OperationResult Win(string reason)
{
    return new OperationResult(true, reason);
}public static OperationResult Win(string reason, params string[] reasonAddendum)
{
    return new OperationResult(true, reason, reasonAddendum);
}public static OperationResult Fail(string reason)
{
    return new OperationResult(false, reason);
}public static OperationResult Fail(string reason, params string[] reasonAddendum)
{
    return new OperationResult(false, reason, reasonAddendum);
}public static OperationResult Fail(Exception exception)
{
    return new OperationResult(false, exception.Message, System.Linq.Enumerable.Select<Exception,string>(exception.Flatten(),(Func<Exception,string>)(x => x.Print())).ToArray());
}
    
private string[] __Property__Initializer__ReasonAddendum=new string[0];}
}
