using H.Skeepy.Click.Web.UI.Pages;
using H.Skeepy.Core.Operations.UI.Navigation;
using ProductiveRage.ReactRouting;

namespace H.Skeepy.Click.Web.UI.Components.Navigation
{
    public class AppPageDispatcherAction<TPage> : INavigationDispatcherAction
        where TPage : ImAnAppPage
    {
        public UiNavigationParams NavigationParams { get; private set; }

        public AppPageDispatcherAction(UiNavigationParams navigationParams) : base() { NavigationParams = navigationParams; }

        public AppPageDispatcherAction() : this(UiNavigationParams.None) { }
    }
}
