using H.Skeepy.Core.Common;
using System;
using System.Text;

namespace H.Skeepy.Core.Model
{
    public class SettingsPool
    {
        Iota[] rawSettings = new Iota[0];
        string rawSettingsIDsHash = null;

        public virtual string ID { get; set; }

        public Iota[] RawSettings
{
    get
    {
        return rawSettings;
    }

    
    set
    {
        UpdateRawSettings(value);
    }
    
}
        public SettingsPool Set(string key, object value)
        {
            RawSettings = RawSettings.Set(new Iota(key, value));
            return this;
        }

        public bool HasSameSettingKeysAs(SettingsPool other)
        {
            return rawSettingsIDsHash == other.rawSettingsIDsHash;
        }

        protected virtual void UpdateRawSettings(Iota[] newValues)
        {
            rawSettings = newValues ?? new Iota[0];
            UpdateRawSettingsIDsHash();
        }

        private void UpdateRawSettingsIDsHash()
        {
            StringBuilder stringBuilder = new StringBuilder();
            foreach (Iota setting in rawSettings)
            {
                stringBuilder.Append(setting.ID);
            }
            rawSettingsIDsHash = stringBuilder.ToString();
        }

    
private string __Property__Initializer__ID=Guid.NewGuid().ToString();}
}