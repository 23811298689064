using H.Skeepy.Core.Operations.UseCases;

namespace H.Skeepy.Core.Branding
{
    public class BrandingStyle : ImAUseCaseBootstrapper<ColorPalette>, ImAUseCaseBootstrapper<Typography>
    {
        public const int DefaultSizingUnitInPixels = 15;

        public const string DayOfWeekFormat = "dddd";
        public const string TimeFormat = "HH:mm";
        public const string TimeStampFormatThisYear = "MMM dd 'at' HH:mm";
        public const string TimeStampFormat = "MMM dd, yyyy 'at' HH:mm";
        public const string TimeStampIdentifierFormat = "yyyyMMdd_HHmmss";

        public BrandingStyle()
        {
            colorPalette = ColorPalette.Default;
            typography = Typography.Default;
        }

        #region Construct
        private ColorPalette colorPalette;
        private Typography typography;
        public void Use(ColorPalette colorPalette)
        {
            this.colorPalette = colorPalette ?? ColorPalette.Default;
        }

        public void Use(Typography typography)
        {
            this.typography = typography ?? Typography.Default;
        }
#endregion
public ColorInfo BackgroundColor
{
    get
    {
        return colorPalette.Complementary.Lighter(10);
    }
}public ColorInfo DefaultTextColor
{
    get
    {
        return colorPalette.Complementary.Darker(10);
    }
}public ColorInfo MutedTextColor
{
    get
    {
        return colorPalette.Complementary.Lighter(2);
    }
}public ColorInfo HighlightTextColor
{
    get
    {
        return colorPalette.Primary.Color;
    }
}public ColorInfo SuccessColor
{
    get
    {
        return new ColorInfo("#00B945");
    }
}public ColorInfo WarningColor
{
    get
    {
        return new ColorInfo("#FF8700");
    }
}public ColorInfo DangerColor
{
    get
    {
        return new ColorInfo("#FF2C00");
    }
}public ColorPalette Colors
{
    get
    {
        return colorPalette;
    }
}public Typography Typography
{
    get
    {
        return typography;
    }
}
        public readonly int SizingUnitInPixels = 15;
    }
}
