using H.Skeepy.Core.Infrastructure;
using H.Skeepy.Core.Model;
using H.Skeepy.Core.Operations.Storage;
using Retyped;
using System;
using System.Linq;
using System.Threading.Tasks;

namespace H.Skeepy.Click.Web.UI.Components.LocalStorage
{
    public class PinGroupSettingsStorageService : SyncedStorageServiceBase<PinGroupSettings, Guid>, ImAPinGroupSettingsStorageService
    {
        #region Construct
        public override void ReferDependencies(ImADependencyPoolContainer dependencyPoolContainer)
        {
            base.ReferDependencies(dependencyPoolContainer);
            storageSyncer.Use((Func<Guid,Task<PinGroupSettings>>)Load);
        }
        #endregion

        public Task Delete(Guid id)
        {
            return base.Delete(storage.PinGroupSettings, id);
        }

        public Task<PinGroupSettings> Load(Guid id)
        {
            return base.Load(storage.PinGroupSettings, id);
        }

        public Task Save(PinGroupSettings pinGroupSettings)
        {
            return base.Save(storage.PinGroupSettings, pinGroupSettings);
        }

        protected override dexie.Dexie.Collection<object, object> ApplyFilter(object filter)
        {
            Guid[] filterRequest = filter.As<Guid[]>();

            dexie.Dexie.Collection<object, object> filteredCollection;

            if (filterRequest == null || !System.Linq.Enumerable.Any<Guid>(filterRequest))
                return storage.PinGroupSettings.toCollection();
            else
                filteredCollection = storage.PinGroupSettings.where("ID")
                    .anyOfIgnoreCase(System.Linq.Enumerable.Select<Guid,string>(filterRequest,(Func<Guid,string>)(x => x.ToString())).ToArray())
                    ;

            return filteredCollection;
        }
protected override Guid GetIdFor(PinGroupSettings item)
{
    return item.ID;
}    }
}
