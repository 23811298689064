using Bridge.React;
using H.Skeepy.Click.Web.UI.Components.Chromes;
using H.Skeepy.Click.Web.UI.Components.UI.Controls;
using H.Skeepy.Click.Web.UI.Components.UI.Layout;
using H.Skeepy.Click.Web.UI.Components.UI.Styles;
using H.Skeepy.Core.Operations.UI.ViewState.PinGroupViewStates;

namespace H.Skeepy.Click.Web.UI.Pages.PinGroupPages
{
    public class PinGroupOperationsPage : PageBase<PinGroupOperationsPage.Props, PinGroupOperationsViewState>
    {
public override string Title
{
    get
    {
        return "SKeepy Click!";
    }
}
        public PinGroupOperationsPage(Props props) : base(props, null) { }

        public override ReactElement Render()
        {
            return
                new DefaultChrome
                (
                    RenderNecessaire(),

                    new PaddedContent
                    (
                        new Title(global::Bridge.Script.ToTemp("key1",state.PinGroup)!=null?global::Bridge.Script.FromTemp<Core.Model.Display.PinGroupDisplay>("key1").Name:(string)null),

                        new FlexFormLayout
                        (
                            new Button(new Button.Props
                            {

                                Width = "100%",
                                Height = "100%",
                                OnClick = async () => await state.Click(),
                                IsDisabled = state.IsValidating,
                                StyleDecorator = style => style.With((System.Action<ReactStyle>)(x => { x.Flex = "1"; })),

                            }, DOM.Div("Click"), DOM.Div(state.Count.ToString()))
                        ),

                        new FormLayout(

                            new FormLayout.Props { LayoutMode = FormLayoutMode.OnePerRow },

                            new Button(new Button.Props
                            {

                                Width = "100%",
                                OnClick = async () => await state.Undo(),
                                IsDisabled = !state.CanUndo || state.IsValidating,

                            }, "Undo")
                        )

                    )

                )
                ;
        }

        public class Props : PagePropsBase
        {

        }
    }
}
