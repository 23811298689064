using Bridge.React;
using H.Skeepy.Click.Web.UI.Components.UI.Layout;

namespace H.Skeepy.Click.Web.UI.Components.UI.Elements
{
    public class BusyCurtain : ComponentBase<BusyCurtain.Props, BusyCurtain.State>
    {
        public BusyCurtain(Props props) : base(props, null) { }
        public BusyCurtain() : this(new Props { }) { }

        public override ReactElement Render()
        {
            return
                new Curtain(new Curtain.Props { StyleDecorator = x => { x.Cursor = Bridge.Html5.Cursor.Wait; return x; } },

                    new CenteredContent(

                        new BusyIndicator(new BusyIndicator.Props { Size = 50, Resolution = 10, AnimationSpeed = 8, Style = BusyIndicatorStyle.Random })

                    )

                );
        }

        public class State { }
        public class Props { }
    }
}
