using System;

namespace H.Skeepy.Core.Operations.Broadcasting.Concrete
{
    public class BroadcastEvent : ImABroadcastEvent
    {
        private BroadcastEvent()
        {
            HappenedAt = Rolex.Time;
        }
        public BroadcastEvent(object payload) : this()
        {
            Payload = payload;
            Type = payload.GetType();
        }

        public DateTime HappenedAt { get; private set; }

        public object Payload { get; private set; }

        public Type Type { get; private set; }

        public T Unwrap<T>()
        {
            return (T)Payload;
        }
    }

    public static class BroadcastEventExtensions
    {
public static BroadcastEvent ToBroadcastEvent(this object payload)
{
    return new BroadcastEvent(payload);
}    }

    public enum BroadcastEventCommand
    {
        OpenAbout = 0,
    }
}
