using H.Skeepy.Core.Infrastructure;
using H.Skeepy.Core.Model;
using H.Skeepy.Core.Operations.Storage;
using System.Threading.Tasks;

namespace H.Skeepy.Core.Operations.UseCases.Concrete
{
    public class SkeepyConsumerUseCase : ImASkeepyOperationContract, ImASkeepyConsumerUseCase
    {
        #region Construct
        ImADeviceInfoService deviceInfoService;
        ImASkeepyConsumerStorageService skeepyConsumerStorageService;
        ImADependencyPoolContainer dependencyPoolContainer;
        public void ReferDependencies(ImADependencyPoolContainer dependencyPoolContainer)
        {
            this.dependencyPoolContainer = dependencyPoolContainer;
            deviceInfoService = deviceInfoService ?? dependencyPoolContainer.Resolve<ImADeviceInfoService>();
            skeepyConsumerStorageService = skeepyConsumerStorageService ?? dependencyPoolContainer.Resolve<ImASkeepyConsumerStorageService>();
        }
        #endregion

        public async Task<SkeepyConsumer> CreateOrResurrect()
        {
            SkeepyConsumer device = (await skeepyConsumerStorageService.Search()) ?? new SkeepyConsumer();

            if (deviceInfoService != null)
            {
                device.RawProperties = await deviceInfoService.GetRawProperties();
                device.AsOf = Rolex.Time;
            }

            await skeepyConsumerStorageService.Save(device);

            dependencyPoolContainer.Register<SkeepyConsumer>((System.Func<object>)(() => device));

            return device;
        }

        public async Task<SkeepyConsumer> Resurrect()
        {
            SkeepyConsumer device = await skeepyConsumerStorageService.Search();

            if (deviceInfoService != null)
            {
                device.RawProperties = await deviceInfoService.GetRawProperties();
                device.AsOf = Rolex.Time;
            }

            dependencyPoolContainer.Register<SkeepyConsumer>((System.Func<object>)(() => device));

            return device;
        }
    }
}
