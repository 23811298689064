using Bridge;
using Bridge.React;
using H.Skeepy.Click.Web.UI.Components.UI.Styles;

namespace H.Skeepy.Click.Web.UI.Components.UI.Layout
{
    public class ScrollableContent : ComponentBase<ScrollableContent.Props, ScrollableContent.State>
    {
        public ScrollableContent(params Union<ReactElement, string>[] children) : base(new Props { }, children) { }

        public override ReactElement Render()
        {
            return
                DOM.Div(
                    new Attributes
                    {
                        Style = new ReactStyle().FlexNode().ScrollContent(),
                    },
                    Children
                );
        }

        public class State { }

        public class Props { }
    }
}
