using H.Skeepy.Click.Web.UI.Components;
using H.Skeepy.Click.Web.UI.Components.GPS;
using H.Skeepy.Click.Web.UI.Components.LocalStorage;
using H.Skeepy.Click.Web.UI.Components.Logging;
using H.Skeepy.Core.Common.ExecutionTamers;
using H.Skeepy.Core.Infrastructure;
using H.Skeepy.Core.Infrastructure.Concrete;
using H.Skeepy.Core.Infrastructure.Timing;
using H.Skeepy.Core.Infrastructure.Timing.Concrete;
using H.Skeepy.Core.Operations;
using H.Skeepy.Core.Operations.Broadcasting;
using H.Skeepy.Core.Operations.Caching;
using H.Skeepy.Core.Operations.Concrete;
using H.Skeepy.Core.Operations.Http;
using H.Skeepy.Core.Operations.Http.Concrete;
using H.Skeepy.Core.Operations.Storage;
using H.Skeepy.Core.Operations.Storage.Concrete;
using H.Skeepy.Core.Operations.Sync;
using H.Skeepy.Core.Operations.UI;
using H.Skeepy.Core.Operations.UseCases;
using System;
using System.Threading.Tasks;

namespace H.Skeepy.Core
{
    public class Wireup
    {
        readonly ImADependencyPoolContainer dependencyPoolContainer;

        static ImADependencyPoolContainer defaultDependencyPoolContainer;

        public Wireup(ImADependencyPoolContainer dependencyPoolContainer)
        {
            this.dependencyPoolContainer = dependencyPoolContainer;
            defaultDependencyPoolContainer = dependencyPoolContainer;
        }
public static T Get<T>()
{
    return defaultDependencyPoolContainer == null ? default(T) : defaultDependencyPoolContainer.Resolve<T>();
}
        public async Task<Wireup> Boot()
        {
            ImAStartupUseCase startupUseCase = dependencyPoolContainer.Resolve<ImAStartupUseCase>();

            if (startupUseCase != null)
            {
                await startupUseCase.Go();
            }

            return this;
        }

        public Wireup With(Action<ImADependencyPoolContainer> action)
        {
            action(dependencyPoolContainer);
            return this;
        }
public Wireup ForWebUI()
{
    return WithInfrastructureModule().WithCaching().WithBrowserServices().WithHttpOperations().WithSyncOperations().WithUIOperations().WithUseCaseOperations().WithBroadcastingOperations().WithLocalStorageStorage().WithStorageServicesGroup().With((Action<ImADependencyPoolContainer>)(x => x.Register<ImAVersionProviderService>((Func<object>)(() => new VersionProviderService())))).With((Action<ImADependencyPoolContainer>)(x => x.Register<ImADataZipper>((Func<object>)(() => new JSZipDataZipper())))).With((Action<ImADependencyPoolContainer>)(x => x.Register<FileDownloadDeliveryService>((Func<object>)(() => new FileDownloadDeliveryService())))).With((Action<ImADependencyPoolContainer>)(x => x.Register<ImAContentDeliveryService>((Func<object>)(() => new FileDownloadDeliveryService()))));
}
        private Wireup WithInfrastructureModule()
        {
            dependencyPoolContainer.Register<ImATimekeeper>((Func<object>)(() => new Timekeeper()));

            dependencyPoolContainer.RegisterAlwaysNew<ImADaemon>((Func<object>)(() => new WebWorkerDaemon()));
            dependencyPoolContainer.RegisterAlwaysNew<ImAPeriodicAction>((Func<object>)(() => new JsPeriodicAction()));
            dependencyPoolContainer.RegisterAlwaysNew<Debouncer>((Func<object>)(() => new Debouncer()));
            dependencyPoolContainer.RegisterAlwaysNew<Throttler>((Func<object>)(() => new Throttler()));

            return this;
        }

        private Wireup WithBrowserServices()
        {
            dependencyPoolContainer.Register<ImADeviceInfoService>((Func<object>)(() => new BrowserBasedDeviceInfoService()));
            dependencyPoolContainer.Register<ImALogService>((Func<object>)(() => new ConsoleLogService()));
            dependencyPoolContainer.Register<ImAGpsService>((Func<object>)(() => new GpsService()));

            return this;
        }

        private Wireup WithHttpOperations()
        {
            dependencyPoolContainer.Register<ImAnHttpClient>((Func<object>)(() => new JQueryAjaxHttpClient()));

            return this;
        }

        private Wireup WithLocalStorageStorage()
        {
            dependencyPoolContainer.Register<LocalStorageModule>((Func<object>)(() => new LocalStorageModule()));
            return this;
        }

        private Wireup WithStorageServicesGroup()
        {
            dependencyPoolContainer.Register<StorageServicesOperationContract>((Func<object>)(() => new StorageServicesOperationContract()));
            return this;
        }

        private Wireup WithSyncOperations()
        {
            dependencyPoolContainer.Register<SyncModule>((Func<object>)(() => new SyncModule()));
            return this;
        }

        private Wireup WithUIOperations()
        {
            dependencyPoolContainer.Register<UiModule>((Func<object>)(() => new UiModule()));
            return this;
        }

        private Wireup WithUseCaseOperations()
        {
            dependencyPoolContainer.Register<UseCasesModule>((Func<object>)(() => new UseCasesModule()));
            return this;
        }

        private Wireup WithBroadcastingOperations()
        {
            dependencyPoolContainer.Register<BroadcastingModule>((Func<object>)(() => new BroadcastingModule()));
            return this;
        }

        private Wireup WithCaching()
        {
            dependencyPoolContainer.Register<CachingModule>((Func<object>)(() => new CachingModule()));
            return this;
        }
    }
}
