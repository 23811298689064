using Bridge.Html5;
using Bridge.jQuery2;
using H.Skeepy.Core.Infrastructure;
using H.Skeepy.Core.Model;
using Newtonsoft.Json;
using System;
using System.Threading.Tasks;

namespace H.Skeepy.Core.Operations.Http.Concrete
{
    public class JQueryAjaxHttpClient : ImASkeepyOperationContract, ImAnHttpClient
    {
        #region Construct
        SkeepyConsumer skeepyConsumer;
        Func<SkeepyConsumer> skeepyConsumerFactory;
        public void ReferDependencies(ImADependencyPoolContainer dependencyPoolContainer)
        {
            skeepyConsumerFactory = () => dependencyPoolContainer.Resolve<SkeepyConsumer>();
        }

        SkeepyConsumer SkeepyConsumer { get { skeepyConsumer = skeepyConsumer ?? skeepyConsumerFactory(); return skeepyConsumer; } }
        #endregion

        public Task<HttpResponse> Delete(string url, object payload = null)
        {
            return DoRequest("DELETE", url, payload);
        }

        public Task<HttpResponse<T>> DeleteJson<T>(string url, object payload = null)
        {
            return DoRequest<T>("DELETE", url, payload);
        }

        public Task<HttpResponse> Get(string url)
        {
            return DoRequest("GET", url);
        }

        public Task<HttpResponse<T>> GetJson<T>(string url)
        {
            return DoRequest<T>("GET", url);
        }

        public Task<HttpResponse> Post(string url, object payload = null)
        {
            return DoRequest("POST", url, payload);
        }

        public Task<HttpResponse<T>> PostJson<T>(string url, object payload = null)
        {
            return DoRequest<T>("POST", url, payload);
        }

        public Task<HttpResponse> Put(string url, object payload = null)
        {
            return DoRequest("PUT", url, payload);
        }

        private Task<HttpResponse> DoRequest(string method, string url, object payload = null, string mimeType = "application/json")
        {
            var tcs = new TaskCompletionSource<HttpResponse>();

            AjaxOptions options = GetAjaxOptions(url, mimeType);
            options.Type = method;
            options.Data = JsonConvert.SerializeObject(payload);

            jqXHR request = jQuery.Ajax(url, options);

            Action<object, string, jqXHR> done = (data, status, xhr) =>
            {
                tcs.SetResult(ParseResultSuccess(xhr));
            };

            Action<jqXHR, string, string> fail = (xhr, status, error) =>
            {
                tcs.SetResult(ParseResultFailure(xhr, error));
            };

            Task<HttpResponse> task = Task.FromPromise<HttpResponse>(request, done, fail);

            return tcs.Task;
        }

        private Task<HttpResponse<T>> DoRequest<T>(string method, string url, object payload = null, string mimeType = "application/json")
        {
            var tcs = new TaskCompletionSource<HttpResponse<T>>();

            AjaxOptions options = GetAjaxOptions(url, mimeType);
            options.Type = method;
            options.Data = JsonConvert.SerializeObject(payload);

            jqXHR request = jQuery.Ajax(url, options);

            Action<object, string, jqXHR> done = (data, status, xhr) =>
            {
                tcs.SetResult(ParseResultSuccess<T>(xhr));
            };

            Action<jqXHR, string, string> fail = (xhr, status, error) =>
            {
                tcs.SetResult(ParseResultFailure<T>(xhr, error));
            };

            Task<HttpResponse<T>> task = Task.FromPromise<HttpResponse<T>>(request, done, fail);

            return tcs.Task;
        }

        private static HttpResponse<T> ParseResultSuccess<T>(jqXHR xhr)
        {
            return new HttpResponse<T>(ParseAjaxPayload<T>(xhr), xhr.Status) { Content = global::Bridge.Script.ToTemp("key1",xhr["responseText"])!=null?global::Bridge.Script.FromTemp<object>("key1").ToString():(string)null };
        }

        private static HttpResponse<T> ParseResultFailure<T>(jqXHR xhr, string error)
        {
            if (xhr.Status == 401) //Unauthorised
            {
                error = "Access Denied";
                return new HttpResponse<T>(default(T), xhr.Status, error) { IsUnauthorized = true };
            }
            else if (xhr.Status == 403) //Forbidden
            {
                dynamic payload = ParseAjaxPayload<dynamic>(xhr);

                if (payload != null && payload.Code == 401)
                    return new HttpResponse<T>(default(T), xhr.Status, "Access Denied");

                if (payload != null && !string.IsNullOrEmpty(payload.Location))
                    Window.Location.Href = payload.Location;

                return new HttpResponse<T>(default(T), xhr.Status, error) { SessionExpired = true };
            }

            string parsedError = ParseAjaxPayload<string>(xhr);
            if (!string.IsNullOrEmpty(parsedError))
                return new HttpResponse<T>(default(T), xhr.Status, parsedError);

            return new HttpResponse<T>(ParseAjaxPayload<T>(xhr), xhr.Status, error);
        }

        private static HttpResponse ParseResultSuccess(jqXHR xhr)
        {
            return new HttpResponse(xhr.Status) { Content = global::Bridge.Script.ToTemp("key2",xhr["responseText"])!=null?global::Bridge.Script.FromTemp<object>("key2").ToString():(string)null };
        }

        private static HttpResponse ParseResultFailure(jqXHR xhr, string error)
        {
            if (xhr.Status == 401) //Unauthorised
            {
                error = "Access Denied";
                return new HttpResponse(xhr.Status, error) { IsUnauthorized = true };
            }
            else if (xhr.Status == 403) //Forbidden
            {
                dynamic response = xhr.Response;

                if (response != null && !string.IsNullOrEmpty(response.Code))
                    return new HttpResponse(xhr.Status, "Access Denied") { SessionExpired = true };

                if (response != null && !string.IsNullOrEmpty(response.Location))
                    Window.Location.Href = response.Location;

                return new HttpResponse(xhr.Status, error) { SessionExpired = true };
            }

            string parsedError = ParseAjaxPayload<string>(xhr);
            if (!string.IsNullOrEmpty(parsedError))
                error = parsedError;

            return new HttpResponse(xhr.Status, error);
        }

        private static T ParseAjaxPayload<T>(jqXHR xhr)
        {
            try
            {
                string response = global::Bridge.Script.ToTemp("key3",xhr["responseText"])!=null?global::Bridge.Script.FromTemp<object>("key3").ToString():(string)null;

                if (string.IsNullOrEmpty(response))
                    return default(T);

                bool isJson = xhr["responseJSON"] != null;

                if (!isJson)
                    response = string.Format("\"{0}\"",response);

                //return JSON.Parse<T>(RemoveTypeHandling(response), (prop, val) =>
                //{
                //    if (val != null && isoDateRegEx.Test(val.ToString()))
                //    {
                //        object boxedDate = DateTime.Parse((string)val.ValueOf());
                //        return boxedDate.ToDynamic().v;
                //    }
                //    return val;
                //});


                // The Bridge version of Json.NET cannot deserialize nullable Guid
                if (typeof(T) == typeof(Guid?))
                {
                    Guid result;
                    if (!Guid.TryParse(response.Trim('"'), out result))
                        return default(T);

                    return (dynamic)result;
                }

                return JsonConvert.DeserializeObject<T>(RemoveTypeHandling(response));
            }
            catch (Exception)
            {
                return default(T);
            }
        }

        //"\$type"\s*:\s*"[a-zA-Z0-9_]+"
        private static readonly RegExp typeHandlerRegEx = new RegExp("\"\\$type\"\\s*:\\s*\"[a-zA-Z0-9_]+\"\\,?", "gi");
        private static string RemoveTypeHandling(string jsonString)
        {
            return jsonString.Replace(typeHandlerRegEx, string.Empty);
        }

        private AjaxOptions GetAjaxOptions(string url, string mimeType = "application/json")
        {
            AjaxOptions options = new AjaxOptions();
            options.Cache = false;
            options.ContentType = mimeType + "; charset=utf-8";
            options.DataType = "json";
            options.Headers = GetHeaders();
            options.Url = FormatUrl(url);
            return options;
        }

        private string FormatUrl(string url)
        {
            if (string.IsNullOrEmpty(url))
                return url;

            if (!url.StartsWith("/"))
                url = "/" + url;

            return url;
        }

        private object GetHeaders()
        {
            //HTMLInputElement input = (HTMLInputElement)Document.GetElementsByName("__RequestVerificationToken")[0];

            //string timeZoneIntl = null;

            //if (Window.ToDynamic().Intl != null)
            //    timeZoneIntl = Window.ToDynamic().Intl.DateTimeFormat().resolvedOptions().timeZone;

            object headers = new object();

            headers["__TimeZone"] = new Date().GetTimezoneOffset().ToString();
            headers["__ClientCulture"] = Window.Navigator.Language;
            if (SkeepyConsumer != null)
                headers[OperationRequestContext.SkeepyConsumerIDKey] = SkeepyConsumer.ID.ToString();

            return headers;
        }
    }
}
