namespace H.Skeepy.Click.Web.UI.Components.Navigation
{
    public static class AppNavigationExtensions
    {
        public static bool IsHomePath(this string path)
        {
            path = path!=null?path.Trim():(string)null;

            return
                string.IsNullOrWhiteSpace(path)
                || path == "#"
                || path == "#/"
                || path == "/"
                || path == "/#"
                || path == "/#/"
                ;
        }
    }

    public static class Navi
    {
        public static void Go(params object[] pathParts)
        {
            string url = "/" + string.Join("/", pathParts);
            Bridge.Html5.Window.Location.Hash = url;
        }
    }
}
