namespace H.Skeepy.Core.Model.Display.Health
{
    public class StorageIdentifier
    {
        public string Name { get; set; }
        public StorageType Type { get; set; }
        public string TypeNotes { get; set; }

        public bool Equals(StorageIdentifier other)
        {
            if (other == null)
                return false;

            return
                Name == other.Name
                && Type == other.Type
                ;
        }

        public override string ToString()
        {
            return string.Format("{0}",Name);
        }

    
private StorageType __Property__Initializer__Type=StorageType.Unknown;}
}
