using Bridge;
using Bridge.React;
using H.Skeepy.Click.Web.UI.Components.Chromes;
using H.Skeepy.Click.Web.UI.Components.UI.Controls;
using H.Skeepy.Click.Web.UI.Components.UI.Controls.UserFeedback;
using H.Skeepy.Click.Web.UI.Components.UI.Elements;
using H.Skeepy.Click.Web.UI.Components.UI.Layout;
using H.Skeepy.Click.Web.UI.Components.UI.Styles;
using H.Skeepy.Core.Model.Display;
using H.Skeepy.Core.Operations.UI.UserInput;
using H.Skeepy.Core.Operations.UI.ViewState;
using System;
using System.Linq;

namespace H.Skeepy.Click.Web.UI.Pages
{
    public class WelcomePage : PageBase<WelcomePage.Props, WelcomeViewState>
    {
public override string Title
{
    get
    {
        return "SKeepy Click!";
    }
}
        public WelcomePage() : base(new Props { }, null) { }

        public override ReactElement Render()
        {
            bool isAnyActiveGroupAvailable = (global::Bridge.Script.ToTemp("key1",state)!=null&&global::Bridge.Script.ToTemp("key2",global::Bridge.Script.FromTemp<WelcomeViewState>("key1").PinGroupsStillActive)!=null?global::Bridge.Script.FromTemp<PinGroupDisplay[]>("key2").Any():(bool?)null) ?? false;
            bool isAnySealedGroupAvailable = (global::Bridge.Script.ToTemp("key3",state)!=null&&global::Bridge.Script.ToTemp("key4",global::Bridge.Script.FromTemp<WelcomeViewState>("key3").PinGroupsSealed)!=null?global::Bridge.Script.FromTemp<PinGroupDisplay[]>("key4").Any():(bool?)null) ?? false;

            return
                new DefaultChrome
                (
                    RenderNecessaire(),

                    new PaddedContent
                    (
                        new Title("Welcome!"),

                        isAnyActiveGroupAvailable ? new Subtitle("What do you want to do next?") : null,

                        new FormLayout
                        (
                            new FormLayout.Props { LayoutMode = FormLayoutMode.OnePerRow },

                            new Button(new Button.Props
                            {

                                Width = "100%",
                                OnClick = state.TriggerStartNewClickSession,

                            }, "Start a new Click! session")
                        ),

                        isAnyActiveGroupAvailable ? new Subtitle("...or resume clicking:") : null,

                        isAnyActiveGroupAvailable ? RenderActivePinGroups() : null,

                        isAnySealedGroupAvailable ? new Subtitle("...or review an old one:") : null,

                        isAnySealedGroupAvailable ? RenderSealedPinGroups() : null
                    )

                )
                ;
        }

        private ReactElement RenderActivePinGroups()
        {
            if (state.IsLoadingPinGroups)
            {
                return
                    DOM.Div
                    (
                        new Attributes { },
                        new BusyIndicator()
                    );
            }

            return
                new FormLayout
                (
                    new FormLayout.Props { LayoutMode = FormLayoutMode.OnePerRow },
System.Linq.Enumerable.Select<PinGroupDisplay,Union<ReactElement,string>>(
                    state.PinGroupsStillActive,(Func<PinGroupDisplay,Union<ReactElement,string>>)RenderPinGroup).ToArray()

                );
        }

        private ReactElement RenderSealedPinGroups()
        {
            if (state.IsLoadingPinGroups)
            {
                return
                    DOM.Div
                    (
                        new Attributes { },
                        new BusyIndicator()
                    );
            }

            return
                new FormLayout
                (
                    new FormLayout.Props { LayoutMode = FormLayoutMode.OnePerRow },
System.Linq.Enumerable.Select<PinGroupDisplay,Union<ReactElement,string>>(
                    state.PinGroupsSealed,(Func<PinGroupDisplay,Union<ReactElement,string>>)RenderPinGroup).ToArray()

                );
        }

        private Union<ReactElement, string> RenderPinGroup(PinGroupDisplay pinGroup)
        {
            return
                new ListItem(new ListItem.Props
                {
                    Title = pinGroup.Name,
                    Payload = pinGroup,
                    Width = "100%",
                    OnClick = x => TriggerViewPinGroupOperations(x.As<PinGroupDisplay>()),
                    OnMoreClick = x => TrigggerPinGroupOptions(x.As<PinGroupDisplay>()),
                }, RenderPinGroupDetails(pinGroup));
        }

        private async void TrigggerPinGroupOptions(PinGroupDisplay x)
        {
            await AskUserForSingleAnswer((Func<UserOptionsContext,ReactElement>)(ctx => RenderPinGroupOptions(ctx, x)), null, null,
                new UserOption { Payload = x, Label = "View", OnClick = z => TriggerViewPinGroupOperations(z.As<PinGroupDisplay>()) },
                x.IsSealed ? null : new UserOption { Payload = x, Label = "Resume", OnClick = z => TriggerResumePinGroupOperations(z.As<PinGroupDisplay>()) },
                x.IsSealed ? null : new UserOption { Payload = x, Label = "Seal", OnClick = z => TriggerSealPinGroup(z.As<PinGroupDisplay>()) },
                new UserOption { Payload = x, Label = "Delete", OnClick = z => TriggerDeletePinGroup(z.As<PinGroupDisplay>()) }
            );
        }

        private ReactElement RenderPinGroupOptions(UserOptionsContext context, PinGroupDisplay pinGroup)
        {
            return
                new ActionSheet(new ActionSheet.Props
                {
                    UserOptionsContext = context,
                    Header = new ListItem(new ListItem.Props
                    {
                        Title = pinGroup.Name,
                        Width = "100%",
                    }, RenderPinGroupDetails(pinGroup))
                });
        }

        private ReactElement RenderPinGroupDetails(PinGroupDisplay pinGroup)
        {
            return
                DOM.Div
                (
                    new Attributes { Style = new ReactStyle().FlexNode(), },

                    DOM.Div(
                        new Attributes { Style = new ReactStyle { Flex = "3" }.FlexNode(isVerticalFlow: true) },
                        DOM.Div(
                            new Attributes { Style = new ReactStyle { JustifyContent = Bridge.Html5.JustifyContent.Center, FontSize = style.Typography.FontSizeSmaller.PointsCss, Opacity = "0.8" }.FlexNode(isVerticalFlow: true) },
                            pinGroup.PrintBriefDescription()
                        )
                        ,
                        DOM.Div(
                            new Attributes { Style = new ReactStyle { JustifyContent = Bridge.Html5.JustifyContent.Center, FontSize = style.Typography.FontSizeSmall.PointsCss }.FlexNode(isVerticalFlow: true) },
string.Format("Created {0}",pinGroup.PrintCreatedAt())                        )
                    )
                    ,
                    DOM.Div(
                        new Attributes { Style = new ReactStyle { JustifyContent = Bridge.Html5.JustifyContent.Center, PaddingRight = style.SizingUnitInPixels }.FlexNode(isVerticalFlow: true) },
                        new Subtitle(new Subtitle.Props { TextAlign = Bridge.Html5.TextAlign.Right },
                            pinGroup.PrintCount()
                        )
                    )

                );
        }

        private async void TriggerDeletePinGroup(PinGroupDisplay x)
        {
            if (!(await Confirm(string.Format("Are you sure you want to delete Session {0}?",x.Name), string.Format("<strong>{0}</strong>, created {1}",x.PrintCount(),x.PrintCreatedAt()))))
            {
                TrigggerPinGroupOptions(x);
                return;
            }

            await state.DeleteClickSession(x.ID);
        }

        private async void TriggerSealPinGroup(PinGroupDisplay x)
        {
            if (!(await Confirm(string.Format("Are you sure you want to seal Session {0}?",x.Name), string.Format("<strong>{0}</strong>, created {1}",x.PrintCount(),x.PrintCreatedAt()))))
            {
                TrigggerPinGroupOptions(x);
                return;
            }

            await state.SealClickSession(x.ID);
        }

        private void TriggerResumePinGroupOperations(PinGroupDisplay pinGroupDisplay)
        {
            state.TriggerOpenClickSession(pinGroupDisplay.ID);
        }

        private void TriggerViewPinGroupOperations(PinGroupDisplay pinGroupDisplay)
        {
            state.TriggerViewClickSession(pinGroupDisplay.ID);
        }

        public class Props : PagePropsBase { }
    }
}
