using H.Skeepy.Core.Common;
using H.Skeepy.Core.Infrastructure;
using H.Skeepy.Core.Infrastructure.Timing;
using H.Skeepy.Core.Model;
using H.Skeepy.Core.Model.Display;
using H.Skeepy.Core.Operations.UI.Abstract;
using H.Skeepy.Core.Operations.UI.Navigation;
using H.Skeepy.Core.Operations.UseCases;
using System;
using System.Linq;
using System.Threading.Tasks;

namespace H.Skeepy.Core.Operations.UI.ViewState.PinGroupViewStates
{
    public class PinGroupDetailedDisplayViewState : ViewStateBase
    {
        #region Construct

#if DEBUG
        readonly TimeSpan refreshInterval = TimeSpan.FromSeconds(5);
#else
        readonly TimeSpan refreshInterval = TimeSpan.FromSeconds(60);
#endif
        DataNormalizer pinIntervalToPixelsNormalizer = new DataNormalizer(new NumberInterval(0, 0), new NumberInterval(0, 0));

        ImAPeriodicAction periodicAction;
        ImAPinGroupViewerUseCase pinGroupViewerUseCase;
        public override void ReferDependencies(ImADependencyPoolContainer dependencyPoolContainer)
        {
            base.ReferDependencies(dependencyPoolContainer);

            pinGroupViewerUseCase = pinGroupViewerUseCase ?? dependencyPoolContainer.Resolve<ImAPinGroupViewerUseCase>();
            periodicAction = periodicAction ?? dependencyPoolContainer.Resolve<ImAPeriodicAction>();
        }

        Guid pinGroupID = Guid.Empty;
        public override void Use(UiNavigationParams uiNavigationParams)
        {
            base.Use(uiNavigationParams);

            pinGroupID = uiNavigationParams.GetValue<Guid>();
        }
        #endregion

        #region Properties
        DetailedPinGroupDisplay pinGroupDetails = null;
        public DetailedPinGroupDisplay PinGroupDetails
        {
            get { return pinGroupDetails; }
            set
            {
                pinGroupDetails = value;
                RebuildPinIntervalToPixelsNormalizer();
                CanResume = !pinGroupDetails.IsSealed;
                viewUpdater!=null?global::Bridge.Script.FromLambda(()=>viewUpdater.Invoke("PinGroupDetails")):null;
            }
        }

        bool canResume = false;
        public bool CanResume
        {
            get { return canResume; }
            set
            {
                canResume = value;
                viewUpdater!=null?global::Bridge.Script.FromLambda(()=>viewUpdater.Invoke("CanResume")):null;
            }
        }

        bool isAllPinsMapVisible = false;
        public bool IsAllPinsPinMapVisible
        {
            get { return isAllPinsMapVisible; }
            set
            {
                isAllPinsMapVisible = value;
                viewUpdater!=null?global::Bridge.Script.FromLambda(()=>viewUpdater.Invoke("IsAllPinsPinMapVisible")):null;
            }
        }

        int pinMapIndexToDisplay = -1;
        public int PinMapIndexToDisplay
        {
            get { return pinMapIndexToDisplay; }
            set
            {
                pinMapIndexToDisplay = value;
                viewUpdater!=null?global::Bridge.Script.FromLambda(()=>viewUpdater.Invoke("PinMapIndexToDisplay")):null;
            }
        }
        #endregion

        #region Operations
        public override async Task Initialize()
        {
            using (BusyIndicator())
            {
                await base.Initialize();

                await
                    new Func<Task>(async () =>
                    {
                        await RefreshPinGroupDetails();
                        periodicAction.StartDelayed(refreshInterval, refreshInterval, (Func<Task>)RefreshPinGroupDetails);
                    })
                    .TryOrFailWithGrace(
                        numberOfTimes: 1,
onFail:(Action<Exception>)(                        x =>
                        {
                            PinGroupDetails = null;
                            InitializeResult.FailBecause(System.Linq.Enumerable.Select<Exception,string>(x.Flatten(),(Func<Exception,string>)(ex => ex.Print())).ToArray());
                        }
)                        );

                if (PinGroupDetails == null && InitializeResult.IsValid)
                {
                    InitializeResult.FailBecause(string.Format("PinGroup with ID {0} does not exist",pinGroupID));
                }
            }
        }

        public override async Task Leaving()
        {
            periodicAction.Stop();

            await base.Leaving();
        }

        public void GoHome()
        {
            uiNavigator.Go<WelcomeViewState>();
        }

        public void ResumePinGroupOperations()
        {
            uiNavigator.Go<PinGroupOperationsViewState>(new UiNavigationParams(pinGroupID));
        }

        public void ToggleAllPinsMap()
        {
            if (PinMapIndexToDisplay != -1) PinMapIndexToDisplay = -1;

            IsAllPinsPinMapVisible = !IsAllPinsPinMapVisible;
        }

        public void TooglePinMap(PinDisplay pin)
        {
            if (IsAllPinsPinMapVisible) IsAllPinsPinMapVisible = false;

            PinMapIndexToDisplay = pin.Index == PinMapIndexToDisplay ? -1 : pin.Index;
        }

        public int TranslatePinIntervalToPixels(TimeSpan pinInterval)
        {
            return (int)pinIntervalToPixelsNormalizer.Do(pinInterval.TotalSeconds);
        }

        private async Task RefreshPinGroupDetails()
        {
            PinGroupDetails = await pinGroupViewerUseCase.Load(pinGroupID);
        }

        private void RebuildPinIntervalToPixelsNormalizer()
        {
            if (pinGroupDetails.MaximumPinInterval == TimeSpan.Zero)
            {
                pinIntervalToPixelsNormalizer = new DataNormalizer(new NumberInterval(0, 0), new NumberInterval(0, 0));
                return;
            }

            double minPixels = 25;
            TimeSpan maxInterval = pinGroupDetails.MaximumPinInterval;
            double maxPixels =
                pinGroupDetails.MaximumPinInterval < H.Skeepy.Core.Common.DataExtensions.Set<TimeSpan>(TimeSpan.FromMinutes(5),(Action<TimeSpan>)(x => maxInterval = x))
                ? 180
                : pinGroupDetails.MaximumPinInterval < H.Skeepy.Core.Common.DataExtensions.Set<TimeSpan>(TimeSpan.FromMinutes(15),(Action<TimeSpan>)(x => maxInterval = x))
                ? 200
                : pinGroupDetails.MaximumPinInterval < H.Skeepy.Core.Common.DataExtensions.Set<TimeSpan>(TimeSpan.FromMinutes(30),(Action<TimeSpan>)(x => maxInterval = x))
                ? 220
                : pinGroupDetails.MaximumPinInterval < H.Skeepy.Core.Common.DataExtensions.Set<TimeSpan>(TimeSpan.FromHours(1),(Action<TimeSpan>)(x => maxInterval = x))
                ? 240
                : pinGroupDetails.MaximumPinInterval < H.Skeepy.Core.Common.DataExtensions.Set<TimeSpan>(TimeSpan.FromHours(2),(Action<TimeSpan>)(x => maxInterval = x))
                ? 250
                : pinGroupDetails.MaximumPinInterval < H.Skeepy.Core.Common.DataExtensions.Set<TimeSpan>(TimeSpan.FromHours(8),(Action<TimeSpan>)(x => maxInterval = x))
                ? 270
                : pinGroupDetails.MaximumPinInterval < H.Skeepy.Core.Common.DataExtensions.Set<TimeSpan>(TimeSpan.FromHours(16),(Action<TimeSpan>)(x => maxInterval = x))
                ? 300
                : H.Skeepy.Core.Common.DataExtensions.Set<int>(330,(Action<int>)(x => maxInterval = pinGroupDetails.MaximumPinInterval))
                ;

            pinIntervalToPixelsNormalizer = new DataNormalizer(
                    new NumberInterval(pinGroupDetails.MinimumPinInterval.TotalSeconds, maxInterval.TotalSeconds),
                    new NumberInterval(minPixels, maxPixels)
                );
        }
        #endregion
    }
}
