using H.Skeepy.Core.Infrastructure;

namespace H.Skeepy.Core.Operations.UI.ViewState.PinGroupViewStates
{
    public class PinGroupViewStateModule : ImADependencyModule
    {
        public void RegisterDependencies(ImADependencyPoolContainer dependencyPoolContainer)
        {
            dependencyPoolContainer.RegisterAlwaysNew<DefineNewPinGroupViewState>((System.Func<object>)(() => new DefineNewPinGroupViewState()));
            dependencyPoolContainer.RegisterAlwaysNew<PinGroupOperationsViewState>((System.Func<object>)(() => new PinGroupOperationsViewState()));
            dependencyPoolContainer.RegisterAlwaysNew<PinGroupDetailedDisplayViewState>((System.Func<object>)(() => new PinGroupDetailedDisplayViewState()));
        }
    }
}
